import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalHeader, ModalBody, ModalFooter, Button, Grid } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import moment from 'moment';
import get from 'lodash/get';

import DatePicker from '../../../components/DatePicker/DatePicker';
import Input from '../../../components/Input';

const SearchModal = ({ isOpen, onClose, state, dispatch }) => {
  const findValue = (key) => {
    const whereClone = { ...state.where };
    const filtersClone = [...state.filters];
    const object = filtersClone.find((filter) => filter.key === key);
    if (object) {
      if (key === 'createdAt_lte' || key === 'createdAt_gte') return moment(get(whereClone, key)).toDate();
      return object.value;
    }
    return '';
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>User suchen</ModalHeader>

        <Formik
          initialValues={{
            email: findValue('user.email_contains'),
            firstName: findValue('user.firstName_contains'),
            lastName: findValue('user.lastName_contains'),
            street: findValue('address.street_contains'),
            houseNumber: findValue('address.houseNumber_contains'),
            postalCode: findValue('address.postalCode_contains'),
            city: findValue('address.city_contains'),
            createdAt_gte: findValue('createdAt_gte'),
            createdAt_lte: findValue('createdAt_lte'),
          }}
          onSubmit={async (values) => {
            const { email, firstName, lastName, street, houseNumber, postalCode, city, createdAt_gte, createdAt_lte } = values;

            let where = {
              isActive: true,
            } as any;

            let filters = [];

            if (createdAt_gte) {
              where = {
                ...where,
                createdAt_gte: moment(createdAt_gte).add(moment(createdAt_gte).utcOffset(), 'minutes').toISOString(),
              };
              filters = [...filters, { value: moment(createdAt_gte).format('DD.MM.YYYY'), label: 'Erstellt von', key: 'createdAt_gte' }];
              console.log(where, filters);
            }
            if (createdAt_lte) {
              where = {
                ...where,
                createdAt_lte: moment(createdAt_lte).endOf('day').add(moment(createdAt_lte).utcOffset(), 'minutes').toISOString(),
              };
              filters = [...filters, { value: moment(createdAt_lte).format('DD.MM.YYYY'), label: 'Erstellt bis', key: 'createdAt_lte' }];
            }

            if (email) {
              where = {
                ...where,
                user: {
                  ...where.user,
                  email_contains: email,
                },
              };
              filters = [...filters, { value: email, label: 'E-Mail', key: 'user.email_contains' }];
            }
            if (firstName) {
              where = {
                ...where,
                user: {
                  ...where.user,
                  firstName_contains: firstName,
                },
              };
              filters = [...filters, { value: firstName, label: 'Vorname', key: 'user.firstName_contains' }];
            }
            if (lastName) {
              where = {
                ...where,
                user: {
                  ...where.user,
                  lastName_contains: lastName,
                },
              };
              filters = [...filters, { value: lastName, label: 'Nachname', key: 'user.lastName_contains' }];
            }
            if (street) {
              where = {
                ...where,
                address: {
                  ...where.address,
                  street_contains: street,
                },
              };
              filters = [...filters, { value: street, label: 'Straße', key: 'address.street_contains' }];
            }
            if (houseNumber) {
              where = {
                ...where,
                address: {
                  ...where.address,
                  houseNumber_contains: houseNumber,
                },
              };
              filters = [...filters, { value: houseNumber, label: 'Hausnummer', key: 'address.houseNumber_contains' }];
            }
            if (postalCode) {
              where = {
                ...where,
                address: {
                  ...where.address,
                  postalCode_contains: postalCode,
                },
              };
              filters = [...filters, { value: postalCode, label: 'PLZ', key: 'address.postalCode_contains' }];
            }
            if (city) {
              where = {
                ...where,
                address: {
                  ...where.address,
                  city_contains: city,
                },
              };
              filters = [...filters, { value: city, label: 'Stadt', key: 'address.city_contains' }];
            }

            dispatch({ type: 'SET_FILTERS', payload: filters });
            dispatch({ type: 'SET_WHERE', payload: where });
            onClose();
          }}>
          {() => (
            <Form>
              <ModalBody>
                <Input name="email" label="Email-Adresse" />
                <Grid gridTemplateColumns="1fr 1fr" gridGap="24px">
                  <Input name="firstName" label="Vorname" />
                  <Input name="lastName" label="Nachname" />
                </Grid>

                <Grid gridTemplateColumns="2fr 1fr" gridGap="24px">
                  <Input name="street" label="Straße" />
                  <Input name="houseNumber" label="Hausnummer" />
                </Grid>
                <Grid gridTemplateColumns="1fr 1fr" gridGap="24px">
                  <Input name="postalCode" label="PLZ" />
                  <Input name="city" label="Stadt" />
                </Grid>

                <Grid gridTemplateColumns="1fr 1fr" gridGap="24px">
                  <DatePicker name="createdAt_gte" label="Erstellt vom" />
                  <DatePicker name="createdAt_lte" label="bis" />
                </Grid>
              </ModalBody>

              <ModalFooter>
                <Button variant="ghost" size="sm" colorScheme="red" type="button" onClick={onClose}>
                  Schließen
                </Button>
                <Button ml="4" size="sm" colorScheme="brand" type="submit">
                  Speichern
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};

export default SearchModal;
