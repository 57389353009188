import { Button } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/hooks';

import NewHistoryEntry from '../modals/NewHistoryEntry';

export const AddNewEntry = ({ userId, refetch, isOpen, onOpen, onClose }) => {
  return (
    <>
      <Button variant="outline" colorScheme="brand" size="xs" mr="2" mb="2" onClick={onOpen}>
        History Eintrag
      </Button>

      <NewHistoryEntry isOpen={isOpen} onClose={onClose} refetch={refetch} userId={userId} />
    </>
  );
};
