import React from 'react';
import { useField } from 'formik';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  NumberInput as ChakraInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  InputRightAddon,
} from '@chakra-ui/react';

const NumberInput = ({ label, ...props }) => {
  // @ts-ignore
  const [field, meta, helpers] = useField(props);
  const handleChange = (value) => {
    helpers.setTouched(true, true);
    helpers.setValue(value && value >= 0 ? parseFloat(value) : 0);
  };

  return (
    <FormControl mb="4" isInvalid={!!(meta.touched && meta.error)}>
      <FormLabel htmlFor={field.name}>{label}</FormLabel>
      <ChakraInput
        value={field.value}
        id={field.name}
        {...props}
        variant="filled"
        size="md"
        errorBorderColor="red.300"
        onChange={handleChange}>
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </ChakraInput>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};

export default NumberInput;
