import React from 'react';
import { Button } from '@chakra-ui/react';
import { IoIosPin, IoMdClose } from 'react-icons/io';

const AdvertisementActiveFilter = ({ state, dispatch, refetch }) => {
  const handleShowActiveAdvertisements = async () => {
    await refetch({
      where: {
        ...state.searchValues,
        advertisements_some: {
          isActive: true,
        },
      },
    });
    dispatch({ type: 'SET_ADVERTISEMENT_IS_ACTIVE', value: true });
  };
  const handleHideActiveAdvertisements = async () => {
    await refetch({
      where: {
        ...state.searchValues,
        advertisements_some: state.advertisementIsInactive
          ? {
              isActive: state.advertisementIsInactive ? false : undefined,
            }
          : undefined,
      },
    });
    dispatch({ type: 'SET_ADVERTISEMENT_IS_ACTIVE', value: false });
  };

  return (
    <Button
      leftIcon={state.advertisementIsActive ? <IoMdClose /> : <IoIosPin />}
      onClick={state.advertisementIsActive ? handleHideActiveAdvertisements : handleShowActiveAdvertisements}
      size="sm"
      mb="2"
      mr="2">
      Ad Aktiv
    </Button>
  );
};

export default AdvertisementActiveFilter;
