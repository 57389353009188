import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useQuery } from '@apollo/client';
import { Spinner, Flex, Text, Grid, Button, useDisclosure, useColorMode } from '@chakra-ui/react';

import Layout from '../../components/Layout';
import { ADVERTISEMENTS } from './query';
import AdvertisementCard from './AdvertisementCard';
import CreateAdvertisementModal from './CreateAdvertisement';
import EditAdvertisementModal from './EditAdvertisement';

interface AdvertisementProps extends RouteComponentProps {}

const AdvertisementPage: React.FC<AdvertisementProps> = () => {
  const [advertisement, setAdvertisement] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenEdit, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const { colorMode } = useColorMode();
  const { loading, error, data, fetchMore, refetch } = useQuery(ADVERTISEMENTS, {
    variables: {
      orderBy: 'createdAt_DESC',
      first: 50,
      skip: 0,
    },
    fetchPolicy: 'network-only',
  });

  const handleEditClose = () => {
    setAdvertisement(null);
    onEditClose();
  };

  return (
    <Layout>
      <Flex alignItems="center" mb="8" flexDirection={['column', 'row']}>
        <Text
          as="h2"
          fontSize="4xl"
          color={colorMode === 'light' ? 'gray.900' : 'gray.100'}
          fontWeight="bold"
          flex="1"
          fontFamily="heading">
          Werbeverwaltung
        </Text>
        <Button colorScheme="brand" size="sm" onClick={onOpen}>
          Neue Werbung
        </Button>
      </Flex>
      {loading ? (
        <Flex width="100%" height="100%" justifyContent="center" alignItems="center" py="4">
          <Spinner />
        </Flex>
      ) : null}
      {error ? (
        <Flex width="100%" height="100%" justifyContent="center" alignItems="center" py="4">
          Es ist ein Fehler aufgetreten. Osamah kontaktieren.
        </Flex>
      ) : null}
      {data?.advertisements.length === 0 ? (
        <Text gridColumn="span 3" p="4" textAlign="center" backgroundColor="red.100" color="red.800" borderRadius="4px">
          Das Imperium hat keine Propaganda veröffentlicht.
        </Text>
      ) : (
        <>
          <Grid gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr 1fr']} gap="16px" mb="8" alignItems="flex-start">
            {data?.advertisements.map((advertisement) => {
              const handleOpenModal = () => {
                setAdvertisement(advertisement);
                onEditOpen();
              };
              return <AdvertisementCard key={advertisement.id} {...advertisement} refetch={refetch} openModal={handleOpenModal} />;
            })}
          </Grid>
          {data && data.advertisements.length !== 0 && data.advertisements.length < data.totalAdvertisements.total ? (
            <Flex width="100%" justifyContent="center" mb="8">
              <Button
                onClick={() =>
                  fetchMore({
                    variables: {
                      skip: data.advertisements.length,
                    },
                    updateQuery: (prev: any, { fetchMoreResult, variables }) => {
                      if (!fetchMoreResult) return prev;
                      console.log('reuslt', fetchMoreResult, variables);

                      return Object.assign({}, prev, {
                        advertisements: [...prev.advertisements, ...fetchMoreResult.advertisements],
                      });
                    },
                  })
                }>
                Mehr laden
              </Button>
            </Flex>
          ) : null}
        </>
      )}
      <CreateAdvertisementModal isOpen={isOpen} onClose={onClose} refetch={refetch} />
      <EditAdvertisementModal isOpen={isOpenEdit} onClose={handleEditClose} refetch={refetch} advertisement={advertisement} />
    </Layout>
  );
};

export default AdvertisementPage;
