import React, { useContext, useState } from 'react';
import * as Yup from 'yup';
import {
  Text,
  Flex,
  Box,
  Button,
  useColorMode,
  Tooltip,
  Heading,
  SliderMark,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Alert,
  AlertDescription,
  AlertIcon,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import useMedia from 'use-media';
import { get } from 'lodash';
import { HiQuestionMarkCircle } from 'react-icons/hi';
import { MdEuroSymbol } from 'react-icons/md';
import { useLazyQuery } from '@apollo/client';

import Input from '../Input';
import Results from './Results';
import { useCustomToast } from '../../hooks/useCustomToast';
import SpinnerContext from '../../contexts/SpinnerContext';
import SliderBar from '../Slider';
import { CALCULATE_POTENTIAL } from './query';

const PotentialCalculatorSchema = Yup.object().shape({
  postalCode: Yup.string()
    .required('Dieses Pflichtfeld muss ausgefüllt werden')
    .min(5, 'Die PLZ muss genau 5 Zeichen lang sein')
    .max(5, 'Die PLZ muss genau 5 Zeichen lang sein'),
  averageNumberOfVisitors: Yup.string().required('Dieses Pflichtfeld muss ausgefüllt werden'),
});

const PotentialCalculator = () => {
  const { colorMode } = useColorMode();
  const isWide = useMedia({ minWidth: 1264 });
  const toast = useCustomToast();
  const { toggleSpinner } = useContext(SpinnerContext);
  const [submitted, setSubmitted] = useState(false);
  const [calculatePotential, { data, loading }] = useLazyQuery(CALCULATE_POTENTIAL);

  return (
    <Flex height="100%" justifyContent="center" alignItems="flex-start" direction="column">
      <Formik
        initialValues={{
          postalCode: '',
          averageNumberOfVisitors: '',
          couponType: 0,
          averageValueOfGoods: '',
          couponValue: 5,
        }}
        validationSchema={PotentialCalculatorSchema}
        onSubmit={async ({ postalCode, averageNumberOfVisitors, couponType, averageValueOfGoods, couponValue }) => {
          toggleSpinner(true);
          try {
            await calculatePotential({
              variables: {
                postalCode,
                averageNumberOfVisitors,
                averageValueOfGoods,
                couponType: couponType === 1 ? 'DiscountByCart' : null,
                couponValue: couponType === 2 ? 50 : couponValue,
              },
            });

            setSubmitted(true);
            toggleSpinner(false);
          } catch (error) {
            toggleSpinner(false);
            console.log(error);
            toast({
              description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
              status: 'error',
            });
          }
        }}>
        {({ values, setFieldValue }) => (
          <>
            {submitted ? (
              <Results
                returnToForm={(values) => {
                  setSubmitted(false);
                }}
                calculationResults={data?.calculatePotential}
                loading={loading}
                values={values}
              />
            ) : (
              <Form>
                <Box
                  maxWidth={isWide ? '500px' : '440px'}
                  width="100%"
                  borderWidth="1px"
                  rounded="lg"
                  bg={colorMode === 'light' ? 'white' : 'gray.700'}
                  p="6">
                  <Heading fontSize="xl" textAlign="center" mb="6">
                    Berechne das Potenzial von dem Standort eines Ladenlokals
                  </Heading>
                  <Input
                    name="postalCode"
                    isRequired
                    label={
                      <Flex alignItems="center">
                        <Text mr="2"> Postleitzahl Deines Standorts *</Text>

                        <Tooltip
                          placement="auto-start"
                          label="Bitte gib hier die Postleitzahl Deiner Filiale ein. So können wir bestimmen, wie viele potentielle Kunden sich in Deinem Umfeld befinden."
                          aria-label="A tooltip">
                          <Box>
                            <HiQuestionMarkCircle />
                          </Box>
                        </Tooltip>
                      </Flex>
                    }
                  />

                  <Input
                    name="averageNumberOfVisitors"
                    isRequired
                    mb="3"
                    label={
                      <Flex alignItems="center">
                        <Text> Durchschnittliche monatliche Besucherzahl *</Text>
                        <Box display="inline" ml="2">
                          <Tooltip
                            placement="auto-start"
                            label="Bitte trage hier eine geschätzte aktuelle durchschnittliche Besucherzahl ein, die im Zeitraum eines Monats Deine Filiale besucht."
                            aria-label="A tooltip">
                            <Box>
                              <HiQuestionMarkCircle />
                            </Box>
                          </Tooltip>
                        </Box>
                      </Flex>
                    }
                  />

                  <Heading fontSize="md" textAlign="center">
                    Einkaufstrigger: Gutschein als Belohnung per DropFriends-App übermitteln
                  </Heading>
                  <Text fontSize="xs" my="5">
                    Ladenlokal-Besitzer können zur Umsatzsteigerung optionale Gutscheine über die DropFriends-Software anlegen. Sie sind
                    frei in der Ausgestaltung selbiger. Entscheide hier, welche Art einer Gutscheinnutzung für die Potenzialberechnung als
                    Beispiel angewendet werden soll.
                  </Text>

                  <Tabs defaultIndex={values.couponType} onChange={(index) => setFieldValue('couponType', index)}>
                    <TabList>
                      <Tab fontSize="sm" py="1" px="2">
                        Coupon allgemein
                      </Tab>
                      <Tab fontSize="sm" py="1" px="2">
                        Rabatt ab Warenwert
                      </Tab>
                      <Tab fontSize="sm" py="1" px="2">
                        Kauf 1, bekomm‘ 1
                      </Tab>
                    </TabList>

                    <TabPanels>
                      <TabPanel px="0">
                        <Alert mb="6" status="info">
                          <AlertIcon />
                          <AlertDescription fontSize="sm" lineHeight="1.5">
                            Nutzer verwenden diese Gutscheine im Ladenlokal für Vergünstigungen beim Einkauf. Hieraus ergibt sich ein
                            Anstieg der Laufkundschaft und der potentielle Mehr-Umsatz für das Ladenlokal.
                          </AlertDescription>
                        </Alert>

                        <Input
                          name="averageValueOfGoods"
                          rightElement={<MdEuroSymbol />}
                          label={
                            <Flex alignItems="center">
                              <Text>Durchschnittlicher Warenwert</Text>

                              <Box display="inline" ml="2">
                                <Tooltip
                                  tabIndex={-1}
                                  placement="auto-start"
                                  label="Bitte gib hier einen Warenwert ein, den ein Kunde in Deiner Filiale durchschnittlich beim Einkauf bezahlt. Daraus ergibt sich in Kombination mit Deinem angegebenen Couponwert und der gestiegenen Anzahl der Laufkundschaft, wie hoch das Potenzial Deines Mehr-Umsatzes ist."
                                  aria-label="averageValueOfGoods">
                                  <Box>
                                    <HiQuestionMarkCircle />
                                  </Box>
                                </Tooltip>
                              </Box>
                            </Flex>
                          }
                        />

                        <SliderBar
                          max={50}
                          min={0}
                          aria-label="Couponwert"
                          name="couponValue"
                          label={
                            <Flex alignItems="center">
                              <Text fontSize="md">Couponwert</Text>
                              <Box display="inline" ml="2">
                                <Tooltip
                                  tabIndex={-1}
                                  placement="auto-start"
                                  label="Bitte wähle hier einen Couponwert aus; z.B. 10% Rabatt auf Bücher. Ein Coupon bei der Herausgabe eines Pakets erhöht die Potenziale für Impulskäufe und den Umsatzanstieg.  ">
                                  <Box>
                                    <HiQuestionMarkCircle />
                                  </Box>
                                </Tooltip>
                              </Box>
                            </Flex>
                          }>
                          <SliderMark value={0} mt="2" fontSize="sm">
                            0%
                          </SliderMark>
                          <SliderMark ml="-2.5" value={10} mt="2" fontSize="sm">
                            10%
                          </SliderMark>

                          <SliderMark ml="-2.5" value={20} mt="2" fontSize="sm">
                            20%
                          </SliderMark>
                          <SliderMark ml="-2.5" value={30} mt="2" fontSize="sm">
                            30%
                          </SliderMark>
                          <SliderMark ml="-2.5" value={40} mt="2" fontSize="sm">
                            40%
                          </SliderMark>
                          <SliderMark ml="-2.5" value={50} mt="2" fontSize="sm">
                            50%
                          </SliderMark>
                        </SliderBar>
                      </TabPanel>

                      <TabPanel px="0">
                        <Alert mb="6" status="info">
                          <AlertIcon />
                          <AlertDescription fontSize="sm" lineHeight="1.5">
                            Ab einem bestimmten Einkaufswert wird ein Rabatt ermöglicht; z.B. ab einem Einkauf von € 20,00 gibt es 10%
                            Rabatt. Sowohl der Warenwert, wie auch die Höhe des prozentualen Rabatts kann frei bestimmt werden.
                          </AlertDescription>
                        </Alert>

                        <Input
                          name="averageValueOfGoods"
                          rightElement={<MdEuroSymbol />}
                          label={
                            <Flex alignItems="center">
                              <Text>Notwendiger Warenwert</Text>

                              <Box display="inline" ml="2">
                                <Tooltip
                                  tabIndex={-1}
                                  placement="auto-start"
                                  label="Bitte gib hier den Warenwert ein, der als Schwellwert für den möglichen Rabatt dient; z.B. erst ab € 20,00 gibt es einen Rabatt. Daraus ergibt sich in Kombination mit Deinem angegebenen Couponwert und der gestiegenen Anzahl der Laufkundschaft, wie hoch das Potenzial Deines Mehr-Umsatzes ist."
                                  aria-label="averageValueOfGoods">
                                  <Box>
                                    <HiQuestionMarkCircle />
                                  </Box>
                                </Tooltip>
                              </Box>
                            </Flex>
                          }
                        />

                        <SliderBar
                          max={50}
                          min={0}
                          aria-label="Couponwert"
                          name="couponValue"
                          label={
                            <Flex alignItems="center">
                              <Text fontSize="md">Couponwert</Text>
                              <Box display="inline" ml="2">
                                <Tooltip
                                  tabIndex={-1}
                                  placement="auto-start"
                                  label="Bitte wähle hier einen Couponwert aus; z.B. 10% Rabatt ab einem Einkaufswert von € 20,00. Ein Coupon bei der Herausgabe eines Pakets erhöht die Potenziale für Impulskäufe und den Umsatzanstieg. ">
                                  <Box>
                                    <HiQuestionMarkCircle />
                                  </Box>
                                </Tooltip>
                              </Box>
                            </Flex>
                          }>
                          <SliderMark value={0} mt="2" fontSize="sm">
                            0%
                          </SliderMark>
                          <SliderMark ml="-2.5" value={10} mt="2" fontSize="sm">
                            10%
                          </SliderMark>

                          <SliderMark ml="-2.5" value={20} mt="2" fontSize="sm">
                            20%
                          </SliderMark>
                          <SliderMark ml="-2.5" value={30} mt="2" fontSize="sm">
                            30%
                          </SliderMark>
                          <SliderMark ml="-2.5" value={40} mt="2" fontSize="sm">
                            40%
                          </SliderMark>
                          <SliderMark ml="-2.5" value={50} mt="2" fontSize="sm">
                            50%
                          </SliderMark>
                        </SliderBar>
                      </TabPanel>

                      <TabPanel px="0">
                        <Alert mb="6" status="info">
                          <AlertIcon />
                          <AlertDescription fontSize="sm" lineHeight="1.5">
                            Beim Kauf eines bestimmten Produktes erhält der Nutzer das gleiche Produkt nochmal gratis dazu; z.B. Kauf eine
                            Tafel Schokolade und Du bekommst eine Tafel Schokolade gratis dazu. Dieser Fall tritt häufig bei
                            Kleinunternehmen, wie Kiosken ein.
                          </AlertDescription>
                        </Alert>

                        <Input
                          name="averageValueOfGoods"
                          rightElement={<MdEuroSymbol />}
                          label={
                            <Flex alignItems="center">
                              <Text>Warenwert des Aktionsproduktes</Text>

                              <Box display="inline" ml="2">
                                <Tooltip
                                  tabIndex={-1}
                                  placement="auto-start"
                                  label="Beim Kauf eines bestimmten Produktes erhält der Nutzer das gleiche Produkt nochmal gratis dazu; z.B. Kauf eine Tafel Schokolade und Du bekommst eine Tafel Schokolade gratis dazu. Wähle hier den Wert des Produktes aus."
                                  aria-label="averageValueOfGoods">
                                  <Box>
                                    <HiQuestionMarkCircle />
                                  </Box>
                                </Tooltip>
                              </Box>
                            </Flex>
                          }
                        />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>

                  {values.couponType === 1 ? (
                    <Text mb="6" fontSize="xs" color={colorMode === 'light' ? 'gray.500' : 'gray.400'}>
                      Beachte: Umso geringer die Produktpreise in Deinem Geschäft sind, desto weniger Anwendung wird diese
                      Gutscheinkategorie bei Deinen Kunden finden. Du verkaufst z.B. 1 Flasche Limo oder Gummibärchen? Dann ist diese
                      Gutscheinkategorie für die Laufkundschaft ein geringer Mehrwert. Dein Umsatzpotenzial fällt deutlich niedriger aus,
                      als bei Geschäften mit hochpreisigen Produkten (z.B. Videospiele).
                    </Text>
                  ) : null}

                  <Button colorScheme="brand" width="100%" type="submit">
                    Potenzial ausrechnen
                  </Button>
                </Box>
              </Form>
            )}
          </>
        )}
      </Formik>
    </Flex>
  );
};

export default PotentialCalculator;
