import React from 'react';
import { useField } from 'formik';
import { FormControl, FormLabel, FormErrorMessage, Select as ChakraSelect } from '@chakra-ui/react';

const Select = ({ label, options = [], ...props }) => {
  // @ts-ignore
  const [field, meta] = useField(props);

  return (
    <FormControl mb="4" isInvalid={!!(meta.touched && meta.error)}>
      <FormLabel htmlFor={field.name}>{label}</FormLabel>
      <ChakraSelect {...field} {...props} id={field.name} size="md" variant="filled" errorBorderColor="red.300">
        {options.map((option) => {
          return (
            <option key={option.value} value={option.value} disabled={option.disabled}>
              {option.label}
            </option>
          );
        })}
      </ChakraSelect>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};

export default Select;
