import React, { useContext } from 'react';
import {
  Flex,
  Text,
  Grid,
  Button,
  Avatar,
  Box,
  Badge,
  Checkbox,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useColorMode,
} from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { Link } from '@reach/router';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { HiPencilAlt, HiTrash, HiEyeOff, HiEye } from 'react-icons/hi';

import SpinnerContext from '../../contexts/SpinnerContext';
import UserContext from '../../contexts/UserContext';
import Input from '../../components/Input';
import { getMomentFromTime } from '../../utils';
import { getCategoryText } from './utils';
import { DELETE_ADVERTISEMENT, ACTIVATE_ADVERTISEMENT } from './query';
import { useCustomToast } from '../../hooks/useCustomToast';

const CancelUserSchema = Yup.object().shape({
  comment: Yup.string().required('Warum möchtest Du die Änderung durchführen?'),
});

const AdvertisementCard = ({
  id,
  title,
  description,
  url,
  delivery,
  category,
  photo,
  isActive,
  user,
  company,
  address: {
    street,
    houseNumber,
    postalCode,
    city,
    geometry: { coordinates },
  },
  deliveryTimes,
  skipModals = false,
  openModal,
  refetch,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef();
  const [isOpenActivate, setIsOpenActivate] = React.useState(false);
  const onCloseActivate = () => setIsOpenActivate(false);
  const cancelRefActivate = React.useRef();
  const [deleteAdvertisement] = useMutation(DELETE_ADVERTISEMENT);
  const [activateAdvertisement] = useMutation(ACTIVATE_ADVERTISEMENT);
  const { toggleSpinner } = useContext(SpinnerContext);
  const { user: contextUser } = useContext(UserContext);
  const toast = useCustomToast();
  const { colorMode } = useColorMode();

  const handleActivation = async ({ comment, sendEmail }) => {
    setIsOpenActivate(false);
    try {
      toggleSpinner(true);
      await activateAdvertisement({
        variables: {
          advertisementId: id,
          isActive: !isActive,
          comment,
          sendEmail,
          internalUser: contextUser.id,
        },
      });
      await refetch();
      toast({
        title: 'Erfolg!',
        description: !isActive ? 'Die Werbung wurde aktiviert.' : 'Die Werbung wurde deaktiviert',
        status: 'success',
      });
    } catch (error) {
      console.log(error);
      toast({
        description: 'Ein Fehler ist aufgetreten!',
        status: 'error',
      });
    }
    toggleSpinner(false);
  };

  const handleDelete = async () => {
    try {
      toggleSpinner(true);
      await deleteAdvertisement({
        variables: {
          where: { id },
        },
      });
      await refetch();
      toast({
        title: 'Erfolg!',
        description: 'Die Werbung wurde gelöscht.',
        status: 'success',
      });
    } catch (error) {
      console.log(error);
      toast({
        description: 'Ein Fehler ist aufgetreten!',
        status: 'error',
      });
    }
    toggleSpinner(false);
  };

  return (
    <Box width="100%" rounded="md" borderWidth="1px" p="4" position="relative">
      <Popover trigger="hover" placement="top-start">
        {/* @ts-ignore */}
        <PopoverTrigger>
          <button>
            <Icon
              as={isActive ? HiEyeOff : HiEye}
              position="absolute"
              right={skipModals ? '16px' : '64px'}
              top="8px"
              cursor="pointer"
              onClick={() => setIsOpenActivate(true)}
              title={isActive ? 'Deaktivieren' : 'Aktivieren'}
            />
          </button>
        </PopoverTrigger>
        <PopoverContent width="100px" textAlign="center" backgroundColor="gray.500" color="white">
          {isActive ? 'Deaktivieren' : 'Aktivieren'}
        </PopoverContent>
      </Popover>
      {skipModals ? null : (
        <>
          <Icon as={HiPencilAlt} position="absolute" right="40px" top="8px" cursor="pointer" onClick={openModal} />

          <Icon as={HiTrash} position="absolute" right="16px" top="8px" cursor="pointer" onClick={() => setIsOpen(true)} color="red.500" />
        </>
      )}

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Werbung Löschen
          </AlertDialogHeader>

          <AlertDialogBody>Bist du sicher? Diese Aktion kann nicht rückgängig gemacht werden.</AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Abbrechen
            </Button>
            <Button colorScheme="red" onClick={handleDelete} ml={3}>
              Löschen
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog isOpen={isOpenActivate} leastDestructiveRef={cancelRef} onClose={onCloseActivate}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <Formik initialValues={{ sendEmail: false, comment: '' }} validationSchema={CancelUserSchema} onSubmit={handleActivation}>
            {({ values, handleChange }) => {
              return (
                <Form>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Ad wirklich aktivieren?
                  </AlertDialogHeader>

                  <AlertDialogBody>
                    <Text mb="4">
                      Möchtest Du die Ad für {company ? company.name : `${user?.firstName} ${user?.lastName}, ${user?.email}`} wirklich
                      aktivieren?
                    </Text>
                    <Input name="comment" label="Kommentar" />

                    <Checkbox isChecked={values.sendEmail} onChange={handleChange('sendEmail')}>
                      {isActive ? 'Systemmail zur Ad-Deaktivierung versenden?' : 'Systemmail zur Ad-Aktivierung versenden?'}
                    </Checkbox>
                  </AlertDialogBody>

                  <AlertDialogFooter>
                    <Button ref={cancelRefActivate} onClick={onCloseActivate}>
                      Abbrechen
                    </Button>
                    <Button colorScheme="red" type="submit" ml={3}>
                      {isActive ? 'Jetzt deaktivieren' : 'Jetzt aktivieren'}
                    </Button>
                  </AlertDialogFooter>
                </Form>
              );
            }}
          </Formik>
        </AlertDialogContent>
      </AlertDialog>

      <Flex flexWrap="wrap" mb="2">
        <Badge colorScheme={isActive ? 'green' : 'red'} mr="2">
          {isActive ? 'Aktiv' : 'Inaktiv'}
        </Badge>
        {company ? (
          <Badge colorScheme="blue" mr="2">
            Unternehmen
          </Badge>
        ) : null}
      </Flex>
      <Flex flexWrap="wrap" mb="2">
        {delivery.includes('Delivery') ? <Badge mr="2">Lieferung</Badge> : null}
        {delivery.includes('Pickup') ? <Badge mr="2">Abholung</Badge> : null}
        {delivery.includes('Coupon') ? <Badge mr="2">Gutschein</Badge> : null}
        {delivery.includes('Online') ? <Badge mr="2">Online</Badge> : null}
      </Flex>
      <Flex alignItems="center" mb="4">
        <Avatar size="lg" src={(photo && photo.uri) || company?.logo || user?.photo?.uri} mr="4" />
        <Box flex="1">
          <Text fontSize="lg" fontWeight="bold">
            {title}
          </Text>
          <Text fontSize="sm">{getCategoryText(category)}</Text>
        </Box>
      </Flex>
      {description ? <Text mb="4">{description}</Text> : null}

      {deliveryTimes.length > 0 ? (
        <Box mb="4">
          <Text fontWeight={500}>Liefer- & Abholzeiten</Text>
          {deliveryTimes.map(({ from, to }) => {
            return (
              <Text key={from + to}>
                {getMomentFromTime(from).format('HH:mm')} Uhr - {getMomentFromTime(to).format('HH:mm')} Uhr
              </Text>
            );
          })}
        </Box>
      ) : null}
      <Box mb="4" fontSize="sm">
        {street} {houseNumber}
        <br />
        {postalCode} {city}
      </Box>
      <Grid templateColumns={url ? '1fr 1fr' : '1fr'} gridGap="3" mb="4">
        {url ? (
          <Button flex="1" colorScheme="brand" onClick={() => window.open(url, '_blank')}>
            Ansehen
          </Button>
        ) : null}
        <Button
          flex="1"
          colorScheme="brand"
          onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${coordinates[1]},${coordinates[0]}`, '_blank')}>
          Route
        </Button>
      </Grid>
      <Box>
        {company ? (
          <Link to={`/companies/${company?.id}`}>
            <Text fontSize="xs" color={colorMode === 'light' ? 'gray.900' : 'gray.100'} textDecoration="underline">
              Unternehmen: {company?.name}
            </Text>
          </Link>
        ) : (
          <Link to={`/users/${user?.id}`}>
            <Text fontSize="xs" color={colorMode === 'light' ? 'gray.700' : 'gray.300'} textDecoration="underline">
              Nutzer: {user?.firstName} {user?.lastName}
            </Text>
          </Link>
        )}
      </Box>
    </Box>
  );
};

export default AdvertisementCard;
