import React, { useContext } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Checkbox,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';

import UserContext from '../../../contexts/UserContext';
import Input from '../../../components/Input';
import { LOCK_USER } from '../query';

const LockConfirmSchema = Yup.object().shape({
  comment: Yup.string().required('Das ist ein Pflichtfeld'),
});

const LockConfirmModal = ({ isOpen, onClose, refetch, userId }) => {
  const [lockUser] = useMutation(LOCK_USER);
  const { user } = useContext(UserContext);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>User sperren</ModalHeader>

        <Formik
          initialValues={{
            comment: '',
            sendEmail: false,
          }}
          validationSchema={LockConfirmSchema}
          onSubmit={async ({ comment, sendEmail }) => {
            await lockUser({
              variables: {
                userId,
                comment,
                sendEmail,
                internalUser: user.id,
              },
            });
            await refetch();
            onClose();
          }}>
          {({ values, handleChange }) => (
            <Form>
              <ModalBody>
                <Input name="comment" label="Kommentar" />
                <Checkbox isChecked={values.sendEmail} onChange={handleChange('sendEmail')}>
                  Email an User verschicken?
                </Checkbox>
              </ModalBody>

              <ModalFooter>
                <Button variant="ghost" size="sm" colorScheme="red" type="button" onClick={onClose}>
                  Schließen
                </Button>
                <Button ml="4" size="sm" colorScheme="brand" type="submit">
                  Speichern
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};

export default LockConfirmModal;
