import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalHeader, ModalBody, ModalFooter, Button } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import * as Yup from 'yup';

import Input from '../../components/Input';
import NumberInput from '../../components/NumberInput';
import Select from '../../components/Select';
import { CREATE_COUPON } from './query';

const CreateCouponSchema = Yup.object().shape({
  code: Yup.string().required('Das ist ein Pflichtfeld'),
  orderer: Yup.string().when('type', {
    is: 'INDIVIDUAL',
    then: Yup.string().required('Das ist ein Pflichtfeld'),
    otherwise: Yup.string(),
  }),
  description: Yup.string().required('Das ist ein Pflichtfeld'),
  validUntil: Yup.string().required('Das ist ein Pflichtfeld'),
  validUses: Yup.number().min(1, 'Eine Anwendung ist das Minimum').required('Das ist ein Pflichtfeld'),
  discountForOrderer: Yup.number().required('Das ist ein Pflichtfeld'),
});

const CreateCouponModal = ({ isOpen, onClose, refetch }) => {
  const [createCoupon] = useMutation(CREATE_COUPON);

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Neuen Coupon erstellen</ModalHeader>

        <Formik
          initialValues={{
            code: '',
            type: 'GLOBAL',
            orderer: '',
            description: '',
            validUntil: '',
            validUses: '',
            discountForOrderer: 3.99,
          }}
          validationSchema={CreateCouponSchema}
          onSubmit={async ({ code, type, orderer, description, validUntil, validUses, discountForOrderer }) => {
            await createCoupon({
              variables: {
                data: {
                  code,
                  type,
                  users:
                    type === 'INDIVIDUAL'
                      ? {
                          connect: [
                            {
                              id: orderer,
                            },
                          ],
                        }
                      : null,
                  description,
                  validUntil: moment(validUntil, 'DD.MM.YYYY').format('YYYY-MM-DD'),
                  validUses: parseInt(validUses),
                  discountForOrderer: discountForOrderer * 100,
                },
              },
            });
            await refetch();
            onClose();
          }}>
          {({ values }) => (
            <Form>
              <ModalBody>
                <Input name="code" label="Coupon-Code" />
                <Select
                  name="type"
                  label="Generell oder Individuell?"
                  options={[
                    { label: 'Alle Besteller', value: 'GLOBAL' },
                    { label: 'Ein Besteller', value: 'INDIVIDUAL' },
                  ]}
                />
                {values.type === 'INDIVIDUAL' ? <Input name="orderer" label="Besteller-ID" /> : null}
                <Input name="description" label="Beschreibung" />
                <Input name="validUntil" placeholder="DD.MM.YYYY" label="Gültig bis" />
                <NumberInput name="validUses" type="number" label="Anwendungen" />
                <NumberInput name="discountForOrderer" precision={2} step={0.01} type="number" min={0} max={3.99} label="Finaler Preis" />
              </ModalBody>

              <ModalFooter>
                <Button variant="ghost" size="sm" colorScheme="red" type="button" onClick={onClose}>
                  Schließen
                </Button>
                <Button ml="4" size="sm" colorScheme="brand" type="submit">
                  Speichern
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};

export default CreateCouponModal;
