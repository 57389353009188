import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { useQuery } from '@apollo/client';
import { Text, Flex, Grid, Tabs, TabList, Tab, TabPanel, TabPanels } from '@chakra-ui/react';
import concat from 'lodash/concat';
import sortBy from 'lodash/sortBy';

import Layout from '../../components/Layout';
import { GET_INVOICE_COUNT, GET_INVOICES } from './query';
import { InvoiceRow } from './InvoiceRow';
import History from './History';

interface InvoiceProps extends RouteComponentProps {}

const Invoice: React.FC<InvoiceProps> = () => {
  const { data } = useQuery(GET_INVOICE_COUNT, {
    fetchPolicy: 'network-only',
    pollInterval: 1000,
  });
  const { data: receiptData } = useQuery(GET_INVOICES, {
    fetchPolicy: 'network-only',
    pollInterval: 10000,
  });

  const getReceipts = () => {
    const array = concat(receiptData.receipts, receiptData.payouts, receiptData.invoices, receiptData.essentialsInvoices);
    return sortBy(array, 'number').reverse();
  };

  const receipts = receiptData ? getReceipts() : [];

  return (
    <Grid height="100%" templateRows="auto 1fr" templateColumns="1fr">
      <Flex mb="4">
        <Layout>
          <Flex flexDirection="column" alignItems="center" justifyContent="space-between" mb="3">
            <Text as="h2" fontSize="4xl" flex="1" fontWeight="bold" fontFamily="heading">
              Rechnungen
            </Text>
            {data ? (
              <Flex alignItems="center">
                <Text as="span" fontSize="lg" mr="2">
                  Aktuelle Rechnungsnummer:
                </Text>
                <Text fontSize="2xl"> {data && data.runningInvoiceCount}</Text>
              </Flex>
            ) : null}
          </Flex>
        </Layout>
      </Flex>
      <Tabs>
        <TabList>
          <Tab>Rechnungen</Tab>
          <Tab>History</Tab>
        </TabList>
        <TabPanels>
          <TabPanel p="6">
            {receipts.map((receipt) => {
              return <InvoiceRow key={receipt.id} {...receipt} />;
            })}
          </TabPanel>
          <TabPanel p="6">
            <History />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Grid>
  );
};

export default Invoice;
