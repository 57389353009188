import { useState } from 'react';
import { Box, Flex, Button, Link, Icon, Text, useColorMode, Tr, Td, Tag, Tooltip, IconButton, HStack } from '@chakra-ui/react';
import { HiStar, HiChevronRight } from 'react-icons/hi';
import moment from 'moment';
import get from 'lodash/get';

import { getVariant } from '../../utils';

export const OrderRow = ({
  id,
  iid,
  createdAt,
  status,
  packages,
  packageStation,
  payment,
  orderer,
  pickuper,
  pickuperNoUser,
  review,
  stationReview,
  onDrawerOpen,
  openModal,
}) => {
  const { colorMode } = useColorMode();

  const [open, setOpen] = useState(false);
  const paymentType = get(payment, '0.type', null);

  const pickuperData = pickuper || pickuperNoUser;

  return (
    <>
      <Tr cursor={packages.length > 0 ? 'pointer' : 'default'} onClick={() => packages.length > 0 && setOpen(!open)}>
        <Td fontSize="xs" px="2" py="2" wordBreak="break-word" borderBottomWidth="1px">
          {moment(createdAt).format('DD.MM.YYYY, HH:mm')}
          <Box mt="1">
            <Tag size="sm" colorScheme={getVariant(status)} mr="2">
              {status}
            </Tag>

            {paymentType ? (
              <Tag size="sm" mr="2">
                {paymentType}
              </Tag>
            ) : null}
          </Box>
        </Td>
        {orderer ? (
          <Td fontFamily="heading" px="2" py="2" wordBreak="break-word" borderBottomWidth="1px">
            {pickuperData ? (
              <Text mb="1">
                {pickuperData.firstName} {pickuperData.lastName}
              </Text>
            ) : (
              <Link fontWeight="bold" href={`/users/${orderer?.id}`}>
                <Text mb="1">
                  {orderer.firstName} {orderer.lastName}
                </Text>
              </Link>
            )}
            <Text fontSize="sm" color={colorMode === 'light' ? 'gray.700' : 'gray.300'}>
              {iid}
            </Text>
          </Td>
        ) : null}

        {packageStation.company ? (
          <Td fontFamily="heading" fontWeight="bold" px="2" py="2" wordBreak="break-word" borderBottomWidth="1px">
            <Link href={`/companies/${packageStation.company.id}`}>{packageStation.locationName}</Link>
          </Td>
        ) : (
          <Td fontFamily="heading" fontWeight="bold" px="2" py="2" wordBreak="break-word" borderBottomWidth="1px">
            <Link href={`/users/${packageStation?.user?.id}`}>
              {packageStation.user.firstName} {packageStation.user.lastName}
            </Link>
          </Td>
        )}

        <Td px="2" py="2">
          <Box>
            {review ? (
              <>
                <Box mb="1">
                  <Text fontSize="md" fontWeight="bold">
                    Nutzer-Bewertung:
                  </Text>
                </Box>
                <Flex>
                  <Icon as={HiStar} mr="1" color={review.rating > 0 ? 'yellow.400' : 'gray.500'} />
                  <Icon as={HiStar} mr="1" color={review.rating > 1 ? 'yellow.400' : 'gray.500'} />
                  <Icon as={HiStar} mr="1" color={review.rating > 2 ? 'yellow.400' : 'gray.500'} />
                  <Icon as={HiStar} mr="1" color={review.rating > 3 ? 'yellow.400' : 'gray.500'} />
                  <Icon as={HiStar} mr="1" color={review.rating > 4 ? 'yellow.400' : 'gray.500'} />
                </Flex>
                {review.note ? (
                  <Text fontSize="md" fontWeight="bold" color={colorMode === 'light' ? 'gray.400' : 'gray.200'}>
                    {review.note}
                  </Text>
                ) : null}
              </>
            ) : (
              <Text fontSize="sm" fontStyle="italic" color={colorMode === 'light' ? 'gray.400' : 'gray.200'}>
                Keine Notiz hinterlegt
              </Text>
            )}
          </Box>
        </Td>
        <Td>
          {stationReview ? (
            <>
              <Box mb="1">
                <Text fontSize="md" fontWeight="bold">
                  DropPoint-Bewertung:
                </Text>
              </Box>
              <Flex>
                <Icon as={HiStar} mr="1" color={stationReview.rating > 0 ? 'yellow.400' : 'gray.500'} />
                <Icon as={HiStar} mr="1" color={stationReview.rating > 1 ? 'yellow.400' : 'gray.500'} />
                <Icon as={HiStar} mr="1" color={stationReview.rating > 2 ? 'yellow.400' : 'gray.500'} />
                <Icon as={HiStar} mr="1" color={stationReview.rating > 3 ? 'yellow.400' : 'gray.500'} />
                <Icon as={HiStar} mr="1" color={stationReview.rating > 4 ? 'yellow.400' : 'gray.500'} />
              </Flex>
              {stationReview.note ? (
                <Text fontSize="md" fontWeight="bold">
                  {stationReview.note}
                </Text>
              ) : null}
            </>
          ) : (
            <Text fontSize="sm" fontStyle="italic" color={colorMode === 'light' ? 'gray.400' : 'gray.200'}>
              Keine Notiz hinterlegt
            </Text>
          )}
        </Td>

        <Td px="0">
          <HStack align="right" justify="right">
            {status === 'DONE' || status === 'CANCELED' || status === 'EXPIRED' || status === 'RETOURE' || status === 'DISPUTED' ? null : (
              <>
                {status !== 'CANCELED' ? (
                  <Button
                    onClick={() => openModal(id, 'cancel')}
                    size="xs"
                    borderRadius="full"
                    variant="outline"
                    colorScheme="red"
                    cursor="pointer">
                    Stornieren
                  </Button>
                ) : null}

                {status !== 'EXPIRED' ? (
                  <Button
                    onClick={() => openModal(id, 'expire')}
                    size="xs"
                    borderRadius="full"
                    variant="outline"
                    colorScheme="blue"
                    ml="2"
                    cursor="pointer">
                    Abgelaufen
                  </Button>
                ) : null}
              </>
            )}

            <Tooltip label="Weitere Infos">
              <IconButton
                size="sm"
                variant="ghost"
                aria-label="more info"
                icon={<HiChevronRight />}
                onClick={() => onDrawerOpen(id, packageStation.id)}
              />
            </Tooltip>
          </HStack>
        </Td>
      </Tr>
    </>
  );
};
