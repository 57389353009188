import { useToast, UseToastOptions } from '@chakra-ui/toast';

export const useCustomToast = () => {
  const toast = useToast();

  const showToast = (options: UseToastOptions) => {
    toast({
      position: 'top',
      duration: null,
      isClosable: true,
      ...options,
    });
  };

  return showToast;
};
