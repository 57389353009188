import React, { useContext } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Grid,
  Text,
  useColorMode,
  Box,
  IconButton,
  Stack,
  InputGroup,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import { useMutation, useQuery } from '@apollo/client';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import get from 'lodash/get';
import { FaXing, FaLinkedinIn, FaFacebook, FaInstagram, FaTwitter, FaGlobe } from 'react-icons/fa';
import { BiTrash } from 'react-icons/bi';

import Input from '../../Input';
import SpinnerContext from '../../../contexts/SpinnerContext';
import UserContext from '../../../contexts/UserContext';
import Select from '../../Select';
import { useCustomToast } from '../../../hooks/useCustomToast';
import { CHECK_ADDRESS, CREATE_INTERNAL_CUSTOMER, INTERNAL_USERS } from '../query';
import { SingleCheckbox } from '../../Checkbox';
import { HiPlusCircle } from 'react-icons/hi';
import { QuillInput } from '../../QuillInput';
import { formatGoogleResponse } from '../../../utils';

const CreateInternalCustomerSchema = Yup.object().shape({
  companyName: Yup.string().required('Du musst einen Firmennamen angeben'),
  email: Yup.string().matches(
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
    'Du musst eine valide E-Mail angeben'
  ),
});

const CreateCustomerModal = ({ isOpen, onClose, refetch: parentRefetch, asigneeId }) => {
  const [createInternalCustomer] = useMutation(CREATE_INTERNAL_CUSTOMER);
  const [checkAddress] = useMutation(CHECK_ADDRESS);
  const { toggleSpinner } = useContext(SpinnerContext);
  const { user } = useContext(UserContext);
  const { colorMode } = useColorMode();
  const toast = useCustomToast();
  const { data, refetch } = useQuery(INTERNAL_USERS, {
    fetchPolicy: 'cache-first',
  });

  if (!data?.internalUsers) return null;

  const asigneeOptions = data?.internalUsers.map((asignee) => ({ value: asignee.id, label: `${asignee.firstName} ${asignee.lastName}` }));

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl" closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Lead hinzufügen </ModalHeader>

        <Formik
          initialValues={{
            companyName: '',
            contactName: '',
            email: '',
            phone: '',
            phoneNumbers: [''],
            address: {
              street: '',
              postalCode: '',
              city: '',
            },
            xing: '',
            linkedin: '',
            facebook: '',
            instagram: '',
            twitter: '',
            message: '',
            website: '',
            contactProfession: '',
            branchAmount: '',
            contactMethod: '',
            businessType: '',
            productOffered: '',
            api: false,
            business: false,
            sprint: false,
            events: false,
            asignee: user?.id,
          }}
          validationSchema={CreateInternalCustomerSchema}
          onSubmit={async (
            {
              companyName,
              contactName,
              email,
              phone,
              phoneNumbers,
              address: { street, postalCode, city },
              xing,
              linkedin,
              facebook,
              instagram,
              twitter,
              message,
              website,
              contactProfession,
              branchAmount,
              contactMethod,
              businessType,
              productOffered,
              api,
              business,
              sprint,
              events,
              asignee,
            },
            { setFieldError }
          ) => {
            try {
              toggleSpinner(true);

              let formattedAddress, locationId, geometry;

              if (street) {
                const locationString = `${street}, ${postalCode} ${city}`;

                const {
                  data: { checkAddress: addressCheckData },
                } = await checkAddress({
                  variables: {
                    address: locationString,
                  },
                });

                if (!addressCheckData || !addressCheckData.address) {
                  setFieldError(
                    'address.street',
                    'Die Adresse konnte nicht gespeichert werden. Achten Sie auf eine korrekte Schreibweise und korrekte Zuordnung der Postleitzahl.'
                  );
                  toggleSpinner(false);
                  return;
                }
                const googleResponse = formatGoogleResponse(addressCheckData?.address);
                formattedAddress = googleResponse.address;
                locationId = googleResponse.locationId;
                geometry = googleResponse.geometry;
              }

              await createInternalCustomer({
                variables: {
                  data: {
                    companyName,
                    contactName,
                    email: (email || '').toLowerCase(),
                    phone,
                    address: formattedAddress
                      ? {
                          create: {
                            ...formattedAddress,
                            locationId,
                            geometry: {
                              create: {
                                ...geometry,
                                coordinates: {
                                  set: geometry.coordinates,
                                },
                              },
                            },
                          },
                        }
                      : undefined,
                    notes: {
                      create: [
                        message
                          ? {
                              message,
                              type: 'COMMENT',
                              commenter: {
                                connect: { id: user.id },
                              },
                            }
                          : null,
                        {
                          message: `${companyName} wurde erstellt `,
                          type: 'COMMENT',
                          commenter: {
                            connect: { id: user.id },
                          },
                        },
                      ].filter(Boolean),
                    },

                    status: 'OPEN',
                    xing,
                    linkedin,
                    facebook,
                    instagram,
                    twitter,
                    website,
                    contactProfession,
                    branchAmount,
                    contactMethod,
                    businessType,
                    productOffered: productOffered || null,
                    productsOffered: {
                      create: {
                        api,
                        business,
                        sprint,
                        events,
                      },
                    },
                    asignee: {
                      connect: { id: user?.id },
                    },
                    phoneNumbers: { set: phoneNumbers },
                  },
                },
              });
              await parentRefetch();
              await refetch();
              toast({
                title: 'Erfolg!',
                description: 'Kunde erfolgreich erstellt 🎉',
                status: 'success',
                isClosable: false,
                duration: 3000,
              });
              toggleSpinner(false);
              onClose();
            } catch (error) {
              console.log(error);
              toast({
                description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
                status: 'error',
              });
              toggleSpinner(false);
            }
          }}>
          {({ values, setFieldValue }) => (
            <Form>
              <ModalBody>
                <Accordion defaultIndex={[0]} allowMultiple allowToggle mb="6">
                  <AccordionItem py="3">
                    <AccordionButton px="0">
                      <Text flex="1" textAlign="left" fontWeight="bold" fontSize="sm" fontFamily="heading">
                        Stammdaten
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb="0" px="0">
                      <Grid gridTemplateColumns="1fr 1fr" columnGap="4">
                        <Input name="companyName" label="Firmenname*" />
                        <Input name="email" label="E-Mail" />
                        <Input name="contactName" label="Kontaktname" />
                        <Input name="contactProfession" label="Jobbezeichnung" />

                        <Select
                          options={[{ value: '', label: 'Mitarbeiter auswählen', disabled: true }, ...asigneeOptions]}
                          label="Mitarbeiter"
                          name="asignee"
                        />

                        <Box>
                          <FieldArray name="phoneNumbers">
                            {(fieldArrayProps) => {
                              const { push, remove, form } = fieldArrayProps;
                              const { values } = form;
                              const { phoneNumbers } = values;

                              return (
                                <div>
                                  {phoneNumbers.map((phoneNumber, index) => (
                                    <Box key={index}>
                                      <Stack spacing={3}>
                                        <InputGroup alignSelf="center" justifyContent="center">
                                          <Input name={`phoneNumbers[${index}]`} label={`Telefonnummer #${index + 1}`} />
                                          {index > 0 && (
                                            <IconButton
                                              mt="3"
                                              aria-label="add"
                                              icon={<BiTrash />}
                                              onClick={() => remove(index)}
                                              alignSelf="center"
                                              justifyContent="center"
                                              colorScheme="red"
                                              variant="outline"
                                              ml="3"
                                            />
                                          )}
                                        </InputGroup>
                                      </Stack>
                                    </Box>
                                  ))}

                                  <Button
                                    mb="3"
                                    size="sm"
                                    aria-label="delete"
                                    colorScheme="brand"
                                    type="button"
                                    leftIcon={<HiPlusCircle />}
                                    onClick={() => push('')}>
                                    Telefonnummer hinzufügen
                                  </Button>
                                </div>
                              );
                            }}
                          </FieldArray>
                        </Box>
                      </Grid>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem py="3">
                    <AccordionButton px="0">
                      <Text flex="1" textAlign="left" fontWeight="bold" fontSize="sm" fontFamily="heading">
                        Firmendetails
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={0} px="0">
                      <Grid gridTemplateColumns="1fr 1fr" columnGap="4">
                        <Input name="branchAmount" label="Filialenanzahl " />
                        <Input name="contactMethod" label="Kontaktmethode " />
                        <Input name="businessType" label="Geschäftsart " />
                      </Grid>

                      <Grid gridGap="4" gridTemplateColumns="1fr 1fr">
                        <Input name="address.street" label="Strasse & Hnr." />
                        <div />

                        <Input name="address.postalCode" label="PLZ" />
                        <Input name="address.city" label="Stadt" />
                      </Grid>
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem py="3">
                    <AccordionButton px="0">
                      <Text flex="1" textAlign="left" fontWeight="bold" fontSize="sm" fontFamily="heading">
                        Angebotenes Produkt
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={0} px="0">
                      <Grid gridTemplateColumns="1fr 1fr" mb="4">
                        <SingleCheckbox name="api" label="⚙️ Api " />
                        <SingleCheckbox name="business" label="💼 Business " />
                        <SingleCheckbox name="events" label="📅 Events " />
                        <SingleCheckbox name="sprint" label="📈 sprint " />
                      </Grid>
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem py="3">
                    <AccordionButton px="0">
                      <Text flex="1" textAlign="left" fontWeight="bold" fontSize="sm" fontFamily="heading">
                        Social Media Links
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={0} px="0">
                      <Input name="website" label="Website" leftElement={<FaGlobe color={colorMode === 'light' ? 'gray' : 'white'} />} />
                      <Input name="xing" label="Xing Profil" leftElement={<FaXing color={colorMode === 'light' ? 'gray' : 'white'} />} />
                      <Input
                        name="linkedin"
                        label="Linkedin Profil"
                        leftElement={<FaLinkedinIn color={colorMode === 'light' ? 'gray' : 'white'} />}
                      />
                      <Input
                        name="facebook"
                        label="Facebook Profil"
                        leftElement={<FaFacebook color={colorMode === 'light' ? 'gray' : 'white'} />}
                      />
                      <Input
                        name="instagram"
                        label="Instagram Profil"
                        leftElement={<FaInstagram color={colorMode === 'light' ? 'gray' : 'white'} />}
                      />
                      <Input
                        name="twitter"
                        label="Twitter Profil"
                        leftElement={<FaTwitter color={colorMode === 'light' ? 'gray' : 'white'} />}
                        mb="8"
                      />
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>

                <QuillInput label="Dein Kommentar" name="message" placeholder="Gib hier einen zusätzlichen Kommentar ein..." />
              </ModalBody>

              <ModalFooter>
                <Button variant="ghost" size="sm" colorScheme="red" type="button" onClick={onClose}>
                  Schließen
                </Button>
                <Button ml="4" size="sm" colorScheme="brand" type="submit">
                  Speichern
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};

export default CreateCustomerModal;
