import React from 'react';
import { Editable, EditableInput, EditablePreview, Flex, Grid, Text, useColorMode } from '@chakra-ui/react';

export const DataRow = ({
  label,
  value = '',
  placeholder = '',
  onSubmit = null,
  alignItems = 'center',
  gridTemplateColumns = '1fr 2fr',
  children = null,
  description = null,
}) => {
  const { colorMode } = useColorMode();

  return (
    <Grid
      gridGap="2"
      gridTemplateColumns={gridTemplateColumns}
      py="3"
      borderBottom="1px solid"
      borderBottomColor={colorMode === 'light' ? 'gray.200' : 'gray.500'}
      alignItems={alignItems}>
      <Flex direction="column">
        <Text fontWeight="100" color={colorMode === 'light' ? 'gray.700' : 'gray.300'}>
          {label}
        </Text>
        {description}
      </Flex>
      {children || (
        <Editable placeholder={placeholder || 'Hier angeben'} defaultValue={value} onSubmit={onSubmit}>
          <EditablePreview />
          <EditableInput />
        </Editable>
      )}
    </Grid>
  );
};
