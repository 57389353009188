import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useQuery } from '@apollo/client';
import { Spinner, Flex, Text, Grid, Button, useDisclosure } from '@chakra-ui/react';

import Layout from '../../components/Layout';
import { GET_COUPONS } from './query';
import CreateCouponModal from './CreateCouponModal';
import { CouponCard } from './CouponCard';
import EditCouponModal from './EditCouponModal';

interface CouponsProps extends RouteComponentProps {}

const Coupons: React.FC<CouponsProps> = () => {
  const [coupon, setCoupon] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenEdit, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const { loading, error, data, refetch } = useQuery(GET_COUPONS, {
    variables: {
      orderBy: 'createdAt_DESC',
      first: 100,
    },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return (
      <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
        <Spinner />
      </Flex>
    );
  }
  if (error) {
    return (
      <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
        Ein Fehler ist aufgetreten. Schreib Osamah an.
      </Flex>
    );
  }

  const handleEditClose = () => {
    setCoupon(null);
    onEditClose();
  };

  return (
    <Layout>
      <Flex alignItems={['flex-start', 'center']} flexDirection={['column', 'row']} mb="8">
        <Text as="h2" fontSize="4xl" fontWeight="bold" flex="1" fontFamily="heading">
          Besteller Coupons
        </Text>
        <Button colorScheme="brand" size="sm" onClick={onOpen}>
          Neuer Coupon Code
        </Button>
      </Flex>
      <Grid templateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr']} gap="16px">
        {data?.coupons?.map((coupon) => {
          const handleOpenModal = () => {
            setCoupon(coupon);
            onEditOpen();
          };
          return <CouponCard key={coupon.id} {...coupon} refetch={refetch} openModal={handleOpenModal} />;
        })}
      </Grid>
      <CreateCouponModal isOpen={isOpen} onClose={onClose} refetch={refetch} />
      <EditCouponModal isOpen={isOpenEdit} onClose={handleEditClose} refetch={refetch} coupon={coupon} />
    </Layout>
  );
};

export default Coupons;
