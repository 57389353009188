import React from 'react';
import { useColorMode, Box, Grid, Text, Flex } from '@chakra-ui/react';

export const FormWrapper = ({
  title,
  description = null,
  children = null,
  footer = null,
  showDivider = false,
  boxPadding = null,
  rightChild = null,
  customLeft = null,
}) => {
  const { colorMode } = useColorMode();

  return (
    <>
      <Grid templateColumns={['1fr', '1fr', '1fr', '1fr 3fr', '1fr 2fr']} gap="6">
        <Flex direction="column">
          <Text
            as="h3"
            fontFamily="heading"
            fontSize="lg"
            fontWeight="medium"
            lineHeight="24px"
            color={colorMode === 'light' ? 'gray.900' : 'gray.300'}
            mb="1">
            {title}
          </Text>
          <Box fontSize="sm" lineHeight="20px" color={colorMode === 'light' ? 'gray.600' : 'gray.400'}>
            {description}
          </Box>
          {rightChild}
        </Flex>
        {customLeft || (
          <Flex direction="column" rounded={['', 'md']} shadow="md" overflow="hidden" bg={colorMode === 'light' ? 'white' : 'gray.900'}>
            <Box p={boxPadding || ['4', '6']} flex="1">
              {children}
            </Box>
            {footer ? (
              <Box textAlign="right" px={['4', '6']} py="3" bg={colorMode === 'light' ? 'gray.50' : 'gray.700'}>
                {footer}
              </Box>
            ) : null}
          </Flex>
        )}
      </Grid>
      {showDivider ? (
        <Box py="6">
          <Box height="1px" bg={colorMode === 'light' ? 'gray.200' : 'gray.700'} width="full" />
        </Box>
      ) : null}
    </>
  );
};
