import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter, Button, Text } from '@chakra-ui/react';

const NewInvoiceNumberModal = ({ isOpen, onClose, newInvoiceNumber }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Text fontSize="xl" textAlign="center" mt="12">
            Reservierte Rechnungsnummer:
          </Text>
          <Text fontSize="4xl" textAlign="center">
            {newInvoiceNumber}
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button ml="4" size="sm" colorScheme="brand" type="submit" onClick={onClose}>
            Schließen
          </Button>
        </ModalFooter>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};

export default NewInvoiceNumberModal;
