import React, { useState } from 'react';
import { Box, Flex, Spinner, Grid, Text, Button, Image, Icon, useColorMode } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import moment from 'moment';

import { GET_DROPPOINT } from '../query';

export const getMomentFromTime = (time) => {
  const quotient = Math.floor(time / 60);
  const remainder = time % 60;

  return moment()
    .hour(quotient - 1)
    .minute(remainder);
};

const getPackageSizeValue = (packageSize) => {
  switch (packageSize) {
    case 'FOLDER':
      return 'Aktenordnergröße';
    case 'WATERCONTAINER':
      return 'Getränkekasten';
    case 'TWO_WATERCONTAINERS':
      return 'Reisekoffer';
    case 'ALL_SIZES':
      return 'Alle Größen';
    default:
      return 'Nicht gefunden';
  }
};

const getPackageWeightValue = (packageWeight) => {
  switch (packageWeight) {
    case 'BOOK':
      return 'Dickes Buch';
    case 'WATERCONTAINER':
      return 'Getränkekasten';
    case 'THIRTY_KILOS':
      return '30 kg';
    default:
      return 'Nicht gefunden';
  }
};

const DropPoint = ({ dropPointId }) => {
  const { colorMode } = useColorMode();
  const [maskedIban, setMaskedIban] = useState(true);
  const { loading, error, data, refetch } = useQuery(GET_DROPPOINT, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        id: dropPointId,
      },
      dropPointId,
    },
    skip: !dropPointId,
  });

  if (loading) {
    return (
      <Box px="4" py="8">
        <Flex width="100%" height="300px" justifyContent="center" alignItems="center">
          <Spinner />
        </Flex>
      </Box>
    );
  }

  if (error) {
    return (
      <Box px="4" py="8">
        <Flex width="100%" height="300px" justifyContent="center" alignItems="center">
          Ein Fehler ist aufgetreten. Schreib Osamah an.
        </Flex>
      </Box>
    );
  }

  if (!data?.packageStation) {
    return (
      <Box backgroundColor={colorMode === 'light' ? 'gray.100' : 'gray.500'} px="4" py="8">
        <Flex width="100%" height="300px" justifyContent="center" alignItems="center">
          Dieser Nutzer hat keinen DropPoint in seinem Namen oder gehört zu einem Unternehmen.
        </Flex>
      </Box>
    );
  }

  const {
    identificationMethods,
    pickupTimes,
    packageSize,
    packageWeight,
    numberOfPackages,
    stationPhotos,

    accountType,
    taxId,
    accountHolder,
    bankName,
    iban,

    activeCoupon,
    updatedAt,
  } = data?.packageStation || ({} as any);

  return (
    <Grid
      borderLeftWidth="1px"
      borderRightWidth="1px"
      borderBottomWidth="1px"
      templateColumns={['1fr', '1fr 1fr']}
      gridGap="8"
      px="4"
      py="8">
      <Flex gridColumn={['span 1', 'span 2']}>
        <Button colorScheme="brand" onClick={() => refetch()} size="xs" mr="4">
          DropPoint Neu laden
        </Button>
        <Text>Letzte Änderung: {moment(updatedAt).format('DD.MM.YYYY HH:mm')}</Text>
      </Flex>
      <Box>
        <Text>
          <Text as="span" fontWeight="bold">
            Unternehmensart:{' '}
          </Text>
          {accountType === 'COMPANY' ? 'Unternehmen' : 'Privat oder Kleinunternehmer'}
        </Text>
        {taxId ? (
          <Text>
            <Text as="span" fontWeight="bold">
              Steuernummer/USt-IdNr.:{' '}
            </Text>
            {taxId}
          </Text>
        ) : null}
        <Text mt="4">
          <Text as="span" fontWeight="bold">
            Identifizierungsmethode:{' '}
          </Text>
          {identificationMethods.idCheck ? 'Ausweischeck' : 'Fotovergleich'}
        </Text>
        <Text fontWeight="bold">Abholzeiten:</Text>
        {pickupTimes.length > 0
          ? pickupTimes.map(({ from, to }) => {
              return (
                <Text key={from + to}>
                  {getMomentFromTime(from).format('HH:mm')} Uhr - {getMomentFromTime(to).format('HH:mm')} Uhr
                </Text>
              );
            })
          : 'Keine hinterlegt'}
        <Text>
          <Text as="span" fontWeight="bold">
            Paketgröße:{' '}
          </Text>
          {packageSize ? getPackageSizeValue(packageSize) : 'Nicht angegeben'}
        </Text>
        <Text>
          <Text as="span" fontWeight="bold">
            Paketgewicht:{' '}
          </Text>
          {packageWeight ? getPackageWeightValue(packageWeight) : 'Nicht angegeben'}
        </Text>
        <Text mb="4">
          <Text as="span" fontWeight="bold">
            Paketlimit:{' '}
          </Text>
          {numberOfPackages ? `${numberOfPackages} Pakete` : 'Kein Limit'}
        </Text>
        <Text fontWeight="bold" fontSize="lg">
          Auszahlungsmittel:
        </Text>
        {iban ? (
          <>
            <Text color="gray.600">{accountHolder}</Text>
            <Text color="gray.600">{bankName}</Text>
            <Flex alignItems="center">
              <Button size="xs" onClick={() => setMaskedIban(!maskedIban)} mr="2">
                <Icon name={maskedIban ? 'view' : 'view-off'} />
              </Button>
              <Text color="gray.600">{maskedIban ? `DE** **** **** **** **${iban.slice(-4)}` : iban}</Text>
            </Flex>
          </>
        ) : (
          <Text>Noch nicht hinterlegt</Text>
        )}
        {activeCoupon ? (
          <Box mt="4">
            <Text fontWeight="bold" fontSize="lg">
              Aktiver Couponcode:
            </Text>
            <Text fontWeight="bold" color="gray.600">
              {activeCoupon.code}
            </Text>
            <Text color="gray.600">{`Gültig bis: ${moment(activeCoupon.validUntil).format('DD.MM.YYYY')}`}</Text>
          </Box>
        ) : null}
      </Box>
      <Box>
        <Text fontWeight="bold" mb="3">
          Stationsfotos:
        </Text>
        {stationPhotos.length > 0
          ? stationPhotos.map(({ uri }) => {
              return (
                <Box key={uri}>
                  <Image src={uri} />
                </Box>
              );
            })
          : 'Kein Bild hinterlegt'}
      </Box>
    </Grid>
  );
};

export default DropPoint;
