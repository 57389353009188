import React from 'react';
import { Button } from '@chakra-ui/react';
import { IoIosHome, IoMdClose } from 'react-icons/io';

const ActiveDropPointButton = ({ refetch, dispatch, state }) => {
  const handleShowActiveDropPoints = async () => {
    await refetch({
      where: {
        ...state.searchValues,
        packageStations_some: {
          isActive: true,
        },
      },
    });
    dispatch({ type: 'SET_ACTIVE_DROPPOINT', value: true });
  };
  const handleHideActiveDropPoints = async () => {
    await refetch({
      where: {
        ...state.searchValues,
        packageStations_some: {
          isActive: state.inactiveDropPointIsActive ? false : undefined,
          isOnboarded: state.inactiveDropPointIsActive ? true : undefined,
        },
      },
    });
    dispatch({ type: 'SET_ACTIVE_DROPPOINT', value: false });
  };

  return (
    <Button
      leftIcon={state.dropPointIsActive ? <IoMdClose /> : <IoIosHome />}
      onClick={state.dropPointIsActive ? handleHideActiveDropPoints : handleShowActiveDropPoints}
      size="sm"
      mb="2"
      mr="2">
      Aktiv
    </Button>
  );
};

export default ActiveDropPointButton;
