import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalHeader, ModalBody, ModalFooter, Button } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';
import moment from 'moment';

import Input from '../../components/Input';
import NumberInput from '../../components/NumberInput';
import Select from '../../components/Select';
import { CREATE_COUPON } from './query';

const CreateCouponSchema = Yup.object().shape({
  code: Yup.string().required('Das ist ein Pflichtfeld'),
  uses: Yup.number().min(1, 'Eine Anwendung ist das Minimum').required('Das ist ein Pflichtfeld'),
  amount: Yup.number().required('Das ist ein Pflichtfeld'),
});

const CreateCouponModal = ({ isOpen, onClose, refetch }) => {
  const [createCoupon] = useMutation(CREATE_COUPON);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Neuen Coupon erstellen</ModalHeader>

        <Formik
          initialValues={{
            code: '',
            type: 'PERCENT',
            amount: 0,
            uses: 1,
          }}
          validationSchema={CreateCouponSchema}
          onSubmit={async ({ code, type, amount, uses }) => {
            await createCoupon({
              variables: {
                data: {
                  code: code.toUpperCase(),
                  type,
                  amount: type === 'AMOUNT' ? amount * 100 : amount,
                  uses,
                },
              },
            });
            await refetch();
            onClose();
          }}>
          {({ values }) => (
            <Form>
              <ModalBody>
                <Input name="code" label="Coupon-Code" />
                <Select
                  name="type"
                  label="Art des Rabatts?"
                  options={[
                    { label: 'Prozent', value: 'PERCENT' },
                    { label: 'Betrag', value: 'AMOUNT' },
                  ]}
                />

                {values.type === 'PERCENT' ? (
                  <NumberInput name="amount" precision={1} step={0.5} type="number" min={0} max={100} label="Rabatt in %" />
                ) : null}
                {values.type === 'AMOUNT' ? (
                  <NumberInput name="amount" precision={1} step={0.5} type="number" min={0} max={500} label="Rabatt in €" />
                ) : null}

                <NumberInput name="uses" precision={0} step={1} type="number" min={1} max={999999} label="Anwendungen" />
              </ModalBody>

              <ModalFooter>
                <Button variant="ghost" size="sm" colorScheme="red" type="button" onClick={onClose}>
                  Schließen
                </Button>
                <Button ml="4" size="sm" colorScheme="brand" type="submit">
                  Speichern
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};

export default CreateCouponModal;
