import React from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import { IoIosSearch } from 'react-icons/io';

import SearchModal from '../modals/SearchModal';

const UserSearch = ({ state, dispatch }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button leftIcon={<IoIosSearch />} variant="ghost" size="sm" mb="2" onClick={onOpen}>
        DropPoints filtern
      </Button>
      <SearchModal isOpen={isOpen} onClose={onClose} state={state} dispatch={dispatch} />
    </>
  );
};

export default UserSearch;
