import React, { useContext } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Grid,
  CheckboxGroup,
  Checkbox,
  FormControl,
  FormLabel,
  Text,
  Avatar,
  Flex,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';
import get from 'lodash/get';
import Axios from 'axios';

import Input from '../../components/Input';
import Select from '../../components/Select';
import { CHECK_ADDRESS, UPDATE_ADVERTISEMENT } from './query';
import SpinnerContext from '../../contexts/SpinnerContext';
import { formatGoogleResponse, getMomentFromTime } from '../../utils';
import { getEnvironment } from '../../environment';
import DeliveryTimes from './DeliveryTimes';
import { useCustomToast } from '../../hooks/useCustomToast';

const env = getEnvironment();

const CreateAdvertisementSchema = Yup.object().shape({
  title: Yup.string().required('Du musst einen Firmennamen angeben'),
  description: Yup.string().max(200, 'Deine Angabe darf max. 200 Zeichen lang sein'),
  url: Yup.string().matches(
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
    'Der angegebene Link ist nicht valide, der Link muss mit https:// oder http:// beginnen.'
  ),
  address: Yup.object().shape({
    street: Yup.string().required('Das ist ein Pflichtfeld'),
    houseNumber: Yup.string().required('Das ist ein Pflichtfeld'),
    postalCode: Yup.string().required('Das ist ein Pflichtfeld'),
    city: Yup.string().required('Das ist ein Pflichtfeld'),
  }),
});

const categoryOptions = [
  { label: 'Gastronomie', value: 'Gastronomy' },
  { label: 'Film & Kultur', value: 'Culture' },
  { label: 'Bücher & Print', value: 'Book' },
  { label: 'Bäckerei', value: 'Bakery' },
  { label: 'Metzgerei', value: 'Butcher' },
  { label: 'allgemeine Lebensmittel', value: 'Groceries' },
  { label: 'Drogerie- & Reformartikel', value: 'Drugstore' },
  { label: 'Kiosk', value: 'Kiosk' },
  { label: 'Florist', value: 'Florist' },
  { label: 'Handwerker', value: 'Craftsman' },
  { label: 'Sonstiges', value: 'Others' },
];

const EditAdvertisementModal = ({ isOpen, onClose, refetch, advertisement }) => {
  const [updateAdvertisement] = useMutation(UPDATE_ADVERTISEMENT);
  const [checkAddress] = useMutation(CHECK_ADDRESS);
  const { toggleSpinner } = useContext(SpinnerContext);
  const toast = useCustomToast();
  const fileUpload = React.useRef<HTMLInputElement>();

  if (!advertisement) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Neue Werbung schalten</ModalHeader>
        <Formik
          initialValues={{
            ...advertisement,
            deliveryTimes:
              advertisement.deliveryTimes && advertisement.deliveryTimes.length > 0
                ? advertisement.deliveryTimes.map(({ from, to }) => ({
                    from: getMomentFromTime(from),
                    to: getMomentFromTime(to),
                  }))
                : [
                    {
                      from: '',
                      to: '',
                    },
                  ],
            photoUrl: '',
          }}
          validationSchema={CreateAdvertisementSchema}
          onSubmit={async (
            { title, description, url, photo, address: { street, houseNumber, postalCode, city }, delivery, deliveryTimes, category },
            { setFieldError, setFieldTouched, setSubmitting }
          ) => {
            try {
              toggleSpinner(true);

              const locationString = `${street} ${houseNumber}, ${postalCode} ${city}`;

              const {
                data: { checkAddress: addressCheckData },
              } = await checkAddress({
                variables: {
                  address: locationString,
                },
              });

              if (!addressCheckData || !addressCheckData.address) {
                setFieldError(
                  'address.street',
                  'Die Adresse konnte nicht gespeichert werden. Achten Sie auf eine korrekte Schreibweise und korrekte Zuordnung der Postleitzahl.'
                );
                toggleSpinner(false);
                return;
              }

              const { address: formattedAddress, locationId, geometry } = formatGoogleResponse(addressCheckData.address);

              let submitPhoto = null;
              if (photo && !photo.uri) {
                const formData = new FormData();
                formData.append('photo', photo);
                const {
                  data: {
                    uploadedFile: { Location },
                  },
                } = await Axios.post(`${env.apiUrl}/upload-image`, formData);
                submitPhoto = {
                  uri: Location,
                  width: 100,
                  height: 100,
                };
              }

              await updateAdvertisement({
                variables: {
                  where: {
                    id: advertisement.id,
                  },
                  data: {
                    deliveryTimes: {
                      deleteMany: {
                        allDays: true,
                      },
                    },
                  },
                },
              });

              await updateAdvertisement({
                variables: {
                  where: {
                    id: advertisement.id,
                  },
                  data: {
                    title,
                    description,
                    url: (url || '').toLowerCase(),
                    category,
                    delivery: { set: delivery },
                    deliveryTimes:
                      delivery.includes('Delivery') || delivery.includes('Pickup')
                        ? {
                            create: deliveryTimes.map(({ from, to }) => {
                              const newFrom = (from.hour() + 1) * 60 + from.minute();
                              const newTo = (to.hour() + 1) * 60 + to.minute();

                              return {
                                from: newFrom,
                                to: newTo,
                                allDays: true,
                              };
                            }),
                          }
                        : null,
                    address: {
                      create: {
                        ...formattedAddress,
                        locationId,
                        geometry: {
                          create: {
                            ...geometry,
                            coordinates: {
                              set: geometry.coordinates,
                            },
                          },
                        },
                      },
                    },
                    photo: submitPhoto
                      ? {
                          create: submitPhoto,
                        }
                      : null,
                    isActive: true,
                  },
                },
              });
              await refetch();
              toast({
                title: 'Erfolg!',
                description: 'Die Werbung wurden erfolgreich erstellt',
                status: 'success',
              });
              toggleSpinner(false);
              onClose();
            } catch (error) {
              console.log(error);
              toast({
                description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
                status: 'error',
              });
              toggleSpinner(false);
            }
          }}>
          {({ values, setFieldValue }) => {
            const handleUpload = (event) => {
              setFieldValue('photo', event.target.files[0]);
              setFieldValue('photoUrl', URL.createObjectURL(event.target.files[0]));
            };

            const selectedDelivery = values.delivery.includes('Delivery') || values.delivery.includes('Pickup');

            return (
              <Form>
                <ModalBody>
                  <Select options={categoryOptions} label="Kategorie" name="category" />
                  <FormControl mb="4">
                    <FormLabel htmlFor="delivery">Ich biete an...</FormLabel>
                    <CheckboxGroup
                      colorScheme="brand"
                      defaultValue={[]}
                      value={values.delivery}
                      onChange={(value) => setFieldValue('delivery', value)}>
                      <Checkbox value="Delivery">Lieferung</Checkbox>
                      <Checkbox value="Pickup">Abholung</Checkbox>
                      <Checkbox value="Coupon">Gutschein</Checkbox>
                      <Checkbox value="Online">Online</Checkbox>
                    </CheckboxGroup>
                  </FormControl>

                  {selectedDelivery ? <DeliveryTimes /> : null}

                  <Input name="description" label="Beschreibung" />
                  <Input name="url" label="Link" />
                  <Input name="title" label="Firmenname" />
                  <Grid gridGap="4" gridTemplateColumns="2fr 1fr">
                    <Input name="address.street" label="Strasse" />
                    <Input name="address.houseNumber" label="Hausnummer" />
                  </Grid>
                  <Grid gridGap="4" gridTemplateColumns="1fr 1fr">
                    <Input name="address.postalCode" label="PLZ" />
                    <Input name="address.city" label="Stadt" />
                  </Grid>

                  <Flex alignItems="center" mb="3">
                    {values.photoUrl || (values.photo && values.photo.uri) ? (
                      <Avatar src={values.photoUrl || (values.photo && values.photo.uri)} mr="3" />
                    ) : null}
                    <Grid flex="1" templateColumns="1fr 1fr" gridGap="3">
                      <Button size="sm" onClick={() => fileUpload.current.click()}>
                        {values.photoUrl || (values.photo && values.photo.uri) ? 'Bearbeiten' : 'Foto uploaden'}
                      </Button>
                      {values.photoUrl || (values.photo && values.photo.uri) ? (
                        <Button size="sm" colorScheme="red" variant="link" onClick={() => setFieldValue('photo', null)}>
                          Löschen
                        </Button>
                      ) : null}
                    </Grid>
                  </Flex>
                  <Text fontSize="sm" color="gray.600">
                    Bilder bitte maximal 1 MB groß und quadratisch.
                  </Text>
                  <input
                    ref={fileUpload}
                    style={{ visibility: 'hidden', width: 0, height: 0 }}
                    type="file"
                    name="photo"
                    accept="image/png, image/jpeg"
                    onChange={handleUpload}
                  />
                </ModalBody>

                <ModalFooter>
                  <Button variant="ghost" size="sm" colorScheme="red" type="button" onClick={onClose}>
                    Schließen
                  </Button>
                  <Button ml="4" size="sm" colorScheme="brand" type="submit">
                    Speichern
                  </Button>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};

export default EditAdvertisementModal;
