import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Grid, Box, Text, Spinner, Flex, useColorMode, Button } from '@chakra-ui/react';

import Layout from '../../components/Layout';
import { DELETE_ALL_RETOURES, TOTAL_USERS } from './query';
import { useMutation, useQuery } from '@apollo/client';

interface HomeProps extends RouteComponentProps {}

export const StatisticsPage: React.FC<HomeProps> = () => {
  const { colorMode } = useColorMode();
  const { data, loading } = useQuery(TOTAL_USERS, {
    fetchPolicy: 'network-only',
    pollInterval: 2000,
  });
  const [deleteAllRetoures] = useMutation(DELETE_ALL_RETOURES);

  return (
    <Layout>
      <Text as="h2" fontSize="4xl" fontWeight="bold" flex="1" fontFamily="heading" mb="8">
        Statistiken
      </Text>
      <Text as="h3" fontSize="2xl" fontWeight="bold" flex="1" fontFamily="heading" mb="6">
        Live LeadQuest-Daten
      </Text>
      <Grid gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr 1fr']} gridGap="4" mb="4">
        <Box rounded="md" borderWidth="1px" p="6" _hover={{ boxShadow: 'md' }} bg={colorMode === 'light' ? 'gray.50' : 'gray.700'}>
          {loading ? (
            <Spinner />
          ) : (
            <Flex direction="column">
              <Text fontSize="5xl" color="green.500" fontWeight="bold" fontFamily="heading" mr="2" lineHeight="36px">
                {data.totalInternalEssentialsInvestors?.total}
              </Text>
              <Text fontSize="xl" fontFamily="heading" mb="3">
                Gesamt Investoren-Leads
              </Text>
              <Flex color={colorMode === 'light' ? 'gray.700' : 'gray.300'} justifyContent="space-between">
                <span>Neue Investoren-Leads heute: </span>
                <strong>{data.totalInternalEssentialsInvestors?.totalToday}</strong>
              </Flex>
              <Flex color={colorMode === 'light' ? 'gray.700' : 'gray.300'} justifyContent="space-between">
                <span>Neue Konten letzte 7 Tage: </span>
                <strong>{data.totalInternalEssentialsInvestors?.totalThisWeek}</strong>
              </Flex>
            </Flex>
          )}
        </Box>
        <Box rounded="md" borderWidth="1px" p="6" _hover={{ boxShadow: 'md' }} bg={colorMode === 'light' ? 'gray.50' : 'gray.700'}>
          {loading ? (
            <Spinner />
          ) : (
            <Flex direction="column">
              <Text fontSize="5xl" color="green.500" fontWeight="bold" fontFamily="heading" mr="2" lineHeight="36px">
                {data.totalInternalEssentialsLeads?.total}
              </Text>
              <Text fontSize="xl" fontFamily="heading" mb="3">
                Gesamt Leads
              </Text>
              <Flex color={colorMode === 'light' ? 'gray.700' : 'gray.300'} justifyContent="space-between">
                <span>Neue Leads heute: </span>
                <strong>{data.totalInternalEssentialsLeads?.totalToday}</strong>
              </Flex>
              <Flex color={colorMode === 'light' ? 'gray.700' : 'gray.300'} justifyContent="space-between">
                <span>Neue Leads letzte 7 Tage: </span>
                <strong>{data.totalInternalEssentialsLeads?.totalThisWeek}</strong>
              </Flex>
            </Flex>
          )}
        </Box>
        <Box rounded="md" borderWidth="1px" p="6" _hover={{ boxShadow: 'md' }} bg={colorMode === 'light' ? 'gray.50' : 'gray.700'}>
          {loading ? (
            <Spinner />
          ) : (
            <Flex direction="column">
              <Text fontSize="5xl" color="green.500" fontWeight="bold" fontFamily="heading" mr="2" lineHeight="36px">
                {parseFloat(data.averageEssentialsLeads).toFixed(2)}
              </Text>
              <Text fontSize="xl" fontFamily="heading">
                Durchschnittliche Leads pro Konto
              </Text>
            </Flex>
          )}
        </Box>
      </Grid>
      <Grid gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr 1fr']} gridGap="4" mb="12">
        <Box
          rounded="md"
          borderWidth="1px"
          p="6"
          _hover={{ boxShadow: 'md' }}
          bg={colorMode === 'light' ? 'gray.50' : 'gray.700'}
          gridColumn="span 2">
          {loading ? (
            <Spinner />
          ) : (
            <Flex direction="column">
              <Text fontSize="5xl" color="green.500" fontWeight="bold" fontFamily="heading" mr="2" lineHeight="36px">
                {data.totalEssentialsAccounts.total}
              </Text>
              <Text fontSize="xl" fontFamily="heading" mb="3">
                Registrierte Konten
              </Text>
              <Flex color={colorMode === 'light' ? 'gray.700' : 'gray.300'} justifyContent="space-between">
                <span>Neue Konten heute: </span>
                <strong>{data.totalEssentialsAccounts.totalToday}</strong>
              </Flex>
              <Flex color={colorMode === 'light' ? 'gray.700' : 'gray.300'} justifyContent="space-between">
                <span>Neue Konten letzte 7 Tage: </span>
                <strong>{data.totalEssentialsAccounts.totalThisWeek}</strong>
              </Flex>
            </Flex>
          )}
        </Box>
        <Box rounded="md" borderWidth="1px" p="6" _hover={{ boxShadow: 'md' }} bg={colorMode === 'light' ? 'gray.50' : 'gray.700'}>
          {loading ? (
            <Spinner />
          ) : (
            <Flex direction="column">
              <Text fontSize="5xl" color="green.500" fontWeight="bold" fontFamily="heading" mr="2" lineHeight="36px">
                {data.totalStarter.total}
              </Text>
              <Text fontSize="xl" fontFamily="heading" mb="3">
                Starter-Abos
              </Text>
              <Flex color={colorMode === 'light' ? 'gray.700' : 'gray.300'} justifyContent="space-between">
                <span>Neue Starter-Abos heute: </span>
                <strong>{data.totalStarter.totalToday}</strong>
              </Flex>
              <Flex color={colorMode === 'light' ? 'gray.700' : 'gray.300'} justifyContent="space-between">
                <span>Neue Starter-Abos letzte 7 Tage: </span>
                <strong>{data.totalStarter.totalThisWeek}</strong>
              </Flex>
            </Flex>
          )}
        </Box>
        <Box rounded="md" borderWidth="1px" p="6" _hover={{ boxShadow: 'md' }} bg={colorMode === 'light' ? 'gray.50' : 'gray.700'}>
          {loading ? (
            <Spinner />
          ) : (
            <Flex direction="column">
              <Text fontSize="5xl" color="green.500" fontWeight="bold" fontFamily="heading" mr="2" lineHeight="36px">
                {data.totalBasic.total}
              </Text>
              <Text fontSize="xl" fontFamily="heading" mb="3">
                Basic-Abos
              </Text>
              <Flex color={colorMode === 'light' ? 'gray.700' : 'gray.300'} justifyContent="space-between">
                <span>Neue Basic-Abos heute: </span>
                <strong>{data.totalBasic.totalToday}</strong>
              </Flex>
              <Flex color={colorMode === 'light' ? 'gray.700' : 'gray.300'} justifyContent="space-between">
                <span>Neue Basic-Abos letzte 7 Tage: </span>
                <strong>{data.totalBasic.totalThisWeek}</strong>
              </Flex>
            </Flex>
          )}
        </Box>
        <Box rounded="md" borderWidth="1px" p="6" _hover={{ boxShadow: 'md' }} bg={colorMode === 'light' ? 'gray.50' : 'gray.700'}>
          {loading ? (
            <Spinner />
          ) : (
            <Flex direction="column">
              <Text fontSize="5xl" color="green.500" fontWeight="bold" fontFamily="heading" mr="2" lineHeight="36px">
                {data.totalProfessional.total}
              </Text>
              <Text fontSize="xl" fontFamily="heading" mb="3">
                Professional-Abos
              </Text>
              <Flex color={colorMode === 'light' ? 'gray.700' : 'gray.300'} justifyContent="space-between">
                <span>Neue Professional-Abos heute: </span>
                <strong>{data.totalProfessional.totalToday}</strong>
              </Flex>
              <Flex color={colorMode === 'light' ? 'gray.700' : 'gray.300'} justifyContent="space-between">
                <span>Neue Professional-Abos letzte 7 Tage: </span>
                <strong>{data.totalProfessional.totalThisWeek}</strong>
              </Flex>
            </Flex>
          )}
        </Box>
        <Box rounded="md" borderWidth="1px" p="6" _hover={{ boxShadow: 'md' }} bg={colorMode === 'light' ? 'gray.50' : 'gray.700'}>
          {loading ? (
            <Spinner />
          ) : (
            <Flex direction="column">
              <Text fontSize="5xl" color="green.500" fontWeight="bold" fontFamily="heading" mr="2" lineHeight="36px">
                {data.totalPremium.total}
              </Text>
              <Text fontSize="xl" fontFamily="heading" mb="3">
                Premium-Abos
              </Text>
              <Flex color={colorMode === 'light' ? 'gray.700' : 'gray.300'} justifyContent="space-between">
                <span>Neue Premium-Abos heute: </span>
                <strong>{data.totalPremium.totalToday}</strong>
              </Flex>
              <Flex color={colorMode === 'light' ? 'gray.700' : 'gray.300'} justifyContent="space-between">
                <span>Neue Premium-Abos letzte 7 Tage: </span>
                <strong>{data.totalPremium.totalThisWeek}</strong>
              </Flex>
            </Flex>
          )}
        </Box>
      </Grid>
      <Text as="h3" fontSize="2xl" fontWeight="bold" flex="1" fontFamily="heading" mb="6">
        Live Nutzer-Daten
      </Text>
      <Grid gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr 1fr']} gridGap="4" mb="12">
        <Box rounded="md" borderWidth="1px" p="6" _hover={{ boxShadow: 'md' }} bg={colorMode === 'light' ? 'gray.50' : 'gray.700'}>
          {loading ? (
            <Spinner />
          ) : (
            <Flex direction="column">
              <Text fontSize="5xl" color="green.500" fontWeight="bold" fontFamily="heading" mr="2" lineHeight="36px">
                {data.totalUsers.total}
              </Text>
              <Text fontSize="xl" fontFamily="heading" mb="3">
                Registrierte Nutzer
              </Text>
              <Flex color={colorMode === 'light' ? 'gray.700' : 'gray.300'} justifyContent="space-between">
                <span>Neue Nutzer heute: </span>
                <strong>{data.totalUsers.totalToday}</strong>
              </Flex>
              <Flex color={colorMode === 'light' ? 'gray.700' : 'gray.300'} justifyContent="space-between">
                <span>Neue Nutzer letzte 7 Tage: </span>
                <strong>{data.totalUsers.totalThisWeek}</strong>
              </Flex>
            </Flex>
          )}
        </Box>
        <Box rounded="md" borderWidth="1px" p="6" _hover={{ boxShadow: 'md' }} bg={colorMode === 'light' ? 'gray.50' : 'gray.700'}>
          {loading ? (
            <Spinner />
          ) : (
            <Flex direction="column">
              <Text fontSize="5xl" color="green.500" fontWeight="bold" fontFamily="heading" mr="2" lineHeight="36px">
                {data.totalDropPoints.total}
              </Text>
              <Text fontSize="xl" fontFamily="heading" mb="3">
                Registrierte DropPoints
              </Text>
              <Flex color={colorMode === 'light' ? 'gray.700' : 'gray.300'} justifyContent="space-between">
                <span>Neue DropPoints heute: </span>
                <strong>{data.totalDropPoints.totalToday}</strong>
              </Flex>
              <Flex color={colorMode === 'light' ? 'gray.700' : 'gray.300'} justifyContent="space-between">
                <span>Neue DropPoints letzte 7 Tage: </span>
                <strong>{data.totalDropPoints.totalThisWeek}</strong>
              </Flex>
            </Flex>
          )}
        </Box>
        <Box rounded="md" borderWidth="1px" p="6" _hover={{ boxShadow: 'md' }} bg={colorMode === 'light' ? 'gray.50' : 'gray.700'}>
          {loading ? (
            <Spinner />
          ) : (
            <Flex direction="column">
              <Text fontSize="5xl" color="green.500" fontWeight="bold" fontFamily="heading" mr="2" lineHeight="36px">
                {data.totalActiveDropPoints.total}
              </Text>
              <Text fontSize="xl" fontFamily="heading" mb="3">
                Aktive DropPoints
              </Text>
              <Flex color={colorMode === 'light' ? 'gray.700' : 'gray.300'} justifyContent="space-between">
                <span>Neue Aktive DropPoints heute: </span>
                <strong>{data.totalActiveDropPoints.totalToday}</strong>
              </Flex>
              <Flex color={colorMode === 'light' ? 'gray.700' : 'gray.300'} justifyContent="space-between">
                <span>Neue Aktive DropPoints letzte 7 Tage: </span>
                <strong>{data.totalActiveDropPoints.totalThisWeek}</strong>
              </Flex>
            </Flex>
          )}
        </Box>
      </Grid>
      <Text as="h3" fontSize="2xl" fontWeight="bold" flex="1" fontFamily="heading" mb="6">
        Live Werbungsdaten
      </Text>
      <Grid gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr 1fr']} gridGap="4" mb="12">
        <Box rounded="md" borderWidth="1px" p="6" _hover={{ boxShadow: 'md' }} bg={colorMode === 'light' ? 'gray.50' : 'gray.700'}>
          {loading ? (
            <Spinner />
          ) : (
            <Flex direction="column">
              <Text fontSize="5xl" color="green.500" fontWeight="bold" fontFamily="heading" mr="2" lineHeight="36px">
                {data.totalAdvertisements.total}
              </Text>
              <Text fontSize="xl" fontFamily="heading" mb="3">
                Registrierte Werbungen
              </Text>
              <Flex color={colorMode === 'light' ? 'gray.700' : 'gray.300'} justifyContent="space-between">
                <span>Neue Werbungen heute: </span>
                <strong>{data.totalAdvertisements.totalToday}</strong>
              </Flex>
              <Flex color={colorMode === 'light' ? 'gray.700' : 'gray.300'} justifyContent="space-between">
                <span>Neue Werbungen letzte 7 Tage: </span>
                <strong>{data.totalAdvertisements.totalThisWeek}</strong>
              </Flex>
            </Flex>
          )}
        </Box>
        <Box rounded="md" borderWidth="1px" p="6" _hover={{ boxShadow: 'md' }} bg={colorMode === 'light' ? 'gray.50' : 'gray.700'}>
          {loading ? (
            <Spinner />
          ) : (
            <Flex direction="column">
              <Text fontSize="5xl" color="green.500" fontWeight="bold" fontFamily="heading" mr="2" lineHeight="36px">
                {data.totalActiveAdvertisements.total}
              </Text>
              <Text fontSize="xl" fontFamily="heading" mb="3">
                Aktive Werbungen
              </Text>
              <Flex color={colorMode === 'light' ? 'gray.700' : 'gray.300'} justifyContent="space-between">
                <span>Neue Aktive Werbungen heute: </span>
                <strong>{data.totalActiveAdvertisements.totalToday}</strong>
              </Flex>
              <Flex color={colorMode === 'light' ? 'gray.700' : 'gray.300'} justifyContent="space-between">
                <span>Neue Aktive Werbungen letzte 7 Tage: </span>
                <strong>{data.totalActiveAdvertisements.totalThisWeek}</strong>
              </Flex>
            </Flex>
          )}
        </Box>
        <Box rounded="md" borderWidth="1px" p="6" _hover={{ boxShadow: 'md' }} bg={colorMode === 'light' ? 'gray.50' : 'gray.700'}>
          {loading ? (
            <Spinner />
          ) : (
            <Flex direction="column">
              <Text fontSize="5xl" color="green.500" fontWeight="bold" fontFamily="heading" mr="2" lineHeight="36px">
                {data.totalInactiveAdvertisements.total}
              </Text>
              <Text fontSize="xl" fontFamily="heading" mb="3">
                Inaktive Werbungen
              </Text>
              <Flex color={colorMode === 'light' ? 'gray.700' : 'gray.300'} justifyContent="space-between">
                <span>Neue Inaktive Werbungen heute: </span>
                <strong>{data.totalInactiveAdvertisements.totalToday}</strong>
              </Flex>
              <Flex color={colorMode === 'light' ? 'gray.700' : 'gray.300'} justifyContent="space-between">
                <span>Neue Inaktive Werbungen letzte 7 Tage: </span>
                <strong>{data.totalInactiveAdvertisements.totalThisWeek}</strong>
              </Flex>
            </Flex>
          )}
        </Box>
      </Grid>
      <Text as="h3" fontSize="2xl" fontWeight="bold" flex="1" fontFamily="heading" mb="6">
        Live Bestellungs-Daten
      </Text>
      <Grid gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr 1fr']} gridGap="8" mb="12">
        <Box>
          {loading ? (
            <Spinner />
          ) : (
            <Flex direction="column">
              <Text fontSize="5xl" color="green.500" fontWeight="bold" fontFamily="heading" mr="2" lineHeight="36px">
                {data.totalOrders}
              </Text>
              <Text fontSize="xl" fontFamily="heading">
                Gesamtanzahl Bestellung
              </Text>
            </Flex>
          )}
        </Box>
        <Box>
          {loading ? (
            <Spinner />
          ) : (
            <Flex direction="column">
              <Text fontSize="5xl" color="green.500" fontWeight="bold" fontFamily="heading" mr="2" lineHeight="36px">
                {data.totalOpenOrders}
              </Text>
              <Text fontSize="xl" fontFamily="heading">
                offene Bestellungen
              </Text>
            </Flex>
          )}
        </Box>
        <Box>
          {loading ? (
            <Spinner />
          ) : (
            <Flex direction="column">
              <Text fontSize="5xl" color="green.500" fontWeight="bold" fontFamily="heading" mr="2" lineHeight="36px">
                {data.totalOrdersCurrentMonth}
              </Text>
              <Text fontSize="xl" fontFamily="heading">
                Bestellungen in diesem Monat
              </Text>
            </Flex>
          )}
        </Box>
        <Box>
          {loading ? (
            <Spinner />
          ) : (
            <Flex direction="column">
              <Text fontSize="5xl" color="green.500" fontWeight="bold" fontFamily="heading" mr="2" lineHeight="36px">
                {data.totalOrdersCurrentYear}
              </Text>
              <Text fontSize="xl" fontFamily="heading">
                Bestellungen in diesem Jahr
              </Text>
            </Flex>
          )}
        </Box>
      </Grid>
      {process.env.REACT_APP_ENV === 'staging' || process.env.NODE_ENV === 'development' ? (
        <>
          <Flex alignItems="center" justifyContent="space-between" mb="6">
            <Text as="h3" fontSize="2xl" fontWeight="bold" flex="1" fontFamily="heading">
              Live Retouren-Daten
            </Text>
            <Button onClick={() => deleteAllRetoures()}>Alle Retouren löschen</Button>
          </Flex>
          <Grid gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr 1fr']} gridGap="8" mb="12">
            <Box>Coming soon</Box>
          </Grid>
        </>
      ) : null}
    </Layout>
  );
};
