import React from 'react';
import { Text, Flex, Box, Button, useColorMode, Heading, Spinner, Alert, AlertIcon, AlertTitle, AlertDescription } from '@chakra-ui/react';
import { get } from 'lodash';
import useMedia from 'use-media';
import { useMutation } from '@apollo/client';

import { GENERATE_POTENTIAL_CALCULATOR_PDF } from './query';
import { useCustomToast } from '../../hooks/useCustomToast';
import { useSpinner } from '../../contexts/SpinnerContext';

const Results = ({ returnToForm, calculationResults, loading, values }) => {
  const { colorMode } = useColorMode();
  const { toggleSpinner } = useSpinner();
  const toast = useCustomToast();
  const isWide = useMedia({ minWidth: 1264 });
  const isMobile = useMedia({ maxWidth: '767px' });
  const [generatePDF] = useMutation(GENERATE_POTENTIAL_CALCULATOR_PDF);

  if (!loading && !calculationResults) {
    return (
      <Flex height="100%" justifyContent="center" alignItems="flex-start" direction="column">
        <Button mb="4" variant="link" onClick={returnToForm} size={isMobile ? 'xs' : 'sm'}>
          {'< Zurück'}
        </Button>
        <Box>
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>Achtung</AlertTitle>
            <AlertDescription ml={isMobile ? '3' : null}>Bitte überprüfen Sie Ihre Angaben noch einmal. </AlertDescription>
          </Alert>
        </Box>
      </Flex>
    );
  }

  const handlePDF = async () => {
    try {
      toggleSpinner(true);
      const {
        data: { generatePotentialCalculatorPDF },
      } = await generatePDF({
        variables: {
          ...values,
        },
      });
      window.open(generatePotentialCalculatorPDF, '_blank');
    } catch (error) {
      console.log(error);

      toast({
        description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
        status: 'error',
      });
    }
    toggleSpinner(false);
  };

  return (
    <Flex height="100%" justifyContent="center" alignItems="flex-start" direction="column">
      <Button mb="4" variant="link" onClick={returnToForm} size={isMobile ? 'xs' : 'sm'}>
        {'< Zurück'}
      </Button>

      <Box
        maxWidth={isWide ? '500px' : '440px'}
        width="100%"
        alignItems="center"
        borderWidth="1px"
        rounded="lg"
        bg={colorMode === 'light' ? 'white' : 'gray.700'}
        p="7">
        {loading ? (
          <Flex justifyContent="center" py="4">
            <Spinner />
          </Flex>
        ) : (
          <>
            <Heading fontSize="xl" textAlign="center" fontWeight="600">
              In Ihrer Postleitzahl haben Sie ein bisher ungenutztes Laufkundschaftspotenzial von:
            </Heading>

            <Heading fontSize="4xl" color="green.400" fontWeight="900" mb="6">
              {Intl.NumberFormat('de-DE', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(
                calculationResults?.walkInIncreaseMin / 100
              )}{' '}
              -{' '}
              {Intl.NumberFormat('de-DE', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(
                calculationResults?.walkInIncreaseMax / 100
              )}{' '}
              p.m.
            </Heading>

            {calculationResults?.revenuePotentialMin && calculationResults?.revenuePotentialMax ? (
              <Box textAlign="center" fontSize="lg" my="6">
                <Text fontSize="md" fontWeight="400" color={colorMode === 'light' ? 'gray.600' : 'gray.400'}>
                  Zu Deinem aktuellen Umsatz ergibt sich hieraus ein <u>zusätzliches</u> Umsatzpotenzial von{' '}
                  <Box as="span" fontWeight="bolder" fontSize="lg" color="blue.500">
                    {Intl.NumberFormat('de', { style: 'currency', currency: 'EUR' }).format(
                      Math.round(calculationResults?.revenuePotentialMin)
                    )}{' '}
                    bis{' '}
                    {Intl.NumberFormat('de', { style: 'currency', currency: 'EUR' }).format(
                      Math.round(calculationResults?.revenuePotentialMax)
                    )}
                  </Box>{' '}
                  pro Monat.
                </Text>

                {/* <Box mb="5">
                In deiner PLZ werden im Schnitt
                <span style={{ fontWeight: 'bolder', marginRight: '5px' }}>{calculationResults?.potentialData?.parcelCount}</span>
                Pakete geliefert.
              </Box> */}

                {/* <Text mb="4" fontSize="lg" color="green.500">
                Zusätzlich liegt Dein potentieller Umsatzanstieg bei einem Couponwert von x% bei Ergebnis aus der zweiten Rechnung
              </Text>
              <Text fontSize="lg" fontWeight="400" mb="5">
                Das bedeutet, dass Du pro Monat ca. <span style={{ fontWeight: 'bolder' }}> 500</span> mehr Kunden bekommen kannst.
              </Text> */}

                {/* <Text fontSize="xs" mb="5">
                Für eine detaillierte Analyse, kontakiere uns jetzt.
              </Text>
              <Button colorScheme="purple" width="50%" type="submit">
                Kontaktiere uns
              </Button> */}
              </Box>
            ) : null}
          </>
        )}

        <Button colorScheme="green" width="50%" onClick={handlePDF}>
          als PDF exportieren
        </Button>
      </Box>
    </Flex>
  );
};

export default Results;
