import React, { createContext, useState, useEffect, useContext } from 'react';
import decode from 'jwt-decode';
import Cookies from 'js-cookie';
import { navigate } from '@reach/router';
import { client } from '../client';
import { USER_ACCESS, UPDATE_USER } from './query';

interface UserContextProps {
  user?: any;
  loading: boolean;
  setToken: (token: string) => void;
  access?: string[];
  checkAccess: (key: string) => boolean;
  logOut: () => void;
}

const UserContext = createContext<Partial<UserContextProps>>({
  user: null,
});

export const UserProvider: React.FC<any> = ({ children }) => {
  const [user, setUser] = useState<any>(null);
  const [access, setAccess] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  const setToken = (token) => {
    const decoded = decode(token);

    if (decoded) {
      setUser(decoded);
      Cookies.set('userToken', token);
    }
  };

  const logOut = () => {
    Cookies.remove('userToken');
    setUser(null);
    navigate('/');
  };

  const fetchAccess = async () => {
    try {
      const { data } = await client.query({
        query: USER_ACCESS,
        variables: {
          userId: user.id,
        },
      });

      const accesses = data?.internalUser?.access?.map(({ key }) => key);
      setAccess(accesses || []);

      const subscription = Cookies.get('pushToken');
      if (subscription) {
        await client.mutate({
          mutation: UPDATE_USER,
          variables: {
            userId: user.id,
            subscription: JSON.parse(subscription),
          },
        });
      }
    } catch (error) {}
  };

  const checkAccess = (key) => {
    if (access.includes('EMPEROR')) return true;
    if (access.includes(key)) return true;
    return false;
  };

  useEffect(() => {
    const userToken = Cookies.get('userToken');
    if (userToken) {
      const decoded = decode(userToken);

      setUser(decoded);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (user) {
      fetchAccess();
    }
  }, [user]);

  return <UserContext.Provider value={{ user, loading, access, checkAccess, setToken, logOut }}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const user = useContext(UserContext);

  return user;
};

export default UserContext;
