import React from 'react';

const WideLogo = ({ className = '', size = 24 }) => {
  return (
    <svg height={size} viewBox="0 0 1149.4 202" className={className}>
      <path
        fill="#4A808C"
        d="M34.5,200.6c-8.6-2.8-14.2-6.2-20.5-12.6c-7.2-7.2-11.5-14.9-13-23c-1.3-7.1-1.3-120.9,0-128
		C4.1,20.7,19.6,4.9,36.3,1c2.5-0.6,29.2-1,65.2-1c66.2,0,66.3,0,76.5,5.6c11.9,6.5,21.8,20.7,24,34.4c1.3,8.6,1.3,113.4,0,122
		c-2.8,17.9-17.2,34-34.4,38.6C160.4,202.5,40.5,202.5,34.5,200.6z"
      />
      <g>
        <path
          fill="#4A808C"
          d="M527,130.5V68h9.3h9.2l1.7,4l1.6,3.9l7.2-3.7c7-3.6,7.7-3.7,17.4-3.7c9.5,0,10.5,0.2,14.4,2.8
		c8.7,5.7,12.2,15.3,12.9,35.3c1,25.5-4.3,39.5-16.6,43.9c-7.4,2.6-22.2,1.4-32.6-2.5l-2.5-1v23v23h-11h-11V130.5z M575.3,130.8
		c4.4-5.9,4.6-33.5,0.3-40.1c-3.4-5.2-10.1-5.6-21.3-1.5l-5.3,1.9v20.4v20.4l5.3,1C566,135.4,572.4,134.7,575.3,130.8z"
        />
        <path
          fill="#4A808C"
          d="M459.4,150.5c-19.3-4.2-25.2-15-24.1-44.2c0.6-19.5,3.5-27.5,12.2-33.5c5.8-3.9,12.8-5.1,28.4-4.6
		c12.3,0.4,14.1,0.7,18.7,3.1c6.3,3.2,9.6,7,12.5,14.4c2.1,5.3,2.3,7.4,2.3,24.3c0.1,15.3-0.2,19.3-1.7,23
		c-4.3,10.9-9.7,15.1-22.2,17.5C476.2,152.3,467.6,152.3,459.4,150.5z M480.2,132.9c5.6-1.5,7-5,7.6-18.9c0.5-14.1-0.9-22.5-4.3-26
		c-2.3-2.3-3.3-2.5-11-2.5s-8.7,0.2-11,2.5c-3.1,3.1-4.5,10.1-4.5,22.1c0,10.6,1.5,18.1,4,20.4C464.6,133.8,473.2,134.9,480.2,132.9
		z"
        />
        <path
          fill="#4A808C"
          d="M832.9,150.6c-13.2-3.8-17.9-14.6-17.9-41.2c0-23.2,3.6-33.1,14-38.2c5-2.4,6.7-2.7,19.6-3
		c22.2-0.6,29.4,1.8,34.3,11.3c2.3,4.5,2.6,6.2,2.6,16.2c0,10.9-0.1,11.2-3.1,15c-5.4,6.7-7.5,7.3-27.8,7.3h-17.9l0.7,4.7
		c1.4,10.4,5,11.8,27.6,10.9c8.5-0.3,16.5-0.9,17.8-1.2c2.1-0.6,2.2-0.4,2.2,6.9c0,4.2-0.5,7.9-1.1,8.2
		C878.9,150.7,840.9,153,832.9,150.6z M862,101c1.1-0.6,1.5-2.6,1.4-6.6c0-7.9-2-9.4-12.3-9.4c-5.3,0-8.3,0.5-9.8,1.5
		c-2.4,1.7-4.3,7.1-4.3,12.3v3.2h11.8C855.2,102,861.2,101.5,862,101z"
        />
        <path
          fill="#4A808C"
          d="M1008,150c-13-4.4-19.1-20.9-17.6-47.5c1.3-22.2,6.4-31.6,18.6-34.5c7.7-1.8,22.7-0.2,31.3,3.5
		c1.6,0.7,1.7-0.7,1.7-20.9V29h11h11v61v61h-9h-9l-2-3.9l-2-4l-4.2,2.5c-5.6,3.2-16,6.4-20.7,6.4C1015.1,151.9,1011,151.1,1008,150z
		 M1037,130l4.5-1.8l0.3-20.5l0.2-20.5l-4.4-1.1c-7.2-1.8-19-1.4-20.9,0.7c-2.8,3.1-3.9,10.5-3.5,24.4c0.3,11.8,0.6,13.7,2.6,17
		c1.3,2,3.1,3.9,4,4.1C1022.7,133.1,1032.3,131.8,1037,130z"
        />
        <path
          fill="#4A808C"
          d="M1108.5,151.3c-9.1-0.6-21.8-2.4-23.7-3.4c-1.5-0.8-1.8-2.2-1.8-7.9v-7h20.8c14.8,0,21.1-0.3,22-1.2
		c0.7-0.7,1.2-3.3,1.2-6c0-3.7-0.4-5-1.9-5.8c-1.1-0.5-7.6-1-14.6-1c-6.9,0-14.6-0.5-17.1-1.1c-5.7-1.5-10.8-6.2-12.3-11.4
		c-1.4-5-1.4-20.8-0.1-25.7c1.4-4.8,5.5-9.7,9.8-11.5c5.9-2.5,45.3-1.5,52.6,1.3c1.3,0.5,1.6,2.1,1.6,8V86h-20.3
		c-14.5,0-20.6,0.3-21.5,1.2c-1.6,1.6-1.5,10.3,0.1,11.6c0.7,0.6,8.2,1.2,17.3,1.4c15.6,0.3,16.2,0.4,20.2,3.1
		c2.3,1.5,5.2,4.6,6.4,7c2,3.7,2.2,5.7,2.2,15.7c0,12.1-1,15.6-5.7,20.8C1139.5,151.3,1129.3,152.7,1108.5,151.3z"
        />
        <path
          fill="#4A808C"
          d="M265,94V36.9l29.8,0.3l29.7,0.3l5.5,2.7c6.3,3.1,8.3,4.7,12.6,10.3c7,9.2,10.4,23.4,10.4,43.9
		c-0.1,28.1-7.4,45.3-22.8,52.8l-6.7,3.3l-29.2,0.3l-29.3,0.4V94z M319,129.2c7.3-3.6,10.8-12.8,11.7-30.2c1-20.7-2.4-34.8-9.7-39.7
		c-3.1-2.1-4.4-2.3-18.6-2.3H287v37v37h14.3C312.8,131,316.2,130.7,319,129.2z"
        />
        <path
          fill="#4A808C"
          d="M370,109.5V68h9.4c9.1,0,9.4,0.1,10,2.5c0.3,1.3,1.1,3.6,1.6,5l1,2.5l5.1-3.9c6.4-4.8,10.6-6.1,20.3-6.1h7.6
		v10.8v10.8h-9.7c-11.3-0.1-15.9,1.2-20.2,5.6l-3.1,3.2v26.3V151h-11h-11V109.5z"
        />
        <path
          fill="#4A808C"
          d="M620,103.1c0-51.5,0.2-53.4,5.3-58.9c3.5-3.8,9.7-6.4,16.2-6.9c6.7-0.6,49.4,1.4,51.9,2.3
		c1.3,0.5,1.6,2.3,1.6,9.5V58h-24.5c-30.4,0-28.5-1-28.5,15v11l6.3,0.1c3.4,0.1,13.6,0.5,22.7,0.8l16.5,0.6l0.3,9.1l0.3,9.1
		l-15.2,0.6c-8.4,0.4-18.7,0.7-23.1,0.7H642v23v23h-11h-11V103.1z"
        />
        <path
          fill="#4A808C"
          d="M707,109.5V68h9.4h9.4l1.7,5l1.7,5l4.8-3.5c7.2-5.3,10.9-6.5,20-6.5h8v10.8v10.7h-10.2
		c-11.1,0.1-14.6,1.2-20,6.3l-2.8,2.6v26.3V151h-11h-11V109.5z"
        />
        <path fill="#4A808C" d="M776,109.5V68h11h11v41.5V151h-11h-11V109.5z" />
        <path
          fill="#4A808C"
          d="M902,109.5V68h9h9l1.6,4c1.7,4.3,2.8,4.8,5.3,2.6c0.9-0.8,4-2.6,6.9-4.1c10.8-5.4,27.3-4.3,33.4,2.3
		c6.7,7.1,7.8,13.8,7.8,50.5V151h-10.9h-10.9l-0.4-29.9c-0.3-28.6-0.4-30.1-2.4-32.8c-1.8-2.4-2.9-2.8-7.5-3.1
		c-5.7-0.3-12.1,1.6-16.3,4.9l-2.6,2v29.5V151h-11h-11V109.5z"
        />
        <path
          fill="#4A808C"
          d="M776.2,58.8c-0.7-0.7-1.2-4.4-1.2-9.8s0.5-9.1,1.2-9.8c1.6-1.6,20-1.6,21.6,0c1.3,1.3,1.7,16.3,0.6,19.2
		c-0.5,1.3-2.4,1.6-10.8,1.6C781,60,777,59.6,776.2,58.8z"
        />
      </g>
      <g>
        <path
          fill="#93BFBF"
          d="M34.5,200.6c-8.6-2.8-14.2-6.2-20.5-12.6c-7.2-7.2-11.5-14.9-13-23c-1.3-7.1-1.3-120.9,0-128
		C4.1,20.7,19.6,4.9,36.3,1c2.5-0.6,29.2-1,65.2-1c66.2,0,66.3,0,76.5,5.6c11.9,6.5,21.8,20.7,24,34.4c1.3,8.6,1.3,113.4,0,122
		c-2.8,17.9-17.2,34-34.4,38.6C160.4,202.5,40.5,202.5,34.5,200.6z M108.5,158.9c3.9-5.5,9-12.9,11.5-16.4
		c23.3-33.1,26-37.8,28.1-48.5c2.5-12.6-0.8-31.2-7.1-40c-1.7-2.4-2-4.4-2-13.3V30.1l2.8-0.3c2.2-0.2,2.8-0.8,3-3.5l0.3-3.3h-14H117
		v3.5c0,3.1,0.3,3.5,2.5,3.5c2.2,0,2.5,0.4,2.5,3.6c0,3.1-0.3,3.5-1.7,2.9c-9.2-4-23.5-4.6-33.4-1.5C60.8,43,47.1,71.9,56,100.3
		c2.1,6.6,5.1,11.5,26.1,41.7c4.4,6.3,10.4,15,13.2,19.2c2.9,4.3,5.4,7.8,5.7,7.8C101.3,169,104.7,164.5,108.5,158.9z"
        />
        <path
          fill="#93BFBF"
          d="M95.5,108.1c-2.7-1.5-6.6-3.7-8.5-4.8s-5.5-3.2-8-4.6l-4.5-2.5l-0.3-15.5L74,65.2l11.2-6.5
		c17.6-10.2,14.5-10,29.4-1.5l12.9,7.4v15.9v15.9l-6,3.4c-3.3,1.9-7.5,4.3-9.2,5.3c-8,4.6-10.4,5.9-11.1,5.9
		C100.8,111,98.3,109.7,95.5,108.1z M102.1,93.9l0.4-13.7l9-5.3c4.9-3,9.9-5.7,10.9-6.2c1-0.4,1.7-1,1.4-1.3
		c-0.3-0.3-5.4,1.9-11.3,4.9c-12.2,6.2-10.3,6.3-24.8-1c-9.4-4.7-7.6-2.7,3.3,3.8l9,5.3v4.7c0,5.6,1.2,23,1.6,22.7
		C101.7,107.6,102,101.4,102.1,93.9z"
        />
      </g>
      <g>
        <path
          fill="#FEFEFF"
          d="M87.3,103.3L74,95.8V80.4V65.1l13.3-7.5c7.3-4.2,13.7-7.6,14.2-7.5c0.6,0,6.6,3.3,13.5,7.3l12.5,7.2v15.8v15.8
		l-12.8,7.4c-7.1,4.1-13.2,7.4-13.5,7.4C100.8,110.9,94.5,107.5,87.3,103.3z M102.9,80.8c0-0.4,4.8-3.5,10.6-6.9
		c5.8-3.4,10.3-6.4,10.1-6.6c-0.2-0.2-5.3,2-11.3,5.1l-11,5.5L91.1,73c-5.6-2.7-9.9-4.5-9.6-4.1c0.2,0.5,4.5,3.3,9.5,6.2l9,5.3v4.7
		c0,2.5,0.3,8.8,0.8,14l0.7,9.4l0.6-13.5C102.5,87.6,102.8,81.2,102.9,80.8z"
        />
      </g>
    </svg>
  );
};

export default WideLogo;
