import React from 'react';
import { Box, Flex, Grid, IconButton, Menu, MenuButton, MenuGroup, MenuItem, MenuList, Text, useDisclosure } from '@chakra-ui/react';
import { FiBell } from 'react-icons/fi';
import { useMutation, useQuery } from '@apollo/client';
import { HAS_UNREAD, MARK_AS_READ } from './query';
import { useUser } from '../../contexts/UserContext';
import { navigate } from '@reach/router';
import moment from 'moment';

export const Notifications = () => {
  const { user } = useUser();
  const { data, refetch } = useQuery(HAS_UNREAD, { variables: { userId: user.id }, pollInterval: 10000 });
  const [markAsRead] = useMutation(MARK_AS_READ);

  const handleOnClick = (customerId) => {
    navigate(`/alderaan?customerId=${customerId}`);
  };

  const handleOnClose = async () => {
    try {
      await markAsRead({ variables: { userId: user.id } });
      await refetch();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box>
      <Menu onClose={handleOnClose}>
        <MenuButton
          as={IconButton}
          p="1"
          cursor="pointer"
          // onClick={logOut}
          display={['none', 'none', 'block']}
          size="sm"
          fontSize="xl"
          variant="ghost"
          ml="1"
          icon={
            <Flex position="relative" justifyContent="center">
              {data?.unreadInternalNotifications > 0 ? (
                <Box position="absolute" top="0" right="0" width="10px" height="10px" borderRadius="8px" bg="red.500" />
              ) : null}
              <FiBell />
            </Flex>
          }
          aria-label="Ausloggen"
        />
        <MenuList maxHeight="500px" overflow="auto">
          {data?.unread.length > 0 ? (
            <>
              <MenuGroup>
                {data?.unread.map(({ id, title, body, customer, reminderAt }) => {
                  return (
                    <MenuItem key={id} isFocusable={!!customer} onClick={() => (customer ? handleOnClick(customer.id) : null)}>
                      <Grid templateColumns="repeat(2, auto)" width="100%">
                        <Box>
                          <Text fontSize="sm" fontWeight="bold">
                            {customer ? `${customer.companyName} braucht Deine Aufmerksamkeit` : title}
                          </Text>
                          <Text fontSize="xs">{body}</Text>
                        </Box>
                        <Box justifyContent="right">
                          <Box flex="1" textAlign="end">
                            <br />
                            {reminderAt ? (
                              <Text fontSize="xs">{moment(reminderAt).format('DD.MM.YYYY, HH:mm')}</Text>
                            ) : (
                              <Text fontSize="xs">Sofort verschickt</Text>
                            )}
                          </Box>
                        </Box>
                      </Grid>
                    </MenuItem>
                  );
                })}
              </MenuGroup>
            </>
          ) : null}
          {data?.read.length > 0 ? (
            <MenuGroup>
              {data?.read.map(({ id, title, body, customer, reminderAt }) => {
                return (
                  <MenuItem key={id} isFocusable={!!customer} onClick={() => (customer ? handleOnClick(customer.id) : null)}>
                    <Grid templateColumns="repeat(2, auto)" width="100%">
                      <Box>
                        <Text fontSize="sm" fontWeight="bold">
                          {customer ? `${customer.companyName} braucht Deine Aufmerksamkeit` : title}
                        </Text>
                        <Text fontSize="xs">{body}</Text>
                      </Box>
                      <Box justifyContent="right">
                        <Box flex="1" textAlign="end">
                          <br />
                          {reminderAt ? (
                            <Text fontSize="xs">{moment(reminderAt).format('DD.MM.YYYY, HH:mm')}</Text>
                          ) : (
                            <Text fontSize="xs">Sofort verschickt</Text>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  </MenuItem>
                );
              })}
            </MenuGroup>
          ) : null}
          {data?.read.length === 0 && data?.unread.length === 0 ? <Text p="2">Noch keine Nachrichten erhalten</Text> : null}
        </MenuList>
      </Menu>
    </Box>
  );
};
