import {
  Flex,
  Box,
  useColorMode,
  IconButton,
  Text,
  Divider,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from '@chakra-ui/react';
import { IoIosLogOut } from 'react-icons/io';
//@ts-ignore
import { Link as ReachLink, navigate, useLocation } from '@reach/router';

import { useUser } from '../../contexts/UserContext';
import WideLogo from '../../svgs/WideLogo';

export const SidebarLink = ({ to, children, onMobileClose }) => {
  const location = useLocation();
  const { colorMode } = useColorMode();

  const isCurrent = location.pathname.includes(to);

  const navigateTo = (url) => {
    onMobileClose();
    navigate(url);
  };

  const onKeyPress = (event) => {
    if (event.charCode === 13 || event.keyCode === 13) {
      navigateTo(to);
    }
  };

  return (
    <Box
      width="100%"
      bg={colorMode === 'light' ? (isCurrent ? 'gray.100' : 'transparent') : isCurrent ? 'gray.700' : 'transparent'}
      transition="all 0.3s ease"
      onClick={() => navigateTo(to)}
      cursor="pointer"
      onKeyPress={onKeyPress}
      tabIndex={0}
      _hover={{
        backgroundColor: colorMode === 'light' ? 'gray.200' : 'gray.700',
      }}
      _focusWithin={{
        outline: 'none',
        backgroundColor: colorMode === 'light' ? 'gray.200' : 'gray.700',
      }}>
      <Box role="button" fontWeight={isCurrent ? 'semibold' : 'normal'}>
        <Box width="100%" display="flex" alignItems="center" py="3" px="4">
          {children}
        </Box>
      </Box>
    </Box>
  );
};

const Sidebar = ({ onMobileClose }) => {
  const location = useLocation();
  const isCurrent = (to) => location.pathname.includes(to);
  const { checkAccess, logOut } = useUser();
  const { colorMode } = useColorMode();

  return (
    <Flex flexDir="column" borderRightWidth="1px" zIndex={3} bg={colorMode === 'light' ? 'white' : 'gray.900'}>
      <Flex px="4" height="64px" alignItems="center" display={['none', 'none', 'flex']}>
        <ReachLink to="/">
          <WideLogo />
        </ReachLink>
      </Flex>
      <Box flex="1">
        {checkAccess('EMPEROR') ? (
          <>
            <SidebarLink to="/order66" onMobileClose={onMobileClose}>
              <span role="img" aria-label="Chart-Up">
                📈
              </span>
              <Box as="span" ml="2">
                Statistiken
              </Box>
            </SidebarLink>
            <Divider />
          </>
        ) : null}
        {checkAccess('USERS_VIEW') ? (
          <>
            <Box>
              <SidebarLink to="/users" onMobileClose={onMobileClose}>
                <span role="img" aria-label="Man">
                  👨‍💼
                </span>
                <Box as="span" ml="2">
                  Benutzer
                </Box>
              </SidebarLink>
              <Box>
                <SidebarLink to="/active-droppoints" onMobileClose={onMobileClose}>
                  <Text as="span" role="img" aria-label="Building" fontSize="sm">
                    🚂
                  </Text>
                  <Box as="span" ml="2" fontSize="sm">
                    Active DropPoints
                  </Box>
                </SidebarLink>
              </Box>
            </Box>
            <Divider />
          </>
        ) : null}
        {checkAccess('BUSINESS_VIEW') || checkAccess('SPRINT_VIEW') || checkAccess('COMPANY_COUPONS_VIEW') ? (
          <Box
            width="100%"
            bg={
              colorMode === 'light'
                ? isCurrent('companies')
                  ? 'gray.50'
                  : 'transparent'
                : isCurrent('companies')
                ? 'gray.800'
                : 'transparent'
            }
            tabIndex={-1}>
            <Box fontWeight={isCurrent('companies') ? 'semibold' : 'normal'}>
              <Box width="100%" display="flex" alignItems="center" py="3" px="4">
                <Text as="span" role="img" aria-label="Coupon">
                  🏢
                </Text>
                <Box as="span" ml="2">
                  Unternehmen
                </Box>
              </Box>
            </Box>
          </Box>
        ) : null}
        {checkAccess('BUSINESS_VIEW') ? (
          <SidebarLink to="/companies/business" onMobileClose={onMobileClose}>
            <Text as="span" role="img" aria-label="Building" fontSize="sm">
              🏢
            </Text>
            <Box as="span" ml="2" fontSize="sm">
              BUSINESS
            </Box>
          </SidebarLink>
        ) : null}
        {checkAccess('SPRINT_VIEW') ? (
          <SidebarLink to="/companies/sprint" onMobileClose={onMobileClose}>
            <Text as="span" role="img" aria-label="Building" fontSize="sm">
              🏢
            </Text>
            <Box as="span" ml="2" fontSize="sm">
              SPRINT
            </Box>
          </SidebarLink>
        ) : null}
        {checkAccess('COMPANY_COUPONS_VIEW') ? (
          <SidebarLink to="/company-coupons" onMobileClose={onMobileClose}>
            <Text as="span" role="img" aria-label="Flying Money" fontSize="sm">
              💸
            </Text>
            <Box as="span" ml="2" fontSize="sm">
              Unternehmen Coupons
            </Box>
          </SidebarLink>
        ) : null}
        {checkAccess('BUSINESS_VIEW') || checkAccess('SPRINT_VIEW') ? <Divider /> : null}
        {checkAccess('EMPEROR') ? (
          <>
            <SidebarLink to="/orders" onMobileClose={onMobileClose}>
              <Text as="span" role="img" aria-label="Parcel">
                📦
              </Text>
              <Box as="span" ml="2">
                Bestellungen
              </Box>
            </SidebarLink>
            <Divider />
          </>
        ) : null}
        {checkAccess('USER_COUPONS_VIEW') || checkAccess('DROPPOINT_COUPONS_VIEW') ? (
          <Box
            width="100%"
            bg={
              colorMode === 'light' ? (isCurrent('coupons') ? 'gray.50' : 'transparent') : isCurrent('coupons') ? 'gray.800' : 'transparent'
            }
            tabIndex={-1}>
            <Box fontWeight={isCurrent('coupons') ? 'semibold' : 'normal'}>
              <Box width="100%" display="flex" alignItems="center" py="3" px="4">
                <Text as="span" role="img" aria-label="Coupon">
                  🎫
                </Text>
                <Box as="span" ml="2">
                  Coupons
                </Box>
              </Box>
            </Box>
          </Box>
        ) : null}
        {checkAccess('USER_COUPONS_VIEW') ? (
          <>
            <SidebarLink to="/coupons" onMobileClose={onMobileClose}>
              <Text as="span" role="img" aria-label="Arrow Down" fontSize="sm">
                ⬇️
              </Text>
              <Box as="span" ml="2" fontSize="sm">
                Besteller Coupons
              </Box>
            </SidebarLink>
          </>
        ) : null}
        {checkAccess('DROPPOINT_COUPONS_VIEW') ? (
          <>
            <SidebarLink to="/station-coupons" onMobileClose={onMobileClose}>
              <Text as="span" role="img" aria-label="House" fontSize="sm">
                🏚
              </Text>
              <Box as="span" ml="2" fontSize="sm">
                DropPoint Coupons
              </Box>
            </SidebarLink>
          </>
        ) : null}
        {checkAccess('USER_COUPONS_VIEW') || checkAccess('DROPPOINT_COUPONS_VIEW') ? <Divider /> : null}
        {checkAccess('INVOICES_VIEW') ? (
          <>
            <SidebarLink to="/invoices" onMobileClose={onMobileClose}>
              <span role="img" aria-label="Invoice">
                📝
              </span>
              <Box as="span" ml="2">
                Rechnungen
              </Box>
            </SidebarLink>
            <Divider />
          </>
        ) : null}
        {checkAccess('ADBERTISEMENT_VIEW') ? (
          <>
            <SidebarLink to="/advertisements" onMobileClose={onMobileClose}>
              <span role="img" aria-label="Alarm">
                💵
              </span>
              <Box as="span" ml="2">
                Werbeverwaltung
              </Box>
            </SidebarLink>
            <Divider />
          </>
        ) : null}
        {checkAccess('PUSH_VIEW') ? (
          <>
            <SidebarLink to="/push-notifications" onMobileClose={onMobileClose}>
              <span role="img" aria-label="Alarm">
                🔔
              </span>
              <Box as="span" ml="2">
                Push Notifications
              </Box>
            </SidebarLink>
            <Divider />
          </>
        ) : null}

        {checkAccess('ALDERAAN_VIEW') ? (
          <>
            <Box
              width="100%"
              bg={
                colorMode === 'light' ? (isCurrent('sales') ? 'gray.50' : 'transparent') : isCurrent('sales') ? 'gray.800' : 'transparent'
              }
              tabIndex={-1}>
              <Box fontWeight={isCurrent('sales') ? 'semibold' : 'normal'}>
                <Box width="100%" display="flex" alignItems="center" py="3" px="4">
                  <span role="img" aria-label="Coupon">
                    ✨
                  </span>
                  <Box as="span" ml="2">
                    Sales
                  </Box>
                </Box>
              </Box>
            </Box>
            <SidebarLink to="/alderaan" onMobileClose={onMobileClose}>
              <span role="img" aria-label="Alarm">
                💥
              </span>
              <Box as="span" ml="2">
                Alderaan
              </Box>
            </SidebarLink>
            <SidebarLink to="/potential-calculator" onMobileClose={onMobileClose}>
              <span role="img" aria-label="Calculator">
                🧮
              </span>
              <Box as="span" ml="2">
                Potenzialrechner
              </Box>
            </SidebarLink>
            <Divider />
          </>
        ) : null}

        {checkAccess('DHL') ? (
          <>
            <SidebarLink to="/dhl" onMobileClose={onMobileClose}>
              <span role="img" aria-label="House">
                🏚
              </span>
              <Box as="span" ml="2">
                DHL Zonen
              </Box>
            </SidebarLink>
            <Divider />
          </>
        ) : null}
      </Box>

      <Flex borderRadius="40px" alignItems="center" py="3" px="4" cursor="pointer" onClick={logOut}>
        <IconButton
          p="1"
          cursor="pointer"
          onClick={logOut}
          display={['none', 'none', 'block']}
          size="sm"
          fontSize="xl"
          variant="ghost"
          icon={<IoIosLogOut />}
          aria-label="Ausloggen"
        />
        Logout
      </Flex>
    </Flex>
  );
};

export default Sidebar;
