import React, { useContext } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';
import get from 'lodash/get';
import moment from 'moment';

import UserContext from '../../contexts/UserContext';
import SpinnerContext from '../../contexts/SpinnerContext';
import { UPDATE_COMPANY } from './query';
import { useCustomToast } from '../../hooks/useCustomToast';
import NumberInput from '../../components/NumberInput';
import { SingleCheckbox } from '../../components/Checkbox';

const CompensationSchema = Yup.object().shape({
  compensation: Yup.number()
    .required('Du musst eine Vergütung angeben')
    .min(1, 'Minimum 1 Cent Vergütung pro Buchung')
    .max(50, 'Maximal 50 Cent Vergütung pro Buchung'),
});

export const AddCompensationModal = ({ refetch, company }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [updateCompany] = useMutation(UPDATE_COMPANY);
  const { user } = useContext(UserContext);
  const { toggleSpinner } = useContext(SpinnerContext);
  const toast = useCustomToast();

  return (
    <>
      <Button size="sm" onClick={onOpen}>
        Vergütung bearbeiten
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Unternehmen zu DropFriends einladen</ModalHeader>
          <Formik
            initialValues={{
              hasCompensation: company.hasCompensation || false,
              compensation: company.compensation || 0,
              priceWithCompensation: company.priceWithCompensation || 0,
            }}
            validationSchema={CompensationSchema}
            onSubmit={async ({ hasCompensation, compensation, priceWithCompensation }) => {
              toggleSpinner(true);

              try {
                await updateCompany({
                  variables: {
                    where: {
                      id: company.id,
                    },
                    data: {
                      hasCompensation,
                      compensation: hasCompensation ? compensation : null,
                      priceWithCompensation: hasCompensation ? priceWithCompensation : null,
                      history: {
                        create: [
                          {
                            type: 'UPDATED',
                            comment: hasCompensation
                              ? `${user.firstName} hat eine Vergütung in Höhe von ${compensation} Cent hinzugefügt`
                              : `${user.firstName} hat die Vergütung vom Unternehmen entfernt`,
                            timestamp: moment().toISOString(),
                            changedBy: {
                              connect: { id: user.id },
                            },
                          },
                        ],
                      },
                    },
                  },
                });
                await refetch();
                toast({
                  title: 'Erfolg!',
                  description: 'Die Vergütung wurde erfolgreich geändert',
                  status: 'success',
                });
                onClose();
              } catch (error) {
                console.log(error);
                toast({
                  description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
                  status: 'error',
                });
              }

              toggleSpinner(false);
            }}>
            {({ values }) => {
              return (
                <Form>
                  <ModalBody>
                    <SingleCheckbox name="hasCompensation" label="Vergütung freischalten" />
                    {values.hasCompensation ? (
                      <>
                        <NumberInput name="compensation" label="Vergütung pro Buchung in Cent" />
                        <NumberInput name="priceWithCompensation" label="Preis pro Buchung" />
                      </>
                    ) : null}
                  </ModalBody>

                  <ModalFooter>
                    <Button variant="ghost" size="sm" colorScheme="red" type="button" onClick={onClose}>
                      Schließen
                    </Button>
                    <Button ml="4" size="sm" colorScheme="brand" type="submit">
                      Speichern
                    </Button>
                  </ModalFooter>
                </Form>
              );
            }}
          </Formik>
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </>
  );
};
