import React from 'react';
import { useField, useFormikContext } from 'formik';
import ReactQuill from 'react-quill';
import { FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react';

export const QuillInput: React.FC<any> = ({ label, placeholder = '', ...props }) => {
  // @ts-ignore
  const [field, meta] = useField(props);
  const { setFieldValue, setFieldTouched } = useFormikContext();

  return (
    <FormControl mb="4" isInvalid={!!(meta.touched && meta.error)}>
      {label ? <FormLabel>{label}</FormLabel> : null}
      <ReactQuill
        style={{ background: 'white', color: 'black' }}
        value={field.value}
        onChange={(e) => {
          if (e === '<p><br></p>') {
            setFieldValue(props.name, '');
            setFieldTouched(props.name, true, true);
          } else {
            setFieldValue(props.name, e);
            setFieldTouched(props.name, true, true);
          }
        }}
        placeholder={placeholder || 'Gib hier Deine Nachricht ein...'}
        modules={{
          toolbar: [
            [{ header: 1 }, { header: 2 }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ align: [] }],
          ],
        }}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
