import React, { useState } from 'react';
import {
  FormControl,
  FormLabel,
  Slider as ChakraSlider,
  SliderMark,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  FormErrorMessage,
  Tooltip,
} from '@chakra-ui/react';
import { useFormikContext, useField } from 'formik';

const Slider: React.FC<any> = ({ label, children, ...props }) => {
  // @ts-ignore
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <FormControl mb="10" isInvalid={!!(meta.touched && meta.error)}>
      <FormLabel fontSize="sm">{label}</FormLabel>
      <ChakraSlider
        value={field.value}
        onChange={(value) => setFieldValue(field.name, value)}
        colorScheme="brand"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        {...props}>
        {children}
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <Tooltip hasArrow bg="brand.500" color="white" placement="top" isOpen={showTooltip} label={`${field.value}%`}>
          <SliderThumb />
        </Tooltip>
      </ChakraSlider>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};

export default Slider;
