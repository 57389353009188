import React, { useContext } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useMutation, useQuery } from '@apollo/client';
import { Formik, Form } from 'formik';
import DatePicker from 'react-datepicker';
import * as Yup from 'yup';
import get from 'lodash/get';
import moment from 'moment';

import Input from '../../Input';
import Select from '../../Select';
import SpinnerContext from '../../../contexts/SpinnerContext';
import UserContext from '../../../contexts/UserContext';
import { useCustomToast } from '../../../hooks/useCustomToast';
import { INTERNAL_USERS, SCHEDULE_REMINDER, UPDATE_INTERNAL_CUSTOMER } from '../query';
import { SingleCheckbox } from '../../Checkbox';

const SetReminderSchema = Yup.object().shape({
  title: Yup.string().required('Du musst einen Titel für die Erinnerung angeben'),
  body: Yup.string().required('Du musst einen Text für die Erinnerung angeben'),
  notifiedUser: Yup.string().required('Du musst einen Mitarbeiter auswählen'),
});

export const CreateReminderModal = ({ isOpen, onClose, customerId, refetch, currentAsignee }) => {
  const [scheduleReminder] = useMutation(SCHEDULE_REMINDER);
  const [updateInternalCustomer] = useMutation(UPDATE_INTERNAL_CUSTOMER);
  const { toggleSpinner } = useContext(SpinnerContext);
  const { user } = useContext(UserContext);
  const toast = useCustomToast();
  const { data } = useQuery(INTERNAL_USERS, {
    fetchPolicy: 'cache-first',
  });

  if (!data?.internalUsers) return null;

  let notifiedUserOptions = data?.internalUsers.map((asignee) => ({
    value: asignee.id,
    label: `${asignee.firstName} ${asignee.lastName}`,
  }));

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Erinnerung setzen</ModalHeader>
        <Formik
          initialValues={{
            title: '',
            body: '',
            dateTime: null,
            notifiedUser: currentAsignee?.id || '',
            sendNow: false,
            notifier: user.id,
          }}
          validationSchema={SetReminderSchema}
          onSubmit={async ({ dateTime, notifiedUser, title, body, sendNow, notifier }, { setFieldError, setSubmitting }) => {
            try {
              toggleSpinner(true);

              if (!sendNow) {
                if (!dateTime) {
                  setFieldError('dateTime', 'Wähle ein Datum und eine Uhrzeit aus');
                  setSubmitting(false);
                  toggleSpinner(false);
                  return;
                }
                if (moment(dateTime).isBefore(moment())) {
                  setFieldError('dateTime', 'Das Datum liegt in der Vergangenheit');
                  setSubmitting(false);
                  toggleSpinner(false);
                  return;
                }
              }

              await scheduleReminder({
                variables: {
                  sendNow,
                  data: {
                    title,
                    body,
                    reminderAt: dateTime,
                    customer: {
                      connect: {
                        id: customerId,
                      },
                    },
                    notifier: {
                      connect: {
                        id: user?.id,
                      },
                    },
                    notifiedUser: {
                      connect: {
                        id: notifiedUser,
                      },
                    },
                  },
                },
              });

              await updateInternalCustomer({
                variables: {
                  where: {
                    id: customerId,
                  },
                  data: {
                    history: {
                      create: [
                        {
                          type: 'UPDATED',
                          comment: `Eine Erinnerung um ${moment(dateTime).format('DD.MM.YYYY, HH:mm')} wurde angelegt.`,
                          timestamp: moment().toISOString(),
                          changedBy: {
                            connect: { id: user.id },
                          },
                        },
                      ],
                    },
                  },
                },
              });

              await refetch();
              toast({
                title: 'Erfolg!',
                description: 'Erinnerung wurde gesetzt🎉',
                status: 'success',
                isClosable: false,
                duration: 3000,
              });
              onClose();
              toggleSpinner(false);
            } catch (error) {
              console.log(error);
              toast({
                description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
                status: 'error',
              });
              toggleSpinner(false);
            }
          }}>
          {({ values, errors, touched, setFieldValue, setFieldTouched }) => (
            <Form>
              <ModalBody>
                <Input label="Titel" name="title" placeholder="Titel der Erinnerung" />
                <Input label="Nachricht" name="body" placeholder="Nachricht der Erinnerung" />

                <Select
                  options={[{ value: '', label: 'Mitarbeiter auswählen', disabled: true }, ...notifiedUserOptions]}
                  label="Mitarbeiter erinnern"
                  name="notifiedUser"
                />

                <SingleCheckbox label="Nachricht jetzt schicken?" name="sendNow" />
                {values.sendNow ? null : (
                  <FormControl mb="4" isInvalid={!!(touched.dateTime && errors.dateTime)}>
                    <FormLabel>Datum & Uhrzeit</FormLabel>
                    <DatePicker
                      minDate={moment().toDate()}
                      selected={values.dateTime}
                      showTimeSelect
                      onBlur={() => setFieldTouched('dateTime', true)}
                      onChange={(date) => setFieldValue('dateTime', date)}
                      inline
                    />
                    <FormErrorMessage>{errors.dateTime as string}</FormErrorMessage>
                  </FormControl>
                )}
              </ModalBody>

              <ModalFooter>
                <Button variant="ghost" size="sm" colorScheme="red" type="button" onClick={onClose}>
                  Schließen
                </Button>
                <Button ml="4" size="sm" colorScheme="brand" type="submit">
                  Erinnerung setzen
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};
