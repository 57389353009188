import React from 'react';
import { Box } from '@chakra-ui/react';

const Layout = ({ children, fullWidth = false }) => {
  return (
    <Box
      px={['4', '6', '8']}
      py={['6', '12']}
      maxW={fullWidth ? '' : '1200px'}
      width={['100vw', '100vw', '100%']}
      margin="0 auto"
      height="100%">
      {children}
    </Box>
  );
};

export default Layout;
