import React, { useContext, useState } from 'react';
import { Flex, Box, Text, Avatar, useColorMode, IconButton, Menu, MenuButton, MenuList, MenuItem, Link } from '@chakra-ui/react';
import { IoIosLogOut, IoMdMenu, IoIosCloseCircle } from 'react-icons/io';
import { Link as ReachLink, navigate } from '@reach/router';

import WideLogo from '../../svgs/WideLogo';
import UserContext from '../../contexts/UserContext';
import Sidebar from '../Sidebar';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Notifications } from './Notifications';

const Header = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { user, logOut } = useContext(UserContext);
  const { colorMode } = useColorMode();

  const openMobile = () => {
    document.body.style.overflow = 'hidden';
    setMobileOpen(true);
  };
  const closeMobile = () => {
    document.body.style.overflow = '';
    setMobileOpen(false);
  };
  const navigateTo = (url) => {
    navigate(url);
  };

  return (
    <>
      <Flex alignItems="center" px={['2', '4']} borderBottomWidth="1px" zIndex={2} bg={colorMode === 'light' ? 'white' : 'gray.900'}>
        <Box flex="1">
          <Flex px="4" height="64px" alignItems="center" display={['flex', 'flex', 'none']}>
            <ReachLink to="/">
              <WideLogo />
            </ReachLink>
          </Flex>
          {process.env.REACT_APP_ENV === 'staging' ? (
            <Text display={['none', 'none', 'block']} textAlign="left" color="red.500">
              Du befindest dich auf der Staging Umgebung
            </Text>
          ) : null}
          {process.env.NODE_ENV === 'development' ? (
            <Text display={['none', 'none', 'block']} textAlign="left" color="red.500">
              Du befindest dich auf der Development Umgebung
            </Text>
          ) : null}
        </Box>
        <Flex alignItems="center" mx={['1', '4']}>
          <Link as={ReachLink} to="/profile" onClick={() => navigateTo('/profile')}>
            <Flex alignItems="center">
              <Text as="strong" fontSize="md" display={['none', 'none', 'block']}>{`${user.firstName} ${user.lastName}`}</Text>
              <Avatar src={user.photo} size="sm" ml="4" name={`${user.firstName} ${user.lastName}`} />
            </Flex>
          </Link>

          <ColorModeSwitcher />
          <Notifications />
        </Flex>

        <Box
          borderRadius="40px"
          _hover={{ backgroundColor: 'gray.200' }}
          p="2"
          cursor="pointer"
          onClick={mobileOpen ? closeMobile : openMobile}
          display={['block', 'block', 'none']}>
          <Box as={mobileOpen ? IoIosCloseCircle : IoMdMenu} boxSize="24px" title="Ausloggen" />
        </Box>
      </Flex>
      <Box
        zIndex={10}
        position="absolute"
        top="64px"
        left="0"
        width="100vw"
        overflow="scroll"
        height="calc(100vh - 64px)"
        display={mobileOpen ? 'block' : 'none'}
        bg={colorMode === 'light' ? 'white' : 'gray.900'}>
        <Sidebar onMobileClose={closeMobile} />
      </Box>
    </>
  );
};

export default Header;
