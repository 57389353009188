import React, { useContext } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalHeader, ModalBody, ModalFooter, Button, Grid } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';
import get from 'lodash/get';

import Input from '../../../components/Input';
import { EDIT_USER } from '../query';
import UserContext from '../../../contexts/UserContext';
import SpinnerContext from '../../../contexts/SpinnerContext';
import { useCustomToast } from '../../../hooks/useCustomToast';

const UpdateUserSchema = Yup.object().shape({
  firstName: Yup.string().required('Das ist ein Pflichtfeld'),
  lastName: Yup.string().required('Das ist ein Pflichtfeld'),
  address: Yup.object().shape({
    street: Yup.string().required('Das ist ein Pflichtfeld'),
    houseNumber: Yup.string().required('Das ist ein Pflichtfeld'),
    postalCode: Yup.string().required('Das ist ein Pflichtfeld'),
    city: Yup.string().required('Das ist ein Pflichtfeld'),
  }),
  comment: Yup.string().required('Warum möchtest Du die Änderung durchführen?'),
});

const EditUserModal = ({ isOpen, onClose, refetch, user }) => {
  const [updateUser] = useMutation(EDIT_USER);
  const { toggleSpinner } = useContext(SpinnerContext);
  const { user: internalUser } = useContext(UserContext);
  const toast = useCustomToast();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Nutzerdaten bearbeiten</ModalHeader>
        <Formik
          initialValues={{
            firstName: user.firstName,
            lastName: user.lastName,
            address: {
              street: user.address?.street,
              houseNumber: user.address?.houseNumber,
              postalCode: user.address?.postalCode,
              city: user.address?.city,
            },
            comment: '',
          }}
          validationSchema={UpdateUserSchema}
          onSubmit={async ({ firstName, lastName, address: { street, houseNumber, postalCode, city }, comment }) => {
            toggleSpinner(true);
            try {
              await updateUser({
                variables: {
                  where: {
                    id: user.id,
                  },
                  firstName,
                  lastName,
                  street,
                  houseNumber,
                  postalCode,
                  city,
                  internalUser: internalUser.id,
                  comment,
                },
              });
              await refetch();
              toast({
                title: 'Erfolg!',
                description: 'Die Nutzerdaten wurden erfolgreich geändert',
                status: 'success',
              });
              onClose();
            } catch (error) {
              console.log(error);
              toast({
                description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
                status: 'error',
              });
            }
            toggleSpinner(false);
          }}>
          <Form>
            <ModalBody>
              <Grid gridGap="4" gridTemplateColumns="1fr 1fr">
                <Input name="firstName" label="Vorname" />
                <Input name="lastName" label="Nachname" />
              </Grid>
              <Grid gridGap="4" gridTemplateColumns="2fr 1fr">
                <Input name="address.street" label="Strasse" />
                <Input name="address.houseNumber" label="Hausnummer" />
              </Grid>
              <Grid gridGap="4" gridTemplateColumns="1fr 1fr">
                <Input name="address.postalCode" label="PLZ" />
                <Input name="address.city" label="Stadt" />
              </Grid>
              <Input name="comment" label="Kommentar" />
            </ModalBody>

            <ModalFooter>
              <Button variant="ghost" size="sm" colorScheme="red" type="button" onClick={onClose}>
                Schließen
              </Button>
              <Button ml="4" size="sm" colorScheme="brand" type="submit">
                Speichern
              </Button>
            </ModalFooter>
          </Form>
        </Formik>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};

export default EditUserModal;
