import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { RouteComponentProps } from '@reach/router';

interface NotFoundProps extends RouteComponentProps {}

export const NotFound: React.FC<NotFoundProps> = () => {
  return (
    <Flex width="100%" p="24" justifyContent="center" alignItems="center">
      <Text fontFamily="heading" fontWeight="bold" fontSize="2xl">
        Diese Seite existiert nicht oder du hast nicht die Berechtigungen dafür. Falls du denkst, dass es ein Fehler ist, kontaktiere deinen
        Supervisor.
      </Text>
    </Flex>
  );
};
