import gql from 'graphql-tag';

export const INTERNAL_CUSTOMERS = gql`
  query INTERNAL_CUSTOMERS($where: InternalCustomerWhereInput, $orderBy: InternalCustomerOrderByInput, $first: Int, $skip: Int) {
    internalCustomers(where: $where, orderBy: $orderBy, first: $first, skip: $skip) {
      id
      incrementId
      status
      source
      leadStatus
      companyName
      contactName
      phone
      phoneNumbers
      notifications(where: { notified_not: true }, first: 100, orderBy: createdAt_DESC) {
        id
        title
        body
      }
      email
      asignee {
        id
        photo
        firstName
        lastName
      }
      sourceCreatedAt
      updatedAt
      createdAt
    }
    totalInternalCustomers(where: $where)
  }
`;

export const INTERNAL_CUSTOMER = gql`
  query INTERNAL_CUSTOMER($customerId: ID, $userId: ID) {
    internalCustomer(where: { id: $customerId }) {
      id
      incrementId
      status
      source
      leadStatus
      companyName
      contactName
      phone
      phoneNumbers
      email
      notifications(where: { notified_not: true }, first: 100, orderBy: createdAt_DESC) {
        id
        title
        body
      }
      address {
        street
        houseNumber
        city
        postalCode
      }
      asignee {
        id
        firstName
        lastName
        photo
        createdAt
      }
      comments: notes(where: { type: COMMENT }, orderBy: createdAt_DESC) {
        id
        message
        connectedFiles {
          uri
          name
          type
        }
        commenter {
          id
          firstName
          lastName
          createdAt
          photo
        }
        createdAt
      }
      communications: notes(where: { type: COMMUNICATION }, orderBy: createdAt_DESC) {
        id
        message
        connectedFiles {
          uri
          name
          type
        }
        commenter {
          id
          firstName
          lastName
          createdAt
          photo
        }
        createdAt
      }
      emails(orderBy: createdAt_DESC) {
        id
        subject
        content
        sender {
          id
          firstName
          lastName
          photo
        }
        createdAt
        connectedFiles {
          uri
          name
          type
        }
      }
      history(orderBy: createdAt_DESC) {
        id
        type
        comment
        changedBy {
          id
          firstName
          lastName
          photo
        }
        createdAt
      }
      xing
      linkedin
      website
      facebook
      twitter
      instagram
      contactProfession
      branchAmount
      contactMethod
      businessType
      productOffered
      productsOffered {
        api
        business
        events
        sprint
      }
    }
    internalUser(where: { id: $userId }) {
      id
      phoneNumber
    }
  }
`;

export const UPDATE_INTERNAL_CUSTOMER = gql`
  mutation UPDATE_INTERNAL_CUSTOMER($data: InternalCustomerUpdateInput!, $where: InternalCustomerWhereUniqueInput!) {
    updateInternalCustomer(data: $data, where: $where) {
      id
    }
  }
`;

export const HUBSPOT_IMPORT = gql`
  mutation HUBSPOT_IMPORT($uri: String!) {
    importHubspotCSV(uri: $uri)
  }
`;

export const SALES_IMPORT = gql`
  mutation SALES_IMPORT($uri: String!) {
    importSalesCSV(uri: $uri)
  }
`;
