import React from 'react';
import { Box, Flex, Spinner, Grid, Button, Badge, useColorMode } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import moment from 'moment';

import { HISTORY } from '../query';

const getCurrent = ({ type, change, createdAt }) => {
  if (!change) return null;
  if (type === 'EMAIL_CHANGED' || type === 'EMAIL_SENT' || type === 'LOCKED') return change.current;
  if (type === 'UPDATED') {
    const children = [];
    if (change.current.firstName) {
      children.push(
        <Box key={createdAt + 'firstNameC'}>
          <b>Vorname:</b> {change.current.firstName}
        </Box>
      );
    }
    if (change.current.lastName) {
      children.push(
        <Box key={createdAt + 'lastNameC'}>
          <b>Nachname:</b> {change.current.lastName}
        </Box>
      );
    }
    if (change.current.address && change.current.address.update.street) {
      children.push(
        <Box key={createdAt + 'streetC'}>
          <b>Straße:</b> {change.current.address.update.street}
        </Box>
      );
    }
    if (change.current.address && change.current.address.update.houseNumber) {
      children.push(
        <Box key={createdAt + 'houseNumberC'}>
          <b>Hausnummer:</b> {change.current.address.update.houseNumber}
        </Box>
      );
    }
    if (change.current.address && change.current.address.update.postalCode) {
      children.push(
        <Box key={createdAt + 'postalCodeC'}>
          <b>PLZ:</b> {change.current.address.update.postalCode}
        </Box>
      );
    }
    if (change.current.address && change.current.address.update.city) {
      children.push(
        <Box key={createdAt + 'cityC'}>
          <b>Stadt:</b> {change.current.address.update.city}
        </Box>
      );
    }
    if (typeof change.current === 'string' || typeof change.current === 'boolean') {
      children.push(<Box key={createdAt + 'change'}>{change.current}</Box>);
    }
    return children;
  }
  return null;
};

const getPrevious = ({ type, change, createdAt }) => {
  if (!change) return null;
  if (type === 'EMAIL_CHANGED' || type === 'LOCKED') return change.previous;
  if (type === 'UPDATED') {
    const children = [];
    if (change.previous.firstName) {
      children.push(
        <Box key={createdAt + 'firstNameP'}>
          <b>Vorname:</b> {change.previous.firstName}
        </Box>
      );
    }
    if (change.current.lastName) {
      children.push(
        <Box key={createdAt + 'lastNameP'}>
          <b>Nachname:</b> {change.previous.lastName}
        </Box>
      );
    }
    if (change.previous.address && change.previous.address.update.street) {
      children.push(
        <Box key={createdAt + 'streetP'}>
          <b>Straße:</b> {change.previous.address.update.street}
        </Box>
      );
    }
    if (change.previous.address && change.previous.address.update.houseNumber) {
      children.push(
        <Box key={createdAt + 'houseNumberP'}>
          <b>Hausnummer:</b> {change.previous.address.update.houseNumber}
        </Box>
      );
    }
    if (change.previous.address && change.previous.address.update.postalCode) {
      children.push(
        <Box key={createdAt + 'postalCodeP'}>
          <b>PLZ:</b> {change.previous.address.update.postalCode}
        </Box>
      );
    }
    if (change.previous.address && change.previous.address.update.city) {
      children.push(
        <Box key={createdAt + 'cityP'}>
          <b>Stadt:</b> {change.previous.address.update.city}
        </Box>
      );
    }
    if (typeof change.previous === 'string' || typeof change.previous === 'boolean') {
      children.push(<Box key={createdAt + 'previous'}>{change.previous}</Box>);
    }
    return children;
  }
  return null;
};

const History = ({ userId, handleHistoryEntry }) => {
  const { colorMode } = useColorMode();
  const { loading, error, data, refetch } = useQuery(HISTORY, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        id: userId,
      },
    },
  });

  if (loading) {
    return (
      <Box px="4" py="8">
        <Flex width="100%" height="300px" justifyContent="center" alignItems="center">
          <Spinner />
        </Flex>
      </Box>
    );
  }

  if (error) {
    return (
      <Box px="4" py="8">
        <Flex width="100%" height="300px" justifyContent="center" alignItems="center">
          Ein Fehler ist aufgetreten. Schreib Osamah an.
        </Flex>
      </Box>
    );
  }

  return (
    <Box borderLeftWidth="1px" borderRightWidth="1px" borderBottomWidth="1px" px="4" py="8">
      {data.user.history.length > 0 ? (
        <>
          <Flex mb="3" justifyContent="space-between" alignItems="center">
            <Button colorScheme="brand" variant="outline" onClick={() => refetch()} size="xs">
              Neu laden
            </Button>
            <Button colorScheme="brand" onClick={handleHistoryEntry} size="sm">
              Neuer Eintrag
            </Button>
          </Flex>
          <Grid width="100%" gridTemplateColumns="auto auto 2fr 2fr 3fr auto">
            <Box p="2" backgroundColor={colorMode === 'light' ? 'gray.100' : 'gray.700'}>
              Timestamp
            </Box>
            <Box p="2" backgroundColor={colorMode === 'light' ? 'gray.100' : 'gray.700'}>
              Typ
            </Box>
            <Box p="2" backgroundColor={colorMode === 'light' ? 'gray.100' : 'gray.700'}>
              Neue Daten
            </Box>
            <Box p="2" backgroundColor={colorMode === 'light' ? 'gray.100' : 'gray.700'}>
              Alte Daten
            </Box>
            <Box p="2" backgroundColor={colorMode === 'light' ? 'gray.100' : 'gray.700'}>
              Kommentar
            </Box>
            <Box p="2" backgroundColor={colorMode === 'light' ? 'gray.100' : 'gray.700'}>
              Mitarbeiter
            </Box>
            {data.user.history.map(({ createdAt, type, change, changedBy, comment }) => {
              return (
                <React.Fragment key={`${type}-${createdAt}`}>
                  <Box fontSize="sm" px="2" py="4" wordBreak="break-word" borderBottomWidth="1px">
                    {moment(createdAt).format('DD.MM.YYYY, HH:mm')}
                  </Box>
                  <Box px="2" py="4" wordBreak="break-word" borderBottomWidth="1px">
                    <Badge>{type}</Badge>
                  </Box>
                  <Box px="2" py="4" wordBreak="break-word" borderBottomWidth="1px">
                    {getCurrent({ type, change, createdAt })}
                  </Box>
                  <Box px="2" py="4" wordBreak="break-word" borderBottomWidth="1px">
                    {getPrevious({ type, change, createdAt })}
                  </Box>
                  <Box px="2" py="4" wordBreak="break-word" borderBottomWidth="1px">
                    {comment}
                  </Box>
                  <Box px="2" py="4" wordBreak="break-word" borderBottomWidth="1px">
                    {changedBy ? changedBy.firstName : ''}
                  </Box>
                </React.Fragment>
              );
            })}
          </Grid>
        </>
      ) : (
        <Flex width="100%" height="300px" justifyContent="center" alignItems="center" flexDir="column">
          <Box mb="3">Es wurde noch keine Änderung über das CRM gemacht</Box>
          <Flex justifyContent="center" alignItems="center">
            <Button colorScheme="brand" variant="outline" size="sm" mr="3" onClick={() => refetch()}>
              Neu laden
            </Button>
            <Button colorScheme="brand" onClick={handleHistoryEntry} size="sm">
              Neuer Eintrag
            </Button>
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

export default History;
