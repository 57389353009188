import React from 'react';
import { Box, Text, Flex, Badge, useColorMode } from '@chakra-ui/react';
import { Link } from '@reach/router';

const DropPointTableRow = ({
  id,
  firstName,
  lastName,
  accountLocked,
  lockReason,
  inDefault,
  defaultAmount,
  companyRole,
  myCompany,
  email,
  orders,
  acceptEmails,
  packageStations,
  advertisements,
  emailVerified,
}) => {
  const { colorMode } = useColorMode();
  const packageStation = packageStations && packageStations.length > 0 ? packageStations[0] : ({} as any);
  const onboardedPackageStation = packageStation?.isOnboarded;
  const activePackageStation = packageStation?.isActive;
  const hasActiveAdvertisement = advertisements.find(({ isActive }) => isActive);

  return (
    <>
      {activePackageStation ? (
        <Box
          as={Link}
          // @ts-ignore
          to={`/users/${id}`}
          gridColumn="span 3"
          width="100%"
          borderWidth="1px"
          borderBottom="0"
          px="4"
          py="2"
          _last={{ borderBottom: '1px solid #E2E8F0' }}
          _hover={{ boxShadow: 'sm', backgroundColor: colorMode === 'light' ? 'white' : 'gray.700' }}>
          <Flex width="100%" alignItems="center">
            <Box flex="1">
              <Flex flexWrap="wrap">
                {accountLocked && lockReason === 'ACCOUNT_DISABLED' ? (
                  <Badge colorScheme="red" mr="2">
                    Account gekündigt
                  </Badge>
                ) : (
                  <>
                    {accountLocked ? (
                      <Badge colorScheme="red" mr="2" mb="1">
                        Account gesperrt
                      </Badge>
                    ) : null}
                    {inDefault ? (
                      <Badge colorScheme="red" mr="2" mb="1">
                        Zahlungsrückstand:{' '}
                        {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(defaultAmount / 100)}
                      </Badge>
                    ) : null}
                    {myCompany?.id ? (
                      <Badge colorScheme="blue" mr="2" mb="1">
                        {myCompany.name}: {companyRole}
                      </Badge>
                    ) : null}
                    {!myCompany?.id && !onboardedPackageStation ? (
                      <Badge colorScheme={onboardedPackageStation ? 'green' : 'yellow'} mr="2" mb="1">
                        DropPoint nicht erstellt
                      </Badge>
                    ) : null}
                    {!myCompany?.id && onboardedPackageStation ? (
                      <Badge variant="solid" mr="2" colorScheme={activePackageStation ? 'green' : 'red'} mb="1">
                        {activePackageStation ? 'DropPoint aktiv' : 'DropPoint inaktiv'}
                      </Badge>
                    ) : null}
                    <Badge colorScheme={acceptEmails ? 'green' : 'orange'} mr="2" mb="1">
                      {acceptEmails ? 'Werbung gestattet' : 'Werbung unerwünscht'}
                    </Badge>
                    {advertisements.length > 0 ? (
                      <Badge variant="solid" colorScheme={hasActiveAdvertisement ? 'green' : 'yellow'} mb="1">
                        {hasActiveAdvertisement ? 'Ad online' : 'Ad offline'}
                      </Badge>
                    ) : null}
                    <Badge colorScheme="orange" mb="1">
                      {emailVerified ? 'E-Mail verifiziert' : 'E-Mail nicht verifiziert'}
                    </Badge>
                  </>
                )}
              </Flex>
              <Text fontFamily="heading" fontSize="md" fontWeight="bold" display="flex" alignItems="center">
                {firstName} {lastName}
              </Text>
              <Text fontSize="xs" wordBreak="break-all">
                {email}
              </Text>
            </Box>
            <Flex alignItems="flex-end" flexDir="column">
              <Text color={colorMode === 'light' ? 'gray.700' : 'gray.300'} fontSize="sm">
                <strong>{orders.length}</strong> Sendungen an DropPoints verschickt
              </Text>
              {packageStation?.packages ? (
                <Text color={colorMode === 'light' ? 'gray.700' : 'gray.300'} fontSize="sm">
                  <strong>{packageStation?.packages?.length}</strong> Buchungen als DropPoint erhalten
                </Text>
              ) : null}
            </Flex>
          </Flex>
        </Box>
      ) : null}
    </>
  );
};

export default DropPointTableRow;
