import React, { useContext } from 'react';
import { Formik, Form } from 'formik';
import { RouteComponentProps } from '@reach/router';
import { Button, Flex, Box, useColorMode } from '@chakra-ui/react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';

import UserContext from '../contexts/UserContext';
import Input from '../components/Input';
import WideLogo from '../svgs/WideLogo';

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Du musst eine valide Email-Adresse angeben').required('Du musst eine Email-Adresse angeben'),
  password: Yup.string().min(2, 'Deine Angabe muss min. 2 Zeichen lang sein').required('Du musst ein Passwort angeben'),
});

const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    internalLogin(email: $email, password: $password)
  }
`;

interface LoginHomeProps extends RouteComponentProps {}

const LoginHome: React.FC<LoginHomeProps> = () => {
  const { setToken } = useContext(UserContext);
  const [login] = useMutation(LOGIN);
  const { colorMode } = useColorMode();

  return (
    <Flex
      width="100vw"
      height="100vh"
      bg={colorMode === 'light' ? 'gray.100' : null}
      justifyContent="center"
      alignItems="center"
      direction="column">
      <Box mb="12">
        <WideLogo size={48} />
      </Box>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={async ({ email, password }) => {
          const { data } = await login({
            variables: {
              email,
              password,
            },
          });
          if (data && data.internalLogin) {
            setToken(data.internalLogin);
          }
        }}
        validationSchema={LoginSchema}>
        <Form>
          <Box width="400px" borderWidth="1px" rounded="lg" bg={colorMode === 'light' ? 'white' : 'gray.700'} p="4">
            <Input name="email" isRequired label="Email-Adresse" type="email" />
            <Input name="password" isRequired label="Passwort" type="password" />
            <Button colorScheme="brand" width="100%" type="submit">
              Einloggen
            </Button>
          </Box>
        </Form>
      </Formik>
    </Flex>
  );
};

export default LoginHome;
