import React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Link, Text, Badge, Box, useColorMode } from '@chakra-ui/react';
import moment from 'moment';

export const HistoryTab = ({ invitations }) => {
  const { colorMode } = useColorMode();
  return (
    <>
      {invitations.length === 0 ? (
        <Text gridColumn="span 3" p="4" textAlign="center" backgroundColor="red.100" color="red.800" borderRadius="4px">
          Der Widerstand versteckt sich gut und konnte nicht gefunden werden. Wiederhole die Suche oder schicke Boba Fett! (Keine
          Unternehmen gefunden)
        </Text>
      ) : (
        <Table>
          <Thead backgroundColor={colorMode === 'light' ? 'gray.100' : 'gray.700'}>
            <Tr>
              <Th>Timestamp</Th>
              <Th>Unternehmen</Th>
              <Th>Owner</Th>
              <Th>Einladungsstatus</Th>
              <Th>Kommentar</Th>
            </Tr>
          </Thead>
          <Tbody>
            {invitations.map(
              ({
                id,
                inviteLink,
                firstName,
                lastName,
                email,
                companyName,
                companyType,
                invitedBy,
                status,
                comment,
                companyCreatedAt,
                createdAt,
              }) => {
                return (
                  <Tr key={id} width="100%">
                    <Td>{moment(createdAt).format('DD.MM.YYYY, HH:mm')}</Td>
                    <Td>
                      <Text fontWeight="bold" mb="1">
                        {companyName}
                      </Text>
                      <Badge>{companyType}</Badge>
                    </Td>
                    <Td>
                      <Text fontWeight="bold" mb="1">
                        {firstName} {lastName}
                      </Text>
                      <Text>{email}</Text>
                    </Td>
                    <Td>
                      <Box mb="1">
                        {companyCreatedAt ? (
                          <Text>{moment(companyCreatedAt).format('DD.MM.YYYY, HH:mm')}</Text>
                        ) : (
                          <Link href={inviteLink} isExternal color="brand.500">
                            Link zur Einladung
                          </Link>
                        )}
                      </Box>
                      <Badge colorScheme={status === 'DONE' ? 'green' : status === 'OPEN' ? 'blue' : ''}>{status}</Badge>
                    </Td>
                    <Td>
                      <Text>
                        {invitedBy.firstName} {invitedBy.lastName}
                      </Text>
                      <Text>{comment}</Text>
                    </Td>
                  </Tr>
                );
              }
            )}
          </Tbody>
        </Table>
      )}
    </>
  );
};
