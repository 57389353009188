import React, { useContext, useState, useRef } from 'react';
import { useMutation } from '@apollo/client';
import {
  Text,
  Button,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Grid,
} from '@chakra-ui/react';
import get from 'lodash/get';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import UserContext from '../../../contexts/UserContext';
import SpinnerContext from '../../../contexts/SpinnerContext';
import Input from '../../../components/Input';
import { EXPORT_USERS_BY_WEEK } from '../query';
import NumberInput from '../../../components/NumberInput';

const ExportStatisticsSchema = Yup.object().shape({
  start: Yup.number().min(1).max(53).required('Du musst die erste Woche für den Export angeben'),
  end: Yup.number().min(1).max(53).required('Du musst die letzte Woche für den Export angeben'),
});

const ExportStatistics = () => {
  // Export Statistiken Modal
  const [alertOpen, setAlertOpen] = useState(false);
  const onAlertClose = () => setAlertOpen(false);
  const cancelRef = useRef();

  const { toggleSpinner } = useContext(SpinnerContext);
  const [exportUsersByWeekMutation] = useMutation(EXPORT_USERS_BY_WEEK);
  const toast = useToast();

  const handleCancelSubmit = async ({ start, end }) => {
    try {
      toggleSpinner(true);
      const {
        data: { exportUserStatisticsByWeek },
      } = await exportUsersByWeekMutation({
        variables: {
          start: parseInt(start),
          end: parseInt(end),
        },
      });
      toast({
        title: 'Erfolg!',
        description: 'Die CSV Datei wurde erfolgreich erstellt',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      window.open(exportUserStatisticsByWeek, '_blank');
      setAlertOpen(false);
    } catch (error) {
      console.log(error);
      toast({
        description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
    toggleSpinner(false);
  };

  return (
    <>
      <Button size="sm" colorScheme="brand" onClick={() => setAlertOpen(true)}>
        User-Statistiken exportieren
      </Button>

      <AlertDialog isOpen={alertOpen} leastDestructiveRef={cancelRef} onClose={onAlertClose}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <Formik initialValues={{ start: '', end: '' }} validationSchema={ExportStatisticsSchema} onSubmit={handleCancelSubmit}>
            {({ values }) => (
              <Form>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Für welche Kalenderwochen möchtest du die User-Statistiken exportieren?
                </AlertDialogHeader>

                <AlertDialogBody>
                  <Grid gridTemplateColumns="1fr 1fr" gap="4">
                    <NumberInput label="Von" name="start" min="1" />
                    <NumberInput label="Bis" name="end" min={values.start || 1} max="53" />
                  </Grid>
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} size="sm" onClick={onAlertClose}>
                    Abbrechen
                  </Button>
                  <Button colorScheme="green" type="submit" size="sm" ml={3}>
                    Exportieren
                  </Button>
                </AlertDialogFooter>
              </Form>
            )}
          </Formik>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default ExportStatistics;
