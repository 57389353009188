import gql from 'graphql-tag';

export const HAS_UNREAD = gql`
  query HAS_UNREAD($userId: ID!) {
    unreadInternalNotifications(userId: $userId)
    unread: internalNotifications(
      where: { notifiedUser: { id: $userId }, read_not: true, notified: true }
      first: 100
      orderBy: createdAt_DESC
    ) {
      id
      title
      body
      reminderAt
      customer {
        id
        companyName
      }
      createdAt
    }
    read: internalNotifications(where: { notifiedUser: { id: $userId }, read: true, notified: true }, first: 100, orderBy: createdAt_DESC) {
      id
      title
      body
      reminderAt
      customer {
        id
        companyName
      }
      createdAt
    }
  }
`;

export const MARK_AS_READ = gql`
  mutation MARK_AS_READ($userId: String!) {
    markAllAsRead(userId: $userId)
  }
`;
