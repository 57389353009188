import React from 'react';
import { Button } from '@chakra-ui/react';
import { IoIosPin, IoMdClose } from 'react-icons/io';

const AdvertisementInactiveFilter = ({ state, dispatch, refetch }) => {
  const handleShowActiveAdvertisements = async () => {
    await refetch({
      where: {
        ...state.searchValues,
        advertisements_some: {
          isActive: state.advertisementIsActive ? undefined : false,
        },
      },
    });
    dispatch({ type: 'SET_ADVERTISEMENT_IS_INACTIVE', value: true });
  };
  const handleHideActiveAdvertisements = async () => {
    await refetch({
      where: {
        ...state.searchValues,
        advertisements_some: state.advertisementIsActive
          ? {
              isActive: state.advertisementIsActive ? true : undefined,
            }
          : undefined,
      },
    });
    dispatch({ type: 'SET_ADVERTISEMENT_IS_INACTIVE', value: false });
  };

  return (
    <Button
      leftIcon={state.advertisementIsInactive ? <IoMdClose /> : <IoIosPin />}
      onClick={state.advertisementIsInactive ? handleHideActiveAdvertisements : handleShowActiveAdvertisements}
      size="sm"
      mb="2"
      mr="2">
      Ad Inaktiv
    </Button>
  );
};

export default AdvertisementInactiveFilter;
