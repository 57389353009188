import React, { useContext } from 'react';
import { Text, Tag, IconButton, Stack, Tr, Td, Tooltip, Flex, Avatar, useColorMode, HStack, Box, Icon } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { HiMenuAlt3, HiOutlineCheckCircle, HiOutlineXCircle, HiOutlineClock, HiOutlineUserAdd } from 'react-icons/hi';
import get from 'lodash/get';
import moment from 'moment';
import { FiBell } from 'react-icons/fi';

import SpinnerContext from '../../contexts/SpinnerContext';
import UserContext from '../../contexts/UserContext';
import { useCustomToast } from '../../hooks/useCustomToast';
import { getStatusText, getVariant } from './utils';
import { UPDATE_INTERNAL_CUSTOMER } from './query';

export const CustomerRow = ({
  id,
  incrementId,
  status,
  source,
  leadStatus,
  companyName,
  phone,
  phoneNumbers,
  email,
  contactName,
  asignee,
  updatedAt,
  createdAt,
  sourceCreatedAt,
  onDrawerOpen,
  refetch,
  notifications,
}) => {
  const { toggleSpinner } = useContext(SpinnerContext);
  const { user } = useContext(UserContext);
  const [updateInternalCustomer] = useMutation(UPDATE_INTERNAL_CUSTOMER);
  const toast = useCustomToast();
  const { colorMode } = useColorMode();

  const handleUpdateAsignee = async () => {
    try {
      toggleSpinner(true);
      await updateInternalCustomer({
        variables: {
          where: {
            id: id,
          },
          data: {
            asignee: {
              connect: { id: user.id },
            },
            history: {
              create: [
                {
                  type: 'UPDATED',
                  comment: `${user.firstName} ${user.lastName} zugewiesen`,
                  timestamp: moment().toISOString(),
                  changedBy: {
                    connect: { id: user.id },
                  },
                },
              ],
            },
          },
        },
      });
      await refetch();
    } catch (error) {
      console.log(error);

      toast({
        description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
        status: 'error',
      });
    }
    toggleSpinner(false);
  };

  const handleChangeStatusMutation = async (status) => {
    try {
      toggleSpinner(true);
      await updateInternalCustomer({
        variables: {
          where: {
            id: id,
          },
          data: {
            status,
            history: {
              create: [
                {
                  type: 'UPDATED',
                  comment: `Status geändert in ${getStatusText(status)}`,
                  timestamp: moment().toISOString(),
                  changedBy: {
                    connect: { id: user.id },
                  },
                },
              ],
            },
          },
        },
      });
      await refetch();
    } catch (error) {
      console.log(error);

      toast({
        description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
        status: 'error',
      });
    }
    toggleSpinner(false);
  };

  return (
    <Tr>
      <Td fontWeight="100" color={colorMode === 'light' ? 'gray.900' : 'gray.100'}>
        {incrementId}
        <br />
        <Text as="span" color={colorMode === 'light' ? 'gray.700' : 'gray.300'} fontSize="xs">
          {moment(sourceCreatedAt || createdAt).format('DD.MM.YYYY, HH:mm')}
        </Text>
      </Td>
      <Td>
        <HStack>
          <Tag colorScheme={getVariant(status)} size="sm">
            {getStatusText(status)}
          </Tag>
          {source ? (
            <Tag size="sm" colorScheme="purple">
              {source}
            </Tag>
          ) : null}
          {leadStatus ? (
            <Tag size="sm" colorScheme={leadStatus === 'HOT' ? 'red' : leadStatus === 'WARM' ? 'orange' : 'blue'}>
              {leadStatus}
            </Tag>
          ) : null}
        </HStack>
      </Td>
      <Td fontFamily="heading">
        <HStack>
          <Text fontSize="sm" fontWeight="semibold" color={colorMode === 'light' ? 'gray.900' : 'gray.100'}>
            {companyName}
          </Text>

          {notifications?.length > 0 ? (
            <Box>
              <Icon w="5" h="5" as={FiBell} p="1" aria-label="notification" />
            </Box>
          ) : null}
        </HStack>

        <Text fontSize="sm" color={colorMode === 'light' ? 'gray.700' : 'gray.300'}>
          {contactName}
        </Text>
      </Td>
      <Td fontFamily="heading" width="20%">
        <Text fontSize="sm" fontWeight="semibold" color={colorMode === 'light' ? 'gray.900' : 'gray.100'}>
          {email}
        </Text>

        <Text fontSize="sm" color={colorMode === 'light' ? 'gray.700' : 'gray.300'}>
          {phone}
        </Text>
        <Text fontSize="sm" color={colorMode === 'light' ? 'gray.700' : 'gray.300'}>
          {phoneNumbers.map((number, index) => {
            return (
              <React.Fragment key={incrementId + 'phone' + index}>
                <span>{number}</span>
                {index + 1 < phoneNumbers.length ? <br /> : null}
              </React.Fragment>
            );
          })}
        </Text>
      </Td>
      <Td fontFamily="heading" fontSize="sm">
        {asignee ? (
          <Flex alignItems="center">
            <Avatar src={asignee.photo} name={`${asignee.firstName} ${asignee.lastName}`} mr="2" size="sm" />
            <Text>
              {asignee.firstName} {asignee.lastName}
            </Text>
          </Flex>
        ) : null}
      </Td>
      <Td fontFamily="heading">
        <Text color={colorMode === 'light' ? 'gray.900' : 'gray.100'}>{moment(updatedAt).format('DD.MM.YYYY, HH:mm')}</Text>
      </Td>
      <Td px="0" fontFamily="heading">
        <Stack alignItems="center" justifyContent="flex-end" direction="row" spacing="0">
          {status === 'INPROGRESS' ? null : (
            <Tooltip label="zu In Bearbeitung wechseln">
              <IconButton
                size="xs"
                variant="ghost"
                aria-label="inprogress"
                icon={<HiOutlineClock />}
                onClick={() => handleChangeStatusMutation('INPROGRESS')}
              />
            </Tooltip>
          )}

          {status === 'INPROGRESS' ? (
            <>
              <Tooltip label="zu Erledigt wechseln">
                <IconButton
                  size="xs"
                  variant="ghost"
                  aria-label="done"
                  icon={<HiOutlineCheckCircle />}
                  colorScheme="green"
                  onClick={() => handleChangeStatusMutation('DONE')}
                />
              </Tooltip>
              <Tooltip label="zu Abgebrochen wechseln">
                <IconButton
                  size="xs"
                  variant="ghost"
                  aria-label="failure"
                  icon={<HiOutlineXCircle />}
                  colorScheme="red"
                  onClick={() => handleChangeStatusMutation('FAILURE')}
                />
              </Tooltip>
              <Tooltip label="zu Pausiert wechseln">
                <IconButton
                  size="xs"
                  variant="ghost"
                  aria-label="inprogress"
                  icon={<HiOutlineClock />}
                  colorScheme="orange"
                  onClick={() => handleChangeStatusMutation('PARKED')}
                />
              </Tooltip>
              <Tooltip label="Später Nachhaken">
                <IconButton
                  size="xs"
                  variant="ghost"
                  aria-label="contactAgain"
                  icon={<HiOutlineClock />}
                  colorScheme="purple"
                  onClick={() => handleChangeStatusMutation('CONTACT_AGAIN')}
                />
              </Tooltip>
            </>
          ) : null}

          <Tooltip label="Mir zuweisen ">
            <IconButton size="xs" variant="ghost" aria-label="assign" icon={<HiOutlineUserAdd />} onClick={handleUpdateAsignee} />
          </Tooltip>
          <Tooltip label="Weitere Infos">
            <IconButton size="xs" variant="ghost" aria-label="more info" icon={<HiMenuAlt3 />} onClick={() => onDrawerOpen(id)} />
          </Tooltip>
        </Stack>
      </Td>
    </Tr>
  );
};
