import { Box, Button, Flex, Grid, HStack, Icon, Link, Tag, Text, useColorMode, VStack } from '@chakra-ui/react';
import { HiMail, HiMenuAlt3, HiStar } from 'react-icons/hi';
import moment from 'moment';
import get from 'lodash/get';
import { getVariant } from '../../utils';

const OrderCard = ({
  id,
  iid,
  createdAt,
  status,
  packages,
  packageStation,
  payment,
  orderer,
  pickuper,
  pickuperNoUser,
  review,
  stationReview,
  onDrawerOpen,
  openModal,
}) => {
  const { colorMode } = useColorMode();

  const paymentType = get(payment, '0.type', null);

  return (
    <Flex
      direction="column"
      width="100%"
      rounded="md"
      borderWidth="1px"
      borderColor={colorMode === 'light' ? 'gray.200' : 'gray.600'}
      py="4"
      px="4"
      boxShadow="sm">
      <Box flex="1">
        <HStack mb="2">
          <Tag colorScheme="blue" size="sm">
            {moment(createdAt).format('DD.MM.YYYY, HH:mm')}
          </Tag>

          {paymentType ? (
            <Tag size="sm" mr="2">
              {paymentType}
            </Tag>
          ) : null}

          <Tag size="sm" colorScheme={getVariant(status)}>
            {status}
          </Tag>
          <Text fontSize="xs">Boxes: {packages.length}</Text>
        </HStack>
        <Box mb="2">
          <Text mb="2" fontFamily="heading" fontSize="lg" color={colorMode === 'light' ? 'gray.900' : 'gray.100'}>
            {iid}
          </Text>
          <HStack mb="3" spacing={6}>
            <Link href={`/users/${orderer.id}`}>
              <Text fontSize="sm" color={colorMode === 'light' ? 'gray.900' : 'gray.100'}>
                Besteller: {orderer.firstName} {orderer.lastName}
              </Text>
            </Link>
            <Text fontSize="xs">{orderer?.email}</Text>
          </HStack>
          {pickuper ? (
            <HStack>
              <Text fontSize="sm" color={colorMode === 'light' ? 'gray.900' : 'gray.100'}>
                Pickuper??: {pickuper?.firstName} {pickuper?.lastName}
              </Text>
              <Text fontSize="xs">{pickuper?.email}</Text>
            </HStack>
          ) : null}

          {pickuperNoUser ? (
            <HStack>
              <Text fontSize="sm" color={colorMode === 'light' ? 'gray.900' : 'gray.100'}>
                Pickuper: {pickuperNoUser?.firstName} {pickuperNoUser?.lastName}
              </Text>
              <Text fontSize="xs">{pickuperNoUser?.email}</Text>
            </HStack>
          ) : null}
        </Box>
        <Box mb="4">
          {/* DropPoint - if company */}
          {packageStation?.locationName ? (
            <VStack alignItems="left" textAlign="left" mb="2">
              <Text fontSize="sm" color={colorMode === 'light' ? 'gray.900' : 'gray.100'}>
                <Link href={`/companies/${packageStation.company.id}`}>DropPoint: {packageStation?.locationName}</Link>
              </Text>
              {packageStation?.company?.users.map((user) => {
                return (
                  <Box key={id + user?.id}>
                    <Text fontSize="xs">{user?.email}</Text>
                  </Box>
                );
              })}
            </VStack>
          ) : (
            //DropPoint - if single user
            <>
              <HStack spacing={6} mb="2">
                <Link href={`/users/${packageStation.user.id}`}>
                  <Text fontSize="sm" color={colorMode === 'light' ? 'gray.900' : 'gray.100'}>
                    DropPoint: {packageStation?.user?.firstName} {packageStation?.user?.lastName}
                  </Text>
                </Link>
                <Text fontSize="xs">{packageStation?.user?.email}</Text>
              </HStack>
            </>
          )}

          <VStack spacing="3" mt="4" alignItems="flex-start">
            {review?.rating ? (
              <Box>
                <Box>
                  <Text fontSize="sm" fontWeight="bold">
                    Nutzer-Bewertung:
                  </Text>
                </Box>
                <Flex>
                  <Icon as={HiStar} mr="1" color={review?.rating > 0 ? 'yellow.400' : 'gray.500'} />
                  <Icon as={HiStar} mr="1" color={review?.rating > 1 ? 'yellow.400' : 'gray.500'} />
                  <Icon as={HiStar} mr="1" color={review?.rating > 2 ? 'yellow.400' : 'gray.500'} />
                  <Icon as={HiStar} mr="1" color={review?.rating > 3 ? 'yellow.400' : 'gray.500'} />
                  <Icon as={HiStar} mr="1" color={review?.rating > 4 ? 'yellow.400' : 'gray.500'} />
                </Flex>
                {review?.note ? (
                  <Text fontSize="md" fontWeight="bold" color={colorMode === 'light' ? 'gray.500' : 'gray.300'}>
                    {review?.note}
                  </Text>
                ) : null}
              </Box>
            ) : null}

            {stationReview ? (
              <Box>
                <Box>
                  <Text fontSize="sm" fontWeight="bold">
                    DropPoint-Bewertung:
                  </Text>
                </Box>
                <Flex>
                  <Icon as={HiStar} mr="1" color={stationReview.rating > 0 ? 'yellow.400' : 'gray.500'} />
                  <Icon as={HiStar} mr="1" color={stationReview.rating > 1 ? 'yellow.400' : 'gray.500'} />
                  <Icon as={HiStar} mr="1" color={stationReview.rating > 2 ? 'yellow.400' : 'gray.500'} />
                  <Icon as={HiStar} mr="1" color={stationReview.rating > 3 ? 'yellow.400' : 'gray.500'} />
                  <Icon as={HiStar} mr="1" color={stationReview.rating > 4 ? 'yellow.400' : 'gray.500'} />
                </Flex>
                {stationReview.note ? (
                  <Text fontSize="md" fontWeight="bold">
                    {stationReview.note}
                  </Text>
                ) : null}
              </Box>
            ) : null}
          </VStack>
        </Box>
      </Box>

      {status !== 'DONE' ? (
        <>
          <Text fontWeight="bold" fontFamily="heading" fontSize="sm" mb="2">
            Status ändern:
          </Text>
          <Box gap="2" mb="3">
            <Tag
              onClick={() => openModal(id, 'cancel')}
              size="sm"
              borderRadius="full"
              variant="outline"
              colorScheme="red"
              cursor="pointer"
              mr="2">
              Stornieren
            </Tag>
            <Tag
              onClick={() => openModal(id, 'expire')}
              size="sm"
              borderRadius="full"
              variant="outline"
              colorScheme="blue"
              cursor="pointer">
              Expired
            </Tag>
          </Box>
        </>
      ) : null}
      <Grid gridTemplateColumns="1fr 1fr" gap="4">
        <Button rightIcon={<HiMenuAlt3 />} colorScheme="green" onClick={() => onDrawerOpen(id, packageStation?.id)}>
          Weitere Infos
        </Button>
      </Grid>
    </Flex>
  );
};

export default OrderCard;
