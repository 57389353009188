import React, { useContext } from 'react';
import {
  Text,
  Badge,
  Button,
  Box,
  useClipboard,
  Icon,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useColorMode,
} from '@chakra-ui/react';
import { HiPencilAlt, HiTrash } from 'react-icons/hi';
import { useMutation } from '@apollo/client';
import { useDisclosure } from '@chakra-ui/react';
import moment from 'moment';

import SpinnerContext from '../../contexts/SpinnerContext';
import { useCustomToast } from '../../hooks/useCustomToast';
import { DELETE_COUPON } from './query';

export const CouponCard = ({
  id,
  code,
  type,
  description,
  validUntil,
  validUses,
  rateForStation,
  discountForOrderer,
  refetch,
  openModal,
}) => {
  const { onCopy, hasCopied } = useClipboard(code);
  const cancelRef = React.useRef();
  const { toggleSpinner } = useContext(SpinnerContext);
  const [deleteCoupon] = useMutation(DELETE_COUPON);
  const toast = useCustomToast();
  const { colorMode } = useColorMode();
  const { isOpen: isOpenDeleteModal, onOpen: onOpenDeleteModal, onClose: onCloseDeleteModal } = useDisclosure();

  const handleDelete = async () => {
    try {
      toggleSpinner(true);
      await deleteCoupon({
        variables: {
          where: { id },
        },
      });
      await refetch();
      toast({
        title: 'Erfolg!',
        description: 'Der Coupon wurde gelöscht.',
        status: 'success',
      });
    } catch (error) {
      console.log(error);
      toast({
        description: 'Ein Fehler ist aufgetreten!',
        status: 'error',
      });
    }
    toggleSpinner(false);
  };

  return (
    <Box width="100%" rounded="md" borderWidth="1px" py="4" px="6" _hover={{ boxShadow: 'md' }} position="relative">
      <Badge>{type === 'GLOBAL' ? 'Universell' : 'Individual'}</Badge>

      <Icon as={HiPencilAlt} position="absolute" right="37px" top="15px" cursor="pointer" onClick={openModal} />

      <Icon as={HiTrash} position="absolute" right="15px" top="15px" cursor="pointer" color="red.500" onClick={onOpenDeleteModal} />

      <AlertDialog isOpen={isOpenDeleteModal} leastDestructiveRef={cancelRef} onClose={onCloseDeleteModal}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Coupon Löschen
          </AlertDialogHeader>

          <AlertDialogBody>Bist du sicher? Diese Aktion kann nicht rückgängig gemacht werden.</AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onCloseDeleteModal}>
              Abbrechen
            </Button>
            <Button colorScheme="red" onClick={handleDelete} ml={3}>
              Löschen
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <Text fontFamily="heading" fontSize="lg" fontWeight="bold" display="flex" alignItems="center" mb="2">
        {code}
        <Button ml="4" variant="link" size="xs" onClick={onCopy} colorScheme="brand" fontWeight="normal" letterSpacing="0.5px">
          {hasCopied ? 'Kopiert' : 'Kopieren'}
        </Button>
      </Text>
      <Text fontSize="md">
        {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(rateForStation / 100)} Vergütung
      </Text>
      <Text fontSize="md" mb="2">
        {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(discountForOrderer / 100)} Besteller-Preis
      </Text>
      <Text fontSize="sm" mb="1" color={colorMode === 'light' ? 'gray.700' : 'gray.300'}>
        {description}
      </Text>
      <Text fontSize="xs" color={colorMode === 'light' ? 'gray.700' : 'gray.300'}>
        Gültig bis: {moment(validUntil).format('DD.MM.YYYY')} • {validUses} gültige Anwendungen übrig
      </Text>
    </Box>
  );
};
