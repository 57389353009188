import React, { useContext } from 'react';
import {
  Button,
  Badge,
  Text,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalContent,
  ModalFooter,
} from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import get from 'lodash/get';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';

import SpinnerContext from '../../../contexts/SpinnerContext';
import { useCustomToast } from '../../../hooks/useCustomToast';
import UserContext from '../../../contexts/UserContext';
import { QuillInput } from '../../QuillInput';
import { UPDATE_ORDER } from '../query';

const CancelOrderSchema = Yup.object().shape({
  comment: Yup.string().required('Du musst einen Kommentar hinterlegen'),
});

export const StatusCanceledModal = ({ refetch, isOpen, onClose, orderId }) => {
  const { toggleSpinner } = useContext(SpinnerContext);
  const { user } = useContext(UserContext);
  const toast = useCustomToast();
  const [updateOrderStatus] = useMutation(UPDATE_ORDER);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Buchung stornieren</ModalHeader>
          <Formik
            initialValues={{
              orderId,
              internalUser: user.id,
              comment: '',
            }}
            validationSchema={CancelOrderSchema}
            onSubmit={async ({ orderId, comment, internalUser }) => {
              try {
                toggleSpinner(true);

                await updateOrderStatus({
                  variables: {
                    orderId,
                    type: 'STATUS_CHANGED',
                    internalUser,
                    comment,
                    status: 'CANCELED',
                  },
                });

                await refetch();

                toast({
                  title: 'Erfolg!',
                  description: 'Status wurde erfolgreich geändert!',
                  status: 'success',
                  isClosable: false,
                  duration: 3000,
                });
                toggleSpinner(false);
                onClose();
              } catch (error) {
                console.log(error);

                toast({
                  description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
                  status: 'error',
                });
                toggleSpinner(false);
              }
            }}>
            {({ isValid, dirty }) => (
              <Form>
                <ModalBody>
                  <Text mb="4">
                    <Badge colorScheme="red">Achtung</Badge> Das Stornieren kann nicht rückgängig gemacht werden. Möchtest Du die Buchung
                    tatsächlich stornieren und getätigte Zahlungen automatisch rückbuchen? Der DropPoint erhält keine Vergütung!
                  </Text>

                  <QuillInput
                    label="Warum soll die Buchung storniert werden?"
                    placeholder="Geben Sie hier Ihre Beschreibung ein "
                    name="comment"
                  />
                </ModalBody>

                <ModalFooter>
                  <Button variant="ghost" size="sm" colorScheme="red" type="button" onClick={onClose}>
                    Abbrechen
                  </Button>

                  <Button disabled={!(isValid && dirty)} ml="4" size="sm" colorScheme="brand" type="submit">
                    Stornieren
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </>
  );
};
