import { Button } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/hooks';

import EditUserModal from '../modals/EditUser';

export const EditUser = ({ user, refetch }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button variant="outline" colorScheme="brand" size="xs" mr="2" mb="2" onClick={onOpen}>
        Nutzerdaten ändern
      </Button>

      <EditUserModal isOpen={isOpen} onClose={onClose} refetch={refetch} user={user} />
    </>
  );
};
