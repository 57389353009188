import React, { useContext, useEffect, useState } from 'react';
import { Editable, EditableInput, EditablePreview, Grid, Box, Text, Link, Alert, AlertIcon } from '@chakra-ui/react';

import { DataRow } from './DataRow';

import { useMutation } from '@apollo/client';
import { formatGoogleResponse } from '../../utils';
import SpinnerContext from '../../contexts/SpinnerContext';
import { CHECK_ADDRESS } from './query';

const CompanyInfo = ({ internalCustomer, handleUpdateCustomerInfo }) => {
  const [checkAddress] = useMutation(CHECK_ADDRESS);
  const { address } = internalCustomer;
  const [coordinates, setCoordinates] = useState(null);

  const fetchCoordinates = async () => {
    try {
      const locationString = `${address?.street} ${address?.houseNumber}, ${address?.postalCode} ${address?.city}`;
      const {
        data: { checkAddress: addressCheckData },
      } = await checkAddress({
        variables: {
          address: locationString,
        },
      });

      if (!addressCheckData || !addressCheckData.address) {
        setCoordinates(null);
        return;
      }

      const { geometry } = formatGoogleResponse(addressCheckData?.address);
      setCoordinates({ lat: geometry?.coordinates[0], lng: geometry?.coordinates[1] });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (address?.street && address?.city && address?.postalCode) {
      fetchCoordinates();
    }
  }, [address]);

  return (
    <>
      <DataRow
        label="Adresse"
        alignItems="flex-start"
        description={
          coordinates ? (
            <Link
              color="teal.500"
              target="_blank"
              onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${coordinates.lng},${coordinates?.lat}`)}>
              In Maps öffnen
            </Link>
          ) : null
        }>
        <Box>
          <Grid gridTemplateColumns="1fr">
            <Editable
              placeholder="Hier angeben"
              defaultValue={internalCustomer?.address?.street}
              onSubmit={(nextValue) =>
                handleUpdateCustomerInfo(
                  { address: { update: { street: nextValue } } },
                  nextValue,
                  internalCustomer?.address.street,
                  'Strasse'
                )
              }>
              <EditablePreview />
              <EditableInput />
            </Editable>
          </Grid>

          <Grid gridTemplateColumns="2fr 1fr">
            <Editable
              placeholder="Hier angeben"
              defaultValue={internalCustomer?.address?.city}
              onSubmit={(nextValue) =>
                handleUpdateCustomerInfo({ address: { update: { city: nextValue } } }, nextValue, internalCustomer?.address.city, 'Stadt')
              }>
              <EditablePreview />
              <EditableInput />
            </Editable>
            <Editable
              placeholder="Hier angeben"
              defaultValue={internalCustomer?.address?.postalCode}
              onSubmit={(nextValue) =>
                handleUpdateCustomerInfo(
                  { address: { update: { postalCode: nextValue } } },
                  nextValue,
                  internalCustomer?.address.postalCode,
                  'PLZ'
                )
              }>
              <EditablePreview />
              <EditableInput />
            </Editable>
          </Grid>
        </Box>
      </DataRow>

      <DataRow
        label="Geschäftsart"
        value={internalCustomer?.businessType}
        onSubmit={(nextValue) =>
          handleUpdateCustomerInfo({ businessType: nextValue }, nextValue, internalCustomer?.businessType, 'Geschäftsart')
        }
      />
      <DataRow
        label="Kontaktmethode"
        value={internalCustomer?.contactMethod}
        onSubmit={(nextValue) =>
          handleUpdateCustomerInfo({ contactMethod: nextValue }, nextValue, internalCustomer?.contactMethod, 'Kontaktmethode')
        }
      />
      <DataRow
        label="Filialenanzahl "
        value={internalCustomer?.branchAmount}
        onSubmit={(nextValue) =>
          handleUpdateCustomerInfo({ branchAmount: nextValue }, nextValue, internalCustomer?.branchAmount, 'Filialenanzahl')
        }
      />
    </>
  );
};

export default CompanyInfo;
