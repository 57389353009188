import gql from 'graphql-tag';

export const ADVERTISEMENTS = gql`
  query ADVERTISEMENTS($where: AdvertisementWhereInput, $orderBy: AdvertisementOrderByInput, $first: Int, $skip: Int) {
    advertisements(where: $where, orderBy: $orderBy, first: $first, skip: $skip) {
      id
      title
      category
      description
      delivery
      url
      isActive
      deliveryTimes {
        from
        to
        allDays
        weekday
      }
      address {
        street
        houseNumber
        postalCode
        city
        locationId
        geometry {
          type
          coordinates
        }
      }
      photo {
        uri
      }
      company {
        id
        name
        logo
      }
      user {
        id
        firstName
        lastName
        email
        photo {
          uri
        }
      }
    }
    totalAdvertisements {
      total
    }
  }
`;

export const CHECK_ADDRESS = gql`
  mutation CHECK_ADDRESS($latlng: String, $address: String) {
    checkAddress(latlng: $latlng, address: $address) {
      address
    }
  }
`;

export const CREATE_ADVERTISEMENT = gql`
  mutation CREATE_ADVERTISEMENT($data: AdvertisementUpdateInput!) {
    internalCreateAdvertisement(data: $data) {
      id
    }
  }
`;

export const UPDATE_ADVERTISEMENT = gql`
  mutation UPDATE_ADVERTISEMENT($data: AdvertisementUpdateInput!, $where: AdvertisementWhereUniqueInput!) {
    updateAdvertisement(data: $data, where: $where) {
      id
    }
  }
`;

export const ACTIVATE_ADVERTISEMENT = gql`
  mutation ACTIVATE_ADVERTISEMENT(
    $advertisementId: String!
    $isActive: Boolean!
    $sendEmail: Boolean
    $comment: String!
    $internalUser: String!
  ) {
    internalActivateAdvertisement(
      advertisementId: $advertisementId
      isActive: $isActive
      sendEmail: $sendEmail
      comment: $comment
      internalUser: $internalUser
    ) {
      id
    }
  }
`;

export const DELETE_ADVERTISEMENT = gql`
  mutation DELETE_ADVERTISEMENT($where: AdvertisementWhereUniqueInput!) {
    deleteAdvertisement(where: $where) {
      id
    }
  }
`;
