import gql from 'graphql-tag';

export const GET_ORDER = gql`
  query GET_ORDER($orderId: ID, $stationId: String!) {
    order(where: { id: $orderId }) {
      id
      iid
      createdAt
      status
      tip
      packageStation {
        id
        locationName
        company {
          id
          logo
          name
          users {
            id
            email
          }
        }
        user {
          id
          firstName
          lastName
          email
        }
      }
      orderer {
        id
        firstName
        lastName
        email
      }
      pickuper {
        id
        firstName
        lastName
        email
      }
      pickuperNoUser {
        firstName
        lastName
        email
      }
      payment {
        id
        type
      }
      review {
        id
        rating
        note
      }
      stationReview {
        id
        rating
        note
      }
      packages {
        id
        handedOver
        handedOverTimestamp
        timestamp
        holdingFee
        note
        photos {
          uri
        }
      }
      history(orderBy: createdAt_DESC) {
        id
        type
        comment
        timestamp
        createdAt
        changedBy {
          id
          firstName
          lastName
        }
      }
    }
    retrieveAccount(stationId: $stationId) {
      id
      phone
    }
  }
`;

export const EXPORT_CSV = gql`
  mutation EXPORT_CSV {
    exportOrdersToCsv
  }
`;

export const UPDATE_ORDER = gql`
  mutation UPDATE_ORDER($orderId: String!, $status: OrderStatus!, $internalUser: String!, $comment: String!) {
    internalChangeOrderStatus(orderId: $orderId, status: $status, internalUser: $internalUser, comment: $comment) {
      id
    }
  }
`;
export const ADD_COMMENT = gql`
  mutation UPDATE_INTERNAL_CUSTOMER($data: OrderUpdateInput!, $where: OrderWhereUniqueInput!) {
    updateOrder(data: $data, where: $where) {
      id
    }
  }
`;
