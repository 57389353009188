import React, { useState, useContext, useRef } from 'react';
import {
  Box,
  Flex,
  Spinner,
  Grid,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  useColorMode,
} from '@chakra-ui/react';
import { useQuery, useMutation } from '@apollo/client';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import Input from '../../components/Input';
import SpinnerContext from '../../contexts/SpinnerContext';
import UserContext from '../../contexts/UserContext';
import NewInvoiceNumberModal from './NewInvoiceNumber';
import { HISTORY, RESERVE_INVOICE_NUMBER } from './query';

const HistoySchema = Yup.object().shape({
  comment: Yup.string().required('Warum möchtest Du eine Rechnungsnummer reservieren?'),
});

const History = () => {
  // Kündigung Modal
  const [alertOpen, setAlertOpen] = useState(false);
  const onAlertClose = () => setAlertOpen(false);
  const cancelRef = useRef();

  const { toggleSpinner } = useContext(SpinnerContext);
  const { user } = useContext(UserContext);
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newInvoiceNumber, setNewInvoiceNumber] = useState('');
  const [reserveNumber] = useMutation(RESERVE_INVOICE_NUMBER);

  const { loading, error, data, refetch } = useQuery(HISTORY, {
    fetchPolicy: 'network-only',
  });

  const handleReserve = async ({ comment }) => {
    toggleSpinner(true);
    const {
      data: { reserveInvoiceNumber },
    } = await reserveNumber({
      variables: {
        comment,
        internalUser: user.id,
      },
    });
    await refetch();
    setNewInvoiceNumber(reserveInvoiceNumber);
    onOpen();
    setAlertOpen(false);
    toggleSpinner(false);
  };

  if (loading) {
    return (
      <Box px="4" py="8">
        <Flex width="100%" height="300px" justifyContent="center" alignItems="center">
          <Spinner />
        </Flex>
      </Box>
    );
  }

  if (error) {
    return (
      <Box px="4" py="8">
        <Flex width="100%" height="300px" justifyContent="center" alignItems="center">
          Ein Fehler ist aufgetreten. Schreib Osamah an.
        </Flex>
      </Box>
    );
  }

  return (
    <Box>
      {data.histories.length > 0 ? (
        <>
          <Flex mb="3" justifyContent="space-between" alignItems="center">
            <Button colorScheme="brand" variant="outline" onClick={() => refetch()} size="xs">
              Neu laden
            </Button>
            <Button colorScheme="brand" size="sm" onClick={() => setAlertOpen(true)}>
              Rechnungsnummer reservieren
            </Button>
          </Flex>
          <Grid width="100%" gridTemplateColumns="auto 1fr 1fr auto">
            <Box p="2" backgroundColor={colorMode === 'light' ? 'gray.100' : 'gray.700'}>
              Timestamp
            </Box>
            <Box p="2" backgroundColor={colorMode === 'light' ? 'gray.100' : 'gray.700'}>
              Rechnungsnummer
            </Box>
            <Box p="2" backgroundColor={colorMode === 'light' ? 'gray.100' : 'gray.700'}>
              Kommentar
            </Box>
            <Box p="2" backgroundColor={colorMode === 'light' ? 'gray.100' : 'gray.700'}>
              Mitarbeiter
            </Box>
            {data.histories.map(({ createdAt, type, change, changedBy, comment }) => {
              return (
                <React.Fragment key={`${type}-${createdAt}`}>
                  <Box fontSize="sm" px="2" py="4" wordBreak="break-word" borderBottomWidth="1px" borderBottomColor="gray.100">
                    {moment(createdAt).format('DD.MM.YYYY, HH:mm')}
                  </Box>
                  <Box px="2" py="4" wordBreak="break-word" borderBottomWidth="1px" borderBottomColor="gray.100">
                    {change ? change.current : ''}
                  </Box>
                  <Box px="2" py="4" wordBreak="break-word" borderBottomWidth="1px" borderBottomColor="gray.100">
                    {comment}
                  </Box>
                  <Box px="2" py="4" wordBreak="break-word" borderBottomWidth="1px" borderBottomColor="gray.100">
                    {changedBy ? changedBy.firstName : ''}
                  </Box>
                </React.Fragment>
              );
            })}
          </Grid>
        </>
      ) : (
        <Flex width="100%" height="300px" justifyContent="center" alignItems="center" flexDir="column">
          <Box mb="3">Es wurde noch keine Änderung über das CRM gemacht</Box>
          <Flex justifyContent="center" alignItems="center">
            <Button colorScheme="brand" variant="outline" size="sm" mr="3" onClick={() => refetch()}>
              Neu laden
            </Button>
            <Button colorScheme="brand" size="sm" onClick={() => setAlertOpen(true)}>
              Rechnungsnummer reservieren
            </Button>
          </Flex>
        </Flex>
      )}

      <AlertDialog isOpen={alertOpen} leastDestructiveRef={cancelRef} onClose={onAlertClose}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <Formik initialValues={{ comment: '' }} validationSchema={HistoySchema} onSubmit={handleReserve}>
            <Form>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Soll eine Rechnungsnummer reserviert werden?
              </AlertDialogHeader>

              <AlertDialogBody>
                <Input name="comment" label="Kommentar" />
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onAlertClose}>
                  Abbrechen
                </Button>
                <Button colorScheme="green" type="submit" ml={3}>
                  Reservieren
                </Button>
              </AlertDialogFooter>
            </Form>
          </Formik>
        </AlertDialogContent>
      </AlertDialog>
      <NewInvoiceNumberModal isOpen={isOpen} newInvoiceNumber={newInvoiceNumber} onClose={onClose} />
    </Box>
  );
};

export default History;
