import React, { useContext, useRef, useState } from 'react';
import {
  Button,
  Text,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import get from 'lodash/get';

import SpinnerContext from '../../../contexts/SpinnerContext';
import { useUser } from '../../../contexts/UserContext';
import { SEND_ACTIVITY_CHECK } from '../query';
import { useCustomToast } from '../../../hooks/useCustomToast';

const SendEmailButton = ({ where, refetch, totalUsers }) => {
  const [alertOpen, setAlertOpen] = useState(false);
  const onAlertClose = () => setAlertOpen(false);
  const toast = useCustomToast();
  const cancelRef = useRef();
  const { toggleSpinner } = useContext(SpinnerContext);
  const { user } = useUser();
  const [sendActivityCheck] = useMutation(SEND_ACTIVITY_CHECK);

  const handleSendActivityCheck = async () => {
    try {
      toggleSpinner(true);

      await sendActivityCheck({
        variables: {
          internalUserId: user.id,
          where: where,
        },
      });

      await refetch();
      toast({
        title: 'Erfolg!',
        description: `Aktivitätscheck an ${totalUsers} User versendet.`,
        status: 'success',
      });
    } catch (error) {
      console.log(error);
      toast({
        description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
        status: 'error',
      });
    }
    setAlertOpen(false);
    toggleSpinner(false);
  };

  return (
    <>
      <Button size="sm" colorScheme="green" onClick={() => setAlertOpen(true)}>
        Aktivitätscheck senden
      </Button>

      <AlertDialog isOpen={alertOpen} leastDestructiveRef={cancelRef} onClose={onAlertClose}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Bist Du sicher?
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text mb="4">Möchtest du eine Aktivitätscheck E-mail an {totalUsers} User senden?</Text>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onAlertClose}>
              Abbrechen
            </Button>
            <Button colorScheme="orange" type="submit" ml={3} onClick={handleSendActivityCheck}>
              Aktivitätscheck senden
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default SendEmailButton;
