import Cookies from 'js-cookie';

export const register = () => {
  const publicVapidKey = 'BJZH09sLEq3txCfHWt2aDrKy9PodhCvGJJsjc7q1HCIYQ--_B38NMEnPCvaxTi9cYSUutUqMbYP8fgJiT1fGHnM';

  // Check for service worker
  if ('serviceWorker' in navigator) {
    send().catch((err) => console.error(err));
  }

  // Register SW, Register Push, Send Push
  async function send() {
    // Register Service Worker
    console.log('Registering service worker...');
    const register = await navigator.serviceWorker.register('/worker.js', {});
    console.log('Service Worker Registered...');
    await Notification.requestPermission();

    // Register Push
    console.log('Registering Push...');
    const subscription = await register.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
    });
    await Cookies.set('pushToken', JSON.stringify(subscription));
    console.log('Push Token Registered...');

    // Send Push Notification
    // console.log('Sending Push...');
    // await fetch('/subscribe', {
    //   method: 'POST',
    //   body: JSON.stringify(subscription),
    //   headers: {
    //     'content-type': 'application/json',
    //   },
    // });
    // console.log('Push Sent...');
  }

  function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }
};
