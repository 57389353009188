import React from 'react';
import { Flex, Icon, Text, Link, Badge, useColorMode } from '@chakra-ui/react';
import { HiExternalLink } from 'react-icons/hi';

export const InvoiceRow = ({ id, number, inDefaultReceipt, inputData, url, payment, company, subscription }) => {
  const { colorMode } = useColorMode();

  let displayNumber = '';
  if (number < 10) {
    displayNumber = `00000${number}`;
  } else if (number < 100) {
    displayNumber = `0000${number}`;
  } else if (number < 1000) {
    displayNumber = `000${number}`;
  } else if (number < 10000) {
    displayNumber = `00${number}`;
  } else if (number < 100000) {
    displayNumber = `0${number}`;
  } else {
    displayNumber = `${number}`;
  }

  return (
    <Flex borderWidth="1px" px="4" py="4" rounded="md" flexDirection="column" mb="2">
      <Flex>{payment || company?.paymentMethod ? <Badge>{payment?.type || company?.paymentMethod}</Badge> : null}</Flex>
      <Text as="h4" fontSize="lg" fontFamily="heading">
        Rechnung {displayNumber} - {inputData.description || inputData.iid}
        {subscription ? ` - ${subscription.subscriptionId}` : ''}
      </Text>
      {payment ? (
        <Flex alignItems="center" justifyContent="space-between">
          <Text>
            Betrag:{' '}
            {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
              inDefaultReceipt ? payment.amount / 100 : (payment.amount + payment.dropfriendsAmount) / 100
            )}
          </Text>
          <Text>
            DropFriends-Betrag:{' '}
            {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(payment.dropfriendsAmount / 100)}
          </Text>
        </Flex>
      ) : null}
      <Flex alignItems="center" justifyContent="space-between">
        <Link href={url} isExternal color={colorMode === 'light' ? 'brand.500' : 'brand.400'}>
          Rechnung ansehen
          <Icon as={HiExternalLink} size="16" fontSize="md" aria-label="external-link" mx="1" />
        </Link>
      </Flex>
    </Flex>
  );
};
