import React, { useContext } from 'react';
import { useLocation, navigate } from '@reach/router';
import { Avatar, Box, Flex, Text } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';

import UserContext from '../../contexts/UserContext';
import { USER } from './query';

const NavMenuLink = ({ children, to }) => {
  const location = useLocation();

  const isCurrent = location.pathname.includes(to);

  const navigateTo = (url) => {
    navigate(url);
  };

  const onKeyPress = (event) => {
    if (event.charCode === 13 || event.keyCode === 13) {
      navigateTo(to);
    }
  };

  return (
    <Box
      onKeyPress={onKeyPress}
      onClick={() => navigateTo(to)}
      mr="2"
      height="32px"
      cursor="pointer"
      borderBottom={isCurrent ? '2px' : ''}
      borderBottomColor="gray.600"
      color={isCurrent ? 'gray.600' : 'gray.400'}
      fontWeight="semibold"
      fontSize="sm"
      _hover={{ borderBottom: '2px', color: 'gray.700' }}>
      <Box px="2" py="1">
        {children}
      </Box>
    </Box>
  );
};
const NavMenu = ({ navList, titleIconBG }) => {
  return (
    <Flex mt="1">
      {navList.map((link) => {
        return <NavMenuLink key={link.to} titleIconBG={titleIconBG} {...link} />;
      })}
    </Flex>
  );
};

export const PageLayout = ({
  children,
  title,
  subTitle = '',
  height = '100%',
  titleIcon = null,
  titleIconBG = 'gray.300',
  navList = [],
  headerRightSide = null,
}) => {
  const { user } = useContext(UserContext);
  const { data } = useQuery(USER, {
    variables: {
      userId: user.id,
    },
    skip: !user.id,
  });

  return (
    <Flex
      flexDir="column"
      width={['100vw', '100vw', '100%']}
      margin="0 auto"
      height={['100%', '100%', '100%']}
      overflowY="auto"
      backgroundColor="gray.50">
      <Flex justifyContent="center" alignItems="center" background="white" borderBottom="1px" borderColor="gray.200">
        <Flex
          direction="column"
          pt={['4', '6', '8']}
          flex="1"
          alignItems="center"
          width="100%"
          maxW="1600px"
          px={['4', '6', '8']}
          pb={navList.length > 0 ? 0 : ['4', '6', '8']}>
          <Flex width="100%" alignItems="center">
            <Flex flex="1" mb={navList.length > 0 ? '6' : 0} alignItems="center">
              <Avatar src={data?.user?.myCompany?.logo} name={data?.user?.myCompany?.name} mr={['4', '4', '8']} size="xl" />
              <Box>
                <Text as="h2" lineHeight="28px" fontSize="2xl" color="brand.800" fontWeight="semibold" flex="1" fontFamily="heading">
                  {title}
                </Text>
                {subTitle ? (
                  <Text fontSize="sm" lineHeight="20px" color="gray.500">
                    {subTitle}
                  </Text>
                ) : null}
              </Box>
            </Flex>
            <Box display={['none', 'none', 'none', 'none', 'block']}>{headerRightSide}</Box>
          </Flex>
          {navList.length > 0 ? (
            <Flex width="100%" mt="1">
              <NavMenu titleIconBG={titleIconBG} navList={navList} />
            </Flex>
          ) : null}
        </Flex>
      </Flex>
      <Box width="100%" maxW="1600px" margin="0 auto" px={['4', '6', '8']} py={['4', '6', '8']} flex="1">
        {children}
      </Box>
    </Flex>
  );
};
