import { gql } from '@apollo/client';

export const CALCULATE_POTENTIAL = gql`
  query CALCULATE_POTENTIAL(
    $postalCode: String!
    $averageNumberOfVisitors: String!
    $averageValueOfGoods: String
    $couponType: String
    $couponValue: Float
  ) {
    calculatePotential(
      postalCode: $postalCode
      averageNumberOfVisitors: $averageNumberOfVisitors
      averageValueOfGoods: $averageValueOfGoods
      couponType: $couponType
      couponValue: $couponValue
    ) {
      walkInIncreaseMin
      walkInIncreaseMax
      revenuePotentialMin
      revenuePotentialMax
    }
  }
`;

export const GENERATE_POTENTIAL_CALCULATOR_PDF = gql`
  mutation GENERATE_POTENTIAL_CALCULATOR_PDF(
    $postalCode: String!
    $averageNumberOfVisitors: String!
    $averageValueOfGoods: String
    $couponValue: Float
  ) {
    generatePotentialCalculatorPDF(
      postalCode: $postalCode
      averageNumberOfVisitors: $averageNumberOfVisitors
      averageValueOfGoods: $averageValueOfGoods
      couponValue: $couponValue
    )
  }
`;
