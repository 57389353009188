import React from 'react';
import { useField, useFormikContext } from 'formik';
import { FormControl, FormLabel, FormErrorMessage, CheckboxGroup, Checkbox as ChakraCheckbox, Stack } from '@chakra-ui/react';

const Checkbox = ({ label, options = [], ...props }) => {
  // @ts-ignore
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  return (
    <FormControl mb="4" isInvalid={!!(meta.touched && meta.error)}>
      <FormLabel fontSize="sm">{label}</FormLabel>
      <CheckboxGroup onChange={(values) => setFieldValue(field.name, values)} value={field.value}>
        <Stack spacing="3">
          {options.map(({ value, label, disabled, ...rest }) => {
            return (
              <ChakraCheckbox spacing="3" key={value} name={field.name} value={value} isDisabled={disabled} {...rest}>
                {label}
              </ChakraCheckbox>
            );
          })}
        </Stack>
      </CheckboxGroup>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};

export default Checkbox;

export const SingleCheckbox = ({ label, ...props }) => {
  // @ts-ignore
  const [field, meta] = useField(props);

  return (
    <FormControl mb="4" isInvalid={!!meta.error}>
      <ChakraCheckbox spacing="6" isChecked={field.value} {...field}>
        {label}
      </ChakraCheckbox>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
