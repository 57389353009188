import React, { useContext, useState, useRef } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useMutation, useQuery } from '@apollo/client';
import { Spinner, Flex, Text, Grid, Button, Box, Avatar } from '@chakra-ui/react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';

import Layout from '../../components/Layout';
import { FormWrapper } from '../../components/FormWrapper';
import { useUser } from '../../contexts/UserContext';
import { UPDATE_INTERNAL_USER, USER_PROFILE, UPDATE_PASSWORD } from './query';
import { PageLayout } from '../../components/PageLayout';
import SpinnerContext from '../../contexts/SpinnerContext';
import { useCustomToast } from '../../hooks/useCustomToast';
import Input from '../../components/Input';

const ChangePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Du musst dein altes Passwort angeben'),
  newPassword: Yup.string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&-_])[A-Za-z\d@$!%*#?&-_]{8,}$/,
      'Prüfen Sie Ihre Angabe. Ihr Passwort muss min. 8 Zeichen lang sein, sowie min. eine Zahl UND ein Sonderzeichen enthalten.'
    )
    .required('Du musst ein neues Passwort angeben'),
  newPasswordConfirm: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Leider stimmen Ihre Angaben nicht überein. Prüfen Sie diese und wiederholen Sie den Vorgang.')
    .required('Du musst dein neues Passwort bestätigen'),
});

interface ProfileProps extends RouteComponentProps {}

const Profile: React.FC<ProfileProps> = () => {
  const { user } = useUser();
  const { toggleSpinner } = useContext(SpinnerContext);
  const toast = useCustomToast();
  const [updateInternalUser] = useMutation(UPDATE_INTERNAL_USER);
  const [changePassword] = useMutation(UPDATE_PASSWORD);

  const { loading, error, data, refetch } = useQuery(USER_PROFILE, {
    fetchPolicy: 'network-only',
    variables: {
      userId: user.id,
    },
  });

  if (loading) {
    return (
      <PageLayout title="Profil">
        <Flex width="full" height="full" justifyContent="center" alignItems="center">
          <Spinner size="xl" />
        </Flex>
      </PageLayout>
    );
  }
  if (error) {
    return (
      <PageLayout title="Profil">
        <Box width="full" height="full">
          <Box width="full" maxW="800px" backgroundColor="red.100" textAlign="center" p="2" rounded="md" border="1px" borderColor="red.400">
            <Text color="red.800">Ein Fehler ist aufgetreten.</Text>
          </Box>
        </Box>
      </PageLayout>
    );
  }

  return (
    <>
      <Box width="100%">
        <Flex mb="9">
          <Layout fullWidth>
            <Flex direction={['column', 'column', 'column', 'row']} alignItems="center" mb="6">
              <Text as="h2" fontSize="4xl" flex="1" fontWeight="bold" fontFamily="heading">
                Profil Übersicht
              </Text>
            </Flex>

            <Formik
              enableReinitialize
              initialValues={{
                firstName: data?.internalUser?.firstName,
                lastName: data?.internalUser?.lastName,
                email: data?.internalUser?.email,
                phoneNumber: data?.internalUser?.phoneNumber || '',
              }}
              onSubmit={async ({ firstName, lastName, phoneNumber }) => {
                toggleSpinner(true);
                try {
                  await updateInternalUser({
                    variables: {
                      where: {
                        id: user?.id,
                      },
                      data: {
                        firstName,
                        lastName,
                        phoneNumber,
                      },
                    },
                  });
                  refetch();

                  toast({
                    title: 'Deine Profiländerungen waren erfolgreich.',
                    status: 'success',
                  });
                } catch (error) {
                  console.log(error);
                  toast({
                    title: 'Ein Fehler ist aufgetreten',
                    description: 'Es tut uns leid, etwas ist schiefgelaufen. Versuche es später noch einmal.',
                    status: 'error',
                  });
                }
                toggleSpinner(false);
              }}>
              {({ isValid, dirty, handleSubmit }) => {
                return (
                  <>
                    <FormWrapper
                      title="Profil"
                      description="Hier kannst Du Deine Profildaten bearbeiten"
                      footer={
                        <Button isDisabled={!dirty} size="md" colorScheme={isValid ? 'green' : ''} onClick={() => handleSubmit()}>
                          Speichern
                        </Button>
                      }
                      showDivider>
                      <Form>
                        <Grid mb="4" templateColumns={['1fr', '1fr 1fr']} gap="6">
                          <Input label="Vorname" name="firstName" />
                          <Input label="Nachname" name="lastName" />
                        </Grid>
                        <Grid templateColumns={['1fr', '1fr 1fr']} gap="6">
                          <Input label="Email-Adresse" name="email" disabled={true} />
                          <Input label="Telefonnummer" name="phoneNumber" />
                        </Grid>
                      </Form>
                    </FormWrapper>
                  </>
                );
              }}
            </Formik>

            <Formik
              enableReinitialize
              validationSchema={ChangePasswordSchema}
              initialValues={{
                currentPassword: '',
                newPassword: '',
                newPasswordConfirm: '',
              }}
              onSubmit={async ({ currentPassword, newPassword }, { resetForm }) => {
                toggleSpinner(true);
                try {
                  await changePassword({
                    variables: {
                      id: user.id,
                      oldPassword: currentPassword,
                      newPassword,
                    },
                  });
                  await resetForm();
                  refetch();

                  toast({
                    title: 'Dein Passwort wurde erfolgreich bearbeitet',
                    status: 'success',
                  });
                } catch (error) {
                  console.log(error);
                  toast({
                    title: 'Ein Fehler ist aufgetreten',
                    description: 'Es tut uns leid, etwas ist schiefgelaufen. Versuche es später noch einmal.',
                    status: 'error',
                  });
                }
                toggleSpinner(false);
              }}>
              {({ isValid, values, setFieldValue, dirty, handleSubmit }) => {
                return (
                  <>
                    <FormWrapper
                      title="Passwort ändern"
                      footer={
                        <Button
                          isDisabled={!values.currentPassword || !values.newPassword || !values.newPasswordConfirm || !isValid}
                          size="md"
                          colorScheme={'green'}
                          onClick={() => handleSubmit()}
                          type="submit">
                          Passwort ändern
                        </Button>
                      }>
                      <Grid templateColumns={['1fr', '1fr 1fr']} gap="6">
                        <Form>
                          <Input label="Altes Passwort" name="currentPassword" type="password" />
                          <Input label="Neues Passwort" name="newPassword" type="password" />
                          <Input label="Neues Passwort bestätigen" name="newPasswordConfirm" type="password" />
                        </Form>
                      </Grid>
                    </FormWrapper>
                  </>
                );
              }}
            </Formik>
          </Layout>
        </Flex>
      </Box>
    </>
  );
};

export default Profile;
