import React from 'react';
import { Editable, EditableInput, EditablePreview, Box, Heading, HStack, Tag, IconButton } from '@chakra-ui/react';
import { FaFacebook, FaGlobe, FaInstagram, FaLinkedinIn, FaTwitter, FaXing } from 'react-icons/fa';

import { getVariant, getStatusText } from './utils';

const DrawerHeader = ({ internalCustomer, handleUpdateCustomerInfo }) => {
  return (
    <Box alignItems="left" mb="4" mt="6">
      <HStack mb="2">
        <Tag colorScheme={getVariant(internalCustomer?.status)}>{getStatusText(internalCustomer?.status)}</Tag>
        {internalCustomer?.source ? (
          <Tag size="sm" colorScheme="purple">
            {internalCustomer?.source}
          </Tag>
        ) : null}
        {internalCustomer?.leadStatus ? (
          <Tag
            size="sm"
            colorScheme={internalCustomer?.leadStatus === 'HOT' ? 'red' : internalCustomer?.leadStatus === 'WARM' ? 'orange' : 'blue'}>
            {internalCustomer?.leadStatus}
          </Tag>
        ) : null}
      </HStack>
      <Heading size="xl" mb="2">
        <Editable
          placeholder="Hier angeben"
          defaultValue={internalCustomer?.companyName}
          onSubmit={(nextValue) =>
            handleUpdateCustomerInfo({ companyName: nextValue }, nextValue, internalCustomer?.companyName, 'Unternehmensname')
          }>
          <EditablePreview />
          <EditableInput />
        </Editable>
      </Heading>
      <Heading as="h4" size="lg" flex="1" textAlign="left">
        Social Media
      </Heading>
      {internalCustomer?.website ? (
        <IconButton
          variant="ghost"
          aria-label="website"
          icon={<FaGlobe />}
          colorScheme="gray"
          onClick={() => window.open(`${internalCustomer?.website.includes('http') ? '' : 'https://'}${internalCustomer?.website}`)}
        />
      ) : null}
      {internalCustomer?.facebook ? (
        <IconButton
          variant="ghost"
          aria-label="facebook"
          icon={<FaFacebook />}
          colorScheme="blue"
          onClick={() => window.open(`${internalCustomer?.facebook.includes('http') ? '' : 'https://'}${internalCustomer?.facebook}`)}
        />
      ) : null}
      {internalCustomer?.instagram ? (
        <IconButton
          variant="ghost"
          aria-label="linkedin"
          icon={<FaInstagram />}
          colorScheme="pink"
          onClick={() => window.open(`${internalCustomer?.instagram.includes('http') ? '' : 'https://'}${internalCustomer?.instagram}`)}
        />
      ) : null}
      {internalCustomer?.twitter ? (
        <IconButton
          variant="ghost"
          aria-label="twitter"
          icon={<FaTwitter />}
          colorScheme="cyan"
          onClick={() => window.open(`${internalCustomer?.twitter.includes('http') ? '' : 'https://'}${internalCustomer?.twitter}`)}
        />
      ) : null}
      {internalCustomer?.linkedin ? (
        <IconButton
          variant="ghost"
          aria-label="linkedin"
          icon={<FaLinkedinIn />}
          colorScheme="blue"
          onClick={() => window.open(`${internalCustomer?.linkedin.includes('http') ? '' : 'https://'}${internalCustomer?.linkedin}`)}
        />
      ) : null}
      {internalCustomer?.xing ? (
        <IconButton
          variant="ghost"
          aria-label="xing"
          icon={<FaXing />}
          colorScheme="green"
          onClick={() => window.open(`${internalCustomer?.xing.includes('http') ? '' : 'https://'}${internalCustomer?.xing}`)}
        />
      ) : null}
    </Box>
  );
};

export default DrawerHeader;
