import React from 'react';
import { Flex, Tag, TagLabel, TagCloseButton } from '@chakra-ui/react';

const SearchValues = ({ refetch, dispatch, state: { searchValues } }) => {
  const removeSearchValue = async (key, key2 = '') => {
    let newValues;
    if (key2) {
      const { [key]: omitParent, ...restParent } = searchValues;
      const { [key2]: omit, ...restChildren } = omitParent;

      if (Object.keys(restChildren).length > 0) {
        newValues = {
          ...restParent,
          [key]: {
            ...restChildren,
          },
        };
      } else {
        newValues = {
          ...restParent,
        };
      }
    } else {
      const { [key]: omit, ...rest } = searchValues;

      newValues = rest;
    }

    await refetch({
      where: newValues,
    });
    dispatch({ type: 'SET_SEARCH_VALUES', value: newValues });

    if (Object.keys(newValues).length === 0) {
      dispatch({ type: 'SET_SEARCH_VALUES', value: null });
    }
  };

  return (
    <Flex flex="1" flexWrap="wrap">
      {searchValues
        ? Object.keys(searchValues).map((key) => {
            if (key === 'address') {
              return Object.keys(searchValues[key]).map((key2) => {
                const value = searchValues[key][key2];
                if (!value) return null;

                return (
                  <Tag key={key2} colorScheme="gray" size="sm" mr="2" mb="2">
                    <TagLabel>
                      {key2.replace('_contains', '')}: <b>{value}</b>
                    </TagLabel>
                    <TagCloseButton onClick={() => removeSearchValue(key, key2)} />
                  </Tag>
                );
              });
            }
            const value = searchValues[key];
            if (!value) return null;

            return (
              <Tag key={key} colorScheme="gray" size="sm" mr="2" mb="2">
                <TagLabel>
                  {key.replace('_contains', '')}: <b>{value}</b>
                </TagLabel>
                <TagCloseButton onClick={() => removeSearchValue(key)} />
              </Tag>
            );
          })
        : null}
    </Flex>
  );
};

export default SearchValues;
