import React, { useContext } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';
import get from 'lodash/get';
import moment from 'moment';

import UserContext from '../../contexts/UserContext';
import SpinnerContext from '../../contexts/SpinnerContext';
import { UPDATE_COMPANY } from './query';
import { useCustomToast } from '../../hooks/useCustomToast';
import NumberInput from '../../components/NumberInput';
import { SingleCheckbox } from '../../components/Checkbox';
import DeliveryTimes from './DeliveryTimes';
import { getMomentFromTime } from '../../utils';

const ActivateDeliverySchema = Yup.object().shape({
  deliveryService: Yup.boolean(),
  deliveryRadius: Yup.number().when('deliveryService', {
    is: true,
    then: Yup.number()
      .required('Du musst einen Radius angeben')
      .min(1, 'Gib eine gültige Entfernung ab mindestens 1 Meter an oder deaktiviere den Lieferservice über die Checkbox.'),
  }),
  deliveryTimes: Yup.array().when('deliveryService', {
    is: true,
    then: Yup.array()
      .of(
        Yup.object().shape({
          from: Yup.string(),
          to: Yup.string(),
        })
      )
      .min(0, 'Du musst mindestens einen Lieferzeitraum angeben'),
  }),
});

export const ActivateDeliveryModal = ({ refetch, company }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [updateCompany] = useMutation(UPDATE_COMPANY);
  const { user } = useContext(UserContext);
  const { toggleSpinner } = useContext(SpinnerContext);
  const toast = useCustomToast();

  return (
    <>
      <Button size="sm" onClick={onOpen}>
        Lieferservice aktivieren
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Lieferservice aktivieren</ModalHeader>
          <Formik
            initialValues={{
              deliveryService: company.deliveryService || false,
              deliveryRadius: company.deliveryRadius || 0,
              deliveryTimes:
                company.deliveryTimes.length > 0
                  ? company.deliveryTimes.map(({ from, to }) => ({
                      from: getMomentFromTime(from),
                      to: getMomentFromTime(to),
                    }))
                  : [
                      {
                        from: null,
                        to: null,
                      },
                    ],
            }}
            validationSchema={ActivateDeliverySchema}
            onSubmit={async ({ deliveryService, deliveryRadius, deliveryTimes }) => {
              toggleSpinner(true);

              try {
                await updateCompany({
                  variables: {
                    where: {
                      id: company.id,
                    },
                    data: {
                      deliveryTimes: {
                        deleteMany: {
                          allDays: true,
                        },
                      },
                    },
                  },
                });

                await updateCompany({
                  variables: {
                    where: {
                      id: company.id,
                    },
                    data: {
                      deliveryService,
                      deliveryRadius: deliveryService ? deliveryRadius : 0,

                      deliveryTimes: deliveryService
                        ? {
                            create: deliveryTimes.map(({ from, to }) => {
                              const newFrom = (from.hour() + 1) * 60 + from.minute();
                              const newTo = (to.hour() + 1) * 60 + to.minute();

                              return {
                                from: newFrom,
                                to: newTo,
                                allDays: true,
                              };
                            }),
                          }
                        : null,
                    },
                  },
                });
                await refetch();
                toast({
                  title: 'Erfolg!',
                  description: 'Angaben zur Lieferung gespeichert.',
                  status: 'success',
                });
                onClose();
              } catch (error) {
                console.log(error);
                toast({
                  description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
                  status: 'error',
                });
              }

              toggleSpinner(false);
            }}>
            {({ values }) => {
              return (
                <Form>
                  <ModalBody>
                    <SingleCheckbox name="deliveryService" label="Lieferservice aktivieren" />
                    {values.deliveryService ? (
                      <>
                        <NumberInput name="deliveryRadius" label="Liefer-Radius (in Metern)" />
                        <DeliveryTimes />
                      </>
                    ) : null}
                  </ModalBody>

                  <ModalFooter>
                    <Button variant="ghost" size="sm" colorScheme="red" type="button" onClick={onClose}>
                      Schließen
                    </Button>
                    <Button ml="4" size="sm" colorScheme="brand" type="submit">
                      Speichern
                    </Button>
                  </ModalFooter>
                </Form>
              );
            }}
          </Formik>
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </>
  );
};
