import React, { useContext, useState, useRef } from 'react';
import { useMutation } from '@apollo/client';
import {
  Text,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import get from 'lodash/get';
import { Formik, Form } from 'formik';
import { navigate } from '@reach/router';
import * as Yup from 'yup';

import UserContext from '../../../contexts/UserContext';
import SpinnerContext from '../../../contexts/SpinnerContext';
import Input from '../../../components/Input';
import { CANCEL_USER } from '../query';
import { useCustomToast } from '../../../hooks/useCustomToast';

const CancelUserSchema = Yup.object().shape({
  comment: Yup.string().required('Warum möchtest Du die Änderung durchführen?'),
});

const CancelUser = ({ user: { id, firstName, lastName, email }, refetch }) => {
  // Kündigung Modal
  const [alertOpen, setAlertOpen] = useState(false);
  const onAlertClose = () => setAlertOpen(false);
  const cancelRef = useRef();

  const { user: internalUser } = useContext(UserContext);
  const { toggleSpinner } = useContext(SpinnerContext);
  const [cancelUserMutation] = useMutation(CANCEL_USER);
  const toast = useCustomToast();

  const handleCancelSubmit = async ({ comment }) => {
    try {
      toggleSpinner(true);
      await cancelUserMutation({
        variables: {
          userId: id,
          internalUser: internalUser.id,
          comment,
        },
      });
      navigate('/users');
      toast({
        title: 'Erfolg!',
        description: 'Der User wurde gekündigt.',
        status: 'success',
      });
      setAlertOpen(false);
    } catch (error) {
      console.log(error);
      toast({
        description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
        status: 'error',
      });
    }
    toggleSpinner(false);
  };

  return (
    <>
      <Button variant="outline" colorScheme="red" size="xs" mr="2" mb="2" onClick={() => setAlertOpen(true)}>
        User kündigen
      </Button>

      <AlertDialog isOpen={alertOpen} leastDestructiveRef={cancelRef} onClose={onAlertClose}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <Formik initialValues={{ comment: '' }} validationSchema={CancelUserSchema} onSubmit={handleCancelSubmit}>
            <Form>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Bist Du sicher?
              </AlertDialogHeader>

              <AlertDialogBody>
                <Text mb="8">
                  Soll der Kunde {firstName} {lastName}, {email} gekündigt werden?
                </Text>
                <Text mb="4" fontSize="sm">
                  Falls der User noch offene Transaktionen hat, kann er nicht gekündigt werden. (Das System überprüft das automatisch.)
                  <br />
                  <br />
                  Im Falle dass er keine aktiven Transaktionen hat, aber in den letzten zwei Jahren eine Transaktion bei uns hatte, wird er
                  als gekündigt markiert aber nicht gelöscht. (Das System überprüft das automatisch.)
                </Text>
                <Input name="comment" label="Kommentar" />
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onAlertClose}>
                  Abbrechen
                </Button>
                <Button colorScheme="red" type="submit" ml={3}>
                  Kündigen
                </Button>
              </AlertDialogFooter>
            </Form>
          </Formik>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default CancelUser;
