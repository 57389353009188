import { gql } from '@apollo/client';

export const USER_PROFILE = gql`
  query USER_PROFILE($userId: ID!) {
    internalUser(where: { id: $userId }) {
      id
      email
      password
      firstName
      lastName
      phoneNumber
      photo
      subscription
      access {
        id
        type
        key
      }
    }
  }
`;

export const UPDATE_INTERNAL_USER = gql`
  mutation UPDATE_INTERNAL_USER($data: InternalUserUpdateInput!, $where: InternalUserWhereUniqueInput!) {
    updateInternalUser(data: $data, where: $where) {
      id
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation UPDATE_PASSWORD($id: ID, $oldPassword: String, $newPassword: String) {
    internalUpdatePassword(id: $id, oldPassword: $oldPassword, newPassword: $newPassword) {
      id
    }
  }
`;
