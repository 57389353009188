import React from 'react';
import { RouteComponentProps, Link } from '@reach/router';
import { useQuery } from '@apollo/client';
import { Spinner, Flex, Text, Grid, Button, Box, Badge, useDisclosure, useColorMode } from '@chakra-ui/react';
import moment from 'moment';

import Layout from '../../components/Layout';
import { PUSH_NOTIFICATIONS } from './query';
import SendNotificationModal from './SendNotificationModal';

interface PushNotificationsProps extends RouteComponentProps {}

const PushNotifications: React.FC<PushNotificationsProps> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode } = useColorMode();
  const { data, loading, error, refetch } = useQuery(PUSH_NOTIFICATIONS, {
    pollInterval: 10000,
  });

  return (
    <Layout>
      <Flex alignItems="center" mb="8" flexDirection={['column', 'row']}>
        <Text as="h2" fontSize="4xl" fontWeight="bold" flex="1" fontFamily="heading">
          Push Notifications
        </Text>
        <Button colorScheme="brand" size="sm" onClick={onOpen}>
          Neue Push Notification senden
        </Button>
      </Flex>
      {loading ? (
        <Flex width="100%" height="100%" justifyContent="center" alignItems="center" py="4">
          <Spinner />
        </Flex>
      ) : null}
      {error ? (
        <Flex width="100%" height="100%" justifyContent="center" alignItems="center" py="4">
          Ein Fehler ist aufgetreten. Schreib Osamah an.
        </Flex>
      ) : null}
      {data && data.pushNotifications && data.pushNotifications.length > 0 ? (
        <>
          <Flex mb="3">
            <Button colorScheme="brand" onClick={() => refetch()} size="xs">
              Neu laden
            </Button>
          </Flex>
          <Grid width="100%" gridTemplateColumns="auto auto 1fr 20% auto">
            <Box p="2" backgroundColor={colorMode === 'light' ? 'gray.100' : 'gray.700'}>
              Timestamp
            </Box>
            <Box p="2" backgroundColor={colorMode === 'light' ? 'gray.100' : 'gray.700'}>
              Empfänger
            </Box>
            <Box p="2" backgroundColor={colorMode === 'light' ? 'gray.100' : 'gray.700'}>
              Nachricht
            </Box>
            <Box p="2" backgroundColor={colorMode === 'light' ? 'gray.100' : 'gray.700'}>
              Kommentar
            </Box>
            <Box p="2" backgroundColor={colorMode === 'light' ? 'gray.100' : 'gray.700'}>
              Mitarbeiter
            </Box>

            {data.pushNotifications.map(({ createdAt, receiver, title, subTitle, body, changedBy, user, comment }) => {
              return (
                <React.Fragment key={`${receiver}-${createdAt}`}>
                  <Box fontSize="sm" px="2" py="4" wordBreak="break-word" borderBottomWidth="1px">
                    {moment(createdAt).format('DD.MM.YYYY, HH:mm')}
                  </Box>
                  <Box px="2" py="4" wordBreak="break-word" borderBottomWidth="1px">
                    <Badge>{receiver}</Badge>
                    {receiver === 'SINGLE_USER' && user ? (
                      <Flex>
                        An:
                        <Box color={colorMode === 'light' ? 'blue.600' : 'gray.100'} ml="1">
                          <Link to={`/users/${user.id}`}>
                            {user.firstName} {user.lastName}
                          </Link>
                        </Box>
                      </Flex>
                    ) : null}
                  </Box>
                  <Box px="2" py="4" wordBreak="break-word" borderBottomWidth="1px">
                    <Box fontWeight="black" fontFamily="heading">
                      {title}
                    </Box>
                    <Box fontWeight="semibold">{subTitle}</Box>
                    <Box>{body}</Box>
                  </Box>
                  <Box px="2" py="4" wordBreak="break-word" borderBottomWidth="1px">
                    {comment}
                  </Box>
                  <Box px="2" py="4" wordBreak="break-word" borderBottomWidth="1px">
                    {changedBy ? `${changedBy.firstName} ${changedBy.lastName}` : ''}
                  </Box>
                </React.Fragment>
              );
            })}
          </Grid>
        </>
      ) : (
        <Text gridColumn="span 3" p="4" textAlign="center" backgroundColor="red.100" color="red.800" borderRadius="4px">
          Der Widerstand versteckt sich gut und konnte nicht gefunden werden. Sende eine Nachrichten an die Truppen um sie zu finden.
        </Text>
      )}
      <SendNotificationModal isOpen={isOpen} onClose={onClose} refetch={refetch} />
    </Layout>
  );
};

export default PushNotifications;
