import gql from 'graphql-tag';

export const GET_ORDERS = gql`
  query ($orderBy: OrderOrderByInput, $first: Int, $skip: Int) {
    orders(orderBy: $orderBy, first: $first, skip: $skip) {
      id
      iid
      createdAt
      status
      tip
      packageStation {
        id
        locationName
        company {
          id
          logo
          name
          users {
            id
            email
          }
        }
        user {
          id
          firstName
          lastName
          email
        }
      }
      orderer {
        id
        firstName
        lastName
        email
      }
      pickuper {
        id
        firstName
        lastName
        email
      }
      pickuperNoUser {
        firstName
        lastName
        email
      }
      payment {
        id
        type
      }
      review {
        id
        rating
        note
      }
      stationReview {
        id
        rating
        note
      }
      packages {
        id
        handedOver
        handedOverTimestamp
        timestamp
        holdingFee
        note
        photos {
          uri
        }
      }
    }
    totalOrdersCount
  }
`;

export const EXPORT_CSV = gql`
  mutation EXPORT_CSV {
    exportOrdersToCsv
  }
`;
