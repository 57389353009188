import React, { useContext } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useMutation, useQuery } from '@apollo/client';
import { Text, Flex, Box, Button, Spinner, HStack } from '@chakra-ui/react';
import get from 'lodash/get';

import SpinnerContext from '../../contexts/SpinnerContext';
import Layout from '../../components/Layout';
import { useCustomToast } from '../../hooks/useCustomToast';
import { OrderTable } from '../../components/OrderTable';
import { GET_ORDERS, EXPORT_CSV } from './query';

interface InvoiceProps extends RouteComponentProps {}

const Orders: React.FC<InvoiceProps> = ({ location }) => {
  const { toggleSpinner } = useContext(SpinnerContext);
  const { data, refetch, loading, error, fetchMore } = useQuery(GET_ORDERS, {
    fetchPolicy: 'network-only',
    variables: {
      orderBy: 'createdAt_DESC',
      first: 51,
      skip: 0,
    },
  });
  const [exportOrders] = useMutation(EXPORT_CSV);
  const toast = useCustomToast();

  const handleExportOrders = async () => {
    try {
      toggleSpinner(true);
      const {
        data: { exportOrdersToCsv },
      } = await exportOrders();
      window.open(exportOrdersToCsv, '_blank');
    } catch (error) {
      console.log(error);

      toast({
        description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
        status: 'error',
      });
    }
    toggleSpinner(false);
  };

  return (
    <Box width="100%">
      <Flex mb="4">
        <Layout fullWidth>
          <Flex flexDirection={['column', 'row']} alignItems="center" justifyContent="space-between" mb="3">
            <Text as="h2" fontSize="4xl" flex="1" fontWeight="bold" fontFamily="heading">
              Bestellungen
            </Text>
          </Flex>
          <HStack justifyContent="flex-end" mb="2">
            <Button colorScheme="green" size="sm" onClick={() => refetch()}>
              Aktualisieren
            </Button>
            <Button size="sm" onClick={handleExportOrders}>
              CSV exportieren
            </Button>
          </HStack>
          {loading ? (
            <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
              <Spinner />
            </Flex>
          ) : null}
          {error ? (
            <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
              Ein Fehler ist aufgetreten. Schreib Osamah an.
            </Flex>
          ) : null}

          {data?.orders ? (
            <OrderTable
              orders={data?.orders}
              totalCount={data?.totalOrdersCount}
              refetch={refetch}
              fetchMore={fetchMore}
              location={location}
            />
          ) : null}
        </Layout>
      </Flex>
    </Box>
  );
};

export default Orders;
