import { theme } from '@chakra-ui/react';

import { extendTheme, ThemeConfig } from '@chakra-ui/react';

// 2. Add your color mode config
const config: ThemeConfig = {
  initialColorMode: 'dark',
};

// 3. extend the theme
const newTheme = extendTheme({
  config,
  fonts: {
    ...theme.fonts,
    body: 'Roboto, sans-serif',
    heading: 'Ubuntu, sans-serif',
    mono: 'Menlo, monospace',
  },
  colors: {
    ...theme.colors,
    brand: {
      100: '#E1F9F6',
      200: '#C5F3F1',
      300: '#9EDADC',
      400: '#78B3BA',
      500: '#4A808C',
      600: '#366778',
      700: '#254F64',
      800: '#173951',
      900: '#0E2943',
    },
  },
});

export default newTheme;

// export default {
//   ...theme,

// };
