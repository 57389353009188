import React, { useContext } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalHeader, ModalBody, ModalFooter, Button } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import get from 'lodash/get';

import Input from '../../Input';
import Select from '../../Select';
import SpinnerContext from '../../../contexts/SpinnerContext';
import UserContext from '../../../contexts/UserContext';
import { useCustomToast } from '../../../hooks/useCustomToast';
import { UPDATE_INTERNAL_CUSTOMER } from '../query';

const EditInternalCustomerSchema = Yup.object().shape({
  message: Yup.string().required('Du musst einen Kommentar hinterlegen'),
});

let statusOptions = [
  { label: 'Offen 😎   ', value: 'OPEN' },
  { label: 'Kontaktiert ✔  ', value: 'INPROGRESS' },
  { label: 'Nachhaken 👀   ', value: 'CONTACT_AGAIN' },
  { label: 'Pausiert ⏱   ', value: 'PARKED' },
  { label: 'Abgeschlossen 🤝   ', value: 'DONE' },
  { label: 'Abgebrochen ❌  ', value: 'FAILURE' },
];
let leadStatusOptions = [
  { label: 'Hot 🔥', value: 'HOT' },
  { label: 'Warm ', value: 'WARM' },
  { label: 'Cold ❄️', value: 'COLD' },
];

const EditStatusModal = ({ isOpen, onClose, refetch, customer, refetch: parentRefetch }) => {
  const [updateInternalCustomer] = useMutation(UPDATE_INTERNAL_CUSTOMER);
  const { toggleSpinner } = useContext(SpinnerContext);
  const { user } = useContext(UserContext);
  const toast = useCustomToast();

  if (!customer) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Kunden bearbeiten </ModalHeader>

        <Formik
          initialValues={{
            status: customer.status,
            leadStatus: customer.leadStatus || 'COLD',
            message: '',
          }}
          validationSchema={EditInternalCustomerSchema}
          onSubmit={async ({ status, leadStatus, message }) => {
            try {
              toggleSpinner(true);

              await updateInternalCustomer({
                variables: {
                  where: {
                    id: customer.id,
                  },
                  data: {
                    notes: {
                      create: [
                        {
                          message,
                          type: 'COMMENT',
                          commenter: {
                            connect: { id: user.id },
                          },
                        },
                      ],
                    },
                    status,
                    leadStatus,
                  },
                },
              });

              await refetch();
              await parentRefetch();

              toast({
                title: 'Erfolg!',
                description: 'Status erfolgreich bearbeitet',
                status: 'success',
                isClosable: false,
                duration: 3000,
              });
              toggleSpinner(false);
              onClose();
            } catch (error) {
              console.log(error);
              toast({
                description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
                status: 'error',
              });
              toggleSpinner(false);
            }
          }}>
          {() => (
            <Form>
              <ModalBody>
                <Select options={statusOptions} label="Status" name="status" />
                <Select options={leadStatusOptions} label="Status" name="leadStatus" />

                <Input
                  label="Kommentar"
                  as="textarea"
                  type="text"
                  name="message"
                  height="100px"
                  placeholder="Geben Sie hier Ihre Beschreibung ein "
                />
              </ModalBody>

              <ModalFooter>
                <Button variant="ghost" size="sm" colorScheme="red" type="button" onClick={onClose}>
                  Schließen
                </Button>
                <Button ml="4" size="sm" colorScheme="brand" type="submit">
                  Speichern
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};

export default EditStatusModal;
