import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { Avatar, Box, Button, Flex, Grid, HStack, Tag, Text, useColorMode } from '@chakra-ui/react';
import { HiMenuAlt3 } from 'react-icons/hi';
import get from 'lodash/get';
import moment from 'moment';

import SpinnerContext from '../../contexts/SpinnerContext';
import UserContext from '../../contexts/UserContext';
import { useCustomToast } from '../../hooks/useCustomToast';
import { UPDATE_INTERNAL_CUSTOMER } from './query';
import { getStatusText } from './utils';
import { getVariant } from '../../components/CustomerDrawer/utils';

export const CustomerCard = ({
  id,
  incrementId,
  status,
  source,
  leadStatus,
  companyName,
  phoneNumbers,
  email,
  contactName,
  asignee,
  refetch,
  onDrawerOpen,
  openChangeAsigneeModal,
}) => {
  const { toggleSpinner } = useContext(SpinnerContext);
  const { user } = useContext(UserContext);
  const [updateInternalCustomer] = useMutation(UPDATE_INTERNAL_CUSTOMER);
  const toast = useCustomToast();
  const { colorMode } = useColorMode();

  const handleUpdateAsignee = async () => {
    try {
      toggleSpinner(true);
      await updateInternalCustomer({
        variables: {
          where: {
            id: id,
          },
          data: {
            asignee: {
              connect: { id: user.id },
            },
            history: {
              create: [
                {
                  type: 'UPDATED',
                  comment: `${user.firstName} ${user.lastName} zugewiesen`,
                  timestamp: moment().toISOString(),
                  changedBy: {
                    connect: { id: user.id },
                  },
                },
              ],
            },
          },
        },
      });
      await refetch();
    } catch (error) {
      console.log(error);

      toast({
        description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
        status: 'error',
      });
    }
    toggleSpinner(false);
  };

  const handleChangeStatusMutation = async (status) => {
    try {
      toggleSpinner(true);
      await updateInternalCustomer({
        variables: {
          where: {
            id: id,
          },
          data: {
            status,
            history: {
              create: [
                {
                  type: 'UPDATED',
                  comment: `Status geändert in ${getStatusText(status)}`,
                  timestamp: moment().toISOString(),
                  changedBy: {
                    connect: { id: user.id },
                  },
                },
              ],
            },
          },
        },
      });
      await refetch();
    } catch (error) {
      console.log(error);

      toast({
        description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
        status: 'error',
      });
    }
    toggleSpinner(false);
  };

  return (
    <Flex
      direction="column"
      width="100%"
      rounded="md"
      borderWidth="1px"
      borderColor={colorMode === 'light' ? 'gray.200' : 'gray.600'}
      py="4"
      px="4"
      boxShadow="sm">
      <Box flex="1">
        <HStack mb="2">
          <Tag colorScheme="gray" size="sm">
            {incrementId}
          </Tag>
          <Tag colorScheme={getVariant(status)} size="sm">
            {getStatusText(status)}
          </Tag>
          {source ? (
            <Tag size="sm" colorScheme="purple">
              {source}
            </Tag>
          ) : null}
          {leadStatus ? (
            <Tag size="sm" colorScheme={leadStatus === 'HOT' ? 'red' : leadStatus === 'WARM' ? 'orange' : 'blue'}>
              {leadStatus}
            </Tag>
          ) : null}
        </HStack>
        <Box mb="2">
          <Text fontFamily="heading" fontSize="lg" color={colorMode === 'light' ? 'gray.900' : 'gray.100'}>
            {companyName}
          </Text>
          <Text fontSize="sm" color={colorMode === 'light' ? 'gray.900' : 'gray.100'}>
            {contactName}
          </Text>
        </Box>
        <Box mb="4">
          <Text fontSize="sm" color={colorMode === 'light' ? 'gray.900' : 'gray.100'}>
            {email}
          </Text>
          <Text fontSize="sm" color={colorMode === 'light' ? 'gray.900' : 'gray.100'}>
            {phoneNumbers?.map((number, index, id) => {
              return (
                <div key={`${id}-phoneNumber-${index}`}>
                  <Text as="span" whiteSpace="nowrap">
                    {number}
                  </Text>
                  {index + 1 < phoneNumbers.length ? ' , ' : null}
                </div>
              );
            })}
          </Text>
        </Box>
      </Box>
      {asignee ? (
        <Flex alignItems="center" mb="4">
          <Avatar src={asignee.photo} name={`${asignee.firstName} ${asignee.lastName}`} mr="2" size="sm" />
          <Text>
            {asignee.firstName} {asignee.lastName}
          </Text>
        </Flex>
      ) : null}
      <Text fontWeight="bold" fontFamily="heading" fontSize="sm" mb="2">
        Status ändern:
      </Text>
      <Grid gridTemplateColumns="1fr 1fr " gap="2" mb="3">
        {status === 'INPROGRESS' ? (
          <>
            <Button size="sm" colorScheme="orange" variant="outline" onClick={() => handleChangeStatusMutation('CONTACT_AGAIN')}>
              Nachhaken
            </Button>
            <Button size="sm" colorScheme="yellow" variant="outline" onClick={() => handleChangeStatusMutation('PARKED')}>
              Pausieren
            </Button>
            <Button size="sm" colorScheme="green" variant="outline" onClick={() => handleChangeStatusMutation('DONE')}>
              Abschliessen
            </Button>
            <Button size="sm" colorScheme="red" variant="outline" onClick={() => handleChangeStatusMutation('FAILURE')}>
              Abbrechen
            </Button>
          </>
        ) : (
          <>
            <Button size="sm" colorScheme="green" variant="outline" onClick={() => handleChangeStatusMutation('INPROGRESS')}>
              Kontaktiert
            </Button>
            <Button size="sm" colorScheme="orange" variant="outline" onClick={() => handleChangeStatusMutation('CONTACT_AGAIN')}>
              Nachhaken
            </Button>
          </>
        )}
      </Grid>
      <Grid gridTemplateColumns="1fr 1fr" gap="4">
        <Button rightIcon={<HiMenuAlt3 />} colorScheme="green" onClick={() => onDrawerOpen(id)}>
          Weitere Infos
        </Button>
        {asignee ? (
          <Button onClick={() => openChangeAsigneeModal(id, asignee.id)} variant="outline">
            Mitarbeiter ändern
          </Button>
        ) : (
          <Button onClick={handleUpdateAsignee}>Mir zuweisen</Button>
        )}
      </Grid>
    </Flex>
  );
};
