import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import {
  Button,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  Grid,
  IconButton,
  Spacer,
  Stack,
  StackDivider,
  VStack,
  Checkbox,
  Heading,
} from '@chakra-ui/react';
import { BiTrash } from 'react-icons/bi';
import { HiPlusCircle } from 'react-icons/hi';

import { useUser } from '../../contexts/UserContext';
import { useCustomToast } from '../../hooks/useCustomToast';
import { UPDATE_INTERNAL_CUSTOMER } from './query';
import { DataRow } from './DataRow';

const BasicContactInfo = ({ internalCustomer, refetch, handleUpdateCustomerInfo }) => {
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [customerAvailable, setCustomerAvailable] = useState(false);
  const { user } = useUser();
  const toast = useCustomToast();
  const [updateCustomer] = useMutation(UPDATE_INTERNAL_CUSTOMER);

  const handleUpdatePhoneNumbers = async () => {
    try {
      await updateCustomer({
        variables: {
          where: {
            id: internalCustomer?.id,
          },
          data: {
            phoneNumbers: {
              set: phoneNumbers,
            },
            history: {
              create: [
                {
                  type: 'UPDATED',
                  comment: `Telefonnummer(n) geändert`,
                  timestamp: moment().toISOString(),
                  changedBy: {
                    connect: { id: user?.id },
                  },
                },
              ],
            },
          },
        },
      });
      await refetch();
    } catch (error) {
      console.log(error);
      toast({
        description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
        status: 'error',
      });
    }
  };

  useEffect(() => {
    if (!isEqual(phoneNumbers, internalCustomer?.phoneNumbers)) {
      setPhoneNumbers(internalCustomer?.phoneNumbers);
      setCustomerAvailable(true);
    }
  }, [internalCustomer]);

  useEffect(() => {
    if (customerAvailable) {
      // Deep Check for validitiy
      if (internalCustomer?.phoneNumbers && !isEqual(phoneNumbers, internalCustomer?.phoneNumbers)) {
        handleUpdatePhoneNumbers();
      }
    }
  }, [phoneNumbers]);

  const handleRemoveClick = (index) => {
    const list = [...phoneNumbers];
    list.splice(index, 1);
    setPhoneNumbers(list);
  };

  const handleAddClick = () => {
    setPhoneNumbers([...phoneNumbers, '']);
  };

  return (
    <>
      <Heading as="h4" size="lg" flex="1" textAlign="left">
        Stammdaten
      </Heading>
      <DataRow
        label="Kundenkontakt"
        value={internalCustomer?.contactName}
        onSubmit={(nextValue) =>
          handleUpdateCustomerInfo({ contactName: nextValue }, nextValue, internalCustomer?.contactName, 'Kundenkontakt')
        }
      />
      <DataRow
        label="Jobbezeichnung"
        value={internalCustomer?.contactProfession}
        onSubmit={(nextValue) =>
          handleUpdateCustomerInfo({ contactProfession: nextValue }, nextValue, internalCustomer?.contactProfession, 'Jobbezeichnung')
        }
      />

      <DataRow
        label="E-Mail"
        value={internalCustomer?.email}
        onSubmit={(nextValue) => handleUpdateCustomerInfo({ email: nextValue }, nextValue, internalCustomer?.email, 'E-Mail')}
      />

      <DataRow
        label="Telefonnummer (bitte nicht mehr verwenden)"
        value={internalCustomer?.phone}
        onSubmit={(nextValue) => handleUpdateCustomerInfo({ phone: nextValue }, nextValue, internalCustomer?.phone, 'Telefonnummer (alt)')}
      />

      <DataRow label="Telefonnummer(n)">
        <VStack divider={<StackDivider borderColor="gray.500" />} spacing="2" align="stretch">
          {phoneNumbers
            ? phoneNumbers.map((phoneNumber, index) => {
                return (
                  <Flex key={index}>
                    <Editable
                      key={index}
                      placeholder="Hier angeben"
                      defaultValue={phoneNumber}
                      onSubmit={(nextValue) => {
                        let nextArray = [...phoneNumbers];
                        nextArray[index] = nextValue;
                        setPhoneNumbers(nextArray);
                      }}>
                      <EditablePreview />
                      <EditableInput />
                    </Editable>
                    <Spacer />
                    <IconButton
                      size="xs"
                      aria-label="delete"
                      icon={<BiTrash />}
                      onClick={() => handleRemoveClick(index)}
                      alignSelf="center"
                      justifyContent="center"
                      colorScheme="red"
                      variant="outline"
                      ml="3"
                    />
                  </Flex>
                );
              })
            : null}

          <Stack direction="row">
            <Button size="sm" aria-label="add" colorScheme="brand" type="button" leftIcon={<HiPlusCircle />} onClick={handleAddClick}>
              Telefonnummer hinzufügen
            </Button>
          </Stack>
        </VStack>
      </DataRow>

      <DataRow label="Angebotenes Produkt">
        <Grid gridGap="4" gridTemplateColumns="1fr 1fr" mb="4">
          <Checkbox
            isChecked={internalCustomer?.productsOffered?.api}
            onChange={(event) =>
              handleUpdateCustomerInfo(
                internalCustomer?.productsOffered
                  ? { productsOffered: { update: { api: event.target.checked } } }
                  : { productsOffered: { create: { api: event.target.checked, business: false, sprint: false, events: false } } },
                event.target.checked,
                internalCustomer?.productsOffered?.api,
                'API'
              )
            }>
            ⚙️ Api{' '}
          </Checkbox>

          <Checkbox
            isChecked={internalCustomer?.productsOffered?.business}
            onChange={(event) =>
              handleUpdateCustomerInfo(
                internalCustomer?.productsOffered
                  ? { productsOffered: { update: { business: event.target.checked } } }
                  : { productsOffered: { create: { business: event.target.checked, api: false, sprint: false, events: false } } },
                event.target.checked,
                internalCustomer?.productsOffered?.business,
                'Business'
              )
            }>
            💼 Business{' '}
          </Checkbox>

          <Checkbox
            isChecked={internalCustomer?.productsOffered?.events}
            onChange={(event) =>
              handleUpdateCustomerInfo(
                internalCustomer?.productsOffered
                  ? { productsOffered: { update: { events: event.target.checked } } }
                  : { productsOffered: { create: { events: event.target.checked, api: false, sprint: false, business: false } } },
                event.target.checked,
                internalCustomer?.productsOffered?.events,
                'Events'
              )
            }>
            📅 Events{' '}
          </Checkbox>

          <Checkbox
            isChecked={internalCustomer?.productsOffered?.sprint}
            onChange={(event) =>
              handleUpdateCustomerInfo(
                internalCustomer?.productsOffered
                  ? { productsOffered: { update: { sprint: event.target.checked } } }
                  : { productsOffered: { create: { sprint: event.target.checked, api: false, events: false, business: false } } },
                event.target.checked,
                internalCustomer?.productsOffered?.sprint,
                'Sprint'
              )
            }>
            📈 Sprint{' '}
          </Checkbox>
        </Grid>
      </DataRow>
    </>
  );
};

export default BasicContactInfo;
