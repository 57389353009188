import gql from 'graphql-tag';

export const USER = gql`
  query USER($userId: ID!) {
    user(where: { id: $userId }) {
      id
      firstName
      lastName
      photo {
        uri
      }
      myCompany {
        id
        name
        logo
      }
    }
  }
`;
