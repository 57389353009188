import React, { useReducer } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useQuery } from '@apollo/client';
import { Spinner, Flex, Text, Grid, Button, IconButton, Stack, Select } from '@chakra-ui/react';
import { IoIosKeypad, IoIosMenu } from 'react-icons/io';

import Layout from '../../components/Layout';
import SendEmailButton from './actions/SendEmailButton';
import UserSearch from './actions/UserSearch';
import { DropPointCard } from './DropPointCard';
import DropPointTableRow from './DropPointTableRow';
import FilterValues from './actions/FilterValues';
import { GET_USERS } from './query';

interface DropPointsProps extends RouteComponentProps {}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_FILTERS':
      return { ...state, filters: action.payload, currentPage: 1 };
    case 'SET_WHERE':
      return { ...state, where: action.payload, currentPage: 1 };
    case 'SET_CARD_VIEW':
      return { ...state, userView: 'card' };
    case 'SET_ORDER_BY':
      return { ...state, orderBy: action.orderBy };
    case 'SET_TABLE_VIEW':
      return { ...state, userView: 'table' };
    default:
      return state;
  }
};

const ActiveDropPoints: React.FC<DropPointsProps> = () => {
  const initialState = {
    filters: [],
    where: {
      isActive: true,
      user: { id_not: null },
    },
    orderBy: 'createdAt_DESC',
    userView: localStorage.getItem('userView') || 'card',
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const { loading, error, data, refetch, fetchMore, variables } = useQuery(GET_USERS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      where: {
        ...state.where,
      },
      orderBy: state.orderBy,
      first: 250,
      skip: 0,
    } as any,
  });

  const switchUserView = (type: 'card' | 'table') => {
    localStorage.setItem('userView', type);

    dispatch({ type: type === 'card' ? 'SET_CARD_VIEW' : 'SET_TABLE_VIEW' });
  };

  const DropPointComponent = state.userView === 'card' ? DropPointCard : DropPointTableRow;

  return (
    <Layout>
      <Flex flexDirection={['column', 'row']} alignItems="center" justifyContent="space-between" mb="6">
        <Text as="h2" fontSize={['2xl', '4xl']} fontWeight="bold" flex="1" fontFamily="heading">
          Aktive DropPoints
        </Text>

        <SendEmailButton where={variables.where} totalUsers={data?.totalDropPoints?.total} refetch={refetch} />
      </Flex>
      <Flex justifyContent="space-between" alignItems="flex-start" mb="4" flexWrap="wrap" gap="3">
        <Stack>
          <FilterValues dispatch={dispatch} state={state} />
          <Text fontSize="sm" gridColumn="span 3">
            Angezeigte User: {data?.totalDropPoints2.total}
          </Text>
        </Stack>

        <Stack flexWrap="wrap" justifyContent="flex-end" flex="1">
          <Flex flexWrap="wrap" justifyContent="flex-end" gap="2">
            <Button
              size="sm"
              onClick={() => {
                dispatch({
                  type: 'SET_WHERE',
                  payload: {
                    ...state.where,
                    activityCheckSent: state.where?.activityCheckSent ? undefined : true,
                  },
                });
              }}
              colorScheme={state.where?.activityCheckSent ? 'blue' : undefined}>
              Check gesendet
            </Button>
            <Button
              size="sm"
              onClick={() => {
                dispatch({
                  type: 'SET_WHERE',
                  payload: {
                    ...state.where,
                    activityCheckSent: state.where?.activityCheckSent === false ? undefined : false,
                  },
                });
              }}
              colorScheme={state.where?.activityCheckSent === false ? 'blue' : undefined}>
              Check nicht gesendet
            </Button>
            <Button
              size="sm"
              onClick={() => {
                dispatch({
                  type: 'SET_WHERE',
                  payload: {
                    ...state.where,
                    activityCheck: state.where?.activityCheck === false ? undefined : false,
                  },
                });
              }}
              colorScheme={state.where?.activityCheck === false ? 'red' : undefined}>
              Keine Rückmeldung
            </Button>
          </Flex>
          <Flex flexWrap="wrap" justifyContent="flex-end" gap="2">
            <Flex>
              <Select
                size="sm"
                w="260px"
                rounded="md"
                onChange={(event) => {
                  console.log(event.target.value);
                  dispatch({ type: 'SET_ORDER_BY', orderBy: event.target.value });
                }}
                value={state.orderBy}>
                <option value="createdAt_DESC">Registriert am (Abw)</option>
                <option value="createdAt_ASC">Registriert am (Aufw)</option>
                <option value="activityCheck_DESC">Auf Aktivitätscheck reagiert</option>
                <option value="activityCheck_ASC">Nicht reagiert auf Aktivitätscheck</option>
              </Select>
            </Flex>
            <UserSearch dispatch={dispatch} state={state} />
            <IconButton
              variant="ghost"
              size="sm"
              aria-label={state.userView === 'card' ? 'Switch to Table' : 'Switch to Card'}
              icon={state.userView === 'card' ? <IoIosMenu /> : <IoIosKeypad />}
              onClick={() => {
                switchUserView(state.userView === 'card' ? 'table' : 'card');
              }}
            />
          </Flex>
        </Stack>
      </Flex>
      {error ? (
        <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
          Ein Fehler ist aufgetreten. Schreib Osamah an.
        </Flex>
      ) : null}
      {data && data.packageStations ? (
        <>
          <Grid gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr 1fr']} gap={state.userView === 'card' ? '16px' : 0} mb="8">
            {data.packageStations.length === 0 ? (
              <Text gridColumn="span 3" p="4" textAlign="center" backgroundColor="red.100" color="red.800" borderRadius="4px">
                Der Widerstand versteckt sich gut und konnte nicht gefunden werden. Wiederhole die Suche oder schicke Boba Fett!
              </Text>
            ) : (
              <>
                {data.packageStations.map(({ user }) => {
                  if (!user) return null;
                  return <DropPointComponent key={user.id} {...user} />;
                })}
              </>
            )}
          </Grid>
          {data.packageStations.length !== 0 && data.packageStations.length < data.totalDropPoints2.total && !loading ? (
            <Flex width="100%" justifyContent="center" mb="8">
              <Button
                onClick={() =>
                  fetchMore({
                    variables: {
                      skip: data.packageStations.length,
                    },
                    updateQuery: (prev: any, { fetchMoreResult }) => {
                      if (!fetchMoreResult) return prev;
                      return Object.assign({}, prev, {
                        packageStations: [...prev.packageStations, ...fetchMoreResult.packageStations],
                      });
                    },
                  })
                }>
                Mehr laden
              </Button>
            </Flex>
          ) : null}
        </>
      ) : null}
      {loading ? (
        <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
          <Spinner />
        </Flex>
      ) : null}
    </Layout>
  );
};

export default ActiveDropPoints;
