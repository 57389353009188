import React, { useContext } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalHeader, ModalBody, ModalFooter, Button } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';

import UserContext from '../../../contexts/UserContext';
import Input from '../../../components/Input';
import { UNLOCK_USER } from '../query';

const UnlockConfirmSchema = Yup.object().shape({
  comment: Yup.string().required('Das ist ein Pflichtfeld'),
});

const UnlockConfirmModal = ({ isOpen, onClose, refetch, userId }) => {
  const [unlockUser] = useMutation(UNLOCK_USER);
  const { user } = useContext(UserContext);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>User sperren</ModalHeader>

        <Formik
          initialValues={{
            comment: '',
            sendEmail: false,
          }}
          validationSchema={UnlockConfirmSchema}
          onSubmit={async ({ comment }) => {
            await unlockUser({
              variables: {
                userId,
                comment,
                internalUser: user.id,
              },
            });
            await refetch();
            onClose();
          }}>
          <Form>
            <ModalBody>
              <Input name="comment" label="Kommentar" />
            </ModalBody>

            <ModalFooter>
              <Button variant="ghost" size="sm" colorScheme="red" type="button" onClick={onClose}>
                Schließen
              </Button>
              <Button ml="4" size="sm" colorScheme="brand" type="submit">
                Speichern
              </Button>
            </ModalFooter>
          </Form>
        </Formik>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};

export default UnlockConfirmModal;
