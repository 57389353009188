import React from 'react';
import {
  Avatar,
  Box,
  Text,
  Flex,
  Divider,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Alert,
  AlertIcon,
  Link,
  VStack,
  useColorMode,
  Heading,
} from '@chakra-ui/react';
import moment from 'moment';
import { HiOutlineLink } from 'react-icons/hi';

import CreateNewNote from './modals/CreateNewNote';
import useMedia from 'use-media';

const CommunicationTab = ({ entries, customerId = '', refetch = null, emptyMessage, type = '' }) => {
  const { colorMode } = useColorMode();

  return (
    <>
      {type ? (
        <Flex justifyContent="flex-end" alignItems="center" mb="4">
          <CreateNewNote customerId={customerId} refetch={refetch} type={type} />
        </Flex>
      ) : null}

      {entries?.length > 0 ? (
        entries?.map(({ id, commenter, comment, subject, content, sender, changedBy, createdAt, message, connectedFiles }) => {
          return (
            <Box overflowY="hidden" mb="4" key={id}>
              {commenter || sender || changedBy ? (
                <Flex fontFamily="heading" mb="1">
                  <Avatar src={(commenter || sender || changedBy)?.photo} size="xs" name="commenter" mr="2" />
                  <Text>
                    {(commenter || sender || changedBy)?.firstName} {(commenter || sender || changedBy)?.lastName}
                  </Text>
                </Flex>
              ) : (
                <Flex fontFamily="heading" mb="1">
                  <Avatar size="xs" name="commenter" mr="2" />
                  <Text>System-Änderung</Text>
                </Flex>
              )}
              <Text fontSize="sm" color={colorMode === 'light' ? 'gray.700' : 'gray.300'} mb="2">
                {moment(createdAt).format('DD.MM.YYYY, HH:mm')}
              </Text>
              {subject ? (
                <Text mb="4" fontWeight="bold">
                  Betreff: {subject}
                </Text>
              ) : null}
              <Text mb="4" dangerouslySetInnerHTML={{ __html: message || comment || content || null }} />
              {connectedFiles?.length > 0 ? (
                <>
                  <Text fontSize="sm" fontWeight="bold">
                    Anhänge:
                  </Text>
                  <VStack mb="4" spacing="4" alignItems="flex-start">
                    {connectedFiles.map(({ uri, name }, index) => {
                      return (
                        <Link key={`connected-${id}-${index}`} href={uri} target="_blank" display="flex">
                          <HiOutlineLink />
                          <Text fontSize="sm" ml="2">
                            {name}
                          </Text>
                        </Link>
                      );
                    })}
                  </VStack>
                </>
              ) : null}
              <Divider />
            </Box>
          );
        })
      ) : (
        <Alert status="info">
          <AlertIcon />
          {emptyMessage}
        </Alert>
      )}
    </>
  );
};

const CommunicationTabs = ({ customerId, refetch, internalCustomer }) => {
  const isMobile = useMedia({ maxWidth: '767px' });
  return (
    <Box overflow="hidden" textAlign="left" mt="12">
      <Flex justifyContent="space-between" alignItems="center" mb="6">
        <Heading as="h4" size="lg">
          History
        </Heading>
      </Flex>
      <Tabs>
        <TabList overflowX="scroll">
          <Tab fontSize={isMobile ? 'xs' : 'md'}>Interne Kommentare</Tab>
          <Tab fontSize={isMobile ? 'xs' : 'md'}>Versendete E-Mails</Tab>
          <Tab fontSize={isMobile ? 'xs' : 'md'}>Kommunikation</Tab>
          <Tab fontSize={isMobile ? 'xs' : 'md'}>System-Einträge</Tab>
        </TabList>

        <TabPanels>
          <TabPanel px="0">
            <CommunicationTab
              entries={internalCustomer?.comments}
              customerId={customerId}
              refetch={refetch}
              type="COMMENT"
              emptyMessage="Es wurde noch kein Kommentar hinterlegt"
            />
          </TabPanel>
          <TabPanel px="0">
            <CommunicationTab entries={internalCustomer?.emails} emptyMessage="Es wurde noch keine E-Mail versendet" />
          </TabPanel>
          <TabPanel px="0">
            <CommunicationTab
              entries={internalCustomer?.communications}
              customerId={customerId}
              refetch={refetch}
              type="COMMUNICATION"
              emptyMessage="Es wurde noch kein Kommentar hinterlegt"
            />
          </TabPanel>
          <TabPanel px="0">
            <CommunicationTab entries={internalCustomer?.history} emptyMessage="Noch keine System-Einträge hinterlegt" />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default CommunicationTabs;
