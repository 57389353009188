import React, { createContext, useContext, useState } from 'react';

interface ContextProps {
  spinnerVisible: boolean;
  toggleSpinner(newVisibilty: boolean): void;
}

const SpinnerContext = createContext<Partial<ContextProps>>(null);

export const SpinnerContextProvider = ({ children }) => {
  const [spinnerVisible, setSpinnerVisible] = useState(false);

  const toggleSpinner = (newVisibility) => {
    setSpinnerVisible(newVisibility);
  };

  return <SpinnerContext.Provider value={{ spinnerVisible, toggleSpinner }}>{children}</SpinnerContext.Provider>;
};

export const useSpinner = () => {
  const spinner = useContext(SpinnerContext);

  return spinner;
};

export default SpinnerContext;
