import React from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import { HiFilter } from 'react-icons/hi';

import FilterCustomerModal from '../../../components/CustomerDrawer/modals/FilterCustomerModal';

const FilterCustomer = ({ state, dispatch, refetch }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button leftIcon={<HiFilter />} size="sm" mb="2" mr="2" variant="outline" onClick={onOpen} colorScheme="brand">
        Kunden filtern
      </Button>
      <FilterCustomerModal isOpen={isOpen} onClose={onClose} refetch={refetch} state={state} dispatch={dispatch} />
    </>
  );
};

export default FilterCustomer;
