import React from 'react';
import {
  Avatar,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Box,
  Text,
  Heading,
  useDisclosure,
  Flex,
  Spinner,
  Divider,
  Grid,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useColorMode,
  Alert,
  AlertIcon,
  AlertDescription,
  HStack,
  Tag,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Icon,
  Link,
  VStack,
  Badge,
} from '@chakra-ui/react';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { HiChevronLeft, HiChevronRight, HiStar } from 'react-icons/hi';
import get from 'lodash/get'; // ES6

import { useUser } from '../../contexts/UserContext';
import { getVariant } from '../../utils';
import CreateNewNote from './modals/CreateNewNote';
import EditStatusModal from './modals/EditStatusModal';
import { GET_ORDER } from './query';

const DataRow = ({ label, value = '', alignItems = 'center', gridTemplateColumns = '1fr 2fr', children = null }) => {
  const { colorMode } = useColorMode();

  return (
    <Grid
      gridGap="2"
      gridTemplateColumns={gridTemplateColumns}
      py="3"
      borderBottom="1px solid"
      borderBottomColor="gray.200"
      alignItems={alignItems}>
      <Text fontWeight="100" color={colorMode === 'light' ? 'gray.700' : 'gray.300'}>
        {label}
      </Text>
      {children || <Box defaultValue={value}></Box>}
    </Grid>
  );
};

const OrderDrawer = ({ isOpen, onClose, refetch: parentRefetch, orderId, stationId, totalAmount, handleDrawerOpen }) => {
  const { user } = useUser();
  const { colorMode } = useColorMode();
  const { isOpen: isOpenEditModal, onOpen: openEditModal, onClose: closeEditModal } = useDisclosure();

  const { loading, error, data, refetch } = useQuery(GET_ORDER, {
    fetchPolicy: 'network-only',
    variables: {
      orderId,
      stationId,
      userId: user.id,
    },
    skip: !orderId || !stationId,
  });

  let currentId = data?.order?.id;
  const index = totalAmount?.map((e) => e.id).indexOf(currentId);
  const paymentType = get(data?.order?.payment, '0.type', null);

  const handlePreviousCustomer = async () => {
    const previousIndex = index - 1;
    const newCustomer = totalAmount?.at(previousIndex);
    handleDrawerOpen(newCustomer?.id, newCustomer?.packageStation?.id);
  };

  const handleNextCustomer = async () => {
    const NextIndex = index + 1;
    const newCustomer = totalAmount?.at(NextIndex);
    handleDrawerOpen(newCustomer?.id, newCustomer?.packageStation?.id);
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="md" autoFocus={false}>
      <DrawerOverlay />
      <DrawerContent>
        <Stack direction="row" spacing={5} justifyContent="center" pt="4">
          {index <= 0 ? (
            <Flex justifyContent="space-around">
              <Button leftIcon={<HiChevronLeft />} colorScheme="brand" variant="outline" size="xs" isDisabled={true}>
                Vorheriger Order
              </Button>
            </Flex>
          ) : (
            <Button onClick={handlePreviousCustomer} leftIcon={<HiChevronLeft />} colorScheme="brand" variant="outline" size="xs">
              Vorheriger Order
            </Button>
          )}
          {index === totalAmount?.length - 1 ? (
            <Button rightIcon={<HiChevronRight />} colorScheme="brand" variant="outline" size="xs" isDisabled={true}>
              Nächster Order
            </Button>
          ) : (
            <Button onClick={handleNextCustomer} rightIcon={<HiChevronRight />} colorScheme="brand" variant="outline" size="xs">
              Nächster Order
            </Button>
          )}
        </Stack>
        <DrawerBody>
          {loading ? (
            <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
              <Spinner />
            </Flex>
          ) : null}
          {error ? (
            <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
              Ein Fehler ist aufgetreten. Schreib Osamah an.
            </Flex>
          ) : null}
          {data?.order ? (
            <Box width="100%" flex="1" mb="4">
              {data.internalNotifications ? (
                <Box mb="4">
                  {data.internalNotifications.map(({ id, title, body }) => {
                    return (
                      <Alert key={id} status="info" mb="2">
                        <AlertIcon />
                        <AlertDescription>
                          <Text fontWeight="bold" mb="-1.5">
                            {title}
                          </Text>
                          <Text fontSize="sm">{body}</Text>
                        </AlertDescription>
                      </Alert>
                    );
                  })}
                </Box>
              ) : null}
              <Box alignItems="left" mb="4">
                <HStack mb="2">
                  <Tag colorScheme={getVariant(data?.order?.status)}>{data?.order?.status}</Tag>

                  {paymentType ? (
                    <Tag size="sm" mr="2">
                      {paymentType}
                    </Tag>
                  ) : null}
                </HStack>
                <Heading size="xl" mb="2">
                  <Text>{data?.order?.iid}</Text>
                </Heading>
              </Box>
              <Grid gridTemplateColumns="1fr 1fr" mb="8" gap="3">
                {data?.order?.status !== 'DONE' ? (
                  <Button onClick={openEditModal} colorScheme="green">
                    Status bearbeiten
                  </Button>
                ) : null}
              </Grid>
              <Heading as="h4" size="lg" flex="1" textAlign="left">
                Details zur Bestellung
              </Heading>
              <DataRow label="Bestellung Id">
                <Text>{data?.order?.id}</Text>
              </DataRow>
              {/* Orderer */}
              <DataRow label="Besteller" alignItems="flex-start">
                <VStack alignItems="flex-start">
                  <Link href={`/users/${data?.order?.orderer?.id}`}>
                    <Box>
                      {data?.order?.orderer?.firstName} {data?.order?.orderer?.lastName}
                    </Box>
                  </Link>
                  <Text>{data?.order?.orderer?.email}</Text>
                </VStack>
              </DataRow>

              {data?.order?.pickuper ? (
                <DataRow label="Pickuper">
                  <VStack alignItems="left">
                    <HStack>
                      <Box> {data?.order?.pickuper?.firstName}</Box>
                      <Box>{data?.order?.pickuper?.lastName}</Box>
                    </HStack>

                    <HStack>
                      <Text>{data?.order?.pickuper?.email}</Text>
                    </HStack>
                  </VStack>
                </DataRow>
              ) : null}
              {data?.order?.pickuperNoUser ? (
                <DataRow label="Pickuper (Ohne Profil)">
                  <VStack alignItems="left">
                    <HStack>
                      <Box> {data?.order?.pickuperNoUser?.firstName}</Box>
                      <Box>{data?.order?.pickuperNoUser?.lastName}</Box>
                    </HStack>

                    <HStack>
                      <Text>{data?.order?.pickuperNoUser?.email}</Text>
                    </HStack>
                  </VStack>
                </DataRow>
              ) : null}

              {/* DropPoint */}
              {/* if private */}
              {data?.order?.packageStation?.user ? (
                <DataRow label="DropPoint" alignItems="flex-start">
                  <VStack alignItems="flex-start">
                    <Link href={`/users/${data?.order?.packageStation?.user?.id}`}>
                      {data?.order?.packageStation?.user?.firstName} {data?.order?.packageStation?.user?.lastName}
                    </Link>
                    <Text>{data?.order?.packageStation?.user?.email}</Text>
                  </VStack>
                </DataRow>
              ) : null}

              {/* if company */}
              {data?.order?.packageStation?.company ? (
                <DataRow label="DropPoint" alignItems="flex-start">
                  <VStack alignItems="flex-start">
                    {/* <Text>{data?.order?.packageStation?.company?.name}</Text> */}
                    <Link fontWeight="semibold" href={`/companies/${data?.order?.packageStation.company.id}`}>
                      {data?.order?.packageStation.locationName}
                    </Link>

                    {data?.order?.packageStation?.company?.users?.map((mail) => {
                      return (
                        <Box key={mail?.id}>
                          <Text textAlign="left">{mail?.email}</Text>
                        </Box>
                      );
                    })}
                  </VStack>
                </DataRow>
              ) : null}

              {data?.retrieveAccount?.phone ? (
                <DataRow label="DropPoint Telefonnummer">
                  <Text textAlign="left">{data?.retrieveAccount?.phone}</Text>
                </DataRow>
              ) : null}

              {data?.order?.review?.rating > 0 ? (
                <DataRow label="Kunden Bewertung">
                  <Box>
                    <Flex>
                      <Icon as={HiStar} mr="1" color={data?.order?.review?.rating > 0 ? 'yellow.400' : 'gray.500'} />
                      <Icon as={HiStar} mr="1" color={data?.order?.review?.rating > 1 ? 'yellow.400' : 'gray.500'} />
                      <Icon as={HiStar} mr="1" color={data?.order?.review?.rating > 2 ? 'yellow.400' : 'gray.500'} />
                      <Icon as={HiStar} mr="1" color={data?.order?.review?.rating > 3 ? 'yellow.400' : 'gray.500'} />
                      <Icon as={HiStar} mr="1" color={data?.order?.review?.rating > 4 ? 'yellow.400' : 'gray.500'} />
                    </Flex>
                    {data?.order?.review?.note ? (
                      <Text fontSize="md" fontWeight="bold" color={colorMode === 'light' ? 'gray.500' : 'gray.300'}>
                        {data?.order?.review?.note}
                      </Text>
                    ) : null}
                  </Box>
                </DataRow>
              ) : null}
              {data?.order?.stationReview ? (
                <DataRow label="DropPoint Bewertung">
                  <Box>
                    <Flex>
                      <Icon as={HiStar} mr="1" color={data?.order?.stationReview?.rating > 0 ? 'yellow.400' : 'gray.500'} />
                      <Icon as={HiStar} mr="1" color={data?.order?.stationReview?.rating > 1 ? 'yellow.400' : 'gray.500'} />
                      <Icon as={HiStar} mr="1" color={data?.order?.stationReview?.rating > 2 ? 'yellow.400' : 'gray.500'} />
                      <Icon as={HiStar} mr="1" color={data?.order?.stationReview?.rating > 3 ? 'yellow.400' : 'gray.500'} />
                      <Icon as={HiStar} mr="1" color={data?.order?.stationReview?.rating > 4 ? 'yellow.400' : 'gray.500'} />
                    </Flex>
                    {data?.order?.stationReview?.note ? (
                      <Text fontSize="md" fontWeight="bold" color={colorMode === 'light' ? 'gray.500' : 'gray.300'}>
                        {data?.order?.stationReview?.note}
                      </Text>
                    ) : null}
                  </Box>
                </DataRow>
              ) : null}

              {data?.order?.packages.length > 0 ? (
                <Box overflow="hidden" textAlign="left" mt="5">
                  <Box justifyContent="space-between" alignItems="center" mb="2">
                    {' '}
                    <Heading as="h4" size="lg">
                      Packages
                    </Heading>
                    <Box>
                      <Accordion allowMultiple>
                        {data?.order?.packages.map((box, index) => {
                          return (
                            <Box key={`${data?.order?.createdAt}${index}`}>
                              <AccordionItem borderTop="none" borderBottom="none">
                                <h2>
                                  <AccordionButton _focus={{ outline: 'none' }}>
                                    <Box flex="1" textAlign="right">
                                      Paket {index + 1}
                                    </Box>
                                    <AccordionIcon />
                                  </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                  <DataRow label="Angenommen am:">
                                    <Text>{moment(box?.timestamp).format('DD.MM.YYYY, HH:mm')}</Text>
                                  </DataRow>
                                  {box?.handedOverTimestamp ? (
                                    <DataRow label="Übergeben am:">
                                      <Text>{moment(box?.handedOverTimestamp).format('DD.MM.YYYY, HH:mm')}</Text>
                                    </DataRow>
                                  ) : null}
                                  <DataRow label="Lagerzeit">
                                    {moment
                                      .duration(
                                        moment(box?.timestamp).diff(box?.handedOverTimestamp ? moment(box?.handedOverTimestamp) : moment())
                                      )
                                      .humanize()}
                                  </DataRow>

                                  {box?.holdingFee ? (
                                    <DataRow label="Lagergebühr">
                                      {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(box?.holdingFee / 100)}
                                    </DataRow>
                                  ) : null}

                                  {box?.note ? (
                                    <DataRow label="Nachricht">
                                      <Text color={colorMode === 'light' ? 'gray.500' : 'gray.300'} fontStyle="italic">
                                        "{box?.note}"
                                      </Text>
                                    </DataRow>
                                  ) : (
                                    <DataRow label="Nachricht">
                                      <Text fontSize="sm" color={colorMode === 'light' ? 'gray.500' : 'gray.300'}>
                                        Keine Notiz hinterlegt
                                      </Text>
                                    </DataRow>
                                  )}

                                  {box?.tip > 0 ? (
                                    <DataRow label="Trinkgeld">
                                      <Text>{box?.tip}%</Text>
                                    </DataRow>
                                  ) : null}

                                  {box?.photos?.map((uri, index) => {
                                    return (
                                      <Link _focus={{ outline: 'none' }} key={`${uri?.uri}`} href={uri?.uri} target="_blank">
                                        <Badge key={uri} mx="1">
                                          🖼️ {index + 1}
                                        </Badge>
                                      </Link>
                                    );
                                  })}
                                </AccordionPanel>
                              </AccordionItem>
                            </Box>
                          );
                        })}
                      </Accordion>
                    </Box>
                  </Box>
                </Box>
              ) : null}

              <Box overflow="hidden" textAlign="left" mt="10">
                <Flex justifyContent="space-between" alignItems="center" mb="6">
                  <Heading as="h4" size="lg">
                    History
                  </Heading>
                </Flex>
                <Tabs>
                  <TabList>
                    <Tab>System-Einträge</Tab>
                  </TabList>

                  <TabPanels>
                    {/* comments & system changes (status, updates, etc) */}
                    <TabPanel px="0">
                      <Flex justifyContent="flex-end" alignItems="center" mb="4">
                        <CreateNewNote key={orderId} orderId={orderId} refetch={refetch} type="OTHER" />
                      </Flex>
                      {data?.order?.history?.length > 0 ? (
                        data?.order?.history?.map((history) => {
                          return (
                            <Box overflowY="hidden" mb="4" key={history?.id}>
                              <Flex fontFamily="heading" mb="1">
                                <Avatar src={history?.changedBy?.photo} size="xs" name="commenter" mr="2" />
                                <Text color={colorMode === 'light' ? 'gray.900' : 'gray.100'}>
                                  {history?.changedBy?.firstName} {history?.changedBy?.lastName}
                                </Text>
                              </Flex>
                              <Text fontSize="sm" color={colorMode === 'light' ? 'gray.700' : 'gray.300'} mb="2">
                                {moment(history?.createdAt).format('DD.MM.YYYY, HH:mm')}
                              </Text>

                              <Box
                                color={colorMode === 'light' ? 'gray.900' : 'gray.100'}
                                mb="4"
                                dangerouslySetInnerHTML={{ __html: history?.comment }}
                              />
                              <Divider />
                            </Box>
                          );
                        })
                      ) : (
                        <Alert status="info">
                          <AlertIcon />
                          Noch keine History-Einträge hinterlegt
                        </Alert>
                      )}
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            </Box>
          ) : null}
        </DrawerBody>
        <EditStatusModal
          isOpen={isOpenEditModal}
          onClose={closeEditModal}
          refetch={async () => {
            await refetch();
            await parentRefetch();
          }}
          orderId={data?.order?.id}
          status={data?.order?.status}
        />

        <DrawerCloseButton />
      </DrawerContent>
    </Drawer>
  );
};
export default OrderDrawer;
