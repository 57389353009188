import React, { useContext } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalHeader, ModalBody, ModalFooter, Button, Grid } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';
import get from 'lodash/get';

import Input from '../../components/Input';
import Select from '../../components/Select';
import UserContext from '../../contexts/UserContext';
import SpinnerContext from '../../contexts/SpinnerContext';
import { INVITE_COMPANY } from './query';
import { useCustomToast } from '../../hooks/useCustomToast';

const LockConfirmSchema = Yup.object().shape({
  companyType: Yup.string().required('Du musst eine Unternehmensart angeben'),
  companyName: Yup.string().required('Du musst einen Unternehmensnamen angeben'),
  firstName: Yup.string().required('Du musst einen Vornamen angeben'),
  lastName: Yup.string().required('Du musst einen Nachnamen angeben'),
  email: Yup.string().required('Du musst eine E-Mail Adresse angeben,').email('Du musst eine valide Email angeben'),
  address: Yup.object().shape({
    street: Yup.string().required('Du musst eine Straße angeben'),
    houseNumber: Yup.string().required('Du musst eine Hausnummer angeben'),
    postalCode: Yup.string()
      .min(5, 'Die PLZ muss 5 Ziffern lang sein')
      .max(5, 'Die PLZ muss 5 Ziffern lang sein')
      .required('Du musst eine PLZ angeben'),
    city: Yup.string().required('Du musst eine Stadt angeben'),
  }),
  comment: Yup.string().required('Warum möchtest Du das Unternehmen einladen?'),
});

export const InviteCompanyModal = ({ isOpen, onClose, refetch }) => {
  const [inviteCompany] = useMutation(INVITE_COMPANY);
  const { user } = useContext(UserContext);
  const { toggleSpinner } = useContext(SpinnerContext);
  const toast = useCustomToast();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Unternehmen zu DropFriends einladen</ModalHeader>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            address: { street: '', houseNumber: '', postalCode: '', city: '' },
            companyName: '',
            companyType: '',
            comment: '',
          }}
          validationSchema={LockConfirmSchema}
          onSubmit={async ({ companyType, firstName, lastName, email, address, companyName, comment }) => {
            toggleSpinner(true);

            try {
              await inviteCompany({
                variables: {
                  data: {
                    firstName,
                    lastName,
                    email,
                    address: {
                      create: { ...address, country: 'DE', countryCode: 'DE' },
                    },
                    companyType,
                    companyName,
                    comment,
                    invitedBy: {
                      connect: {
                        id: user.id,
                      },
                    },
                  },
                },
              });
              await refetch();
              toast({
                title: 'Erfolg!',
                description: 'Die Email wurde erfolgreich geändert',
                status: 'success',
              });
              onClose();
            } catch (error) {
              console.log(error);
              toast({
                description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
                status: 'error',
              });
            }

            toggleSpinner(false);
          }}>
          <Form>
            <ModalBody>
              <Select
                name="companyType"
                label="Unternehmensart"
                options={[
                  { value: '', label: 'Unternehmensart auswählen', disabled: true },
                  { value: 'DROPPOINTS', label: 'DropPoint' },
                  { value: 'COURIER', label: 'Sprint' },
                ]}
              />
              <Input name="companyName" label="Unternehmensname" />
              <Input name="firstName" label="Vorname" />
              <Input name="lastName" label="Nachname" />
              <Input name="email" label="Email-Adresse" />
              <Grid gridGap="4" gridTemplateColumns="2fr 1fr">
                <Input name="address.street" label="Strasse" />
                <Input name="address.houseNumber" label="Hausnummer" />
              </Grid>
              <Grid gridGap="4" gridTemplateColumns="1fr 1fr">
                <Input name="address.postalCode" label="PLZ" />
                <Input name="address.city" label="Stadt" />
              </Grid>
              <Input name="comment" label="Kommentar" />
            </ModalBody>

            <ModalFooter>
              <Button variant="ghost" size="sm" colorScheme="red" type="button" onClick={onClose}>
                Schließen
              </Button>
              <Button ml="4" size="sm" colorScheme="brand" type="submit">
                Speichern
              </Button>
            </ModalFooter>
          </Form>
        </Formik>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};
