import React, { useEffect, useReducer } from 'react';
import {
  Text,
  Flex,
  Box,
  Button,
  useDisclosure,
  Spinner,
  Th,
  Table,
  Thead,
  Tr,
  Tbody,
  Stack,
  Td,
  Grid,
  useColorMode,
} from '@chakra-ui/react';
import { RouteComponentProps, navigate } from '@reach/router';
import { useQuery } from '@apollo/client';
import { HiPlusCircle, HiSortAscending, HiSortDescending } from 'react-icons/hi';
import useMedia from 'use-media';

import Layout from '../../components/Layout';
import { Pagination } from '../../components/Pagination';
import CreateCustomerModal from '../../components/CustomerDrawer/modals/CreateCustomer';
import ChangeAsigneeModal from '../../components/CustomerDrawer/modals/ChangeAsigneeModal';
import FilterCustomer from './actions/FilterCustomer';
import FilterValues from './actions/FilterValues';
import { CustomerRow } from './CustomersRow';
import { CustomerCard } from './CustomerCard';

import { INTERNAL_CUSTOMERS } from './query';
import { UploadCsv } from './actions/UploadCsv';
import { UploadAgencyCSV } from './actions/UploadAgencyCSV';
import CustomerDrawer from '../../components/CustomerDrawer';

interface InternalCustomersProps extends RouteComponentProps {
  customerId?: string;
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_FILTERS':
      return { ...state, filters: action.payload, currentPage: 1 };
    case 'SET_WHERE':
      return { ...state, where: action.payload, currentPage: 1 };
    case 'TOGGLE_SHOW_OPEN':
      return { ...state, showOpen: !state.showOpen, currentPage: 1 };
    case 'TOGGLE_SHOW_IN_PROGRESS':
      return { ...state, showInProgress: !state.showInProgress, currentPage: 1 };
    case 'TOGGLE_SHOW_PARKED':
      return { ...state, showParked: !state.showParked, currentPage: 1 };
    case 'TOGGLE_SHOW_DONE':
      return { ...state, showDone: !state.showDone, currentPage: 1 };
    case 'TOGGLE_SHOW_FAILURE':
      return { ...state, showFailure: !state.showFailure, currentPage: 1 };
    case 'TOGGLE_SHOW_CONTACT_AGAIN':
      return { ...state, showContactAgain: !state.showContactAgain, currentPage: 1 };
    case 'TOGGLE_SHOW_HOT':
      return { ...state, showHot: !state.showHot, currentPage: 1 };
    case 'TOGGLE_SHOW_WARM':
      return { ...state, showWarm: !state.showWarm, currentPage: 1 };
    case 'TOGGLE_SHOW_COLD':
      return { ...state, showCold: !state.showCold, currentPage: 1 };
    case 'SET_ORDER_BY':
      return { ...state, orderBy: action.payload };
    case 'SET_ASIGNEE_ID':
      return { ...state, asigneeId: action.payload };
    case 'SET_CUSTOMER_ID':
      return { ...state, customerId: action.payload };
    case 'SET_PAGE':
      return { ...state, currentPage: action.payload };
    default:
      return state;
  }
};

const initialState = {
  filters: [],
  where: {},
  showOpen: true,
  showInProgress: true,
  showParked: true,
  showDone: false,
  showFailure: false,
  showContactAgain: true,
  showHot: true,
  showWarm: true,
  showCold: true,
  orderBy: 'createdAt_DESC',
  asigneeId: '',
  customerId: '',
  currentPage: 1,
};

const InternalCustomers: React.FC<InternalCustomersProps> = ({ location }) => {
  const urlParams = new URLSearchParams(location.search);
  const [state, dispatch] = useReducer(reducer, initialState);
  const isWide = useMedia({ minWidth: 1264 });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenChangeAsigneeModal, onOpen: openChangeAsigneeModal, onClose: closeChangeAsigneeModal } = useDisclosure();
  const { isOpen: isOpenDrawer, onOpen: openDrawer, onClose: closeDrawer } = useDisclosure();
  const { colorMode } = useColorMode();
  const { loading, error, data, refetch } = useQuery(INTERNAL_CUSTOMERS, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        ...state.where,
        status_in: [
          state.showOpen ? 'OPEN' : null,
          state.showInProgress ? 'INPROGRESS' : null,
          state.showParked ? 'PARKED' : null,
          state.showDone ? 'DONE' : null,
          state.showFailure ? 'FAILURE' : null,
          state.showContactAgain ? 'CONTACT_AGAIN' : null,
        ].filter(Boolean),
        leadStatus_in: [state.showHot ? 'HOT' : null, state.showWarm ? 'WARM' : null, state.showCold ? 'COLD' : null].filter(Boolean),
      },
      orderBy: state.orderBy,
      first: 25,
      skip: 25 * state.currentPage - 25,
    } as any,
  });

  const handleOpenChangeAsignee = (customerId, asigneeId) => {
    dispatch({ type: 'SET_CUSTOMER_ID', payload: customerId });
    dispatch({ type: 'SET_ASIGNEE_ID', payload: asigneeId || '' });
    openChangeAsigneeModal();
  };

  const handleCloseChangeAsignee = () => {
    if (!isOpenDrawer) {
      dispatch({ type: 'SET_CUSTOMER_ID', payload: '' });
    }
    dispatch({ type: 'SET_ASIGNEE_ID', payload: '' });
    closeChangeAsigneeModal();
  };

  const handleDrawerOpen = (id) => {
    dispatch({ type: 'SET_CUSTOMER_ID', payload: id });
    openDrawer();
    urlParams.set('customerId', id);
    navigate(`${location.pathname}?${urlParams.toString()}`);
  };

  const handleDrawerClose = () => {
    dispatch({ type: 'SET_CUSTOMER_ID', payload: '' });
    closeDrawer();
    urlParams.delete('customerId');
    navigate(`${location.pathname}`);
  };

  useEffect(() => {
    if (urlParams.has('customerId')) {
      dispatch({ type: 'SET_CUSTOMER_ID', payload: urlParams.get('customerId') });
      openDrawer();
    }
  }, [location]);

  return (
    <Box width="100%">
      <Flex mb="9">
        <Layout fullWidth>
          <Flex direction={['column', 'column', 'column', 'row']} alignItems="center" mb="6">
            <Text
              as="h2"
              fontSize="4xl"
              flex="1"
              fontWeight="bold"
              fontFamily="heading"
              color={colorMode === 'light' ? 'gray.900' : 'gray.100'}>
              Kunden-Datenbank
            </Text>
            <Stack direction={['column', 'column', 'row']} justifyContent={['center', 'center', 'flex-end']}>
              <UploadCsv refetch={refetch} />
              <UploadAgencyCSV refetch={refetch} />
              <Button size="sm" colorScheme="brand" onClick={onOpen} leftIcon={<HiPlusCircle />}>
                Lead hinzufügen
              </Button>
            </Stack>
          </Flex>

          <Flex justifyContent="space-between" flexWrap="wrap">
            <Flex direction="column" mb="4" alignItems="flex-start">
              <FilterCustomer refetch={refetch} dispatch={dispatch} state={state} />
              <FilterValues dispatch={dispatch} state={state} />
            </Flex>
            <Stack mb="4">
              <Stack gap="2" direction="row" justifyContent="flex-end" flexWrap="wrap">
                <Button
                  size="xs"
                  colorScheme={state.showOpen ? 'blue' : 'gray'}
                  onClick={() => dispatch({ type: 'TOGGLE_SHOW_OPEN' })}
                  mb="2">
                  😎 Offen
                </Button>
                <Button
                  size="xs"
                  colorScheme={state.showInProgress ? 'yellow' : 'gray'}
                  onClick={() => dispatch({ type: 'TOGGLE_SHOW_IN_PROGRESS' })}
                  mb="2">
                  ✔ Kontaktiert
                </Button>
                <Button
                  size="xs"
                  colorScheme={state.showContactAgain ? 'orange' : 'gray'}
                  onClick={() => dispatch({ type: 'TOGGLE_SHOW_CONTACT_AGAIN' })}
                  mb="2">
                  👀 Nachhaken
                </Button>
                <Button
                  size="xs"
                  colorScheme={state.showParked ? 'orange' : 'gray'}
                  onClick={() => dispatch({ type: 'TOGGLE_SHOW_PARKED' })}
                  mb="2">
                  🕗 Pausiert
                </Button>
                <Button
                  size="xs"
                  colorScheme={state.showDone ? 'green' : 'gray'}
                  onClick={() => dispatch({ type: 'TOGGLE_SHOW_DONE' })}
                  mb="2">
                  🤝 Abgeschlossen
                </Button>
                <Button
                  size="xs"
                  colorScheme={state.showFailure ? 'red' : 'gray'}
                  onClick={() => dispatch({ type: 'TOGGLE_SHOW_FAILURE' })}
                  mb="2">
                  ❌ Abgebrochen
                </Button>
              </Stack>
              <Stack gap="2" direction="row" justifyContent="flex-end" flexWrap="wrap">
                <Button size="xs" colorScheme={state.showHot ? 'red' : 'gray'} onClick={() => dispatch({ type: 'TOGGLE_SHOW_HOT' })} mb="2">
                  🔥 Hot Lead
                </Button>
                <Button
                  size="xs"
                  colorScheme={state.showWarm ? 'orange' : 'gray'}
                  onClick={() => dispatch({ type: 'TOGGLE_SHOW_WARM' })}
                  mb="2">
                  Warm Lead
                </Button>
                <Button
                  size="xs"
                  colorScheme={state.showCold ? 'blue' : 'gray'}
                  onClick={() => dispatch({ type: 'TOGGLE_SHOW_COLD' })}
                  mb="2">
                  ❄️ Cold Lead
                </Button>
              </Stack>
            </Stack>
          </Flex>
          {isWide ? (
            <>
              <Table size="sm" alignItems="center">
                <Thead>
                  <Tr>
                    <Th
                      width="135px"
                      onClick={() =>
                        dispatch({ type: 'SET_ORDER_BY', payload: state.orderBy === 'createdAt_DESC' ? 'createdAt_ASC' : 'createdAt_DESC' })
                      }>
                      <Flex alignItems="center" style={{ cursor: 'pointer' }}>
                        <Text mr="2">ID </Text>
                        {state.orderBy.includes('createdAt') ? (
                          <Box as={state.orderBy === 'createdAt_DESC' ? HiSortDescending : HiSortAscending} />
                        ) : null}
                      </Flex>
                    </Th>
                    <Th width="160px">Status</Th>
                    <Th
                      onClick={() =>
                        dispatch({
                          type: 'SET_ORDER_BY',
                          payload: state.orderBy === 'companyName_ASC' ? 'companyName_DESC' : 'companyName_ASC',
                        })
                      }>
                      <Flex alignItems="center" style={{ cursor: 'pointer' }}>
                        <Text mr="2">Kunde</Text>
                        {state.orderBy.includes('companyName') ? (
                          <Box as={state.orderBy === 'companyName_ASC' ? HiSortDescending : HiSortAscending} />
                        ) : null}
                      </Flex>
                    </Th>
                    <Th>Kontakt</Th>
                    <Th>Mitarbeiter</Th>
                    <Th
                      onClick={() =>
                        dispatch({ type: 'SET_ORDER_BY', payload: state.orderBy === 'updatedAt_DESC' ? 'updatedAt_ASC' : 'updatedAt_DESC' })
                      }>
                      <Flex alignItems="center" style={{ cursor: 'pointer' }}>
                        <Text mr="2" flex="1">
                          Zuletzt aktualisiert
                        </Text>
                        {state.orderBy.includes('updatedAt') ? (
                          <Box width="3" height="3" as={state.orderBy === 'updatedAt_DESC' ? HiSortDescending : HiSortAscending} />
                        ) : null}
                      </Flex>
                    </Th>
                    <Th />
                  </Tr>
                </Thead>

                <Tbody>
                  {loading ? (
                    <Tr>
                      <Td colSpan={6}>
                        <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
                          <Spinner />
                        </Flex>
                      </Td>
                    </Tr>
                  ) : null}
                  {error ? (
                    <Tr>
                      <Td colSpan={6}>
                        <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
                          Ein Fehler ist aufgetreten. Schreib Osamah an.
                        </Flex>
                      </Td>
                    </Tr>
                  ) : null}
                  {data?.internalCustomers?.length > 0
                    ? data?.internalCustomers?.map((internalCustomer) => {
                        return (
                          <CustomerRow key={internalCustomer.id} {...internalCustomer} refetch={refetch} onDrawerOpen={handleDrawerOpen} />
                        );
                      })
                    : null}
                </Tbody>
              </Table>
              <Pagination
                totalAmount={data?.totalInternalCustomers}
                amountPerPage={25}
                currentPage={state.currentPage}
                handleOnClick={(newPage) => dispatch({ type: 'SET_PAGE', payload: newPage })}
              />
            </>
          ) : (
            <>
              <Grid gridTemplateColumns={['1fr', '1fr', '1fr 1fr']} gap="4">
                {loading ? (
                  <Flex width="100%" height="100%" justifyContent="center" alignItems="center" gridColumn="span 2">
                    <Spinner />
                  </Flex>
                ) : null}
                {error ? (
                  <Flex width="100%" height="100%" justifyContent="center" alignItems="center" gridColumn="span 2">
                    Ein Fehler ist aufgetreten. Schreib Osamah an.
                  </Flex>
                ) : null}
                {data?.internalCustomers?.length > 0
                  ? data?.internalCustomers?.map((internalCustomer) => {
                      return (
                        <CustomerCard
                          key={internalCustomer.id}
                          {...internalCustomer}
                          refetch={refetch}
                          onDrawerOpen={handleDrawerOpen}
                          openChangeAsigneeModal={handleOpenChangeAsignee}
                        />
                      );
                    })
                  : null}
              </Grid>
              <Pagination
                totalAmount={data?.totalInternalCustomers}
                amountPerPage={25}
                currentPage={state.currentPage}
                handleOnClick={(newPage) => dispatch({ type: 'SET_PAGE', payload: newPage })}
              />
            </>
          )}
          <CustomerDrawer
            isOpen={isOpenDrawer}
            onClose={handleDrawerClose}
            refetch={refetch}
            customerId={state.customerId}
            openChangeAsigneeModal={handleOpenChangeAsignee}
            totalAmount={data?.internalCustomers}
            handleDrawerOpen={handleDrawerOpen}
          />
          <CreateCustomerModal
            isOpen={isOpen}
            onClose={onClose}
            refetch={async () => {
              await refetch();
            }}
            asigneeId={state.asigneeId}
          />

          <ChangeAsigneeModal
            isOpen={isOpenChangeAsigneeModal}
            onClose={handleCloseChangeAsignee}
            refetch={async () => {
              await refetch();
            }}
            customerId={state.customerId}
            asigneeId={state.asigneeId}
          />
        </Layout>
      </Flex>
    </Box>
  );
};

export default InternalCustomers;
