import gql from 'graphql-tag';

export const GET_USERS = gql`
  query GET_USERS($where: UserWhereInput, $orderBy: UserOrderByInput, $first: Int, $skip: Int) {
    users(where: $where, orderBy: $orderBy, first: $first, skip: $skip) {
      id
      firstName
      lastName
      email
      emailVerified
      accountLocked
      lockReason
      inDefault
      acceptEmails
      defaultAmount
      photo {
        uri
      }
      advertisements {
        id
        isActive
      }
      packageStations {
        id
        isOnboarded
        isActive
        packages {
          id
        }
      }
      companyRole
      myCompany {
        id
        name
      }
      orders {
        id
      }
    }
    totalUsers(where: $where) {
      total
    }
  }
`;

export const GET_USER = gql`
  query USER($where: UserWhereUniqueInput!) {
    user(where: $where) {
      id
      firstName
      lastName
      email
      emailVerified
      accountLocked
      lockReason
      inDefault
      defaultAmount
      birthdate
      acceptEmails
      address {
        street
        houseNumber
        postalCode
        city
      }
      companyRole
      myCompany {
        id
        name
      }
      createdAt
      photo {
        uri
      }
      orders {
        id
      }
      advertisements {
        id
        isActive
      }
      packageStations {
        id
        isOnboarded
        isActive
        locationName
        address {
          street
          houseNumber
          postalCode
          city
        }
        company {
          id
        }
        packages {
          id
        }
        lastActivityCheckSent
        lastActivityCheckResponse
        activityCheck
        activityCheckSent
        activityCheckToken
        activityCheckAmount
      }
    }
  }
`;

export const GET_ACCOUNT = gql`
  query ACCOUNT($stationId: String!) {
    retrieveAccount(stationId: $stationId) {
      phone
    }
  }
`;

export const GET_DROPPOINT = gql`
  query GET_DROPPOINT($where: PackageStationWhereUniqueInput!) {
    packageStation(where: $where) {
      id

      taxId
      accountType
      accountHolder
      bankName
      iban

      stationPhotos {
        uri
      }
      identificationMethods {
        iid
        idCheck
      }
      pickupTimes {
        from
        to
      }
      packageSize
      packageWeight
      numberOfPackages
      activeCoupon {
        id
        code
        validUntil
        description
      }
      updatedAt
    }
  }
`;

export const HISTORY = gql`
  query HISTORY($where: UserWhereUniqueInput!) {
    user(where: $where) {
      id
      firstName
      lastName
      birthdate
      address {
        street
        houseNumber
        postalCode
        city
      }
      history(orderBy: createdAt_DESC, first: 500) {
        type
        comment
        createdAt
        change
        changedBy {
          id
          firstName
        }
      }
    }
  }
`;

export const EDIT_USER = gql`
  mutation EDIT_USER(
    $firstName: String
    $lastName: String
    $street: String
    $houseNumber: String
    $postalCode: String
    $city: String
    $where: UserWhereUniqueInput!
    $internalUser: String!
    $comment: String
  ) {
    internalUpdateUser(
      firstName: $firstName
      lastName: $lastName
      street: $street
      houseNumber: $houseNumber
      postalCode: $postalCode
      city: $city
      where: $where
      internalUser: $internalUser
      comment: $comment
    ) {
      id
    }
  }
`;

export const SEND_EMAIL = gql`
  mutation {
    sendEmailToDropPoints
  }
`;

export const EXPORT_USERS = gql`
  mutation {
    exportUsersToCsv
  }
`;
export const EXPORT_USERS_BY_WEEK = gql`
  mutation EXPORT_USERS_BY_WEEK($start: Float!, $end: Float!) {
    exportUserStatisticsByWeek(start: $start, end: $end)
  }
`;

export const UPDATE_USER = gql`
  mutation UPDATE_USER($id: ID!, $email: String!, $internalUser: String!, $comment: String) {
    internalChangeEmail(id: $id, email: $email, internalUser: $internalUser, comment: $comment) {
      id
      email
    }
  }
`;

export const DELETE_USER = gql`
  mutation DELETE_USER($userId: String!) {
    deleteUser(userId: $userId)
  }
`;

export const CANCEL_USER = gql`
  mutation CANCEL_USER($userId: String!, $internalUser: String!, $comment: String!) {
    internalCancelUser(userId: $userId, comment: $comment, internalUser: $internalUser)
  }
`;

export const LOCK_USER = gql`
  mutation LOCK_USER($userId: String!, $comment: String!, $internalUser: String!, $sendEmail: Boolean) {
    lockUser(userId: $userId, comment: $comment, sendEmail: $sendEmail, internalUser: $internalUser) {
      id
    }
  }
`;

export const SEND_EMAIL_TO_USER = gql`
  mutation SEND_EMAIL_TO_USER($userId: String!, $comment: String!, $internalUser: String!, $templateId: String!, $templateName: String) {
    sendEmailToUser(userId: $userId, comment: $comment, templateId: $templateId, internalUser: $internalUser, templateName: $templateName)
  }
`;

export const UNLOCK_USER = gql`
  mutation UNLOCK_USER($userId: String!, $internalUser: String!, $comment: String!) {
    unlockUser(userId: $userId, comment: $comment, internalUser: $internalUser) {
      id
    }
  }
`;

export const NEW_HISTORY_ENTRY = gql`
  mutation NEW_HISTORY_ENTRY($userId: String!, $internalUser: String!, $comment: String!) {
    internalHistoryEntry(userId: $userId, comment: $comment, internalUser: $internalUser) {
      id
    }
  }
`;

export const GET_ORDERS = gql`
  query GET_ORDERS($where: OrderWhereInput, $orderBy: OrderOrderByInput) {
    orders(where: $where, orderBy: $orderBy) {
      id
      iid
      createdAt
      status
      tip
      packageStation {
        id
        locationName
        company {
          id
          logo
          name
          users {
            id
            email
          }
        }
        user {
          id
          firstName
          lastName
          email
        }
      }
      orderer {
        id
        firstName
        lastName
        email
      }
      pickuper {
        id
        firstName
        lastName
        email
      }
      pickuperNoUser {
        firstName
        lastName
        email
      }
      payment {
        id
        type
      }
      review {
        id
        rating
        note
      }
      stationReview {
        id
        rating
        note
      }
      packages {
        id
        handedOver
        handedOverTimestamp
        timestamp
        holdingFee
        note
        photos {
          uri
        }
      }
    }
    totalOrdersCount(where: $where)
  }
`;

export const ORDER_SUBSCRIPTION = gql`
  subscription ORDER_SUBSCRIPTION($userId: String!) {
    order(userId: $userId) {
      id
      iid
      createdAt
      status
      packages {
        id
        handedOver
        handedOverTimestamp
        timestamp
        note
        photos {
          uri
        }
      }
    }
  }
`;

export const GET_ADVERTISEMENTS = gql`
  query GET_ADVERTISEMENTS($where: AdvertisementWhereInput, $orderBy: AdvertisementOrderByInput, $first: Int, $skip: Int) {
    advertisements(where: $where, orderBy: $orderBy, first: $first, skip: $skip) {
      id
      title
      category
      description
      delivery
      url
      isActive
      deliveryTimes {
        from
        to
        allDays
        weekday
      }
      address {
        street
        houseNumber
        postalCode
        city
        locationId
        geometry {
          type
          coordinates
        }
      }
      photo {
        uri
      }
      user {
        id
        firstName
        lastName
        email
        photo {
          uri
        }
      }
    }
    totalAdvertisements {
      total
    }
  }
`;

export const SEND_ACTIVITY_CHECK = gql`
  mutation SEND_ACTIVITY_CHECK($internalUserId: String!, $where: PackageStationWhereInput!) {
    sendActivityCheck(internalUserId: $internalUserId, where: $where)
  }
`;
