import React, { useContext } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useRadioGroup,
  Flex,
} from '@chakra-ui/react';
import { Formik, Form, useField, useFormikContext } from 'formik';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';
import get from 'lodash/get';

import SpinnerContext from '../../contexts/SpinnerContext';
import UserContext from '../../contexts/UserContext';
import Input from '../../components/Input';
import { SEND_PUSH_NOTIFICATION } from './query';
import { useCustomToast } from '../../hooks/useCustomToast';

const CustomRadio = React.forwardRef<any, any>((props, ref) => {
  const { isChecked, isDisabled, value, onChange, ...rest } = props;
  return (
    <Button
      ref={ref}
      colorScheme={isChecked ? 'brand' : 'gray'}
      aria-checked={isChecked}
      role="radio"
      isDisabled={isDisabled}
      mb="3"
      mr="2"
      onClick={() => onChange(value)}
      {...rest}
    />
  );
});

const CustomRadioGroup = ({ name, options, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField({ name });

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: name,
    defaultValue: field.value,
    onChange: (val) => setFieldValue(name, val),
  });

  const group = getRootProps();

  return (
    <Flex {...group} flexWrap="wrap">
      {options.map(({ value, label }) => {
        // @ts-ignore
        const radio = getRadioProps({ value });

        return (
          <CustomRadio key={value} {...radio}>
            {label}
          </CustomRadio>
        );
      })}
    </Flex>
  );
};

const NotificationSchema = Yup.object().shape({
  user: Yup.string().when('receiver', {
    is: (receiver) => receiver === 'SINGLE_USER',
    then: Yup.string().required('Du musst noch diese Eingabe machen, mein junger Padavan.'),
  }),
  body: Yup.string().required('Du musst noch diese Eingabe machen, mein junger Padavan.'),
  comment: Yup.string().required('Du musst noch diese Eingabe machen, mein junger Padavan.'),
});

const SendNotificationModal = ({ isOpen, onClose, refetch }) => {
  const { user: internalUser } = useContext(UserContext);
  const { toggleSpinner } = useContext(SpinnerContext);
  const [sendNotification] = useMutation(SEND_PUSH_NOTIFICATION);
  const toast = useCustomToast();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Push Notification senden</ModalHeader>

        <Formik
          initialValues={{
            receiver: 'ALL_USERS',
            user: '',
            title: '',
            subTitle: '',
            body: '',
            comment: '',
          }}
          validationSchema={NotificationSchema}
          onSubmit={async ({ receiver, user, title, subTitle, body, comment }) => {
            try {
              toggleSpinner(true);
              await sendNotification({
                variables: {
                  receiver,
                  user,
                  title,
                  subTitle,
                  body,
                  comment,
                  internalUser: internalUser.id,
                },
              });
              await refetch();
              toast({
                title: 'Erfolg!',
                description: 'Die Rebellion wurde gewarnt.',
                status: 'success',
              });
            } catch (error) {
              console.log(error);
              toast({
                description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
                status: 'error',
              });
            }
            toggleSpinner(false);
            onClose();
          }}>
          {({ values, setFieldValue }) => (
            <Form>
              <ModalBody>
                <CustomRadioGroup
                  name="receiver"
                  options={[
                    { value: 'ALL_USERS', label: 'Alle Nutzer' },
                    { value: 'ACTIVE_DROPPOINTS', label: 'Aktive DropPoints' },
                    { value: 'INACTIVE_DROPPOINTS', label: 'Inaktive DropPoints' },
                    { value: 'NO_DROPPOINT', label: 'Nutzer ohne DropPoint' },
                    { value: 'SINGLE_USER', label: 'Einzelner Nutzer' },
                  ]}
                />
                {values.receiver === 'SINGLE_USER' ? <Input name="user" label="Besteller-ID" /> : null}
                <Input name="title" label="Überschrift (optional)" />
                <Input name="subTitle" label="Zweite Überschrift (nur iOS & optional)" />
                <Input name="body" label="Nachricht" />
                <Input name="comment" label="Kommentar" />
              </ModalBody>

              <ModalFooter>
                <Button variant="ghost" size="sm" colorScheme="red" type="button" onClick={onClose}>
                  Schließen
                </Button>
                <Button ml="4" size="sm" colorScheme="brand" type="submit">
                  Speichern
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};

export default SendNotificationModal;
