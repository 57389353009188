import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { RouteComponentProps, Router, Link } from '@reach/router';
import {
  Spinner,
  Flex,
  Text,
  Grid,
  Avatar,
  Box,
  IconButton,
  Badge,
  Button,
  useDisclosure,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  useColorMode,
  HStack,
} from '@chakra-ui/react';
import { IoIosKeypad, IoIosMenu } from 'react-icons/io';

// import SpinnerContext from '../../contexts/SpinnerContext';
// import UserContext from '../../contexts/UserContext';
import Layout from '../../components/Layout';
import { GET_COMPANIES } from './query';
import { CompanyDetails } from './CompanyDetails';
import { InviteCompanyModal } from './InviteCompanyModal';
import { HistoryTab } from './HistoryTab';
import moment from 'moment';

const CompanyCard = ({ id, name, logo, type, locked, lockReason, createdAt, hasCompensation, priceWithCompensation, compensation }) => {
  const { colorMode } = useColorMode();
  return (
    <Box
      as={Link}
      //@ts-ignore
      to={`/companies/${id}`}
      width="100%"
      rounded="md"
      borderWidth="1px"
      py="4"
      px="6"
      _hover={{ boxShadow: 'md', backgroundColor: colorMode === 'light' ? 'white' : 'gray.700' }}>
      <HStack flexWrap="wrap" mb="1" spacing="2">
        <Badge colorScheme="blue">{type}</Badge>
        {hasCompensation ? <Badge colorScheme="green">{compensation} Cent Vergütung</Badge> : null}
        {hasCompensation ? <Badge colorScheme="green">{priceWithCompensation} pro Buchung</Badge> : null}
        {locked && lockReason === 'AWAITING_INVOICE' ? <Badge colorScheme="yellow">Offene Rechnung</Badge> : null}
      </HStack>
      <Flex alignItems="center" mb="2">
        <Avatar src={logo} name={name} mr="4" />
        <Box flex="1">
          <Text fontFamily="heading" fontSize="lg" fontWeight="bold" display="flex" alignItems="center">
            {name}
          </Text>
        </Box>
      </Flex>
      <Text fontSize="sm" color={colorMode === 'light' ? 'gray.600' : 'gray.400'}>
        Erstellt am: {moment(createdAt).format('DD.MM.YYYY, HH:mm')}
      </Text>
    </Box>
  );
};

const CompanyTableRow = ({ id, name, logo, type, locked, lockReason, hasCompensation, priceWithCompensation, compensation }) => {
  const { colorMode } = useColorMode();
  return (
    <Box
      as={Link}
      //@ts-ignore
      to={`/companies/${id}`}
      gridColumn="span 3"
      width="100%"
      rounded="md"
      borderWidth="1px"
      py="2"
      px="4"
      _hover={{ boxShadow: 'md', backgroundColor: colorMode === 'light' ? 'white' : 'gray.700' }}>
      <HStack flexWrap="wrap" spacing="2" mb="1">
        <Badge colorScheme="blue">{type}</Badge>
        {hasCompensation ? <Badge colorScheme="green">{compensation} Cent Vergütung</Badge> : null}
        {hasCompensation ? <Badge colorScheme="green">{priceWithCompensation} pro Buchung</Badge> : null}
      </HStack>
      {locked && lockReason === 'AWAITING_INVOICE' ? <Badge colorScheme="blue">Offene Rechnung</Badge> : null}
      <Flex alignItems="center">
        <Avatar src={logo} mr="4" background="white" />
        <Box flex="1">
          <Text fontFamily="heading" fontSize="lg" fontWeight="bold" display="flex" alignItems="center">
            {name}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

const CompanyHome: React.FC<RouteComponentProps<{ type: string }>> = ({ type = 'business' }) => {
  const [companyView, setCompanyView] = useState(localStorage.getItem('companyView') || 'card');
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const { user } = useContext(UserContext);
  // const { toggleSpinner } = useContext(SpinnerContext);
  const { loading, error, data, refetch } = useQuery(GET_COMPANIES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      orderBy: 'createdAt_DESC',
      first: 51,
      skip: 0,
      where: {
        type_in: type === 'business' ? ['DROPPOINTS'] : ['BOOKING', 'COURIER', 'CLICKNCOLLECT'],
      },
      invitationOrderBy: 'createdAt_DESC',
      invitationWhere: {
        companyType_in: type === 'business' ? ['DROPPOINTS'] : ['BOOKING', 'COURIER', 'CLICKNCOLLECT'],
      },
    } as any,
  });

  const switchCompanyView = (type: 'card' | 'table') => {
    localStorage.setItem('companyView', type);

    setCompanyView(type);
  };

  const CompanyComponent = companyView === 'card' ? CompanyCard : CompanyTableRow;

  return (
    <Layout>
      <Flex flexDirection={['column', 'row']} alignItems="center" justifyContent="space-between" mb="6">
        <Text as="h2" fontSize={['2xl', '4xl']} fontWeight="bold" flex="1" fontFamily="heading">
          Unternehmensübersicht
        </Text>
      </Flex>
      <Flex justifyContent="space-between" alignItems="center" mb="4" flexWrap="wrap">
        <Box>
          <Button size="sm" onClick={onOpen}>
            Unternehmen einladen
          </Button>
        </Box>
        <Box>
          <IconButton
            variant="outline"
            size="sm"
            aria-label={companyView === 'card' ? 'Switch to Table' : 'Switch to Card'}
            icon={companyView === 'card' ? <IoIosMenu /> : <IoIosKeypad />}
            onClick={() => {
              switchCompanyView(companyView === 'card' ? 'table' : 'card');
            }}
          />
        </Box>
      </Flex>
      {error ? (
        <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
          Ein Fehler ist aufgetreten. Schreib Osamah an.
        </Flex>
      ) : null}
      {loading ? (
        <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
          <Spinner />
        </Flex>
      ) : null}
      {data && data.companies ? (
        <>
          <Tabs>
            <TabList>
              <Tab>Unternehmen</Tab>
              <Tab>History</Tab>
            </TabList>
            <TabPanels>
              <TabPanel py="3" px="0">
                <Grid gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr 1fr']} gap={companyView === 'card' ? '16px' : 0} mb="8">
                  {data.companies.length === 0 ? (
                    <Text gridColumn="span 3" p="4" textAlign="center" backgroundColor="red.100" color="red.800" borderRadius="4px">
                      Der Widerstand versteckt sich gut und konnte nicht gefunden werden. Wiederhole die Suche oder schicke Boba Fett!
                      (Keine Unternehmen gefunden)
                    </Text>
                  ) : (
                    <>
                      {data.companies.map((company) => {
                        return <CompanyComponent key={company.id} {...company} />;
                      })}
                    </>
                  )}
                </Grid>
              </TabPanel>
              <TabPanel py="3" px="0">
                <HistoryTab invitations={data.companyInvitations} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      ) : null}
      <div></div>
      <InviteCompanyModal isOpen={isOpen} onClose={onClose} refetch={refetch} />
    </Layout>
  );
};

export const CompaniesRoute: React.FC<RouteComponentProps> = () => {
  return (
    <Router>
      <CompanyHome path="/" />
      <CompanyHome path="/business" type="business" />
      <CompanyHome path="/sprint" type="sprint" />
      <CompanyDetails path="/:companyId" />
    </Router>
  );
};
