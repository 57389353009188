import React, { useContext } from 'react';
import { Redirect, RouteComponentProps, Router } from '@reach/router';

import UserContext from './contexts/UserContext';
import LoginHome from './routes/LoginHome';
import HomeDashboard from './routes/HomeDashboard';
import { Flex, Spinner } from '@chakra-ui/react';
import 'react-quill/dist/quill.snow.css';

const RedirectRoute: React.FC<RouteComponentProps> = () => <Redirect to="/" noThrow />;

const App = () => {
  const { user, loading } = useContext(UserContext);

  if (loading) {
    return (
      <Flex width="100vw" height="100vh" bg="gray.100" justifyContent="center" alignItems="center">
        <Spinner thickness="4px" speed="0.65s" size="xl" />
      </Flex>
    );
  }

  if (user) {
    return (
      <Router>
        <HomeDashboard path="*" />
      </Router>
    );
  }

  return (
    <Router>
      <LoginHome path="/" />
      <RedirectRoute path="*" />
    </Router>
  );
};

export default App;
