import gql from 'graphql-tag';

export const GET_COUPONS = gql`
  query GET_COUPONS($where: CompanyCouponWhereInput, $orderBy: CompanyCouponOrderByInput, $first: Int) {
    companyCoupons(where: $where, orderBy: $orderBy, first: $first) {
      id
      code
      type
      amount
      unit
      uses
      companies {
        id
      }
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_COUPON = gql`
  mutation CREATE_COUPON($data: CompanyCouponCreateInput!) {
    createCompanyCoupon(data: $data) {
      id
    }
  }
`;

export const UPDATE_COUPON = gql`
  mutation UPDATE_COUPON($data: CompanyCouponUpdateInput!, $where: CompanyCouponWhereUniqueInput!) {
    updateCompanyCoupon(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_COUPON = gql`
  mutation DELETE_COUPON($where: CompanyCouponWhereUniqueInput!) {
    deleteCompanyCoupon(where: $where) {
      id
    }
  }
`;
