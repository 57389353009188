import { useEffect, useReducer } from 'react';
import { Flex, Grid, Button, useColorMode, Tr, Table, Thead, Th, Tbody, useDisclosure } from '@chakra-ui/react';
import useMedia from 'use-media';
import { navigate } from '@reach/router';

import { StatusCanceledModal } from './modals/StatusCanceledModal';
import { StatusExpiredModal } from './modals/StatusExpiredModal';

import OrderDrawer from './OrderDrawer';
import OrderCard from './OrderCard';
import { OrderRow } from './OrderRow';

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_FILTERS':
      return { ...state, filters: action.payload, currentPage: 1 };
    case 'SET_WHERE':
      return { ...state, where: action.payload, currentPage: 1 };
    case 'SET_PAGE':
      return { ...state, currentPage: action.payload };
    case 'OPEN_DRAWER':
      return { ...state, orderId: action.orderId, stationId: action.stationId };
    case 'CLOSE_DRAWER':
      return { ...state, orderId: '', stationId: '' };
    case 'OPEN_MODAL':
      return { ...state, orderId: action.payload };
    case 'CLOSE_MODAL':
      return { ...state, orderId: '' };
    default:
      return state;
  }
};
const initialState = {
  filters: [],
  where: {},
  currentPage: 1,
  orderId: '',
  stationId: '',
};

export const OrderTable = ({ orders, totalCount, location, refetch, fetchMore }) => {
  const urlParams = new URLSearchParams(location.search);
  const isWide = useMedia({ minWidth: 1264 });
  const { colorMode } = useColorMode();
  const { isOpen: isOpenSetCanceledStatusModal, onOpen: openSetCanceledStatusModal, onClose: closeSetCanceledModal } = useDisclosure();
  const { isOpen: isOpenSetExpiredStatusModal, onOpen: openSetExpiredStatusModal, onClose: closeSetExpiredModal } = useDisclosure();
  const { isOpen: isOpenDrawer, onOpen: openDrawer, onClose: closeDrawer } = useDisclosure();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (urlParams.has('orderId') && urlParams.has('stationId')) {
      dispatch({ type: 'OPEN_DRAWER', orderId: urlParams.get('orderId'), stationId: urlParams.get('stationId') });
      openDrawer();
    }
  }, [location]);

  const handleDrawerOpen = (id, stationId) => {
    dispatch({ type: 'OPEN_DRAWER', stationId, orderId: id });
    openDrawer();
    urlParams.set('orderId', id);
    urlParams.set('stationId', stationId);
    navigate(`${location.pathname}?${urlParams.toString()}`);
  };
  const handleDrawerClose = () => {
    dispatch({ type: 'CLOSE_DRAWER' });
    closeDrawer();
    urlParams.delete('orderId');
    navigate(`${location.pathname}`);
  };

  const openModal = (id, typ) => {
    if (typ === 'cancel') {
      dispatch({ type: 'OPEN_MODAL', payload: id });
      openSetCanceledStatusModal();
    }
    if (typ === 'expire') {
      dispatch({ type: 'OPEN_MODAL', payload: id });
      openSetExpiredStatusModal();
    }
  };

  const closeModal = () => {
    dispatch({ type: 'CLOSE_MODAL', payload: '' });
    closeSetCanceledModal();
    closeSetExpiredModal();
  };

  return (
    <div>
      {isWide ? (
        <Table size="sm" alignItems="center">
          <Thead>
            <Tr backgroundColor={colorMode === 'light' ? 'gray.100' : 'gray.700'}>
              <Th width="180px" px="2">
                Gebucht am
              </Th>
              <Th px="2">Besteller</Th>
              <Th px="2">DropPoint</Th>
              <Th px="2">Abholer Bewertung </Th>
              <Th>Droppoint Bewertung </Th>
              <Th width="60px" />
            </Tr>
          </Thead>

          <Tbody>
            {orders?.length > 0
              ? orders.map((order) => {
                  return (
                    <OrderRow
                      key={'OrderRow' + order?.id}
                      {...order}
                      refetch={refetch}
                      onDrawerOpen={handleDrawerOpen}
                      openModal={openModal}
                      closeModal={closeModal}
                    />
                  );
                })
              : null}
          </Tbody>
        </Table>
      ) : (
        <>
          <Grid gridTemplateColumns={['1fr', '1fr', '1fr 1fr']} gap="4">
            {orders?.length > 0 ? (
              <>
                {orders?.map((order) => {
                  return (
                    <OrderCard
                      mb="2"
                      key={'OrderCard' + order?.id}
                      {...order}
                      refetch={refetch}
                      onDrawerOpen={handleDrawerOpen}
                      orderId={state.orderId}
                      openModal={openModal}
                      closeModal={closeModal}
                    />
                  );
                })}
              </>
            ) : null}
          </Grid>
        </>
      )}
      {totalCount > orders.length ? (
        <Flex width="100%" justifyContent="center" mb="8" pt="6" my="2">
          <Button
            size="sm"
            onClick={() =>
              fetchMore({
                variables: {
                  skip: orders?.length,
                },
                updateQuery: (prev: any, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  return Object.assign({}, prev, {
                    orders: [...prev.orders, ...fetchMoreResult.orders],
                  });
                },
              })
            }>
            Mehr laden
          </Button>
        </Flex>
      ) : null}

      <OrderDrawer
        key={state.orderId}
        isOpen={isOpenDrawer}
        onClose={handleDrawerClose}
        refetch={refetch}
        orderId={state.orderId}
        stationId={state.stationId}
        totalAmount={orders}
        handleDrawerOpen={handleDrawerOpen}
      />
      <StatusCanceledModal isOpen={isOpenSetCanceledStatusModal} onClose={closeModal} refetch={refetch} orderId={state.orderId} />
      <StatusExpiredModal isOpen={isOpenSetExpiredStatusModal} onClose={closeModal} refetch={refetch} orderId={state.orderId} />
    </div>
  );
};
