import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalHeader, ModalBody, ModalFooter, Button, Grid } from '@chakra-ui/react';
import { Formik, Form } from 'formik';

import Input from '../../../components/Input';

const SearchModal = ({ isOpen, onClose, refetch, dispatch }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>User suchen</ModalHeader>

        <Formik
          initialValues={{
            email: '',
            firstName: '',
            lastName: '',
            street: '',
            houseNumber: '',
            postalCode: '',
            city: '',
          }}
          onSubmit={async (values) => {
            const { email, firstName, lastName, street, houseNumber, postalCode, city } = values;

            let where = {} as any;
            if (email) {
              where = {
                ...where,
                email_contains: email,
              };
            }
            if (firstName) {
              where = {
                ...where,
                firstName_contains: firstName,
              };
            }
            if (lastName) {
              where = {
                ...where,
                lastName_contains: lastName,
              };
            }
            if (street) {
              where = {
                ...where,
                address: {
                  ...where.address,
                  street_contains: street,
                },
              };
            }
            if (houseNumber) {
              where = {
                ...where,
                address: {
                  ...where.address,
                  houseNumber_contains: houseNumber,
                },
              };
            }
            if (postalCode) {
              where = {
                ...where,
                address: {
                  ...where.address,
                  postalCode_contains: postalCode,
                },
              };
            }
            if (city) {
              where = {
                ...where,
                address: {
                  ...where.address,
                  city_contains: city,
                },
              };
            }
            console.log(where);

            await refetch({
              where,
            });
            dispatch({ type: 'SET_SEARCH_VALUES', value: where });
            onClose();
          }}>
          {({ values, handleChange }) => (
            <Form>
              <ModalBody>
                <Input name="email" label="Email-Adresse" />
                <Grid gridTemplateColumns="1fr 1fr" gridGap="24px">
                  <Input name="firstName" label="Vorname" />
                  <Input name="lastName" label="Nachname" />
                </Grid>
                <Grid gridTemplateColumns="2fr 1fr" gridGap="24px">
                  <Input name="street" label="Straße" />
                  <Input name="houseNumber" label="Hausnummer" />
                </Grid>
                <Grid gridTemplateColumns="1fr 1fr" gridGap="24px">
                  <Input name="postalCode" label="PLZ" />
                  <Input name="city" label="Stadt" />
                </Grid>
              </ModalBody>

              <ModalFooter>
                <Button variant="ghost" size="sm" colorScheme="red" type="button" onClick={onClose}>
                  Schließen
                </Button>
                <Button ml="4" size="sm" colorScheme="brand" type="submit">
                  Speichern
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};

export default SearchModal;
