import React, { useContext, useRef } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ButtonGroup,
  Box,
  Flex,
  Text,
  FormLabel,
} from '@chakra-ui/react';
import get from 'lodash/get';
import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { HiOutlineLink } from 'react-icons/hi';

import SpinnerContext from '../../../contexts/SpinnerContext';
import UserContext from '../../../contexts/UserContext';
import Input from '../../Input';
import { useCustomToast } from '../../../hooks/useCustomToast';
import { s3 } from '../../../utils';
import { getEnvironment } from '../../../environment';
import { SEND_CUSTOMER_EMAIL } from '../query';
import { QuillInput } from '../../QuillInput';
import { SingleCheckbox } from '../../Checkbox';

const { dOSpacesBucketName, dOCdnUrl } = getEnvironment();

const SendEmailSchema = Yup.object().shape({
  subject: Yup.string().required('Du musst einen Betreff für Deine Email angeben.'),
  content: Yup.string().required('Du musst einen Inhalt für Deine Email angeben.'),
});

const SendEmailModal = ({ refetch, customer, internalUser, isOpen, onClose }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [sendCustomerEmail] = useMutation(SEND_CUSTOMER_EMAIL);
  const { toggleSpinner } = useContext(SpinnerContext);
  const { user, checkAccess } = useContext(UserContext);
  const toast = useCustomToast();

  if (customer?.productsOffered != null && typeof customer?.productsOffered == 'object') {
    var keys = Object.keys(customer?.productsOffered);

    var filtered = keys.filter(function (key) {
      return customer?.productsOffered[key];
    });
    filtered.shift();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <Formik
          validationSchema={SendEmailSchema}
          initialValues={{ content: '', subject: '', salesInCC: false, sendNumber: false, files: [], fileUploadBlobs: [] }}
          onSubmit={async ({ content, salesInCC, sendNumber, fileUploadBlobs, subject }) => {
            toggleSpinner(true);
            try {
              let filesToConnect = [];
              if (fileUploadBlobs) {
                let fileUploadPromises = Array.from(fileUploadBlobs).map((fileBlob, i) => {
                  const index = fileBlob.name.lastIndexOf('.');
                  const extension = fileBlob.name.substring(index + 1);
                  const Key = `notes/${customer?.id}-${i}-${moment().unix()}.${extension}`;

                  const photoUrl = `https://${dOSpacesBucketName}.${dOCdnUrl}/${Key}`;
                  filesToConnect.push(photoUrl);

                  return s3
                    .putObject({
                      Body: fileBlob,
                      Bucket: dOSpacesBucketName,
                      Key,
                      ACL: 'public-read',
                    })
                    .promise();
                });
                await Promise.all(fileUploadPromises);
              }

              await sendCustomerEmail({
                variables: {
                  sendNumber,
                  data: {
                    subject,
                    content,
                    salesInCC,
                    customer: { connect: { id: customer?.id } },
                    sender: {
                      connect: { id: user?.id },
                    },
                    connectedFiles:
                      filesToConnect.length > 0
                        ? {
                            create: filesToConnect.map((uri, index) => {
                              return {
                                uri,
                                name: fileUploadBlobs[index].name,
                                type: fileUploadBlobs[index].type,
                              };
                            }),
                          }
                        : undefined,
                  },
                },
              });
              await refetch();
              toast({
                description: 'E-Mail wurde erfolgreich versendet 🎉 ',
                status: 'success',
                duration: 3000,
                isClosable: false,
              });
              onClose();
            } catch (error) {
              console.log(error);

              toast({
                description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
                status: 'error',
              });
            }
            toggleSpinner(false);
          }}>
          {({ setFieldValue, values, handleSubmit }) => {
            return (
              <Form>
                <ModalHeader>Neue E-Mail</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Box mb="5">
                    <Text fontSize="sm" fontStyle="italic">
                      {customer?.contactName ? `Kundenkontakt: ${customer?.contactName} / ` : null}
                      {customer?.contactProfession ? `Jobbezeichnung: ${customer?.contactProfession} /` : null}
                      {filtered ? `Angebotene(s) Produkt(e): ${filtered?.join(', ')}` : null}
                    </Text>
                  </Box>

                  <Input label="Betreff" as="input" type="text" name="subject" placeholder="Gib hier Deinen Betreff ein" />
                  {checkAccess('SALES_IN_CC') ? (
                    <SingleCheckbox name="salesInCC" label="Soll sales@dropfriends.com ins CC hinzugefügt werden?" />
                  ) : null}

                  <QuillInput label="Deine E-Mail" name="content" />

                  {internalUser?.phoneNumber ? <SingleCheckbox name="sendNumber" label="Telefonnummer in der Signatur hinzufügen" /> : null}

                  <Box>
                    <Button
                      mt="5"
                      type="button"
                      size="sm"
                      onClick={() => {
                        fileInputRef && fileInputRef.current && fileInputRef.current?.click();
                      }}>
                      Dateien hochladen
                    </Button>
                    <input
                      ref={fileInputRef}
                      hidden={true}
                      type="file"
                      name="files"
                      multiple
                      onChange={async (event) => {
                        let eventFiles = event.target.files;
                        let fileUploadBlobs = [...values.fileUploadBlobs, ...Array.from(eventFiles)];
                        let uploadedFiles = Array.from(event.target.files).map((file) => {
                          let reader = new FileReader();

                          return new Promise((resolve) => {
                            // Resolve the promise after reading file
                            reader.onload = () => resolve(reader.result);
                            // Read the file as a text
                            reader.readAsDataURL(file);
                          });
                        });
                        let res = await Promise.all(uploadedFiles);
                        setFieldValue('files', [...values.files, ...res]);
                        setFieldValue('fileUploadBlobs', fileUploadBlobs);
                      }}
                    />
                  </Box>
                  {values.fileUploadBlobs.length > 0 ? (
                    <Flex direction="column" my="4">
                      <FormLabel>Anhänge</FormLabel>
                      {values.fileUploadBlobs.map(({ name }, index) => {
                        return (
                          <Flex key={`file-${index}`} mb="2">
                            <HiOutlineLink />
                            <Text flex="1" fontSize="sm">
                              {name}
                            </Text>
                            <Button
                              size="xs"
                              variant="ghost"
                              colorScheme="red"
                              onClick={() => {
                                const clone1 = [...values.files];
                                const clone2 = [...values.fileUploadBlobs];

                                clone1.splice(index, 1);
                                clone2.splice(index, 1);

                                setFieldValue('files', clone1);
                                setFieldValue('fileUploadBlobs', clone2);
                              }}>
                              Entfernen
                            </Button>
                          </Flex>
                        );
                      })}
                    </Flex>
                  ) : null}
                </ModalBody>

                <ModalFooter>
                  <ButtonGroup size="sm">
                    <Button onClick={() => handleSubmit()} colorScheme="green">
                      Fertig
                    </Button>
                  </ButtonGroup>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default SendEmailModal;
