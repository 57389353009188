export const getStatusText = (status: string) => {
  if (status === 'OPEN') {
    return 'Offen';
  }
  if (status === 'INPROGRESS') {
    return 'Kontaktiert';
  }
  if (status === 'PARKED') {
    return 'Pausiert';
  }

  if (status === 'DONE') {
    return 'Abgeschlossen';
  }
  if (status === 'FAILURE') {
    return 'Abgebrochen';
  }
  if (status === 'CONTACT_AGAIN') {
    return 'Nachhaken';
  }
  return 'Sonstiges';
};

export const getVariant = (status) => {
  if (status === 'OPEN') return 'blue';
  if (status === 'INPROGRESS') return 'yellow';
  if (status === 'PARKED') return 'orange';
  if (status === 'DONE') return 'green';
  if (status === 'FAILURE') return 'red';
  if (status === 'CONTACT_AGAIN') return 'orange';
  return 'gray';
};
