import React, { useContext } from 'react';
import { Button, Modal, ModalOverlay, ModalHeader, ModalCloseButton, ModalBody, ModalContent, ModalFooter } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import get from 'lodash/get';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';

import SpinnerContext from '../../../contexts/SpinnerContext';
import { useCustomToast } from '../../../hooks/useCustomToast';
import UserContext from '../../../contexts/UserContext';
import Input from '../../Input';
import Select from '../../Select';
import { UPDATE_ORDER } from '../query';

const EditStatusSchema = Yup.object().shape({
  comment: Yup.string().required('Du musst einen Kommentar hinterlegen'),
});

let statusOptions = [
  { label: 'Abgebrochen ⏬   ', value: 'CANCELED' },
  { label: 'Expired ⏰   ', value: 'EXPIRED' },
];

const EditStatusModal = ({ refetch, isOpen, onClose, orderId, status }) => {
  const { toggleSpinner } = useContext(SpinnerContext);
  const { user } = useContext(UserContext);
  const toast = useCustomToast();
  const [updateOrderStatus] = useMutation(UPDATE_ORDER);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Status bearbeiten</ModalHeader>
          <Formik
            initialValues={{
              orderId,
              internalUser: user.id,
              comment: '',
              status,
            }}
            validationSchema={EditStatusSchema}
            onSubmit={async ({ status, orderId, comment, internalUser }) => {
              try {
                toggleSpinner(true);

                await updateOrderStatus({
                  variables: {
                    orderId,
                    internalUser,
                    comment: `${moment().format('DD/MM/YYYY')} | ${status}, ${user.firstName} ${user.lastName}`,
                    status,
                  },
                });

                await refetch();

                toast({
                  title: 'Erfolg!',
                  description: 'Status wurde erfolgreich geändert',
                  status: 'success',
                  isClosable: false,
                  duration: 3000,
                });
                toggleSpinner(false);
                onClose();
              } catch (error) {
                console.log(error);
                toast({
                  description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
                  status: 'error',
                });
                toggleSpinner(false);
              }
            }}>
            {({ isValid, dirty }) => (
              <Form>
                <ModalBody>
                  <Select options={statusOptions} label="Status" name="status" />
                  <Input
                    label="Warum möchtest Du den Status ändern?"
                    as="textarea"
                    type="text"
                    name="comment"
                    height="100px"
                    placeholder="Gib hier Deinen Kommentar ein"
                  />
                </ModalBody>

                <ModalFooter>
                  <Button variant="ghost" size="sm" colorScheme="red" type="button" onClick={onClose}>
                    Abbrechen
                  </Button>

                  <Button disabled={!(isValid && dirty)} ml="4" size="sm" colorScheme="brand" type="submit">
                    Status ändern
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditStatusModal;
