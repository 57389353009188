import { Box, Flex, Spinner, Button } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';

import { OrderTable } from '../../../components/OrderTable';
import { GET_ORDERS } from '../query';

const Orders = ({ userId, location }) => {
  const { loading, error, data, refetch, fetchMore } = useQuery(GET_ORDERS, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        orderer: {
          id: userId,
        },
      },
      orderBy: 'createdAt_DESC',
    },
  });

  if (loading) {
    return (
      <Box px="4" py="8">
        <Flex width="100%" height="300px" justifyContent="center" alignItems="center">
          <Spinner />
        </Flex>
      </Box>
    );
  }

  if (error) {
    return (
      <Box px="4" py="8">
        <Flex width="100%" height="300px" justifyContent="center" alignItems="center">
          Ein Fehler ist aufgetreten. Schreib Osamah an.
        </Flex>
      </Box>
    );
  }

  return (
    <Box borderLeftWidth="1px" borderRightWidth="1px" borderBottomWidth="1px" px="6" py="8">
      <Flex mb="3">
        <Button colorScheme="brand" onClick={() => refetch()} size="xs">
          Neu laden
        </Button>
      </Flex>
      {data?.orders ? (
        <OrderTable orders={data?.orders} totalCount={data?.totalOrdersCount} refetch={refetch} fetchMore={fetchMore} location={location} />
      ) : null}
    </Box>
  );
};

export default Orders;
