import React, { useContext, useState, useRef } from 'react';
import { navigate } from '@reach/router';
import { useMutation } from '@apollo/client';
import {
  Text,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import get from 'lodash/get';

import { useUser } from '../../../contexts/UserContext';
import SpinnerContext from '../../../contexts/SpinnerContext';
import { SEND_ACTIVITY_CHECK } from '../query';
import { useCustomToast } from '../../../hooks/useCustomToast';

export const ActivityCheck = ({ where, multipleUsers = false, user: { email }, refetch }) => {
  const { user } = useUser();
  const [alertOpen, setAlertOpen] = useState(false);
  const [sendActivityCheckMutation] = useMutation(SEND_ACTIVITY_CHECK);
  const onAlertClose = () => setAlertOpen(false);
  const cancelRef = useRef();
  const { toggleSpinner } = useContext(SpinnerContext);
  const toast = useCustomToast();

  const handleActivityCheckEmail = async () => {
    try {
      toggleSpinner(true);

      await sendActivityCheckMutation({
        variables: {
          internalUserId: user.id,
          where,
        },
      });

      await refetch();
      toast({
        title: 'Erfolg!',
        description: multipleUsers ? 'Aktivitätscheck an versendet.' : `Aktivitätscheck an ${email} versendet.`,
        status: 'success',
      });
    } catch (error) {
      console.log(error);
      toast({
        description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
        status: 'error',
      });
    }
    setAlertOpen(false);
    toggleSpinner(false);
  };

  return (
    <>
      <Button variant="outline" colorScheme="green" size="xs" mr="2" mb="2" onClick={() => setAlertOpen(true)}>
        Aktivitätscheck absenden
      </Button>

      <AlertDialog isOpen={alertOpen} leastDestructiveRef={cancelRef} onClose={onAlertClose}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Bist Du sicher?
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text mb="4">
              {multipleUsers
                ? 'Möchtest du eine Aktivitätscheck E-mail an die angezeigten User senden?'
                : `Möchtest du eine Aktivitätscheck E-mail an ${email} senden?`}
            </Text>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onAlertClose}>
              Abbrechen
            </Button>
            <Button colorScheme="orange" onClick={handleActivityCheckEmail} ml={3}>
              Aktivitätscheck senden
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
