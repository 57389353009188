import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Flex,
  Grid,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Spinner,
  Stack,
  Tag,
  Text,
} from '@chakra-ui/react';
import { Pencil, Plus, Trash, X } from '@phosphor-icons/react';
import { RouteComponentProps } from '@reach/router';
import GoogleMapReact from 'google-map-react';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import Layout from '../../components/Layout';
import { useCustomToast } from '../../hooks/useCustomToast';
import { useSpinner } from '../../contexts/SpinnerContext';
import { MarkerCard } from './MarkerCard';
import { CREATE_MARKER, GET_MARKERS, DELETE_MARKER, SYNC_OLDEST_MARKERS } from './query';

interface DHLProps extends RouteComponentProps {}

const initialState = {
  mapApis: null,
  map: null,
  mapCircles: [],
  selected: '',
  settingNewMarker: false,
  newMarker: null,
  radius: 1000,
  note: '',
};

type PageState = typeof initialState;

const reducer = (state: PageState, action): PageState => {
  switch (action.type) {
    case 'SET_MAP_CIRCLES':
      return { ...state, mapCircles: action.mapCircles };
    case 'SET_MAP':
      return { ...state, map: action.map, mapApis: action.mapApis };
    case 'SELECT_MARKER':
      return {
        ...state,
        selected: action.selected === state.selected ? '' : action.selected,
        settingNewMarker: false,
        newMarker: null,
        radius: 1000,
        note: '',
      };
    case 'NEW_MARKER':
      return { ...state, selected: '', settingNewMarker: true };
    case 'CLICK_MARKER':
      return { ...state, newMarker: action.newMarker };
    case 'CANCEL_NEW_MARKER':
      return { ...state, selected: '', settingNewMarker: false, newMarker: null, radius: 1000, note: '' };
    case 'SET_RADIUS':
      return { ...state, radius: action.radius };
    case 'SET_NOTE':
      return { ...state, note: action.note };
    default:
      return state;
  }
};
interface Location {
  latitude: number;
  longitude: number;
}
type Boundaries = {
  southWest: Location;
  northEast: Location;
};

export const DHLPage: React.FC<DHLProps> = () => {
  const [boundaries, setBoundaries] = useState<Boundaries>();
  const [orderBy, setOrderBy] = useState('createdAt_DESC');
  const { loading, data, refetch, fetchMore } = useQuery(GET_MARKERS, {
    variables: { mapBoundaries: boundaries, orderBy },
    skip: !boundaries,
  });
  const [createDhlMarker] = useMutation(CREATE_MARKER);
  const [deleteDhlMarker] = useMutation(DELETE_MARKER);
  const [syncOldestMarkers] = useMutation(SYNC_OLDEST_MARKERS);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { toggleSpinner } = useSpinner();
  const toast = useCustomToast();
  const mapRef = useRef<any>();

  useEffect(() => {
    if (!loading && data && state.mapApis) {
      if (data.getMarkersOnMap.length > 0 || state.newMarker) {
        const markers = [state.newMarker ? { ...state.newMarker, radius: state.radius } : null, ...data.getMarkersOnMap].filter(Boolean);

        state.mapCircles.forEach(({ circle }) => circle.setMap(null)); // setMap(null) removes the shapes from map

        dispatch({
          type: 'SET_MAP_CIRCLES',
          mapCircles: markers.map(({ id, latitude, longitude, radius }) => {
            const isSelected = id === state.selected;

            return {
              id,
              circle: new state.mapApis.Circle({
                strokeColor: '#0000ff',
                strokeOpacity: isSelected ? 0.5 : 0.2,
                strokeWeight: 2,
                fillColor: '#0000ff',
                fillOpacity: isSelected ? 0.2 : 0.15,
                map: state.map,
                center: { lat: latitude, lng: longitude },
                radius,
              }),
            };
          }),
        });
      }
    }
  }, [loading, data, state.mapApis, state.selected, state.map, state.radius, state.newMarker]);

  useEffect(() => {
    if (state.map) {
      const handleEvent = () => {
        const bounds = state.map.getBounds();
        const southWest = bounds.getSouthWest();
        const northEast = bounds.getNorthEast();
        if (southWest && northEast) {
          setBoundaries({
            northEast: {
              longitude: northEast.lng(),
              latitude: northEast.lat(),
            },
            southWest: {
              longitude: southWest.lng(),
              latitude: southWest.lat(),
            },
          });
        }
      };
      state.map.addListener('idle', handleEvent);
      handleEvent();
    }
  }, [state.map]);

  const createMarker = async () => {
    try {
      toggleSpinner(true);
      const { latitude, longitude } = state.newMarker;
      const lat = parseFloat(latitude.toFixed(8));
      const long = parseFloat(longitude.toFixed(8));
      await createDhlMarker({
        variables: {
          data: {
            latitude: lat,
            longitude: long,
            radius: state.radius,
            location: {
              create: {
                type: 'Point',
                coordinates: { set: [long, lat] },
              },
            },
            note: state.note,
          },
        },
      });
      await refetch();
      dispatch({ type: 'CANCEL_NEW_MARKER' });
    } catch (error) {
      console.log(error);
    }
    toggleSpinner(false);
  };

  const deleteMarker = async (id) => {
    try {
      toggleSpinner(true);
      await deleteDhlMarker({
        variables: {
          where: {
            id,
          },
        },
      });
      await refetch();
      dispatch({ type: 'CANCEL_NEW_MARKER' });
    } catch (error) {
      console.log(error);
    }
    toggleSpinner(false);
  };

  const syncMarkers = async (id) => {
    try {
      toggleSpinner(true);
      const { data } = await syncOldestMarkers();
      console.log(data.syncOldestDhlMarkers);
      await refetch();

      if (data.syncOldestDhlMarkers === 'success') {
        toast({
          title: 'Erfolg!',
          description: 'Die ältesten 500 Marker wurden aktualisiert',
          status: 'success',
        });
      } else {
        toast({
          title: 'Warnung!',
          description: data.syncOldestDhlMarkers,
          status: 'warning',
        });
      }
    } catch (error) {
      toast({
        title: 'Ein Fehler ist aufgetreten!',
        description: error.message,
        status: 'error',
      });
      console.log(error);
    }
    toggleSpinner(false);
  };

  const syncAll = async () => {};

  return (
    <Box width="100%">
      <Flex mb="4">
        <Layout fullWidth>
          <Flex flexDirection={['column', 'row']} justifyContent="space-between" alignItems="center" mb="8">
            <Box>
              <Text as="h2" fontSize="4xl" flex="1" fontWeight="bold" fontFamily="heading">
                DHL Zonen
              </Text>
              <Text>{data && data.totalDhlMarkerCount ? `(Aktuell insgesamt ${data.totalDhlMarkerCount} Zonen)` : '_'}</Text>
            </Box>
            <Stack direction="row" flexWrap="wrap" gap="4">
              <Button size="sm" colorScheme="brand" onClick={syncAll}>
                Alle Zonen synchronisieren
              </Button>
              <Button size="sm" colorScheme="brand" onClick={syncMarkers}>
                Ältesten Zonen synchronisieren
              </Button>
            </Stack>
          </Flex>
          {loading ? null : <Flex>{}</Flex>}
          <Grid gridTemplateColumns="1fr 350px" gap="4" height="800px">
            <Box width="full" height="800px" rounded="lg" overflow="hidden">
              <GoogleMapReact
                ref={mapRef}
                bootstrapURLKeys={{
                  key: 'AIzaSyDmz4rVVljmXRqI3ds42azmZeosUyN_P8o',
                  language: 'de',
                  region: 'de',
                }}
                onClick={({ lat, lng }) => {
                  if (state.settingNewMarker) {
                    dispatch({ type: 'CLICK_MARKER', newMarker: { latitude: lat, longitude: lng } });
                  }
                }}
                center={{ lat: 50.9182425, lng: 6.9842404 }}
                zoom={8}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => {
                  dispatch({ type: 'SET_MAP', map, mapApis: maps });
                }}
              />
            </Box>
            <Box>
              <Box mb="2" fontWeight="bold">
                Sortierung:
              </Box>
              <Grid gridTemplateColumns="1fr 1fr" gap="4" mb="4">
                <Tag
                  size="lg"
                  colorScheme={orderBy === 'createdAt_DESC' ? 'brand' : undefined}
                  onClick={() => setOrderBy('createdAt_DESC')}
                  cursor="pointer"
                  justifyContent="center">
                  Neueste
                </Tag>
                <Tag
                  size="lg"
                  colorScheme={orderBy === 'lastSync_DESC' ? 'brand' : undefined}
                  onClick={() => setOrderBy('lastSync_DESC')}
                  cursor="pointer"
                  justifyContent="center">
                  Zuletzt aktualisiert
                </Tag>
              </Grid>
              {loading ? (
                <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
                  <Spinner />
                </Flex>
              ) : data && data.getMarkersOnMap ? (
                <Flex direction="column" overflow="scroll">
                  <Flex
                    position="relative"
                    border="1px"
                    borderBottom="0"
                    alignItems="center"
                    justifyContent="center"
                    px="4"
                    py="6"
                    opacity={state.settingNewMarker ? '1' : '0.8'}
                    _first={{ borderTopRadius: 'md' }}
                    _last={{ borderBottomRadius: 'md', borderBottom: '1px' }}
                    _hover={{ opacity: 1 }}
                    cursor="pointer"
                    onClick={() => {
                      if (!state.settingNewMarker) {
                        dispatch({ type: 'NEW_MARKER' });
                      }
                    }}>
                    {state.settingNewMarker ? (
                      <Flex direction="column" gap="2">
                        <Box
                          as={X}
                          w="4"
                          h="4"
                          position="absolute"
                          top="1"
                          right="1"
                          onClick={() => {
                            dispatch({ type: 'CANCEL_NEW_MARKER' });
                          }}
                        />
                        <Flex width="full" gap="4">
                          <NumberInput
                            placeholder="radius"
                            value={state.radius}
                            onChange={(_, radius) => dispatch({ type: 'SET_RADIUS', radius })}
                            size="xs"
                            step={100}
                            max={1000000}
                            min={1000}>
                            <NumberInputField />
                            <NumberInputStepper>
                              <NumberIncrementStepper />
                              <NumberDecrementStepper />
                            </NumberInputStepper>
                          </NumberInput>
                          <Input
                            placeholder="Kommentar"
                            value={state.note}
                            onChange={(event) => dispatch({ type: 'SET_NOTE', note: event.target.value })}
                            size="xs"
                          />
                        </Flex>
                        <Flex justifyContent="space-between" gap="2" alignItems="center">
                          <Text fontSize="sm" fontWeight="semibold">
                            {state.newMarker
                              ? `Koordinaten: ${state.newMarker.latitude.toFixed(6)}, ${state.newMarker.longitude.toFixed(6)}`
                              : ''}
                          </Text>
                          <Button size="xs" colorScheme="green" isDisabled={!state.newMarker} onClick={() => createMarker()}>
                            Speichern
                          </Button>
                        </Flex>
                      </Flex>
                    ) : (
                      <>
                        <Box as={Plus} w="6" h="6" mr="1" />
                        <Text fontWeight="bold">Neue Zone</Text>
                      </>
                    )}
                  </Flex>

                  <Flex direction="column" overflow="scroll">
                    {data.getMarkersOnMap.map(({ id, longitude, latitude, radius, note, totalShops, syncedShops, lastSync }) => {
                      return (
                        <MarkerCard
                          key={id}
                          dispatch={dispatch}
                          isSelected={state.selected === id}
                          deleteMarker={deleteMarker}
                          {...{ id, longitude, latitude, radius, note, totalShops, syncedShops, lastSync }}
                        />
                      );
                    })}
                  </Flex>

                  {data.totalDhlMarkersInBounds > data.getMarkersOnMap.length ? (
                    <Flex justifyContent="center" alignItems="center" py="4">
                      <Button
                        onClick={() =>
                          fetchMore({
                            variables: {
                              skip: data.getMarkersOnMap.length,
                            },
                            updateQuery: (prev: any, { fetchMoreResult }) => {
                              if (!fetchMoreResult) return prev;
                              return Object.assign({}, prev, {
                                getMarkersOnMap: [...prev.getMarkersOnMap, ...fetchMoreResult.getMarkersOnMap],
                              });
                            },
                          })
                        }>
                        Mehr laden
                      </Button>
                    </Flex>
                  ) : null}
                </Flex>
              ) : (
                <div />
              )}
            </Box>
          </Grid>
        </Layout>
      </Flex>
    </Box>
  );
};
