import React, { useEffect } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { useQuery } from '@apollo/client';
import {
  Spinner,
  Flex,
  Text,
  Box,
  Badge,
  Button,
  Avatar,
  useDisclosure,
  useClipboard,
  Grid,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useColorMode,
} from '@chakra-ui/react';
import moment from 'moment';
import useMedia from 'use-media';
import get from 'lodash/get';
import { Link } from '@reach/router';

import Layout from '../../components/Layout';
import Advertisements from './Tabs/Advertisements';
import DropPoint from './Tabs/DropPoint';
import History from './Tabs/History';
import Orders from './Tabs/Orders';
import LockConfirmModal from './modals/LockConfirmModal';
import UnlockConfirmModal from './modals/UnlockConfirmModal';
import CancelUser from './actions/CancelUser';
import DeleteUser from './actions/DeleteUser';
import SendEmailToUser from './actions/SendEmail';
import { EditUser } from './actions/EditUser';
import { EditEmail } from './actions/EditEmail';
import { AddNewEntry } from './actions/AddNewEntry';

import { GET_USER, GET_ACCOUNT } from './query';
import { ActivityCheck } from './actions/ActivityCheck';

interface UserDetailsProps extends RouteComponentProps {
  userId?: string;
}

const IdCopy = ({ id, label }) => {
  const { onCopy, hasCopied } = useClipboard(id);

  return (
    <Flex alignItems="center" mb="2">
      <Text mr="4" flex="1">
        <strong>{label}</strong> {id}
      </Text>
      <Button variant="link" size="xs" onClick={onCopy} colorScheme="brand" fontWeight="normal" letterSpacing="0.5px">
        {hasCopied ? 'Kopiert' : 'Kopieren'}
      </Button>
    </Flex>
  );
};

const UserDetails: React.FC<UserDetailsProps> = ({ userId, location }) => {
  const { colorMode } = useColorMode();
  const isMobile = useMedia({ maxWidth: '767px' });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();
  const { isOpen: isOpenHistoryEntry, onOpen: onOpenHistoryEntry, onClose: onCloseHistoryEntry } = useDisclosure();
  const { loading, error, refetch, data } = useQuery(GET_USER, {
    variables: {
      where: {
        id: userId,
      },
    },
  });
  const { data: accountData, refetch: accountRefetch } = useQuery(GET_ACCOUNT, {
    variables: {
      stationId: get(data, 'user.packageStations.0.id', ''),
    },
    skip: !get(data, 'user.packageStations.0.id', ''),
  });

  useEffect(() => {
    if (get(data, 'user.packageStations.0.id', false) && !accountData) {
      accountRefetch({
        stationId: get(data, 'user.packageStations.0.id', ''),
      });
    }
  }, [data, accountData, accountRefetch]);

  const handleLock = async () => {
    onOpen();
  };

  const handleUnLock = async () => {
    onOpen2();
  };

  // This is needed because the History Tab also opens the Modal
  const handleHistoryEntry = async () => {
    onOpenHistoryEntry();
  };

  if (loading) {
    return (
      <Flex width="100%" height="300px" justifyContent="center" alignItems="center">
        <Spinner />
      </Flex>
    );
  }
  if (error) {
    return (
      <Flex width="100%" height="300px" justifyContent="center" alignItems="center">
        Ein Fehler ist aufgetreten. Schreib Osamah an.
      </Flex>
    );
  }

  const {
    id,
    createdAt,
    photo,
    firstName,
    lastName,
    email,
    birthdate,
    packageStations,
    emailVerified,
    accountLocked,
    lockReason,
    inDefault,
    defaultAmount,
    address,
    orders,
    acceptEmails,
    advertisements,
    companyRole,
    myCompany,
  } = data.user;
  const {
    id: dropPointId,
    isOnboarded,
    isActive,
    address: dropPointAddress,
    packages,
    lastActivityCheckSent,
    lastActivityCheckResponse,
    activityCheck,
    activityCheckSent,
    activityCheckToken,
    activityCheckAmount,
  } = packageStations && packageStations.length > 0 ? packageStations[0] : ({} as any);
  const hasActiveAdvertisement = advertisements.find(({ isActive }) => isActive);

  return (
    <Layout>
      <Box mb="8">
        <Button variant="link" onClick={() => window.history.go(-1)} size={isMobile ? 'xs' : 'sm'} mr="4">
          {'< Zurück'}
        </Button>
        <Button
          colorScheme="brand"
          onClick={() => {
            refetch();
            accountRefetch();
          }}
          size={isMobile ? 'xs' : 'sm'}>
          User Neu laden
        </Button>
      </Box>
      <Flex mb="4">
        <Avatar src={photo ? photo.uri : null} mr={isMobile ? '3' : '8'} size={isMobile ? 'md' : '2xl'} />
        <Box>
          <Flex mb="1" flexWrap="wrap">
            {accountLocked && lockReason === 'ACCOUNT_DISABLED' ? (
              <Badge colorScheme="red" mr="2" mb="1">
                Account gekündigt
              </Badge>
            ) : (
              <>
                {myCompany?.id ? (
                  <Badge
                    as={Link}
                    colorScheme="blue"
                    mr="2"
                    mb="1"
                    // @ts-ignore
                    to={`/companies/${myCompany.id}`}>
                    {myCompany.name}: {companyRole}
                  </Badge>
                ) : null}
                {accountLocked ? (
                  <Badge colorScheme="red" mr="2" mb="1">
                    Account gesperrt
                  </Badge>
                ) : null}
                {inDefault ? (
                  <Badge colorScheme="red" mr="2" mb="1">
                    Zahlungsrückstand: {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(defaultAmount / 100)}
                  </Badge>
                ) : null}
                {!myCompany?.id && !isOnboarded ? (
                  <Badge colorScheme="yellow" mr="2" mb="1">
                    DropPoint nicht erstellt
                  </Badge>
                ) : null}
                {!myCompany?.id && isOnboarded ? (
                  <Badge variant="solid" mr="2" colorScheme={isActive ? 'green' : 'red'} mb="1">
                    {isActive ? 'DropPoint aktiv' : 'DropPoint inaktiv'}
                  </Badge>
                ) : null}
                <Badge colorScheme={acceptEmails ? 'green' : 'orange'} mb="1" mr="2">
                  {acceptEmails ? 'Werbung gestattet' : 'Werbung unerwünscht'}
                </Badge>
                {advertisements.length > 0 ? (
                  <Badge variant="solid" colorScheme={hasActiveAdvertisement ? 'green' : 'yellow'} mb="1">
                    {hasActiveAdvertisement ? 'Ad online' : 'Ad offline'}
                  </Badge>
                ) : null}
                <Badge colorScheme="orange" mb="1">
                  {emailVerified ? 'Email verifiziert' : 'Email nicht verifiziert'}
                </Badge>
              </>
            )}
          </Flex>
          <Flex>
            <Text fontSize="3xl" fontFamily="heading">
              {firstName} {lastName}
            </Text>
          </Flex>
          <Text>{email}</Text>
          <Text fontSize="sm">Geboren am {moment(birthdate).format('DD.MM.YYYY')}</Text>
          <Text fontSize="sm" mb="4" color={colorMode === 'light' ? 'gray.700' : 'gray.300'}>
            Registriert seit {moment(createdAt).format('DD.MM.YYYY, HH:mm')}
          </Text>
          {!myCompany?.id && isOnboarded ? (
            <Box mb="4">
              <Text fontSize="sm" color={colorMode === 'light' ? 'gray.700' : 'gray.300'}>
                {activityCheckSent && activityCheck
                  ? 'Aktivitätsanfrage beantwortet'
                  : activityCheckSent && !activityCheck
                  ? 'Aktivitätsanfrage gesendet, aber noch nicht beantwortet'
                  : 'Aktivitätsanfrage noch nicht gesendet.'}
              </Text>
              <Text fontSize="sm" color={colorMode === 'light' ? 'gray.700' : 'gray.300'}>
                Letzte Aktivitätsanfrage gesendet:{' '}
                {lastActivityCheckSent ? moment(lastActivityCheckSent).format('DD.MM.YYYY, HH:mm') : 'Noch nicht gesendet'}
              </Text>
              <Text fontSize="sm" color={colorMode === 'light' ? 'gray.700' : 'gray.300'}>
                Letzte Rückmeldung zur Aktivität:{' '}
                {lastActivityCheckResponse ? moment(lastActivityCheckResponse).format('DD.MM.YYYY, HH:mm') : 'Noch nicht beantwortet'}
              </Text>
              <Text fontSize="sm" color={colorMode === 'light' ? 'gray.700' : 'gray.300'}>
                {activityCheckAmount || '0'} Aktivitätsanfragen gesendet
              </Text>
              {/*
          for testing purposes */}
              {process.env.NODE_ENV === 'development' ? (
                <Text fontSize="sm" mb="2" color="red.500">
                  activityCheckToken
                  <br />
                  {activityCheckToken}
                </Text>
              ) : null}
            </Box>
          ) : null}

          <Flex alignItems="center" flexWrap="wrap">
            {accountLocked && lockReason === 'ACCOUNT_DISABLED' ? null : (
              <>
                {isActive ? <ActivityCheck refetch={refetch} where={{ user: { id: data?.user.id } }} user={data?.user} /> : null}

                <EditUser refetch={refetch} user={data?.user} />

                <EditEmail refetch={refetch} userId={data?.user.id} email={email} />
                <AddNewEntry
                  refetch={refetch}
                  userId={data?.user.id}
                  onOpen={onOpenHistoryEntry}
                  onClose={onCloseHistoryEntry}
                  isOpen={isOpenHistoryEntry}
                />
                <SendEmailToUser refetch={refetch} user={data?.user} />
                {!emailVerified ? (
                  <Button variant="outline" colorScheme="brand" size="xs" mr="2" mb="2">
                    Verifizierungsemail senden
                  </Button>
                ) : null}
                {accountLocked ? (
                  <Button variant="outline" colorScheme="red" size="xs" mr="2" mb="2" onClick={handleUnLock}>
                    User entsperren
                  </Button>
                ) : (
                  <Button variant="outline" colorScheme="red" size="xs" mr="2" mb="2" onClick={handleLock}>
                    User sperren
                  </Button>
                )}
                {myCompany?.id ? null : <CancelUser refetch={refetch} user={data?.user} />}
                {/* {orders.length > 0 || packages?.length > 0 ? null : <DeleteUser user={data?.user} />} */}
              </>
            )}
          </Flex>
        </Box>
      </Flex>

      <IdCopy label="Besteller-ID:" id={id} />
      <IdCopy label="DropPoint-ID:" id={dropPointId} />
      <Grid templateColumns={['1fr', '1fr 1fr']} gridGap="8" my="8">
        {address ? (
          <Box>
            <Text fontWeight="bold">Rechnungsadresse:</Text>
            <Text>
              {address.street} {address.houseNumber}
            </Text>
            <Text>
              {address.postalCode} {address.city}
            </Text>
          </Box>
        ) : null}
        {dropPointAddress ? (
          <Box>
            <Text fontWeight="bold">DropPoint Adresse:</Text>
            <Text>
              {dropPointAddress.street} {dropPointAddress.houseNumber}
            </Text>
            <Text>
              {dropPointAddress.postalCode} {dropPointAddress.city}
            </Text>
            <Text>{get(accountData, 'retrieveAccount.phone')}</Text>
          </Box>
        ) : null}
      </Grid>
      <Tabs variant="line" colorScheme="brand">
        <TabList>
          {myCompany ? null : <Tab fontSize="sm">DropPoint</Tab>}
          {myCompany ? null : <Tab fontSize="sm">Besteller Verwaltung</Tab>}
          <Tab fontSize="sm">History</Tab>
          {myCompany ? null : <Tab fontSize="sm">Werbeverwaltung</Tab>}
        </TabList>
        <TabPanels>
          {myCompany ? null : (
            <TabPanel p="0">
              <DropPoint dropPointId={dropPointId} />
            </TabPanel>
          )}
          {myCompany ? null : (
            <TabPanel p="0">
              <Orders userId={userId} location={location} />
            </TabPanel>
          )}
          <TabPanel p="0">
            <History userId={userId} handleHistoryEntry={handleHistoryEntry} />
          </TabPanel>
          {myCompany ? null : (
            <TabPanel p="0">
              <Advertisements userId={userId} />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
      <LockConfirmModal isOpen={isOpen} onClose={onClose} refetch={refetch} userId={userId} />
      <UnlockConfirmModal isOpen={isOpen2} onClose={onClose2} refetch={refetch} userId={userId} />
    </Layout>
  );
};

export default UserDetails;
