import React, { useState } from 'react';
import { Box, Flex, Spinner, Grid, useDisclosure, Text } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';

import EditAdvertisementModal from '../../Advertisements/EditAdvertisement';
import AdvertisementCard from '../../Advertisements/AdvertisementCard';
import { GET_ADVERTISEMENTS } from '../query';

const Advertisements = ({ userId }) => {
  const { loading, error, data, refetch } = useQuery(GET_ADVERTISEMENTS, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        user: {
          id: userId,
        },
      },
      orderBy: 'createdAt_DESC',
      first: 50,
      skip: 0,
    },
  });
  const [advertisement, setAdvertisement] = useState(null);
  const { isOpen: isOpenEdit, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();

  const handleEditClose = () => {
    setAdvertisement(null);
    onEditClose();
  };

  if (loading) {
    return (
      <Box px="4" py="8">
        <Flex width="100%" height="300px" justifyContent="center" alignItems="center">
          <Spinner />
        </Flex>
      </Box>
    );
  }

  if (error) {
    return (
      <Box px="4" py="8">
        <Flex width="100%" height="300px" justifyContent="center" alignItems="center">
          Ein Fehler ist aufgetreten. Schreib Osamah an.
        </Flex>
      </Box>
    );
  }

  return (
    <>
      <Grid gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr 1fr']} gap="16px" mb="8" alignItems="flex-start" px="4" py="8">
        {data && data.advertisements && data.advertisements.length > 0 ? (
          data.advertisements.map((advertisement) => {
            const handleOpenModal = () => {
              setAdvertisement(advertisement);
              onEditOpen();
            };
            return <AdvertisementCard key={advertisement.id} {...advertisement} refetch={refetch} openModal={handleOpenModal} />;
          })
        ) : (
          <Text gridColumn="span 3" p="4" textAlign="center" backgroundColor="red.100" color="red.800" borderRadius="4px">
            Das Imperium hat keine Propaganda veröffentlicht.
          </Text>
        )}
      </Grid>
      <EditAdvertisementModal isOpen={isOpenEdit} onClose={handleEditClose} refetch={refetch} advertisement={advertisement} />
    </>
  );
};

export default Advertisements;
