import { Button } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/hooks';

import EditEmailModal from '../modals/ChangeEmail';

export const EditEmail = ({ email, userId, refetch }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button variant="outline" colorScheme="brand" size="xs" mr="2" mb="2" onClick={onOpen}>
        Email ändern
      </Button>

      <EditEmailModal isOpen={isOpen} onClose={onClose} userId={userId} email={email} refetch={refetch} />
    </>
  );
};
