import React, { useContext, useReducer } from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import { useQuery, useMutation } from '@apollo/client';
import { Spinner, Flex, Text, Grid, Button, IconButton, Stack, useColorMode } from '@chakra-ui/react';
import { IoIosKeypad, IoIosMenu } from 'react-icons/io';
import get from 'lodash/get';

import SpinnerContext from '../../contexts/SpinnerContext';
import UserContext from '../../contexts/UserContext';
import Layout from '../../components/Layout';
import { useCustomToast } from '../../hooks/useCustomToast';
import UserDetails from './UserDetails';
import { GET_USERS, EXPORT_USERS } from './query';
import { UserCard } from './UserCard';
import ActiveDropPointFilter from './actions/ActiveDropPointFilter';
import InactiveDropPointFilter from './actions/InactiveDropPointFilter';
import SearchValues from './SearchValues';
import AdvertisementActiveFilter from './actions/AdvertisementActiveFilter';
import AdvertisementInactiveFilter from './actions/AdvertisementInactiveFilter';
import UserSearch from './actions/UserSearch';
import SendEmailsToDropPoints from './actions/SendEmailsToDropPoints';
import ExportStatistics from './actions/ExportStatistics';
import UserTableRow from './UserTableRow';

interface UsersProps extends RouteComponentProps {}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SEARCH_VALUES':
      return { ...state, searchValues: action.value };
    case 'SET_ACTIVE_DROPPOINT':
      return { ...state, dropPointIsActive: action.value };
    case 'SET_INACTIVE_DROPPOINT':
      return { ...state, inactiveDropPointIsActive: action.value };
    case 'SET_ADVERTISEMENT_IS_ACTIVE':
      return { ...state, advertisementIsActive: action.value };
    case 'SET_ADVERTISEMENT_IS_INACTIVE':
      return { ...state, advertisementIsInactive: action.value };
    case 'SET_CARD_VIEW':
      return { ...state, userView: 'card' };
    case 'SET_TABLE_VIEW':
      return { ...state, userView: 'table' };
    default:
      return state;
  }
};

const UsersHome: React.FC<UsersProps> = () => {
  const initialState = {
    searchValues: null,
    dropPointIsActive: false,
    inactiveDropPointIsActive: false,
    advertisementIsActive: false,
    advertisementIsInactive: false,
    userView: localStorage.getItem('userView') || 'card',
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const { user } = useContext(UserContext);
  const { toggleSpinner } = useContext(SpinnerContext);
  const toast = useCustomToast();
  const { colorMode } = useColorMode();
  const [exportUsersMutation] = useMutation(EXPORT_USERS);
  const { loading, error, data, refetch, fetchMore } = useQuery(GET_USERS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      orderBy: 'createdAt_DESC',
      first: 51,
      skip: 0,
    } as any,
  });

  const exportUsers = async () => {
    try {
      toggleSpinner(true);
      const {
        data: { exportUsersToCsv },
      } = await exportUsersMutation();
      window.open(exportUsersToCsv, '_blank');
    } catch (error) {
      console.log(error);

      toast({
        description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
        status: 'error',
      });
    }
    toggleSpinner(false);
  };

  const switchUserView = (type: 'card' | 'table') => {
    localStorage.setItem('userView', type);

    dispatch({ type: type === 'card' ? 'SET_CARD_VIEW' : 'SET_TABLE_VIEW' });
  };

  const hasFiltered = () => {
    const { searchValues, dropPointIsActive, inactiveDropPointIsActive, advertisementIsActive, advertisementIsInactive } = state;
    return searchValues || dropPointIsActive || inactiveDropPointIsActive || advertisementIsActive || advertisementIsInactive;
  };

  const UserComponent = state.userView === 'card' ? UserCard : UserTableRow;

  return (
    <Layout>
      <Flex flexDirection={['column', 'row']} alignItems="center" justifyContent="space-between" mb="6">
        <Text as="h2" fontSize={['2xl', '4xl']} fontWeight="bold" flex="1" fontFamily="heading">
          Benutzer-Übersicht
        </Text>
        <Stack direction="row" flexWrap="wrap">
          <ExportStatistics />
          <Button size="sm" colorScheme="brand" onClick={exportUsers}>
            User als .csv exportieren
          </Button>
        </Stack>
      </Flex>
      <Flex justifyContent={['flex-end', 'space-between']} alignItems="flex-start" mb="4" flexWrap="wrap">
        <SearchValues refetch={refetch} dispatch={dispatch} state={state} />
        {user.email === 'osamah@dropfriends.com' || user.email === 'martin@dropfriends.com' ? <SendEmailsToDropPoints /> : null}
        <ActiveDropPointFilter refetch={refetch} dispatch={dispatch} state={state} />
        <InactiveDropPointFilter refetch={refetch} dispatch={dispatch} state={state} />
        <AdvertisementActiveFilter refetch={refetch} dispatch={dispatch} state={state} />
        <AdvertisementInactiveFilter refetch={refetch} dispatch={dispatch} state={state} />
        <UserSearch refetch={refetch} dispatch={dispatch} state={state} />
        <IconButton
          variant="ghost"
          size="sm"
          aria-label={state.userView === 'card' ? 'Switch to Table' : 'Switch to Card'}
          icon={state.userView === 'card' ? <IoIosMenu /> : <IoIosKeypad />}
          onClick={() => {
            switchUserView(state.userView === 'card' ? 'table' : 'card');
          }}
        />
      </Flex>
      {error ? (
        <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
          Ein Fehler ist aufgetreten. Schreib Osamah an.
        </Flex>
      ) : null}
      {data && data.users ? (
        <>
          <Grid gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr 1fr']} gap={state.userView === 'card' ? '16px' : 0} mb="8">
            {data.users.length === 0 ? (
              <Text gridColumn="span 3" p="4" textAlign="center" backgroundColor="red.100" color="red.800" borderRadius="4px">
                Der Widerstand versteckt sich gut und konnte nicht gefunden werden. Wiederhole die Suche oder schicke Boba Fett!
              </Text>
            ) : (
              <>
                {hasFiltered() ? <Text gridColumn="span 3">Angezeigte User: {data.users.length}</Text> : null}
                {data.users.map((user) => {
                  return <UserComponent key={user.id} {...user} />;
                })}
              </>
            )}
          </Grid>
          {data.users.length !== 0 && data.users.length < data.totalUsers.total && !loading ? (
            <Flex width="100%" justifyContent="center" mb="8">
              <Button
                onClick={() =>
                  fetchMore({
                    variables: {
                      skip: data.users.length,
                    },
                    updateQuery: (prev: any, { fetchMoreResult }) => {
                      if (!fetchMoreResult) return prev;
                      return Object.assign({}, prev, {
                        users: [...prev.users, ...fetchMoreResult.users],
                      });
                    },
                  })
                }>
                Mehr laden
              </Button>
            </Flex>
          ) : null}
        </>
      ) : null}
      {loading ? (
        <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
          <Spinner />
        </Flex>
      ) : null}
    </Layout>
  );
};

const Users: React.FC<UsersProps> = () => {
  return (
    <Router>
      <UsersHome path="/" />
      <UserDetails path="/:userId" />
    </Router>
  );
};

export default Users;
