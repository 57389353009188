import React from 'react';
import { FaXing, FaLinkedinIn, FaFacebook, FaInstagram, FaTwitter, FaGlobe } from 'react-icons/fa';

import { DataRow } from './DataRow';

const SocialMediaLinks = ({ internalCustomer, handleUpdateCustomerInfo }) => {
  return (
    <>
      <DataRow
        label={<FaGlobe />}
        placeholder="Webseite angeben"
        value={internalCustomer?.website}
        gridTemplateColumns="32px 1fr"
        onSubmit={(nextValue) => handleUpdateCustomerInfo({ website: nextValue }, nextValue, internalCustomer?.website, 'Webseite')}
      />
      <DataRow
        label={<FaFacebook />}
        placeholder="Facebook Profil angeben"
        value={internalCustomer?.facebook}
        gridTemplateColumns="32px 1fr"
        onSubmit={(nextValue) =>
          handleUpdateCustomerInfo({ facebook: nextValue }, nextValue, internalCustomer?.facebook, 'Facebook Profil')
        }
      />
      <DataRow
        label={<FaInstagram />}
        placeholder="Instagram Profil angeben"
        value={internalCustomer?.instagram}
        gridTemplateColumns="32px 1fr"
        onSubmit={(nextValue) =>
          handleUpdateCustomerInfo({ instagram: nextValue }, nextValue, internalCustomer?.instagram, 'Instagram Profil')
        }
      />
      <DataRow
        label={<FaTwitter />}
        placeholder="Twitter Profil angeben"
        value={internalCustomer?.twitter}
        gridTemplateColumns="32px 1fr"
        onSubmit={(nextValue) => handleUpdateCustomerInfo({ twitter: nextValue }, nextValue, internalCustomer?.twitter, 'Twitter Profil')}
      />
      <DataRow
        label={<FaLinkedinIn />}
        placeholder="Linkedin Profil angeben"
        value={internalCustomer?.internalCustomer?.linkedin}
        gridTemplateColumns="32px 1fr"
        onSubmit={(nextValue) =>
          handleUpdateCustomerInfo({ linkedin: nextValue }, nextValue, internalCustomer?.linkedin, 'Linkedin Profil')
        }
      />
      <DataRow
        label={<FaXing />}
        placeholder="Xing Profil angeben"
        value={internalCustomer?.xing}
        gridTemplateColumns="32px 1fr"
        onSubmit={(nextValue) => handleUpdateCustomerInfo({ xing: nextValue }, nextValue, internalCustomer?.xing, 'Xing Profil')}
      />
    </>
  );
};

export default SocialMediaLinks;
