import React from 'react';
import { Text, Box, Flex, Avatar, Badge, useColorMode } from '@chakra-ui/react';
import { Link } from '@reach/router';

export const UserCard = ({
  id,
  firstName,
  lastName,
  accountLocked,
  lockReason,
  inDefault,
  defaultAmount,
  photo,
  email,
  orders,
  acceptEmails,
  companyRole,
  myCompany,
  packageStations,
  advertisements,
  emailVerified,
}) => {
  const { colorMode } = useColorMode();
  const packageStation = packageStations && packageStations.length > 0 ? packageStations[0] : ({} as any);
  const onboardedPackageStation = packageStation?.isOnboarded;
  const activePackageStation = packageStation?.isActive;
  const hasActiveAdvertisement = advertisements.find(({ isActive }) => isActive);

  return (
    <>
      {/*
    //@ts-ignore */}
      <Box
        as={Link}
        to={`/users/${id}`}
        width="100%"
        // bg={colorMode === 'light' ? 'white' : 'gray.700'}
        rounded="md"
        borderWidth="1px"
        py="4"
        px="6"
        _hover={{ boxShadow: 'md', backgroundColor: colorMode === 'light' ? 'white' : 'gray.700' }}>
        <Flex flexWrap="wrap" mb="1">
          {accountLocked && lockReason === 'ACCOUNT_DISABLED' ? (
            <Badge colorScheme="red" mr="2">
              Account gekündigt
            </Badge>
          ) : (
            <>
              {accountLocked ? (
                <Badge colorScheme="red" mr="2" mb="1">
                  Account gesperrt
                </Badge>
              ) : null}
              {inDefault ? (
                <Badge colorScheme="red" mr="2" mb="1">
                  Zahlungsrückstand: {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(defaultAmount / 100)}
                </Badge>
              ) : null}
              {myCompany?.id ? (
                <Badge colorScheme="blue" mr="2" mb="1">
                  {myCompany.name}: {companyRole}
                </Badge>
              ) : null}
              {!myCompany?.id && !onboardedPackageStation ? (
                <Badge colorScheme={onboardedPackageStation ? 'green' : 'yellow'} mr="2" mb="1">
                  DropPoint nicht erstellt
                </Badge>
              ) : null}
              {!myCompany?.id && onboardedPackageStation ? (
                <Badge variant="solid" mr="2" colorScheme={activePackageStation ? 'green' : 'red'} mb="1">
                  {activePackageStation ? 'DropPoint aktiv' : 'DropPoint inaktiv'}
                </Badge>
              ) : null}
              <Badge colorScheme={acceptEmails ? 'green' : 'orange'} mb="1" mr="2">
                {acceptEmails ? 'Werbung gestattet' : 'Werbung unerwünscht'}
              </Badge>
              {advertisements.length > 0 ? (
                <Badge mr="2" variant="solid" colorScheme={hasActiveAdvertisement ? 'green' : 'yellow'} mb="1">
                  {hasActiveAdvertisement ? 'Ad online' : 'Ad offline'}
                </Badge>
              ) : null}
              <Badge colorScheme="orange" mb="1">
                {emailVerified ? 'E-Mail verifiziert' : 'E-Mail nicht verifiziert'}
              </Badge>
            </>
          )}
        </Flex>
        <Flex alignItems="center" mb="4">
          <Avatar src={photo ? photo.uri : null} mr="4" />
          <Box flex="1">
            <Text fontFamily="heading" fontSize="lg" fontWeight="bold" display="flex" alignItems="center">
              {firstName} {lastName}
            </Text>
            <Text fontSize="sm" wordBreak="break-all">
              {email}
            </Text>
          </Box>
        </Flex>
        <Text color={colorMode === 'light' ? 'gray.700' : 'gray.300'} fontSize="sm">
          <strong>{orders.length}</strong> Sendungen an DropPoints verschickt
        </Text>
        {packageStation?.packages ? (
          <Text color={colorMode === 'light' ? 'gray.700' : 'gray.300'} fontSize="sm">
            <strong>{packageStation?.packages?.length}</strong> Buchungen als DropPoint erhalten
          </Text>
        ) : null}
      </Box>
    </>
  );
};
