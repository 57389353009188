import React from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import { Grid, Text, useColorMode } from '@chakra-ui/react';

import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Layout from '../../components/Layout';
import Users from '../Users';
import Coupons from '../Coupons';
import StationCoupons from '../StationCoupons';
import Invoice from '../Invoices';
import Advertisements from '../Advertisements';
import Orders from '../Orders';
import { CompaniesRoute } from '../Companies';
import PushNotifications from '../PushNotifications';
import { StatisticsPage } from '../Statistics';
import Alderaan from '../Alderaan';
import { useUser } from '../../contexts/UserContext';
import { NotFound } from '../NotFound';
import ActiveDropPoints from '../ActiveDropPoints';
import Profile from '../Profile';
import PotentialCalculator from '../PotentialCalculator';
import CompanyCoupons from '../CompanyCoupons';
import { DHLPage } from '../DHL';

interface HomeProps extends RouteComponentProps {}

const Home: React.FC<HomeProps> = () => {
  return (
    <Layout>
      <Text as="h2" fontSize="4xl" fontWeight="bold" flex="1" fontFamily="heading" mb="8">
        Willkommen bei DropFriends
      </Text>
    </Layout>
  );
};

const HomeDashboard: React.FC<HomeProps> = () => {
  const { checkAccess } = useUser();
  const { colorMode } = useColorMode();

  return (
    <Grid
      height="100vh"
      width="100vw"
      templateRows="64px auto"
      templateColumns={['1fr', '1fr', '250px auto']}
      templateAreas={[
        `
      "header"
      "main"`,
        `
      "header"
      "main"`,
        `
      "sidebar header"
      "sidebar main"
    `,
      ]}>
      <Grid area="header" bg="white">
        <Header />
      </Grid>
      <Grid area="sidebar" bg="brand.800" display={['none', 'none', 'grid']}>
        <Sidebar onMobileClose={() => {}} />
      </Grid>
      <Grid area="main" bg={colorMode === 'light' ? 'white' : 'gray.800'} overflow="scroll">
        <Router>
          <Home path="/" />
          <NotFound default />
          {checkAccess('USERS_VIEW') ? <Users path="/users/*" /> : null}
          {checkAccess('BUSINESS_VIEW') || checkAccess('SPRINT_VIEW') ? <CompaniesRoute path="/companies/*" /> : null}
          {checkAccess('COMPANY_COUPONS_VIEW') ? <CompanyCoupons path="/company-coupons" /> : null}
          {checkAccess('ORDERS_VIEW') ? <Orders path="/orders" /> : null}
          {checkAccess('USER_COUPONS_VIEW') ? <Coupons path="/coupons" /> : null}
          {checkAccess('DROPPOINT_COUPONS_VIEW') ? <StationCoupons path="/station-coupons" /> : null}
          {checkAccess('INVOICES_VIEW') ? <Invoice path="/invoices" /> : null}
          {checkAccess('ADBERTISEMENT_VIEW') ? <Advertisements path="/advertisements" /> : null}
          {checkAccess('PUSH_VIEW') ? <PushNotifications path="push-notifications" /> : null}
          {checkAccess('EMPEROR') ? <StatisticsPage path="/order66" /> : null}
          {checkAccess('DROPPOINTS_VIEW') ? <ActiveDropPoints path="/active-droppoints/*" /> : null}
          {checkAccess('ALDERAAN_VIEW') ? <Alderaan path="/alderaan/*" /> : null}
          {checkAccess('ALDERAAN_VIEW') ? <PotentialCalculator path="potential-calculator" /> : null}
          {checkAccess('DHL') ? <DHLPage path="dhl" /> : null}
          <Profile path="/profile" />
        </Router>
      </Grid>
    </Grid>
  );
};

export default HomeDashboard;
