import React, { useEffect, useState } from 'react';
import { Flex, Icon, IconButton, Text } from '@chakra-ui/react';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';

export const Pagination = ({ totalAmount, amountPerPage, currentPage, handleOnClick }) => {
  const [totalPages, setTotalPages] = useState(Math.ceil(totalAmount / amountPerPage));

  useEffect(() => {
    setTotalPages(Math.ceil(totalAmount / amountPerPage));
  }, [totalAmount, amountPerPage]);
  if (!totalPages || totalPages < 2) return null;

  return (
    <Flex pt="12">
      <Flex flexWrap="wrap" justifyContent="center">
        {currentPage === 1 ? null : (
          <IconButton onClick={() => handleOnClick(currentPage - 1)} aria-label="Previous Page" variant="ghost" icon={<HiChevronLeft />} />
        )}
        {totalPages &&
          new Array(totalPages).fill(0).map((_, index) => {
            return (
              <IconButton
                key={`PaginationPage${index}`}
                onClick={() => handleOnClick(index + 1)}
                aria-label="Next Page"
                variant="ghost"
                icon={<span>{index + 1}</span>}
                colorScheme={index + 1 === currentPage ? 'blue' : 'gray'}
              />
            );
          })}
        {currentPage === totalPages ? null : (
          <IconButton onClick={() => handleOnClick(currentPage + 1)} aria-label="Next Page" variant="ghost" icon={<HiChevronRight />} />
        )}
      </Flex>
    </Flex>
  );
};
