import find from 'lodash/find';
import AWS from 'aws-sdk';
import moment from 'moment';

import { getEnvironment } from './environment';

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

const findByAddressComponents = ({ address_components, type, short = false }) => {
  const component: AddressComponent = find(address_components, (component: AddressComponent) => component.types.includes(type));

  if (short && component) return component.short_name;

  return component ? component.long_name : '';
};

export const formatGoogleResponse = ({ address_components, place_id, geometry: { location } }) => {
  return {
    geometry: {
      type: 'Point',
      coordinates: [location.lng, location.lat],
    },
    locationId: place_id,
    address: {
      street: findByAddressComponents({ address_components, type: 'route' }),
      houseNumber: findByAddressComponents({ address_components, type: 'street_number' }),
      postalCode: findByAddressComponents({ address_components, type: 'postal_code' }),
      city: findByAddressComponents({ address_components, type: 'locality' }),
      county: findByAddressComponents({ address_components, type: 'administrative_area_level_1' }),
      district: findByAddressComponents({ address_components, type: 'sublocality' }),
      country: findByAddressComponents({ address_components, type: 'country' }),
      countryCode: findByAddressComponents({ address_components, type: 'country', short: true }),
    },
  };
};

const env = getEnvironment();

const spacesEndpoint = new AWS.Endpoint(env.dOSpacesUrl);
export const s3 = new AWS.S3({
  endpoint: spacesEndpoint.href,
  accessKeyId: env.dOSpacesAccess,
  secretAccessKey: env.dOSpacesSecret,
});

export const s3Options = {
  bucketName: env.dOSpacesBucketName,
  region: 'us-east-1',
  digitalOceanSpaces: `https://${env.dOSpacesBucketName}.${env.dOSpacesUrl}/`,
  s3Url: env.dOSpacesUrl,
  successActionStatus: 201,
};

export const uploadToS3 = async (blob, Key) => {
  const index = blob.name.lastIndexOf('.');
  const extension = blob.name.substring(index + 1);

  await s3
    .putObject({
      Body: blob,
      Bucket: env.dOSpacesBucketName,
      Key: `${Key}.${extension}`,
      ACL: 'public-read',
      ContentType: blob.type,
    })
    .promise();

  return `https://${env.dOSpacesBucketName}.${env.dOSpacesUrl}/${Key}.${extension}`;
};

export const getMomentFromTime = (time) => {
  const quotient = Math.floor(time / 60);
  const remainder = time % 60;

  return moment()
    .hour(quotient - 1)
    .minute(remainder);
};

export const getVariant = (status) => {
  if (status === 'RETOURE' || status === 'DISPUTED' || status === 'REPORTED') return 'red';
  if (status === 'CANCELED' || status === 'EXPIRED') return 'orange';
  if (status === 'DELIVERED') return 'yellow';
  if (status === 'DONE') return 'green';
  return 'gray';
};
