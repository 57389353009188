import React, { HTMLAttributes } from 'react';
import { useField, useFormikContext } from 'formik';
import { FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';

import './styles.css';

interface Props {
  isClearable?: boolean;
  showPopperArrow?: boolean;
  name: string;
  label: string;
}

const DatePicker = ({
  isClearable = false,
  showPopperArrow = false,
  id = '',
  label,
  onChange,
  onSelect,
  ...props
}: Props & HTMLAttributes<HTMLElement>) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  return (
    <FormControl mb="4" isInvalid={!!(meta.touched && meta.error)}>
      <FormLabel fontSize="sm" htmlFor={id || props.name}>
        {label}
      </FormLabel>
      <ReactDatePicker
        id={id || props.name}
        selected={(field.value && new Date(field.value)) || null}
        onChange={(date) => {
          setFieldValue(field.name, date);
        }}
        wrapperClassName={!!(meta.touched && meta.error) ? 'error' : ''}
        onBlur={field.onBlur}
        // customInput={<DatePickerInput />}
        popperPlacement="top"
        // popperModifiers={{
        //   // @ts-ignore
        //   flip: {
        //     behavior: ['top'], // don't allow it to flip to be above
        //   },
        //   preventOverflow: {
        //     enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
        //   },
        //   hide: {
        //     enabled: false, // turn off since needs preventOverflow to be enabled
        //   },
        // }}
        placeholderText="TT.MM.JJJJ"
        dateFormat="dd.MM.yyyy"
        locale="de"
        maxDate={moment().toDate()}
        isClearable={isClearable}
        showPopperArrow={showPopperArrow}
        dropdownMode="select"
        {...props}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};

export default DatePicker;
