import React from 'react';
import { Flex, Tag, TagLabel, TagCloseButton } from '@chakra-ui/react';
import omit from 'lodash/omit';

const FilterTags = ({ dispatch, state: { filters, where } }) => {
  const deleteTag = (key) => {
    const whereClone = { ...where };
    const filtersClone = [...filters];

    const newFilters = filtersClone.filter((filter) => filter.key !== key);
    const newWhere = omit(whereClone, [key]);

    dispatch({ type: 'SET_FILTERS', payload: newFilters });
    dispatch({ type: 'SET_WHERE', payload: newWhere });
  };

  return (
    <Flex flex="1" flexWrap="wrap">
      {filters.map(({ key, value, label }) => {
        return (
          <Tag key={key} colorScheme="gray" size="sm" mr="2">
            <TagLabel>
              {label}: <b>{value}</b>
            </TagLabel>
            <TagCloseButton onClick={() => deleteTag(key)} />
          </Tag>
        );
      })}
    </Flex>
  );
};

export default FilterTags;
