import React, { useContext } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalHeader, ModalFooter, Button } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';
import get from 'lodash/get';
import moment from 'moment';

import NumberInput from '../../components/NumberInput';
import Input from '../../components/Input';
import SpinnerContext from '../../contexts/SpinnerContext';
import { useCustomToast } from '../../hooks/useCustomToast';
import { UPDATE_COUPON } from './query';

const CreateCouponSchema = Yup.object().shape({
  code: Yup.string().required('Das ist ein Pflichtfeld'),
  description: Yup.string().max(200, 'Deine Angabe darf max. 200 Zeichen lang sein').required('Das ist ein Pflichtfeld'),
  validUntil: Yup.string().required('Das ist ein Pflichtfeld'),
  validUses: Yup.number().min(1, 'Eine Anwendung ist das Minimum').required('Das ist ein Pflichtfeld'),
  discountForOrderer: Yup.number().required('Das ist ein Pflichtfeld'),
});

const EditCouponModal = ({ isOpen, onClose, refetch, coupon }) => {
  const [updateCoupon] = useMutation(UPDATE_COUPON);
  const { toggleSpinner } = useContext(SpinnerContext);
  const toast = useCustomToast();

  if (!coupon) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Coupon Bearbeiten </ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{
            code: coupon.code,
            users:
              coupon.type === 'INDIVIDUAL'
                ? {
                    connect: [
                      {
                        id: coupon.orderer,
                      },
                    ],
                  }
                : null,
            description: coupon.description,
            validUntil: moment(coupon.validUntil).format('DD.MM.YYYY'),
            validUses: coupon.validUses,
            discountForOrderer: coupon.discountForOrderer / 100,
          }}
          validationSchema={CreateCouponSchema}
          onSubmit={async ({ code, description, validUntil, validUses, discountForOrderer }) => {
            try {
              toggleSpinner(true);

              await updateCoupon({
                variables: {
                  where: {
                    id: coupon.id,
                  },
                  data: {
                    code,
                    description,
                    validUntil: moment(validUntil, 'DD.MM.YYYY').format('YYYY-MM-DD'),
                    validUses: parseInt(validUses),
                    discountForOrderer: discountForOrderer * 100,
                  },
                },
              });
              await refetch();
              toast({
                title: 'Erfolg!',
                description: 'Der Coupon wurde erfolgreich bearbeitet 🎉 ',
                status: 'success',
              });
              toggleSpinner(false);
              onClose();
            } catch (error) {
              console.log(error);
              toast({
                description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
                status: 'error',
              });
              toggleSpinner(false);
            }
          }}>
          {({ values, errors, setFieldValue }) => {
            return (
              <Form>
                <Input name="code" label="Coupon-Code" />
                <Input name="description" label="Beschreibung" />
                <Input name="validUntil" placeholder="DD.MM.YYYY" label="Gültig bis" />
                <NumberInput name="validUses" type="number" label="Anwendungen" />
                <NumberInput name="discountForOrderer" precision={2} step={0.01} type="number" min={0} max={3.99} label="Finaler Preis" />

                <ModalFooter>
                  <Button variant="ghost" size="sm" colorScheme="red" type="button" onClick={onClose}>
                    Schließen
                  </Button>
                  <Button ml="4" size="sm" colorScheme="brand" type="submit">
                    Speichern
                  </Button>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default EditCouponModal;
