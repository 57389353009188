export const getCategoryText = (category: string) => {
  if (category === 'Gastronomy') {
    return 'Gastronomie';
  }
  if (category === 'Culture') {
    return 'Film & Kultur';
  }
  if (category === 'Book') {
    return 'Bücher & Print';
  }
  if (category === 'Bakery') {
    return 'Bäckerei';
  }
  if (category === 'Butcher') {
    return 'Metzgerei';
  }
  if (category === 'Groceries') {
    return 'allgemeine Lebensmittel';
  }
  if (category === 'Drugstore') {
    return 'Drogerie- & Reformartikel';
  }
  if (category === 'Kiosk') {
    return 'Kiosk';
  }
  if (category === 'Florist') {
    return 'Florist';
  }
  if (category === 'Craftsman') {
    return 'Handwerker';
  }
  if (category === 'Others') {
    return 'Sonstiges';
  }
  return 'Sonstiges';
};
