import React, { useContext, useState, useRef } from 'react';
import { useMutation } from '@apollo/client';
import {
  Text,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { IoIosMail } from 'react-icons/io';
import get from 'lodash/get';
import { Formik, Form } from 'formik';

import SpinnerContext from '../../../contexts/SpinnerContext';
import { SEND_EMAIL } from '../query';
import { useCustomToast } from '../../../hooks/useCustomToast';

const SendEmailsToDropPoints = () => {
  // Kündigung Modal
  const [alertOpen, setAlertOpen] = useState(false);
  const onAlertClose = () => setAlertOpen(false);
  const cancelRef = useRef();

  const { toggleSpinner } = useContext(SpinnerContext);
  const [sendEmailToDropPoints] = useMutation(SEND_EMAIL);
  const toast = useCustomToast();

  const sendEmail = async () => {
    try {
      toggleSpinner(true);
      await sendEmailToDropPoints();
      toast({
        title: 'Erfolg!',
        description: 'Alle DropPoints die nicht aktiviert sind, haben eine Email erhalten',
        status: 'success',
      });
      setAlertOpen(false);
    } catch (error) {
      console.log(error);

      toast({
        description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
        status: 'error',
      });
    }
    toggleSpinner(false);
  };

  return (
    <>
      <Button leftIcon={<IoIosMail />} onClick={() => setAlertOpen(true)} size="sm" mr="2" mb="2">
        Email an DropPoints senden
      </Button>

      <AlertDialog isOpen={alertOpen} leastDestructiveRef={cancelRef} onClose={onAlertClose}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <Formik initialValues={{ comment: '' }} onSubmit={sendEmail}>
            <Form>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Bist Du sicher?
              </AlertDialogHeader>

              <AlertDialogBody>
                <Text mb="4">Bist Du Dir sicher, dass Du die Mails an noch nie aktivierte DropPoints versenden möchtest?</Text>
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onAlertClose}>
                  Abbrechen
                </Button>
                <Button colorScheme="green" type="submit" ml={3}>
                  Mails versenden
                </Button>
              </AlertDialogFooter>
            </Form>
          </Formik>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default SendEmailsToDropPoints;
