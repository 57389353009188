const ENV = {
  dev: {
    apiUrl: 'http://localhost:4000',
    wsUrl: 'ws://localhost:4000',
    dOSpacesAccess: 'HKEMEMI3L75QINQFCHNV',
    dOSpacesSecret: 'XkdEvxb2il3zkdF0/fbyctDBlP5CjOwwnbI2UOfRi4Q',
    dOSpacesBucketName: 'df-test-space',
    dOSpacesUrl: 'fra1.digitaloceanspaces.com',
    dOCdnUrl: 'fra1.cdn.digitaloceanspaces.com',
  },
  staging: {
    apiUrl: 'https://api.dev.dropfriends.com',
    wsUrl: 'wss://api.dev.dropfriends.com',

    dOSpacesAccess: 'HKEMEMI3L75QINQFCHNV',
    dOSpacesSecret: 'XkdEvxb2il3zkdF0/fbyctDBlP5CjOwwnbI2UOfRi4Q',
    dOSpacesBucketName: 'df-test-space',
    dOSpacesUrl: 'fra1.digitaloceanspaces.com',
    dOCdnUrl: 'fra1.cdn.digitaloceanspaces.com',
  },
  production: {
    apiUrl: 'https://api.dropfriends.com',
    wsUrl: 'wss://api.dropfriends.com',

    dOSpacesAccess: 'N5GTMGL4XX74HCMI3SQT',
    dOSpacesSecret: '1NOTCbSzSX16fK0xDDqXy60DVv3NsX98f/ZW2o/3CVk',
    dOSpacesBucketName: 'dropfriends',
    dOSpacesUrl: 'fra1.digitaloceanspaces.com',
    dOCdnUrl: 'fra1.cdn.digitaloceanspaces.com',
  },
};

export const getEnvironment = () => {
  return ENV[process.env.REACT_APP_ENV || 'production'];
};
