import gql from 'graphql-tag';

export const PUSH_NOTIFICATIONS = gql`
  query {
    pushNotifications(orderBy: createdAt_DESC) {
      id
      receiver
      title
      subTitle
      body
      comment
      user {
        id
        firstName
        lastName
      }
      changedBy {
        id
        firstName
        lastName
      }
      createdAt
    }
  }
`;

export const SEND_PUSH_NOTIFICATION = gql`
  mutation SEND_PUSH_NOTIFICATION(
    $receiver: RECEIVER!
    $user: String
    $title: String
    $subTitle: String
    $body: String!
    $comment: String!
    $internalUser: String!
  ) {
    sendPushNotification(
      receiver: $receiver
      userId: $user
      title: $title
      subTitle: $subTitle
      body: $body
      comment: $comment
      internalUser: $internalUser
    ) {
      id
    }
  }
`;
