import React, { useContext, useRef, useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ButtonGroup,
  useDisclosure,
  Box,
  Flex,
  Text,
} from '@chakra-ui/react';
import { HiOutlineViewGridAdd } from 'react-icons/hi';
import get from 'lodash/get';
import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import SpinnerContext from '../../../contexts/SpinnerContext';
import UserContext from '../../../contexts/UserContext';
import { useCustomToast } from '../../../hooks/useCustomToast';
import { s3 } from '../../../utils';
import { getEnvironment } from '../../../environment';
import { QuillInput } from '../../QuillInput';
import { ADD_COMMENT } from '../query';

const { dOSpacesBucketName, dOCdnUrl } = getEnvironment();

const CreateNoteSchema = Yup.object().shape({
  comment: Yup.string().required('Du musst einen Kommentar angeben'),
});

const CreateNewNote = ({ refetch, orderId, type }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [addComment] = useMutation(ADD_COMMENT);
  const { isOpen: isOpenPopover, onOpen: openPopover, onClose: closePopover } = useDisclosure();
  const { toggleSpinner } = useContext(SpinnerContext);
  const { user } = useContext(UserContext);
  const toast = useCustomToast();

  return (
    <>
      <Button
        colorScheme="green"
        variant="outline"
        size="sm"
        aria-label="Kommunikation protokollieren"
        onClick={openPopover}
        leftIcon={<HiOutlineViewGridAdd />}>
        {type === 'OTHER' ? 'Neuer Kommentar' : null}
      </Button>
      <Modal isOpen={isOpenPopover} onClose={closePopover} size="lg">
        <ModalOverlay />
        <ModalContent>
          <Formik
            validationSchema={CreateNoteSchema}
            initialValues={{ comment: '', files: [], fileUploadBlobs: [] }}
            onSubmit={async ({ comment, files, fileUploadBlobs }) => {
              toggleSpinner(true);
              try {
                let filesToConnect = [];
                if (fileUploadBlobs) {
                  let fileUploadPromises = Array.from(fileUploadBlobs).map((fileBlob, i) => {
                    const index = fileBlob.name.lastIndexOf('.');
                    const extension = fileBlob.name.substring(index + 1);
                    const Key = `notes/${orderId}-${i}-${moment().unix()}.${extension}`;

                    const photoUrl = `https://${dOSpacesBucketName}.${dOCdnUrl}/${Key}`;
                    filesToConnect.push(photoUrl);

                    return s3
                      .putObject({
                        Body: fileBlob,
                        Bucket: dOSpacesBucketName,
                        Key,
                        ACL: 'public-read',
                      })
                      .promise();
                  });
                  await Promise.all(fileUploadPromises);
                }
                await addComment({
                  variables: {
                    where: {
                      id: orderId,
                    },
                    data: {
                      history: {
                        create: [
                          {
                            comment,
                            type: 'OTHER',
                            connectedFiles:
                              filesToConnect.length > 0
                                ? {
                                    create: filesToConnect.map((uri, index) => {
                                      return {
                                        uri,
                                        name: fileUploadBlobs[index].name,
                                        type: fileUploadBlobs[index].type,
                                      };
                                    }),
                                  }
                                : undefined,
                            changedBy: {
                              connect: { id: user.id },
                            },
                          },
                        ],
                      },
                    },
                  },
                });
                await refetch();
                closePopover();
                toast({
                  description: 'New Nachricht hinzugefügt 🎉 ',
                  status: 'success',
                  duration: 3000,
                  isClosable: false,
                });
              } catch (error) {
                console.log(error);

                toast({
                  description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
                  status: 'error',
                });
              }
              toggleSpinner(false);
            }}>
            {({ setFieldValue, values, handleSubmit }) => {
              return (
                <Form>
                  <ModalHeader>{type === 'OTHER' ? 'Neuer Kommentar' : null}</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <QuillInput
                      label={type === 'COMMUNICATION' ? 'Deine Kommunikation' : 'Dein Kommentar'}
                      name="comment"
                      placeholder="Gib hier Deine Nachricht ein..."
                    />

                    <Box mt="5">
                      <Button
                        type="button"
                        size="sm"
                        onClick={() => {
                          fileInputRef && fileInputRef.current && fileInputRef.current?.click();
                        }}>
                        Dateien hochladen
                      </Button>
                      <input
                        ref={fileInputRef}
                        hidden={true}
                        type="file"
                        name="files"
                        multiple
                        onChange={async (event) => {
                          let eventFiles = event.target.files;
                          let fileUploadBlobs = [...values.fileUploadBlobs, ...Array.from(eventFiles)];

                          let uploadedFiles = Array.from(event.target.files).map((file) => {
                            let reader = new FileReader();

                            return new Promise((resolve) => {
                              // Resolve the promise after reading file
                              reader.onload = () => resolve(reader.result);
                              // Read the file as a text
                              reader.readAsDataURL(file);
                            });
                          });
                          let res = await Promise.all(uploadedFiles);
                          setFieldValue('files', [...values.files, ...res]);
                          setFieldValue('fileUploadBlobs', fileUploadBlobs);
                        }}
                      />
                    </Box>
                    {values.fileUploadBlobs.length > 0 ? (
                      <Flex direction="column" my="4">
                        {values.fileUploadBlobs.map(({ name }, index) => {
                          return (
                            <Flex key={`file-${index}`} mb="2">
                              <Text flex="1" fontSize="sm">
                                {name}
                              </Text>
                              <Button
                                size="xs"
                                variant="ghost"
                                colorScheme="red"
                                onClick={() => {
                                  const clone1 = [...values.files];
                                  const clone2 = [...values.fileUploadBlobs];

                                  clone1.splice(index, 1);
                                  clone2.splice(index, 1);

                                  setFieldValue('files', clone1);
                                  setFieldValue('fileUploadBlobs', clone2);
                                }}>
                                Entfernen
                              </Button>
                            </Flex>
                          );
                        })}
                      </Flex>
                    ) : null}
                  </ModalBody>

                  <ModalFooter>
                    <ButtonGroup size="sm">
                      <Button onChange={() => handleSubmit()} colorScheme="green">
                        Fertig
                      </Button>
                    </ButtonGroup>
                  </ModalFooter>
                </Form>
              );
            }}
          </Formik>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateNewNote;
