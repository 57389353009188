import gql from 'graphql-tag';

export const GET_USERS = gql`
  query GET_USERS($where: PackageStationWhereInput, $orderBy: PackageStationOrderByInput, $first: Int, $skip: Int) {
    # users(where: $where, orderBy: $orderBy, first: $first, skip: $skip) {
    #   id
    #   firstName
    #   lastName
    #   email
    #   emailVerified
    #   accountLocked
    #   lockReason
    #   inDefault
    #   acceptEmails
    #   defaultAmount
    #   photo {
    #     uri
    #   }
    #   advertisements {
    #     id
    #     isActive
    #   }
    #   packageStations {
    #     id
    #     isOnboarded
    #     isActive
    #     packages {
    #       id
    #     }
    #   }
    #   companyRole
    #   myCompany {
    #     id
    #     name
    #   }
    #   orders {
    #     id
    #   }
    # }
    # totalUsers(where: $where) {
    #   total
    # }
    # }
    packageStations(where: $where, orderBy: $orderBy, first: $first, skip: $skip) {
      id
      activityCheck
      activityCheckSent
      user {
        id
        firstName
        lastName
        email
        emailVerified
        accountLocked
        lockReason
        inDefault
        acceptEmails
        defaultAmount
        photo {
          uri
        }
        advertisements {
          id
          isActive
        }
        packageStations {
          id
          isOnboarded
          isActive
          packages {
            id
          }
        }
        companyRole
        myCompany {
          id
          name
        }
        orders {
          id
        }
      }
    }
    totalDropPoints2(where: $where) {
      total
    }
  }
`;

export const SEND_ACTIVITY_CHECK = gql`
  mutation SEND_ACTIVITY_CHECK($internalUserId: String!, $where: PackageStationWhereInput!) {
    sendActivityCheck(internalUserId: $internalUserId, where: $where)
  }
`;
