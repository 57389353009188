import gql from 'graphql-tag';

export const USER_ACCESS = gql`
  query USER_ACCESS($userId: ID!) {
    internalUser(where: { id: $userId }) {
      id
      firstName
      lastName
      subscription
      access {
        id
        type
        key
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UPDATE_USER($userId: ID!, $subscription: Json!) {
    updateInternalUser(where: { id: $userId }, data: { subscription: $subscription }) {
      id
    }
  }
`;
