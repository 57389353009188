import gql from 'graphql-tag';

export const GET_INVOICE_COUNT = gql`
  query {
    runningInvoiceCount
  }
`;

export const GET_INVOICES = gql`
  query {
    receipts {
      id
      number
      url
      inputData
      inDefaultReceipt
      payment {
        id
        amount
        dropfriendsAmount
        isTip
        type
      }
    }
    payouts {
      id
      number
      url
      month
      year
      amount
      inputData
      packageStation {
        id
      }
      payedOut
    }
    histories(where: { type: RESERVE_INVOICE }, orderBy: createdAt_DESC, first: 500) {
      id
      comment
      createdAt
      changedBy {
        id
        firstName
      }
    }
    invoices {
      id
      number
      url
      company {
        id
      }
      inputData
      createdAt
      inDefault
      done
    }
    essentialsInvoices {
      id
      number
      url
      createdAt
      inputData
      company {
        paymentMethod
      }
      subscription {
        id
        subscriptionId
      }
    }
  }
`;

export const HISTORY = gql`
  query HISTORY {
    histories(where: { type: RESERVE_INVOICE }, orderBy: createdAt_DESC, first: 500) {
      id
      comment
      createdAt
      change
      changedBy {
        id
        firstName
      }
    }
  }
`;

export const RESERVE_INVOICE_NUMBER = gql`
  mutation RESERVE_INVOICE_NUMBER($internalUser: String!, $comment: String!) {
    reserveInvoiceNumber(comment: $comment, internalUser: $internalUser)
  }
`;
