import React from 'react';
import { RouteComponentProps } from '@reach/router';
import * as Yup from 'yup';
import { Text, Flex, Box } from '@chakra-ui/react';

import Layout from '../components/Layout';
import PotentialCalculator from '../components/PotentialCalculator';

const PotentialCalculatorSchema = Yup.object().shape({
  postalCode: Yup.string()
    .required('Dieses Pflichtfeld muss ausgefüllt werden')
    .min(5, 'Die PLZ muss genau 5 Zeichen lang sein')
    .max(5, 'Die PLZ muss genau 5 Zeichen lang sein'),
  averageNumberOfVisitors: Yup.string().required('Dieses Pflichtfeld muss ausgefüllt werden'),
});

interface PotentialCalculatorProps extends RouteComponentProps {}

const PotentialCalculatorPage: React.FC<PotentialCalculatorProps> = () => {
  return (
    <Box width="100%">
      <Flex mb="4">
        <Layout fullWidth>
          <Flex flexDirection={['column', 'row']} justifyContent="space-between" mb="3">
            <Text as="h2" fontSize="4xl" flex="1" fontWeight="bold" fontFamily="heading">
              Potenzialrechner
            </Text>
          </Flex>
          <PotentialCalculator />
        </Layout>
      </Flex>
    </Box>
  );
};

export default PotentialCalculatorPage;
