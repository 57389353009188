import React, { useContext } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalHeader, ModalBody, ModalFooter, Button } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';
import get from 'lodash/get';

import Input from '../../../components/Input';
import UserContext from '../../../contexts/UserContext';
import { UPDATE_USER } from '../query';
import { useCustomToast } from '../../../hooks/useCustomToast';

const LockConfirmSchema = Yup.object().shape({
  email: Yup.string().email('Du musst eine valide Email angeben'),
  comment: Yup.string().required('Warum möchtest Du die Änderung durchführen?'),
});

const UpdateEmailModal = ({ isOpen, onClose, refetch, userId, email }) => {
  const [updateProfile] = useMutation(UPDATE_USER);
  const { user } = useContext(UserContext);
  const toast = useCustomToast();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Email-Adresse ändern</ModalHeader>

        <Formik
          initialValues={{
            email,
            comment: '',
          }}
          validationSchema={LockConfirmSchema}
          onSubmit={async ({ email, comment }) => {
            try {
              await updateProfile({
                variables: {
                  id: userId,
                  email: email.toLowerCase(),
                  internalUser: user.id,
                  comment,
                },
              });
              await refetch();
              toast({
                title: 'Erfolg!',
                description: 'Die Email wurde erfolgreich geändert',
                status: 'success',
              });
            } catch (error) {
              console.log(error);
              toast({
                description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
                status: 'error',
              });
            }
            onClose();
          }}>
          <Form>
            <ModalBody>
              <Input name="email" label="Email-Adresse" />
              <Input name="comment" label="Kommentar" />
            </ModalBody>

            <ModalFooter>
              <Button variant="ghost" size="sm" colorScheme="red" type="button" onClick={onClose}>
                Schließen
              </Button>
              <Button ml="4" size="sm" colorScheme="brand" type="submit">
                Speichern
              </Button>
            </ModalFooter>
          </Form>
        </Formik>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};

export default UpdateEmailModal;
