import gql from 'graphql-tag';

export const GET_COUPONS = gql`
  query GET_COUPONS($where: CouponWhereInput, $orderBy: CouponOrderByInput, $first: Int) {
    coupons(where: $where, orderBy: $orderBy, first: $first) {
      id
      code
      type
      description
      validUntil
      validUses
      discountForOrderer
      orders {
        id
      }
      users {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const CREATE_COUPON = gql`
  mutation CREATE_COUPON($data: CouponCreateInput!) {
    createCoupon(data: $data) {
      id
    }
  }
`;

export const UPDATE_COUPON = gql`
  mutation UPDATE_COUPON($data: CouponUpdateInput!, $where: CouponWhereUniqueInput!) {
    updateCoupon(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_COUPON = gql`
  mutation DELETE_COUPON($where: CouponWhereUniqueInput!) {
    deleteCoupon(where: $where) {
      id
    }
  }
`;
