import React, { useContext } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalHeader, ModalBody, ModalFooter, Button, Text } from '@chakra-ui/react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { useMutation } from '@apollo/client';
import get from 'lodash/get';
import moment from 'moment';

import NumberInput from '../../components/NumberInput';
import Input from '../../components/Input';
import SpinnerContext from '../../contexts/SpinnerContext';
import { useCustomToast } from '../../hooks/useCustomToast';
import { UPDATE_COUPON } from './query';

const CreateCouponSchema = Yup.object().shape({
  code: Yup.string().required('Das ist ein Pflichtfeld'),
  uses: Yup.number().min(1, 'Eine Anwendung ist das Minimum').required('Das ist ein Pflichtfeld'),
  amount: Yup.number().required('Das ist ein Pflichtfeld'),
});

const EditCouponModal = ({ isOpen, onClose, refetch, coupon }) => {
  const [updateCoupon] = useMutation(UPDATE_COUPON);
  const { toggleSpinner } = useContext(SpinnerContext);
  const toast = useCustomToast();

  if (!coupon) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Coupon Bearbeiten </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{
              code: coupon.code,
              amount: coupon.type === 'AMOUNT' ? coupon.amount / 100 : coupon.amount,
              uses: coupon.uses,
            }}
            validationSchema={CreateCouponSchema}
            onSubmit={async ({ code, amount, uses }) => {
              try {
                toggleSpinner(true);

                await updateCoupon({
                  variables: {
                    where: {
                      id: coupon.id,
                    },
                    data: {
                      code: code.toUpperCase(),
                      amount: coupon.type === 'AMOUNT' ? amount * 100 : amount,
                      uses,
                    },
                  },
                });
                await refetch();
                toast({
                  title: 'Erfolg!',
                  description: 'Der Coupon wurde erfolgreich bearbeitet 🎉',
                  status: 'success',
                });
                toggleSpinner(false);
                onClose();
              } catch (error) {
                console.log(error);
                toast({
                  description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
                  status: 'error',
                });
                toggleSpinner(false);
              }
            }}>
            {({ values, errors }) => {
              return (
                <Form>
                  <Text fontWeight="bold" fontSize="lg" mb="4">
                    Code: {values.code}
                  </Text>

                  {coupon.type === 'PERCENT' ? (
                    <NumberInput name="amount" precision={1} step={0.5} type="number" min={0} max={100} label="Rabatt in %" />
                  ) : null}
                  {coupon.type === 'AMOUNT' ? (
                    <NumberInput name="amount" precision={1} step={0.5} type="number" min={0} max={500} label="Rabatt in €" />
                  ) : null}
                  <NumberInput name="uses" precision={0} step={1} type="number" min={1} max={999999} label="Anwendungen" />

                  <ModalFooter>
                    <Button variant="ghost" size="sm" colorScheme="red" type="button" onClick={onClose}>
                      Schließen
                    </Button>
                    <Button ml="4" size="sm" colorScheme="brand" type="submit">
                      Speichern
                    </Button>
                  </ModalFooter>
                </Form>
              );
            }}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EditCouponModal;
