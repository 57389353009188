import gql from 'graphql-tag';

export const GET_MARKERS = gql`
  query GET_MARKERS($mapBoundaries: Boundaries!, $skip: Float, $orderBy: DhlMarkerOrderByInput) {
    getMarkersOnMap(mapBoundaries: $mapBoundaries, orderBy: $orderBy, skip: $skip) {
      id
      latitude
      longitude
      radius
      note

      totalShops
      syncedShops
      lastSync
    }
    totalDhlMarkersInBounds(mapBoundaries: $mapBoundaries)
    totalDhlMarkerCount
  }
`;

export const CREATE_MARKER = gql`
  mutation CREATE_MARKER($data: DhlMarkerCreateInput!) {
    createDhlMarkerAndSync(data: $data) {
      id
    }
  }
`;

export const DELETE_MARKER = gql`
  mutation DELETE_MARKER($where: DhlMarkerWhereUniqueInput!) {
    deleteDhlMarker(where: $where) {
      id
    }
  }
`;

export const SYNC_OLDEST_MARKERS = gql`
  mutation {
    syncOldestDhlMarkers
  }
`;
