import React, { useState } from 'react';
import {
  Avatar,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Box,
  Text,
  Heading,
  useDisclosure,
  Flex,
  Spinner,
  Grid,
  Alert,
  AlertIcon,
  AlertDescription,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from '@chakra-ui/react';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/client';
import get from 'lodash/get';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';

import { useUser } from '../../contexts/UserContext';
import { useCustomToast } from '../../hooks/useCustomToast';
import EditStatusModal from './modals/EditStatusModal';
import { INTERNAL_CUSTOMER, UPDATE_INTERNAL_CUSTOMER, DELETE_INTERNAL_CUSTOMER } from './query';
import { CreateReminderModal } from './modals/CreateReminderModal';
import { useSpinner } from '../../contexts/SpinnerContext';
import SendEmailModal from './modals/SendEmailModal';
import CommunicationTabs from '../../components/CustomerDrawer/CommunicationTabs';
import DrawerHeader from './DrawerHeader';
import SocialMediaLinks from '../../components/CustomerDrawer/SocialMediaLinks';
import BasicContactInfo from '../../components/CustomerDrawer/BasicContactInfo';
import CompanyInfo from '../../components/CustomerDrawer/CompanyInfo';

const CustomerDrawer = ({ isOpen, onClose, refetch: parentRefetch, customerId, openChangeAsigneeModal, totalAmount, handleDrawerOpen }) => {
  const { user, checkAccess } = useUser();
  const { toggleSpinner } = useSpinner();
  const toast = useCustomToast();
  const { isOpen: idOpenReminder, onOpen: openReminderModal, onClose: closeReminderModal } = useDisclosure();
  const { isOpen: isOpenEditModal, onOpen: openEditModal, onClose: closeEditModal } = useDisclosure();
  const { isOpen: isOpenSendEmailModal, onOpen: openSendEmailModal, onClose: closeSendEmailModal } = useDisclosure();
  const [updateCustomer] = useMutation(UPDATE_INTERNAL_CUSTOMER);
  const [deleteCustomer] = useMutation(DELETE_INTERNAL_CUSTOMER);

  const { loading, error, data, refetch } = useQuery(INTERNAL_CUSTOMER, {
    fetchPolicy: 'network-only',
    variables: {
      customerId,
      userId: user.id,
    },
    skip: !customerId,
  });
  let currentId = data?.internalCustomer?.id;
  const index = totalAmount?.map((e) => e.id).indexOf(currentId);

  const handleDeleteCustomer = async () => {
    toggleSpinner(true);
    try {
      await deleteCustomer({
        variables: {
          where: {
            id: data?.internalCustomer?.id,
          },
        },
      });
      await parentRefetch();
      onClose();
    } catch (error) {
      console.log(error);
      toast({
        description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
        status: 'error',
      });
    }
    toggleSpinner(false);
  };

  const handlePreviousCustomer = async () => {
    const previousIndex = index - 1;
    const newCustomer = totalAmount?.at(previousIndex);
    handleDrawerOpen(newCustomer?.id);
  };

  const handleNextCustomer = async () => {
    const NextIndex = index + 1;
    const newCustomer = totalAmount?.at(NextIndex);
    handleDrawerOpen(newCustomer?.id);
  };

  const handleUpdateCustomerInfo = async (newData, nextValue, previousValue, label) => {
    if (previousValue === nextValue) return;
    try {
      await updateCustomer({
        variables: {
          where: {
            id: data?.internalCustomer?.id,
          },
          data: {
            ...newData,
            updatedAtRandomizer: Math.random().toString(),
            history: {
              create: [
                {
                  type: 'UPDATED',
                  comment: `${label} ${nextValue} hinterlegt`,
                  timestamp: moment().toISOString(),
                  changedBy: {
                    connect: { id: user.id },
                  },
                },
              ],
            },
          },
        },
      });
      await parentRefetch();
      await refetch();
    } catch (error) {
      console.log(error);
      toast({
        description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
        status: 'error',
      });
    }
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="xl" autoFocus={false}>
      <DrawerOverlay />
      <DrawerContent>
        <Stack direction="row" spacing={5} justifyContent="center" pt="4">
          {index <= 0 ? (
            <Flex justifyContent="space-around">
              <Button leftIcon={<HiChevronLeft />} colorScheme="brand" variant="outline" size="xs" isDisabled={true}>
                Vorheriger Lead
              </Button>
            </Flex>
          ) : (
            <Button onClick={handlePreviousCustomer} leftIcon={<HiChevronLeft />} colorScheme="brand" variant="outline" size="xs">
              Vorheriger Lead
            </Button>
          )}
          {index === totalAmount?.length - 1 ? (
            <Button rightIcon={<HiChevronRight />} colorScheme="brand" variant="outline" size="xs" isDisabled={true}>
              Nächster Lead
            </Button>
          ) : (
            <Button onClick={handleNextCustomer} rightIcon={<HiChevronRight />} colorScheme="brand" variant="outline" size="xs">
              Nächster Lead
            </Button>
          )}
        </Stack>
        <DrawerBody>
          {loading ? (
            <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
              <Spinner />
            </Flex>
          ) : null}
          {error ? (
            <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
              Ein Fehler ist aufgetreten. Schreib Osamah an.
            </Flex>
          ) : null}

          {data?.internalCustomer ? (
            <Box width="100%" flex="1" mb="4">
              {data?.internalCustomer?.notifications.map((reminder) => {
                return (
                  <Box key={reminder?.id}>
                    <Alert status="info" mb="2" borderRadius="md" py="1">
                      <AlertIcon />
                      <Flex alignItems="center" gap={{ sm: '10px', md: '24px', lg: '24px' }} width="100%">
                        <Box flex="1" position="relative">
                          <AlertDescription>
                            <Text fontWeight="bold" fontSize="sm" mb="-1.5">
                              {reminder?.title}
                            </Text>

                            <Text fontSize="xs">{reminder?.body}</Text>
                          </AlertDescription>
                        </Box>
                        <Box alignSelf="end">
                          <Text fontSize="xs">{moment(reminder?.reminderAt).format('DD.MM.YYYY, HH:mm')}</Text>
                        </Box>
                      </Flex>
                    </Alert>
                  </Box>
                );
              })}
              <DrawerHeader internalCustomer={data?.internalCustomer} handleUpdateCustomerInfo={handleUpdateCustomerInfo} />
              <Grid gridTemplateColumns={['1fr 1fr', '1fr 1fr', '1fr 1fr 1fr']} mb="8" gap="3">
                <Button onClick={openEditModal} colorScheme="green">
                  Status bearbeiten
                </Button>
                {data?.internalCustomer?.email ? (
                  <Button onClick={openSendEmailModal} colorScheme="blue">
                    E-Mail senden
                  </Button>
                ) : null}
                <Button onClick={openReminderModal} variant="outline" colorScheme="blue">
                  Erinnerung setzen
                </Button>
                <Button
                  onClick={() => openChangeAsigneeModal(data?.internalCustomer.id, data?.internalCustomer.asignee?.id)}
                  variant="outline">
                  Mitarbeiter zuweisen
                </Button>
                {checkAccess('ALDERAAN_DELETE_CUSTOMER') ? (
                  <Button onClick={handleDeleteCustomer} variant="outline" colorScheme="red">
                    Lead löschen
                  </Button>
                ) : null}
              </Grid>

              {data?.internalCustomer?.asignee ? (
                <>
                  <Heading as="h4" size="md" flex="1" textAlign="left" mb="4">
                    Zugewiesen an
                  </Heading>
                  <Flex alignItems="center" mb="8">
                    <Avatar
                      src={data?.internalCustomer?.asignee.photo}
                      name={`${data?.internalCustomer?.asignee.firstName} ${data?.internalCustomer?.asignee.lastName}`}
                      mr="2"
                      size="sm"
                    />
                    <Text>
                      {data?.internalCustomer?.asignee.firstName} {data?.internalCustomer?.asignee.lastName}
                    </Text>
                  </Flex>
                </>
              ) : null}

              <BasicContactInfo
                internalCustomer={data?.internalCustomer}
                refetch={async () => {
                  await refetch();
                  await parentRefetch();
                }}
                handleUpdateCustomerInfo={handleUpdateCustomerInfo}
              />

              <Accordion allowToggle mt="8">
                <AccordionItem>
                  <AccordionButton px="0" py="3">
                    <Heading flex="1" textAlign="left" fontWeight="bold" size="lg" fontFamily="heading">
                      Firmendetails
                    </Heading>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={12} px="0">
                    <CompanyInfo internalCustomer={data?.internalCustomer} handleUpdateCustomerInfo={handleUpdateCustomerInfo} />
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionButton px="0" py="3">
                    <Heading flex="1" textAlign="left" fontWeight="bold" size="lg" fontFamily="heading">
                      Social Media Links
                    </Heading>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={12} px="0">
                    <SocialMediaLinks internalCustomer={data?.internalCustomer} handleUpdateCustomerInfo={handleUpdateCustomerInfo} />
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>

              <CommunicationTabs
                customerId={customerId}
                refetch={async () => {
                  await refetch();
                  await parentRefetch();
                }}
                internalCustomer={data?.internalCustomer}
              />
            </Box>
          ) : null}
        </DrawerBody>
        <EditStatusModal
          isOpen={isOpenEditModal}
          onClose={closeEditModal}
          refetch={async () => {
            await refetch();
            await parentRefetch();
          }}
          customer={data?.internalCustomer}
        />

        <CreateReminderModal
          isOpen={idOpenReminder}
          onClose={closeReminderModal}
          refetch={async () => {
            await refetch();
            await parentRefetch();
          }}
          customerId={customerId}
          currentAsignee={data?.internalCustomer?.asignee}
        />

        <SendEmailModal
          isOpen={isOpenSendEmailModal}
          onClose={closeSendEmailModal}
          refetch={async () => {
            await refetch();
            await parentRefetch();
          }}
          internalUser={data?.internalUser}
          customer={data?.internalCustomer}
        />
        <DrawerCloseButton />
      </DrawerContent>
    </Drawer>
  );
};

export default CustomerDrawer;
