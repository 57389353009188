import gql from 'graphql-tag';

export const GET_COUPONS = gql`
  query GET_COUPONS($where: StationCouponWhereInput, $orderBy: StationCouponOrderByInput, $first: Int) {
    stationCoupons(where: $where, orderBy: $orderBy, first: $first) {
      id
      code
      type
      description
      validUntil
      validUses
      discountForOrderer
      rateForStation
      orders {
        id
      }
      packageStations {
        id
      }
      activeFor {
        id
      }
    }
  }
`;

export const CREATE_COUPON = gql`
  mutation CREATE_COUPON($data: StationCouponCreateInput!) {
    createStationCoupon(data: $data) {
      id
    }
  }
`;

export const UPDATE_COUPON = gql`
  mutation UPDATE_COUPON($data: StationCouponUpdateInput!, $where: StationCouponWhereUniqueInput!) {
    updateStationCoupon(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_COUPON = gql`
  mutation DELETE_COUPON($where: StationCouponWhereUniqueInput!) {
    deleteStationCoupon(where: $where) {
      id
    }
  }
`;
