import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Grid,
  FormLabel,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useQuery } from '@apollo/client';
import moment from 'moment';

import DatePicker from '../../DatePicker/DatePicker';
import Select from '../../Select';
import Input from '../../Input';
import { INTERNAL_USERS } from '../query';
import { SingleCheckbox } from '../../Checkbox';

const FilterCustomerModal = ({ isOpen, onClose, refetch, state, dispatch }) => {
  const { data } = useQuery(INTERNAL_USERS, {
    fetchPolicy: 'cache-first',
  });

  let asigneeOptions = data?.internalUsers.map((asignee) => ({
    value: asignee.id,
    label: `${asignee.firstName} ${asignee.lastName} `,
  }));

  const findValue = (key) => {
    const whereClone = { ...state.where };
    const filtersClone = [...state.filters];

    const object = filtersClone.find((filter) => filter.key === key);
    if (object) {
      if (key === 'asignee') return whereClone[key]?.id;
      if (key === 'createdAt_lte' || key === 'createdAt_gte') return moment(whereClone[key]).toISOString();
      if (key === 'updatedAt_lte' || key === 'updatedAt_gte') return moment(whereClone[key]).toISOString();

      return object.value;
    }
    return '';
  };

  const findInSource = (key) => {
    const sourceIn = findValue('source_in') || [];
    return sourceIn?.includes(key);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Kunden filtern</ModalHeader>

        <Formik
          initialValues={{
            email: findValue('email_contains'),
            createdAt_gte: findValue('createdAt_gte'),
            createdAt_lte: findValue('createdAt_lte'),
            updatedAt_gte: findValue('updatedAt_gte'),
            updatedAt_lte: findValue('updatedAt_lte'),
            asignee: findValue('asignee'),
            contactName: findValue('contactName_contains'),
            companyName: findValue('companyName_contains'),
            api: findValue('productsOffered.api'),
            business: findValue('productsOffered.business'),
            sprint: findValue('productsOffered.sprint'),
            events: findValue('productsOffered.events'),
            LP_VAR_4: findInSource('LP_VAR_4'),
            LP_VAR_3: findInSource('LP_VAR_3'),
            LP_VAR_2: findInSource('LP_VAR_2'),
            LP_VAR_1: findInSource('LP_VAR_1'),
            HUBSPOT: findInSource('HUBSPOT'),
            FACEBOOK: findInSource('FACEBOOK'),
            LMCL: findInSource('LMCL'),
            INTERNAL: findInSource('INTERNAL'),
            IMPORT: findInSource('IMPORT'),
          }}
          onSubmit={async (values) => {
            const {
              createdAt_gte,
              createdAt_lte,
              updatedAt_gte,
              updatedAt_lte,
              asignee,
              email,
              contactName,
              companyName,
              api,
              business,
              sprint,
              events,
              LP_VAR_4,
              LP_VAR_3,
              LP_VAR_2,
              LP_VAR_1,
              HUBSPOT,
              FACEBOOK,
              LMCL,
              INTERNAL,
              IMPORT,
            } = values;

            let where = {} as any;
            let filters = [];
            if (createdAt_gte) {
              where = {
                ...where,
                createdAt_gte: moment(createdAt_gte).add(moment(createdAt_gte).utcOffset(), 'minutes').toISOString(),
              };
              filters = [...filters, { value: moment(createdAt_gte).format('DD.MM.YYYY'), label: 'Erstellt von', key: 'createdAt_gte' }];
            }
            if (createdAt_lte) {
              where = {
                ...where,
                createdAt_lte: moment(createdAt_lte).endOf('day').add(moment(createdAt_lte).utcOffset(), 'minutes').toISOString(),
              };
              filters = [...filters, { value: moment(createdAt_lte).format('DD.MM.YYYY'), label: 'Erstellt bis', key: 'createdAt_lte' }];
            }
            if (updatedAt_gte) {
              where = {
                ...where,
                updatedAt_gte: moment(updatedAt_gte).add(moment(updatedAt_gte).utcOffset(), 'minutes').toISOString(),
              };

              filters = [
                ...filters,
                { value: moment(updatedAt_gte).format('DD.MM.YYYY'), label: 'Aktualisiert vom', key: 'updatedAt_gte' },
              ];
            }
            if (updatedAt_lte) {
              where = {
                ...where,
                updatedAt_lte: moment(updatedAt_lte).endOf('day').add(moment(updatedAt_lte).utcOffset(), 'minutes').toISOString(),
              };
              filters = [
                ...filters,
                { value: moment(updatedAt_lte).format('DD.MM.YYYY'), label: 'Aktualisiert bis', key: 'updatedAt_lte' },
              ];
            }

            if (asignee === 'NotAssigned') {
              where = {
                ...where,
                asignee: null,
              };
              filters = [...filters, { value: 'ohne Zuweisung', label: 'Mitarbeiter', key: 'asignee' }];
            } else if (asignee) {
              where = {
                ...where,
                asignee: {
                  id: asignee,
                },
              };
              const asigneeObject = asigneeOptions.find(({ value }) => value === asignee);
              filters = [...filters, { value: asigneeObject?.label, label: 'Mitarbeiter', key: 'asignee' }];
            }

            if (email) {
              where = {
                ...where,
                email_contains: email,
              };
              filters = [...filters, { value: email, label: 'E-Mail', key: 'email_contains' }];
            }
            if (contactName) {
              where = {
                ...where,
                contactName_contains: contactName,
              };
              filters = [...filters, { value: contactName, label: 'Kontaktname', key: 'contactName_contains' }];
            }
            if (companyName) {
              where = {
                ...where,
                companyName_contains: companyName,
              };
              filters = [...filters, { value: companyName, label: 'Firma', key: 'companyName_contains' }];
            }
            if (api) {
              where = {
                ...where,
                productsOffered: {
                  ...where.productsOffered,
                  api,
                },
              };
              filters = [...filters, { value: api, label: 'Angebot: API', key: 'productsOffered.api' }];
            }
            if (business) {
              where = {
                ...where,
                productsOffered: {
                  ...where.productsOffered,
                  business,
                },
              };
              filters = [...filters, { value: business, label: 'Angebot: Business', key: 'productsOffered.business' }];
            }

            if (sprint) {
              where = {
                ...where,
                productsOffered: {
                  ...where.productsOffered,
                  sprint,
                },
              };
              filters = [...filters, { value: sprint, label: 'Angebot: Sprint', key: 'productsOffered.sprint' }];
            }

            if (events) {
              where = {
                ...where,
                productsOffered: {
                  ...where.productsOffered,
                  events,
                },
              };
              filters = [...filters, { value: events, label: 'Angebot: Events', key: 'productsOffered.events' }];
            }

            if (LP_VAR_1 || LP_VAR_2 || LP_VAR_3 || LP_VAR_4 || HUBSPOT || FACEBOOK || LMCL || INTERNAL || IMPORT) {
              const sourceIn = [
                LP_VAR_1 ? 'LP_VAR_1' : null,
                LP_VAR_2 ? 'LP_VAR_2' : null,
                LP_VAR_3 ? 'LP_VAR_3' : null,
                LP_VAR_4 ? 'LP_VAR_4' : null,
                HUBSPOT ? 'HUBSPOT' : null,
                FACEBOOK ? 'FACEBOOK' : null,
                LMCL ? 'LMCL' : null,
                INTERNAL ? 'INTERNAL' : null,
                IMPORT ? 'IMPORT' : null,
              ].filter(Boolean);
              where = {
                ...where,
                source_in: sourceIn,
              };
              filters = [
                ...filters,
                {
                  value: sourceIn,
                  label: 'Source gefiltert',
                  key: 'source_in',
                },
              ];
            }

            dispatch({ type: 'SET_FILTERS', payload: filters });
            dispatch({ type: 'SET_WHERE', payload: where });
            onClose();
          }}>
          {() => (
            <Form>
              <ModalBody>
                <Input name="email" label="E-Mail" />
                <Input name="contactName" label="Kontaktname" />
                <Input name="companyName" label="Unternehmensname" />
                <Select
                  options={[
                    { value: '', label: 'Mitarbeiter Finden', disabled: true },
                    { value: 'NotAssigned', label: 'ohne Zuweisung' },
                    ...asigneeOptions,
                  ]}
                  label="Mitarbeiter"
                  name="asignee"
                />

                <FormLabel mb="3">Angebotenes Produkt</FormLabel>
                <Grid gridTemplateColumns="1fr 1fr" mb="4">
                  <SingleCheckbox name="api" label="⚙️ Api " />
                  <SingleCheckbox name="business" label="💼 Business " />
                  <SingleCheckbox name="events" label="📅 Events " />
                  <SingleCheckbox name="sprint" label="📈 sprint " />
                </Grid>

                <Grid gridTemplateColumns="1fr 1fr" gridGap="24px">
                  <DatePicker name="createdAt_gte" label="Erstellt vom" />
                  <DatePicker name="createdAt_lte" label="bis" />
                </Grid>
                <Grid gridTemplateColumns="1fr 1fr" gridGap="24px">
                  <DatePicker name="updatedAt_gte" label="Aktualisiert vom" />
                  <DatePicker name="updatedAt_lte" label="bis" />
                </Grid>

                <FormLabel mb="3">Source</FormLabel>
                <Grid gridTemplateColumns="1fr 1fr" mb="4">
                  <SingleCheckbox name="LP_VAR_4" label="🍀 LP_VAR_4" />
                  <SingleCheckbox name="LP_VAR_3" label="🌴 LP_VAR_3" />
                  <SingleCheckbox name="LP_VAR_2" label="💕 LP_VAR_2" />
                  <SingleCheckbox name="LP_VAR_1" label="📓 LP_VAR_1" />
                  <SingleCheckbox name="HUBSPOT" label="🧗 HUBSPOT" />
                  <SingleCheckbox name="LMCL" label="💆 LMCL" />
                  <SingleCheckbox name="FACEBOOK" label="😤 FACEBOOK" />
                  <SingleCheckbox name="IMPORT" label="📲 IMPORT" />
                  <SingleCheckbox name="INTERNAL" label="🕦 INTERNAL" />
                </Grid>
              </ModalBody>

              <ModalFooter>
                <Button variant="ghost" size="sm" colorScheme="red" type="button" onClick={onClose}>
                  Schließen
                </Button>
                <Button ml="4" size="sm" colorScheme="brand" type="submit">
                  Speichern
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};

export default FilterCustomerModal;
