import React, { useContext } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalHeader, ModalBody, ModalFooter, Button } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';
import get from 'lodash/get';

import UserContext from '../../../contexts/UserContext';
import SpinnerContext from '../../../contexts/SpinnerContext';
import Input from '../../../components/Input';
import { NEW_HISTORY_ENTRY } from '../query';
import { useCustomToast } from '../../../hooks/useCustomToast';

const UnlockConfirmSchema = Yup.object().shape({
  comment: Yup.string().required('Du musst eine Eingabe machen, um sie speichern zu können.'),
});

const NewHistoryEntryModal = ({ isOpen, onClose, refetch, userId }) => {
  const [createEntry] = useMutation(NEW_HISTORY_ENTRY);
  const { toggleSpinner } = useContext(SpinnerContext);
  const { user } = useContext(UserContext);
  const toast = useCustomToast();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Neuer History Eintrag</ModalHeader>

        <Formik
          initialValues={{
            comment: '',
          }}
          validationSchema={UnlockConfirmSchema}
          onSubmit={async ({ comment }) => {
            toggleSpinner(true);
            try {
              await createEntry({
                variables: {
                  userId,
                  comment,
                  internalUser: user.id,
                },
              });
              await refetch();
              toast({
                title: 'Erfolg!',
                description: 'Der History Eintrag wurde erfolgreich erstellt.',
                status: 'success',
              });
              onClose();
            } catch (error) {
              console.log(error);
              toast({
                description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
                status: 'error',
              });
            }
            toggleSpinner(false);
          }}>
          <Form>
            <ModalBody>
              <Input name="comment" label="Kommentar" />
            </ModalBody>

            <ModalFooter>
              <Button variant="ghost" size="sm" colorScheme="red" type="button" onClick={onClose}>
                Abbrechen
              </Button>
              <Button ml="4" size="sm" colorScheme="brand" type="submit">
                Speichern
              </Button>
            </ModalFooter>
          </Form>
        </Formik>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};

export default NewHistoryEntryModal;
