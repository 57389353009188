import gql from 'graphql-tag';

export const INTERNAL_CUSTOMER = gql`
  query INTERNAL_CUSTOMER($customerId: ID, $userId: ID) {
    internalCustomer(where: { id: $customerId }) {
      id
      incrementId
      status
      source
      leadStatus
      companyName
      contactName
      phone
      phoneNumbers
      email
      notifications(where: { notified_not: true }, first: 100, orderBy: createdAt_DESC) {
        id
        title
        body
        reminderAt
      }
      address {
        street
        houseNumber
        city
        postalCode
        geometry {
          coordinates
        }
      }
      asignee {
        id
        firstName
        lastName
        photo
        createdAt
      }
      comments: notes(where: { type: COMMENT }, orderBy: createdAt_DESC) {
        id
        message
        connectedFiles {
          uri
          name
          type
        }
        commenter {
          id
          firstName
          lastName
          createdAt
          photo
        }
        createdAt
        type
      }
      communications: notes(where: { type: COMMUNICATION }, orderBy: createdAt_DESC) {
        id
        message
        connectedFiles {
          uri
          name
          type
        }
        commenter {
          id
          firstName
          lastName
          createdAt
          photo
        }
        createdAt
        type
      }
      emails(orderBy: createdAt_DESC) {
        id
        subject
        content

        sender {
          id
          firstName
          lastName
          photo
        }
        createdAt
        connectedFiles {
          uri
          name
          type
        }
      }
      history(orderBy: createdAt_DESC) {
        id
        type
        comment
        changedBy {
          id
          firstName
          lastName
          photo
        }
        connectedFiles {
          uri
          name
          type
        }
        createdAt
      }
      xing
      linkedin
      website
      facebook
      twitter
      instagram
      contactProfession
      branchAmount
      contactMethod
      businessType
      productOffered
      productsOffered {
        api
        business
        events
        sprint
      }
    }
    internalUser(where: { id: $userId }) {
      id
      phoneNumber
    }
  }
`;

export const DELETE_INTERNAL_CUSTOMER = gql`
  mutation DELETE_INTERNAL_CUSTOMER($where: InternalCustomerWhereUniqueInput!) {
    deleteInternalCustomer(where: $where) {
      id
    }
  }
`;
export const UPDATE_INTERNAL_CUSTOMER = gql`
  mutation UPDATE_INTERNAL_CUSTOMER($data: InternalCustomerUpdateInput!, $where: InternalCustomerWhereUniqueInput!) {
    updateInternalCustomer(data: $data, where: $where) {
      id
    }
  }
`;

export const INTERNAL_USERS = gql`
  query INTERNAL_USERS($where: InternalUserWhereInput, $orderBy: InternalUserOrderByInput, $first: Int, $skip: Int) {
    internalUsers(where: $where, orderBy: $orderBy, first: $first, skip: $skip) {
      id
      email
      firstName
      lastName
      photo
    }
  }
`;

export const CREATE_INTERNAL_CUSTOMER = gql`
  mutation CREATE_INTERNAL_CUSTOMER($data: InternalCustomerCreateInput!) {
    createInternalCustomer(data: $data) {
      id
    }
  }
`;

export const SCHEDULE_REMINDER = gql`
  mutation SCHEDULE_REMINDER($data: InternalNotificationCreateInput!, $sendNow: Boolean) {
    scheduleNotification(data: $data, sendNow: $sendNow) {
      id
    }
  }
`;

export const SEND_CUSTOMER_EMAIL = gql`
  mutation SEND_CUSTOMER_EMAIL($data: InternalEmailCreateInput!, $sendNumber: Boolean) {
    sendCustomerEmail(data: $data, sendNumber: $sendNumber) {
      id
    }
  }
`;

export const CHECK_ADDRESS = gql`
  mutation CHECK_ADDRESS($latlng: String, $address: String) {
    checkAddress(latlng: $latlng, address: $address) {
      address
    }
  }
`;
