import React from 'react';
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  Flex,
  Text,
} from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { Formik, Form } from 'formik';
import { HiCloudUpload } from 'react-icons/hi';
import get from 'lodash/get';

import { uploadToS3 } from '../../../utils';
import { useUser } from '../../../contexts/UserContext';
import { useSpinner } from '../../../contexts/SpinnerContext';
import { useCustomToast } from '../../../hooks/useCustomToast';
import moment from 'moment';
import { HUBSPOT_IMPORT } from '../query';

export const UploadCsv = ({ refetch }) => {
  const { checkAccess } = useUser();
  const { toggleSpinner } = useSpinner();
  const toast = useCustomToast();
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [importCSV] = useMutation(HUBSPOT_IMPORT);

  if (!checkAccess('HUBSPOT_IMPORT_CSV')) return null;

  return (
    <>
      <Button size="sm" colorScheme="gray" onClick={onOpen} leftIcon={<HiCloudUpload />}>
        Hubspot CSV importieren
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>HubSpot CSV importieren</ModalHeader>

          <Formik
            initialValues={{
              fileUpload: null,
              fileUploadBlob: null,
            }}
            onSubmit={async ({ fileUpload, fileUploadBlob }) => {
              try {
                toggleSpinner(true);

                if (!fileUpload || !fileUploadBlob) {
                  toast({
                    description: 'Ein Fehler ist aufgetreten, versuche es erneut',
                    status: 'error',
                  });
                  toggleSpinner(false);
                  onClose();
                  return;
                }

                const uri = await uploadToS3(fileUploadBlob, `csv/${moment().unix()}`);

                await importCSV({ variables: { uri } });

                await refetch();

                toast({
                  title: 'Erfolg!',
                  description: 'CSV erfolgreich importiert',
                  status: 'success',
                });
                toggleSpinner(false);
                onClose();
              } catch (error) {
                console.log(error);
                toast({
                  description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
                  status: 'error',
                });
                toggleSpinner(false);
              }
            }}>
            {({ values, setFieldValue }) => (
              <Form>
                <ModalBody>
                  <Text mb="4">Verwende bitte ein original Export von Hubspot ohne Modifikationen.</Text>
                  <Box>
                    <Button
                      type="button"
                      size="sm"
                      onClick={() => {
                        fileInputRef && fileInputRef.current && fileInputRef.current?.click();
                      }}>
                      Datei hochladen
                    </Button>
                    <input
                      ref={fileInputRef}
                      hidden={true}
                      type="file"
                      name="fileUpload"
                      onChange={(event) => {
                        event.persist();
                        let reader = new FileReader();
                        let file = event.target.files[0];
                        reader.onloadend = () => {
                          setFieldValue('fileUpload', reader.result);
                          setFieldValue('fileUploadBlob', event.target.files[0]);
                        };
                        reader.readAsDataURL(file);
                      }}
                    />
                  </Box>
                  {values.fileUploadBlob ? (
                    <Flex direction="column" my="4">
                      <Flex mb="2">
                        <Text flex="1" fontSize="sm">
                          {values.fileUploadBlob.name}
                        </Text>
                        <Button
                          size="xs"
                          variant="ghost"
                          colorScheme="red"
                          onClick={() => {
                            setFieldValue('fileUpload', null);
                            setFieldValue('fileUploadBlob', null);
                          }}>
                          Entfernen
                        </Button>
                      </Flex>
                    </Flex>
                  ) : null}
                </ModalBody>

                <ModalFooter>
                  <Button variant="ghost" size="sm" colorScheme="red" type="button" onClick={onClose}>
                    Schließen
                  </Button>
                  <Button ml="4" size="sm" colorScheme="brand" type="submit">
                    Speichern
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </>
  );
};
