import React from 'react';
import { Text, Grid, Button, Box, Icon } from '@chakra-ui/react';
import { FieldArray, useFormikContext } from 'formik';
import get from 'lodash/get';
import moment from 'moment';
import TimeRangePicker from '@wojtekmaj/react-timerange-picker';

const DeliveryTimes = () => {
  const { values, errors, touched, setFieldValue } = useFormikContext<any>();

  return (
    <>
      <Text fontWeight={500}>Deine Liefer- bzw. Abholzeiten</Text>
      <FieldArray name="deliveryTimes">
        {({ remove, push }) => {
          return (
            <React.Fragment>
              {values.deliveryTimes.map((times, index) => {
                const hasFromError = get(errors, `deliveryTimes.${index}.from`, false);
                const hasFromTouched = get(touched, `deliveryTimes.${index}.from`, false);
                const hasToError = get(errors, `deliveryTimes.${index}.to`, false);
                const hasToTouched = get(touched, `deliveryTimes.${index}.to`, false);

                const value = [times.from ? moment(times.from).format('HH:mm') : '', times.to ? moment(times.to).format('HH:mm') : ''];

                const handleChange = (value) => {
                  if (value[0]) {
                    setFieldValue(`deliveryTimes.${index}.from`, moment(value[0], 'HH:mm'));
                  }
                  if (value[1]) {
                    setFieldValue(`deliveryTimes.${index}.to`, moment(value[1], 'HH:mm'));
                  }
                };

                return (
                  <Box key={'deliveryTimes' + index}>
                    <Grid templateColumns="1fr 16px" mb="4" alignItems="center" gridGap="2">
                      <TimeRangePicker clearIcon={null} onChange={handleChange} value={value} disableClock={true} locale="de-DE" />
                      {index !== 0 ? <Icon name="delete" onClick={() => remove(index)} color="red" /> : null}
                    </Grid>
                    {(hasFromError || hasToError) && hasFromTouched && hasToTouched ? (
                      <Text color="red" fontSize="sm">
                        Sie müssen eine Start- und Endzeit angeben.
                      </Text>
                    ) : null}
                  </Box>
                );
              })}
              <Text fontSize="sm" mb="3">
                Du kannst mehrere Lieferzeiträume pro Tag angeben.
              </Text>
              <Button
                isDisabled={
                  !values.deliveryTimes[values.deliveryTimes.length - 1].from || !values.deliveryTimes[values.deliveryTimes.length - 1].to
                }
                mb="3"
                variant="outline"
                onClick={() =>
                  push({
                    from: '',
                    to: '',
                  })
                }>
                Zeit hinzufügen
              </Button>
            </React.Fragment>
          );
        }}
      </FieldArray>
    </>
  );
};

export default DeliveryTimes;
