import React, { useContext } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalHeader, ModalBody, ModalFooter, Button } from '@chakra-ui/react';
import { useMutation, useQuery } from '@apollo/client';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import get from 'lodash/get';

import Input from '../../Input';
import Select from '../../Select';
import SpinnerContext from '../../../contexts/SpinnerContext';
import UserContext from '../../../contexts/UserContext';
import { useCustomToast } from '../../../hooks/useCustomToast';
import { INTERNAL_USERS, UPDATE_INTERNAL_CUSTOMER } from '../query';

const ChangeAsigneeInternalCustomerSchema = Yup.object().shape({
  message: Yup.string().required('Du musst einen Kommentar hinterlegen'),
});

const ChangeAsigneeModal = ({ isOpen, onClose, customerId, asigneeId, refetch: parentRefetch }) => {
  const [updateInternalCustomer] = useMutation(UPDATE_INTERNAL_CUSTOMER);
  const { toggleSpinner } = useContext(SpinnerContext);
  const { user } = useContext(UserContext);
  const toast = useCustomToast();
  const { data, refetch } = useQuery(INTERNAL_USERS, {
    fetchPolicy: 'cache-first',
  });

  if (!data?.internalUsers) return null;

  let asigneeOptions = data?.internalUsers.map((asignee) => ({ value: asignee.id, label: `${asignee.firstName} ${asignee.lastName}` }));

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Mitarbeiter zuweisen</ModalHeader>

        <Formik
          initialValues={{
            asignee: asigneeId || '',
            message: '',
          }}
          validationSchema={ChangeAsigneeInternalCustomerSchema}
          onSubmit={async ({ asignee, message }) => {
            try {
              toggleSpinner(true);
              const asigneeOption = asigneeOptions.find(({ value }) => value === asignee);

              await updateInternalCustomer({
                variables: {
                  where: {
                    id: customerId,
                  },
                  data: {
                    updatedAtRandomizer: Math.random().toString(),
                    notes: {
                      create: [
                        {
                          message: `Lead zugewiesen an ${asigneeOption.label}
                          ${message}
                          `,
                          type: 'COMMENT',
                          commenter: {
                            connect: { id: user.id },
                          },
                        },
                      ],
                    },
                    asignee: {
                      connect: {
                        id: asignee,
                      },
                    },
                  },
                },
              });

              await refetch();
              await parentRefetch();

              toast({
                title: 'Erfolg!',
                description: 'Mitarbeiter zugewiesen',
                status: 'success',
                isClosable: false,
                duration: 3000,
              });
              toggleSpinner(false);
              onClose();
            } catch (error) {
              console.log(error);
              toast({
                description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
                status: 'error',
              });
              toggleSpinner(false);
            }
          }}>
          {() => (
            <Form>
              <ModalBody>
                <Select
                  options={[{ value: '', label: 'Mitarbeiter auswählen', disabled: true }, ...asigneeOptions]}
                  label="Mitarbeiter"
                  name="asignee"
                />

                <Input
                  label="Kommentar"
                  as="textarea"
                  type="text"
                  name="message"
                  height="100px"
                  placeholder="Gib hier Deine Beschreibung ein"
                />
              </ModalBody>

              <ModalFooter>
                <Button variant="ghost" size="sm" colorScheme="red" type="button" onClick={onClose}>
                  Schließen
                </Button>
                <Button ml="4" size="sm" colorScheme="brand" type="submit">
                  Speichern
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};

export default ChangeAsigneeModal;
