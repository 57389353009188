import React from 'react';
import { createRoot } from 'react-dom/client';
import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import { ApolloProvider } from '@apollo/client';

import moment from 'moment';
// @ts-ignore
import localization from 'moment/locale/de';

import 'react-datepicker/dist/react-datepicker.css';

import { UserProvider } from './contexts/UserContext';
import * as pushWorker from './pushWorker';
import customTheme from './theme';
import { client } from './client';
import App from './App';
import { SpinnerContextProvider } from './contexts/SpinnerContext';
import { GlobalSpinner } from './components/GlobalSpinner';
import './index.css';

moment.locale('de');
moment.updateLocale('de', localization);

const Index = () => {
  return (
    <SpinnerContextProvider>
      <ApolloProvider client={client}>
        <UserProvider>
          <ChakraProvider theme={customTheme}>
            <CSSReset />
            <App />
            <GlobalSpinner />
          </ChakraProvider>
        </UserProvider>
      </ApolloProvider>
    </SpinnerContextProvider>
  );
};

// ReactDOM.render(<Index />, document.getElementById('root'));
const root = createRoot(document.getElementById('root')); // createRoot(container!) if you use TypeScript
root.render(<Index />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
pushWorker.register();
