import gql from 'graphql-tag';

export const TOTAL_USERS = gql`
  query {
    totalUsers {
      total
      totalToday
      totalThisWeek
    }
    totalDropPoints {
      total
      totalToday
      totalThisWeek
    }
    totalActiveDropPoints {
      total
      totalToday
      totalThisWeek
    }
    totalAdvertisements {
      total
      totalToday
      totalThisWeek
    }
    totalActiveAdvertisements {
      total
      totalToday
      totalThisWeek
    }
    totalInactiveAdvertisements {
      total
      totalToday
      totalThisWeek
    }
    totalOrders
    totalOpenOrders
    totalOrdersCurrentMonth
    totalOrdersCurrentYear

    averageEssentialsLeads
    totalInternalEssentialsInvestors {
      total
      totalToday
      totalThisWeek
    }
    totalInternalEssentialsLeads {
      total
      totalToday
      totalThisWeek
    }
    totalEssentialsAccounts {
      total
      totalToday
      totalThisWeek
    }
    totalStarter: totalEssentialsAccounts(where: { subscription: { priceClass: "starter" } }) {
      total
      totalToday
      totalThisWeek
    }
    totalBasic: totalEssentialsAccounts(where: { subscription: { priceClass: "basic" } }) {
      total
      totalToday
      totalThisWeek
    }
    totalProfessional: totalEssentialsAccounts(where: { subscription: { priceClass: "professional" } }) {
      total
      totalToday
      totalThisWeek
    }
    totalPremium: totalEssentialsAccounts(where: { subscription: { priceClass: "premium" } }) {
      total
      totalToday
      totalThisWeek
    }
  }
`;

export const DELETE_ALL_RETOURES = gql`
  mutation DELETE_ALL_RETOURES {
    deleteAllRetoures
  }
`;
