import React, { useContext, useState, useRef } from 'react';
import { useMutation } from '@apollo/client';
import {
  Text,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import get from 'lodash/get';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import UserContext from '../../../contexts/UserContext';
import SpinnerContext from '../../../contexts/SpinnerContext';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import { SEND_EMAIL_TO_USER } from '../query';
import { useCustomToast } from '../../../hooks/useCustomToast';

const SendEmailSchema = Yup.object().shape({
  templateId: Yup.string().required('Du musst eine Systememail auswählen.'),
  comment: Yup.string().required('Warum möchtest Du die Änderung durchführen?'),
});

const templateOptions = [
  { label: 'Template auswählen', value: '', disabled: true },
  { label: 'Konto-Freischaltung erfolgreich', value: 8 },
  { label: 'Handlungsbedarf | DropFriends-Auszahlung', value: 9 },
  { label: 'Neues Bankkonto zur Auszahlung hinterlegt', value: 10 },
  { label: 'Passwort vergessen?', value: 14 },
  { label: 'Einschränkung Deines DropFriends-Kontos', value: 16 },
  { label: 'Bestätige Deine E-Mail-Adresse', value: 23 },
  { label: 'DropPoint aktivieren & Geld verdienen!', value: 35 },
  { label: 'Profilfoto updaten!', value: 39 },
];

const SendEmail = ({ user: { id, firstName, lastName, email }, refetch }) => {
  const [alertOpen, setAlertOpen] = useState(false);
  const onAlertClose = () => setAlertOpen(false);
  const cancelRef = useRef();

  const { user: internalUser } = useContext(UserContext);
  const { toggleSpinner } = useContext(SpinnerContext);
  const [sendEmailToUserMutation] = useMutation(SEND_EMAIL_TO_USER);
  const toast = useCustomToast();

  const handleSendEmail = async ({ comment, templateId }) => {
    try {
      toggleSpinner(true);
      // eslint-disable-next-line
      const template = templateOptions.find(({ value }) => value == templateId);
      const {
        data: { sendEmailToUser },
      } = await sendEmailToUserMutation({
        variables: {
          userId: id,
          internalUser: internalUser.id,
          comment,
          templateId,
          templateName: template && template.label,
        },
      });
      await refetch();
      toast({
        title: 'Erfolg!',
        description: sendEmailToUser || 'Die Email wurde versandt.',
        status: 'success',
      });
      setAlertOpen(false);
    } catch (error) {
      console.log(error);
      toast({
        description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
        status: 'error',
      });
    }
    toggleSpinner(false);
  };

  return (
    <>
      <Button variant="outline" colorScheme="brand" size="xs" mr="2" mb="2" onClick={() => setAlertOpen(true)}>
        Email senden
      </Button>

      <AlertDialog isOpen={alertOpen} leastDestructiveRef={cancelRef} onClose={onAlertClose}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <Formik initialValues={{ comment: '', templateId: '' }} validationSchema={SendEmailSchema} onSubmit={handleSendEmail}>
            <Form>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Welche Systemmail möchtest Du versenden?
              </AlertDialogHeader>

              <AlertDialogBody>
                <Text mb="4">
                  Wähle die Systemmail, die Du an {firstName} {lastName}, {email} versenden möchtest:
                </Text>
                <Select name="templateId" label="Systememail" options={templateOptions} />
                <Input name="comment" label="Kommentar" />
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onAlertClose}>
                  Abbrechen
                </Button>
                <Button colorScheme="green" type="submit" ml={3}>
                  Email senden
                </Button>
              </AlertDialogFooter>
            </Form>
          </Formik>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default SendEmail;
